import { action, makeObservable, observable, set, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  DefaultApi as masterDataApi,
  KvintaAlarmStatus,
  KvintaDelivery,
  KvintaDocumentKey,
  KvintaGs1DocumentType,
  KvintaSortDirection,
  KvintaSortExpression,
} from 'kvinta/apis/kvinta-masterdata';
import { DefaultApi as epcisCaptureApi } from 'kvinta/apis/kvinta-epcis-capture';
import { deliveryItemFilterIds, getInternalDocumentKey, normalizeFilters } from 'kvinta/apis/utils';
import { EFilterType, IFilter } from '../../../components/FilterBar/FilterBar';
import { downloadFile } from '../../../service/fileExport';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, select, textField, textInput } from '../../../common/formUtils/formDataGenerators';
import { validateForm } from '../../../common/formUtils/core';
import { isNotEmpty, maxLength } from '../../../common/formUtils/validators';
import { TForm, TSelectInputData, TTextInputData } from '../../../common/formUtils/types';

export type TDeliveryNoteFormData = {
  'deliveryNoteForm.docType': TSelectInputData;
  'deliveryNoteForm.docLocation': TTextInputData;
  'deliveryNoteForm.docNumber': TTextInputData;
  'deliveryNoteForm.refType': TSelectInputData;
  'deliveryNoteForm.refLocation': TTextInputData;
  'deliveryNoteForm.refNumber': TTextInputData;
  'deliveryNoteForm.shipFrom': TTextInputData;
  'deliveryNoteForm.shipTo': TTextInputData;
  'deliveryNoteForm.soldFrom': TTextInputData;
  'deliveryNoteForm.soldTo': TTextInputData;
  'deliveryNoteForm.alarmStatus': TTextInputData;
};

export enum EGs1DocType {
  Inbound = 'urn:epcglobal:cbv:btt:recadv',
  Outbound = 'urn:epcglobal:cbv:btt:desadv',
  Return = 'urn:epcglobal:cbv:btt:rma',
  PurchaseOrder = 'urn:epcglobal:cbv:btt:po',
  ProductionOrder = 'urn:epcglobal:cbv:btt:prodorder',
}

const allowedDeliveryNoteDocTypes = [EGs1DocType.Inbound, EGs1DocType.Outbound, EGs1DocType.Return];

export type TDeliveryNote = {
  docType: EGs1DocType;
  docLocation: string;
  docNumber: string;
  refType: EGs1DocType;
  refLocation: string;
  refNumber: string;
  shipFrom: string;
  shipTo: string;
  soldFrom: string;
  soldTo: string;
  alarmStatus: KvintaAlarmStatus;
};

interface IDeliveryNoteRow extends KvintaDelivery, ISelectableRow {}

export class DeliveryNotesStore extends SelectableStore<IDeliveryNoteRow> {
  private _config: TAppOptionsConfig;
  private _masterDataApi: masterDataApi;
  private _epcisCaptureApi: epcisCaptureApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  filter: IFilter;
  currentSort: KvintaSortExpression;
  deliveryNoteFormOpen: boolean;
  deliveryNoteFormData?: TDeliveryNoteFormData;
  currentDeliveryNote?: TDeliveryNote;

  exportActive: boolean;
  exportData: IDeliveryNoteRow[] | KvintaDelivery[] | undefined;
  autofocusSearchInList: boolean;

  searchValue: string;

  constructor(config: TAppOptionsConfig, apis) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchDeliveryNoteList: action.bound,
      unfocusSearchField: action.bound,
      fetchDeliveryNote: action.bound,
      submitDeliveryNote: action.bound,
      submitCreateDeliveryNote: action.bound,
      submitUpdateDeliveryNote: action.bound,
      closeDeliveryNoteForm: action.bound,
      openCreateDeliveryNoteForm: action.bound,
      openUpdateDeliveryNoteForm: action.bound,
      onChangeDeliveryNoteFormField: action.bound,
      onBlurDeliveryNoteFormField: action.bound,
      checkConsistency: action.bound,
      exportAll: action.bound,
      exportOne: action.bound,
      deleteDeliveryNote: action.bound,
      isLoading: observable,
      filter: observable,
      page: observable,
      pageSize: observable,
      deliveryNoteFormOpen: observable,
      deliveryNoteFormData: observable,
      currentDeliveryNote: observable,
      searchValue: observable,
      exportActive: observable,
    });

    this._config = config;
    this._masterDataApi = apis.masterDataApi;
    this._epcisCaptureApi = apis.epcisCaptureApi;
    this._notificationManager = apis.notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.autofocusSearchInList = false;

    this.currentSort = {
      direction: KvintaSortDirection.Desc,
      property: 'id',
      ignoreCase: false,
    };
    this.filter = new IFilter(
      [
        {
          type: EFilterType.TEXT_FILTER,
          id: 'documentNumber',
          label: 'delivery-note-form.field.document.number',
          isActive: true,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'deliveryId',
          label: 'delivery-note-form.field.id',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'location',
          label: 'delivery-note-form.field.document.location',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.SELECT_FILTER,
          id: 'type',
          label: 'delivery-note-form.field.document.type',
          isActive: false,
          value: 'default',
          options: allowedDeliveryNoteDocTypes.map((docType) => ({ key: docType, label: docType })),
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'referenceDocumentNumber',
          label: 'delivery-note-form.field.referenceDocument.number',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.MULITPLE_SELECT_FILTER,
          id: 'alarmStatus',
          label: 'delivery-note-form.field.alarmStatus',
          isActive: false,
          options: Object.values(KvintaAlarmStatus).map((alarmStatus) => ({ key: alarmStatus, label: alarmStatus })),
          value: [],
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'itemProductCode',
          label: 'delivery-note-form.field.itemProductCode',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'itemBatchNumber',
          label: 'delivery-note-form.field.itemBatchNumber',
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.DATE_RANGE_FILTER,
          id: 'itemDateExpected',
          label: 'delivery-note-form.field.itemDateExpected',
          isActive: false,
          value: { from: '', to: '' },
        },
        {
          type: EFilterType.DATE_RANGE_FILTER,
          id: 'itemDateConfirmed',
          label: 'delivery-note-form.field.itemDateConfirmed',
          isActive: false,
          value: { from: '', to: '' },
        },
      ],
      this.doFilter,
    );
  }

  doFilter = async (): Promise<void> => {
    this.isLoading = true;
    this.page = 0;

    return this.fetchDeliveryNoteList().finally(() => {
      this.isLoading = false;
    });
  };

  async fetchPage(page: number): Promise<void> {
    this.isLoading = true;
    this.page = page;

    return this.fetchDeliveryNoteList().finally(() => {
      this.isLoading = false;
    });
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc'): Promise<void> {
    this.isLoading = true;
    const field = getSortingField(orderBy);
    this.currentSort = {
      property: field,
      direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
      ignoreCase: false,
    };
    return this.fetchDeliveryNoteList().finally(() => {
      this.isLoading = false;
    });
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  fetchDeliveryNoteList = (): Promise<void> => {
    this.listData = [];
    this.totalCount = 0;

    const normalizedDeliveryNoteFilters = normalizeFilters(
      this.filter.filters.filter((filter) => !deliveryItemFilterIds.includes(filter.id)),
    );
    const normalizedDeliveryItemFilters = normalizeFilters(
      this.filter.filters.filter((filter) => deliveryItemFilterIds.includes(filter.id)),
    );
    return this._masterDataApi
      .queryDeliveries({
        kvintaQueryDeliveryRequest: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: { expressions: [this.currentSort] },
          },
          delivery: normalizedDeliveryNoteFilters,
          deliveryItem: normalizedDeliveryItemFilters,
        },
      })
      .then((result) => {
        const oldSelection = new Map();
        if (this.listData) {
          this.listData.forEach((row) => {
            oldSelection.set(row.id, row.isSelected);
          });
        }
        this.totalCount = result.total || 0;
        this.listData = (result.list || []).map((comp) => {
          const selected = oldSelection.get(comp.document.number) || false;
          return {
            ...comp,
            id: comp.document.number,
            isSelected: selected,
          } as IDeliveryNoteRow;
        });
      })
      .catch((err) => {
        this.listData = [];
        this._notificationManager.sendError(JSON.stringify(err));
      });
  };

  openCreateDeliveryNoteForm = () => {
    const initialValues = {
      docType: '' as EGs1DocType,
      docLocation: '',
      docNumber: '',
      refType: '' as EGs1DocType,
      refLocation: '',
      refNumber: '',
      shipFrom: '',
      shipTo: '',
      soldFrom: '',
      soldTo: '',
      alarmStatus: KvintaAlarmStatus.Unknown,
    };
    this.deliveryNoteFormData = validateForm<TDeliveryNoteFormData>(
      generateDeliveryNoteFormData(DELIVERY_NOTE_FORM_ROOT_ID, initialValues),
    );
    this.deliveryNoteFormOpen = true;
  };

  checkConsistency(): Promise<void> {
    this.isLoading = true;
    return this._epcisCaptureApi
      .checkConsistencyByDeliveryV2({
        kvintaDocumentKey: toKvintaDocument(this.currentDeliveryNote) as any,
      })
      .then((result) => {
        console.log('checkConsistency result', result);
        set(this.currentDeliveryNote, 'alarmStatus', result.alarmStatus);
      })
      .catch((e) => {
        console.log('checkConsistency e', e);
        this._notificationManager.sendError(`Could not retrieve Status${e.message ? `: ${e.message}` : ''}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeDeliveryNoteForm() {
    this.deliveryNoteFormOpen = false;
    this.deliveryNoteFormData = undefined;
  }

  submitDeliveryNote(nextAction: () => void): Promise<void> {
    this.deliveryNoteFormOpen = false;
    const submitData = transformDeliveryNoteFormData(this.deliveryNoteFormData);
    return this._masterDataApi
      .mutateDelivery({
        kvintaMutateRequestDeliveryDocumentKey: {
          upsert: [submitData],
        },
      })
      .then((response) => {
        if (response.delivery.errors && response.delivery.errors.length) {
          this._notificationManager.sendError(
            `An error occurred while submitting data\n${response.delivery.errors.join('\n')}`,
          );
        } else {
          this._notificationManager.sendSuccess(`Successfully submitted ${submitData.document.number}`);
        }
        nextAction();
        this.closeDeliveryNoteForm();
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.toString()}`);
      });
  }

  submitCreateDeliveryNote = (): Promise<void> => {
    this.isLoading = true;
    return this.submitDeliveryNote(this.fetchDeliveryNoteList).finally(() => (this.isLoading = false));
  };

  openUpdateDeliveryNoteForm = () => {
    const initialValues = {
      docType: this.currentDeliveryNote.docType,
      docLocation: this.currentDeliveryNote.docLocation,
      docNumber: this.currentDeliveryNote.docNumber,
      refType: this.currentDeliveryNote.refType,
      refLocation: this.currentDeliveryNote.refLocation,
      refNumber: this.currentDeliveryNote.refNumber,
      shipFrom: this.currentDeliveryNote.shipFrom,
      shipTo: this.currentDeliveryNote.shipTo,
      soldFrom: this.currentDeliveryNote.soldFrom,
      soldTo: this.currentDeliveryNote.soldTo,
      alarmStatus: this.currentDeliveryNote.alarmStatus || KvintaAlarmStatus.Unknown,
    };

    this.deliveryNoteFormData = validateForm<TDeliveryNoteFormData>(
      generateDeliveryNoteFormData(DELIVERY_NOTE_FORM_ROOT_ID, initialValues),
    );
    this.deliveryNoteFormOpen = true;
  };

  onChangeDeliveryNoteFormField(id: string, value: any) {
    const formData = toJS(this.deliveryNoteFormData);
    this.deliveryNoteFormData = handleFormChange(formData, id, value);
  }

  onBlurDeliveryNoteFormField(id: string) {
    const formData = toJS(this.deliveryNoteFormData);
    this.deliveryNoteFormData = handleFormBlur(formData, id);
  }

  submitUpdateDeliveryNote(): Promise<void> {
    this.isLoading = true;
    return this.submitDeliveryNote(() =>
      this.fetchDeliveryNote(getInternalDocumentKey(toKvintaDocument(this.currentDeliveryNote))),
    ).finally(() => (this.isLoading = false));
  }

  deleteDeliveryNote(document: KvintaDocumentKey): Promise<void> {
    this.isLoading = true;
    return this._masterDataApi
      .mutateDelivery({
        kvintaMutateRequestDeliveryDocumentKey: {
          _delete: [document],
        },
      })
      .then((response) => {
        if (response.delivery.errors && response.delivery.errors.length) {
          this._notificationManager.sendError(`${response.delivery.errors.join('\n')}`);
        } else {
          this.fetchDeliveryNoteList();
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while trying ot delete\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  updateExported() {
    this.exportData = undefined;
  }

  exportOne() {
    this.isLoading = true;
    const deliveryData = toKvintaDocument(this.currentDeliveryNote);
    return this._masterDataApi
      .exportDeliveriesAsCsv({
        kvintaDocumentKey: [deliveryData],
      })
      .then((result) => {
        downloadFile(`delivery_${getInternalDocumentKey(deliveryData)}.csv`, 'text/csv', result);
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  exportAll() {
    this.isLoading = true;

    const normalizedDeliveryNoteFilters = normalizeFilters(
      this.filter.filters.filter((filter) => !deliveryItemFilterIds.includes(filter.id)),
    );
    const normalizedDeliveryItemFilters = normalizeFilters(
      this.filter.filters.filter((filter) => deliveryItemFilterIds.includes(filter.id)),
    );

    return this._masterDataApi
      .queryDeliveries({
        kvintaQueryDeliveryRequest: {
          paging: {
            page: 0,
            size: 100,
          },
          delivery: normalizedDeliveryNoteFilters,
          deliveryItem: normalizedDeliveryItemFilters,
        },
      })
      .then((result) => {
        if (result.total > 100) {
          alert('Export only possible for a maximum of 100 deliveries at a time');
        }
        return this._masterDataApi
          .exportDeliveriesAsCsv({
            kvintaDocumentKey: result.list.map((delivery) => delivery.document),
          })
          .then((result) => {
            downloadFile('deliveries.csv', 'text/csv', result);
          })
          .catch((err) => {
            this._notificationManager.sendError(JSON.stringify(err));
          });
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err.message));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  fetchDeliveryNote = (id: string): Promise<void> => {
    this.isLoading = true;
    this.currentDeliveryNote = null;

    return this._masterDataApi
      .queryDeliveries({
        kvintaQueryDeliveryRequest: {
          delivery: {
            deliveryId: id,
          },
          fetchItems: false,
          paging: {
            page: 0,
            size: 1,
          },
        },
      })
      .then((deliveryNoteResult) => {
        if (!(deliveryNoteResult.list && deliveryNoteResult.list.length)) {
          this._notificationManager.sendError(`Could not find delivery ${id}`);
        } else {
          const currentDeliveryNote = deliveryNoteResult.list[0];
          this.currentDeliveryNote = convertDeliveryNoteToInternalForm(currentDeliveryNote);
        }
      })
      .catch((e) => {
        this._notificationManager.sendError(`Could not fetch delivery${e.message && `: ${e.message}`}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}

export const DELIVERY_NOTE_STORE_ID = 'deliveryNotesStore';
export const DELIVERY_NOTE_FORM_ROOT_ID = 'deliveryNoteForm';

function getSortingField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'documentType';
    case 2:
      return 'documentLocation';
    case 3:
      return 'documentNumber';
    case 4:
      return 'referenceDocType';
    case 5:
      return 'referenceDocLoc';
    case 6:
      return 'referenceDocNum';
    case 7:
      return 'alarmStatus';
    default:
      return 'documentType';
  }
}

const requiredFields = ['docNumber', 'docLocation', 'docType'];

export function errorRequired(id: string, value: any): boolean {
  return requiredFields.includes(id) && (value === undefined || value === '');
}

const requiredDeliveryItemsFields = [
  'itemNumber',
  'productCode',
  'batchNumber',
  'qtyExpected',
  'qtyConfirmed',
  'dateExpected',
  'dateConfirmed',
];

export function errorRequiredDeliveryItems(id: string, value: any): boolean {
  return requiredDeliveryItemsFields.includes(id) && (value === undefined || value === '' || value === 0);
}

function normalizeValue<T extends string>(value: T): undefined | T {
  return value === '' ? undefined : value;
}

export function toKvintaDocument(data: TDeliveryNote): KvintaDocumentKey {
  return {
    type: data.docType as any,
    location: data.docLocation,
    number: data.docNumber,
  };
}

export function fromKvintaDocument(data: KvintaDocumentKey): TDeliveryNote {
  return {
    docType: data.type,
    docLocation: data.location,
    docNumber: data.number,
  } as any;
}

function generateDeliveryNoteFormData(rootFormId: string, initialValues: TDeliveryNote) {
  return formRoot<TDeliveryNoteFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      select({
        path: 'docType',
        value: initialValues.docType,
        options: allowedDeliveryNoteDocTypes.map((docType) => ({ key: docType, label: docType })),
        validations: [isNotEmpty({ errorMessage: 'this value is mandatory' })],
        isRequiredField: true,
      }),
      textInput({
        path: 'docLocation',
        value: initialValues.docLocation,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'docNumber',
        value: initialValues.docNumber,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      select({
        path: 'refType',
        value: initialValues.refType,
        options: Object.values(EGs1DocType).map((docType) => ({ key: docType, label: docType })),
        validations: [isNotEmpty({ errorMessage: 'this value is mandatory' })],
        isRequiredField: false,
      }),
      textInput({
        path: 'refLocation',
        value: initialValues.refLocation,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'refNumber',
        value: initialValues.refNumber,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'shipFrom',
        value: initialValues.shipFrom,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'shipTo',
        value: initialValues.shipTo,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'soldFrom',
        value: initialValues.soldFrom,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'soldTo',
        value: initialValues.soldTo,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textField({
        path: 'alarmStatus',
        value: initialValues.alarmStatus,
      }),
    ],
  });
}

export function convertDeliveryNoteToInternalForm(currentDeliveryNote: KvintaDelivery) {
  return {
    docType: (currentDeliveryNote.document?.type ? currentDeliveryNote.document.type : '') as EGs1DocType,
    docLocation: currentDeliveryNote.document.location || '',
    docNumber: currentDeliveryNote.document.number || '',
    refType: (currentDeliveryNote.referenceDocument?.type
      ? currentDeliveryNote.referenceDocument.type
      : '') as EGs1DocType,
    refLocation: currentDeliveryNote.referenceDocument?.location || '',
    refNumber: currentDeliveryNote.referenceDocument?.number || '',
    alarmStatus: currentDeliveryNote.alarmStatus || KvintaAlarmStatus.Unknown,
    shipFrom: currentDeliveryNote.shipFrom || '',
    shipTo: currentDeliveryNote.shipTo || '',
    soldFrom: currentDeliveryNote.soldFrom || '',
    soldTo: currentDeliveryNote.soldTo || '',
  };
}

function transformDeliveryNoteFormData(deliveryNoteFormData: TDeliveryNoteFormData): KvintaDelivery {
  return {
    document: {
      type: deliveryNoteFormData['deliveryNoteForm.docType'].value as KvintaGs1DocumentType,
      location: deliveryNoteFormData['deliveryNoteForm.docLocation'].value,
      number: deliveryNoteFormData['deliveryNoteForm.docNumber'].value,
    },
    referenceDocument: {
      type: normalizeValue(deliveryNoteFormData['deliveryNoteForm.refType'].value) as KvintaGs1DocumentType,
      location: deliveryNoteFormData['deliveryNoteForm.refLocation'].value,
      number: deliveryNoteFormData['deliveryNoteForm.refNumber'].value,
    },
    shipFrom: normalizeValue(deliveryNoteFormData['deliveryNoteForm.shipFrom'].value),
    shipTo: normalizeValue(deliveryNoteFormData['deliveryNoteForm.shipTo'].value),
    soldFrom: normalizeValue(deliveryNoteFormData['deliveryNoteForm.soldFrom'].value),
    soldTo: normalizeValue(deliveryNoteFormData['deliveryNoteForm.soldTo'].value),
  };
}
