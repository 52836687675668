import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExportButton,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SearchField,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { PRODUCT_STORE_ID, ProductsStore } from './ProductsStore';
import { CreateProductDialog } from './CreateProductDialog';
import { CSVDownload } from 'react-csv';
import { ITableActionsState } from 'kvinta/common';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import MaterialTable from 'material-table';
import { getInternalId } from '../../../apis/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KvintaProduct } from '../../../apis/kvinta-masterdata';
import styles from './Products.module.scss';
import { defineSorting } from '../../../common/utils';

interface ProductsViewProps {
  productsStore: ProductsStore;
  sidebarStore: KSidebarStore;
  intl: any;
  historyStore: HistoryStore;
  pageContentStore?: PageContentStore;
}

class ProductsViewImpl extends Component<ProductsViewProps, ITableActionsState> {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  componentDidMount() {
    this.props.productsStore.fetchPage(0);
    this.props.sidebarStore.clearMainTitle();
  }

  navigateProductSummary = (id: string) => {
    this.props.historyStore.navigateProductPath(id);
  };

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  render() {
    const intl = this.props.intl;
    const {
      productFormOpen,
      exportActive,
      exportData,
      searchValue,
      autofocusSearchInList,
      productFormData,
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
    } = this.props.productsStore;

    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    this.props.productsStore.updateExported();

    return (
      <div className={styles.mainContainer}>
        <div className={styles.productListHeader}>
          <SearchField
            intl={this.props.intl}
            searchValue={searchValue}
            onChange={(value: string) => {
              this.props.productsStore.updateSearch(value);
            }}
            autofocus={autofocusSearchInList}
          />
          <div className={styles.productListButtonContainer}>
            <ExportButton
              onExportAll={() => this.props.productsStore.exportAll()}
              onExportSelected={() => this.props.productsStore.exportSelected()}
              hasSelected={this.props.productsStore.checked > 0}
            />
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.productsStore.openCreateProductForm();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        </div>
        <div id="ProductsListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            title={intl.formatMessage({ id: 'menu.products' })}
            columns={[
              {
                title: intl.formatMessage({ id: 'product-form.field.name' }),
                field: 'name',
                defaultSort: defineSorting(this.props.productsStore.currentSort, 'name'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                  width: '40%',
                },
              },
              {
                title: intl.formatMessage({ id: 'product-form.field.sku' }),
                field: 'sku',
                defaultSort: defineSorting(this.props.productsStore.currentSort, 'sku'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                  width: '30%',
                },
              },
              {
                title: intl.formatMessage({ id: 'product-form.field.baseUom' }),
                field: 'baseUom',
                defaultSort: defineSorting(this.props.productsStore.currentSort, 'baseUom'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                  width: '30%',
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.productsStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.productsStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.navigateProductSummary(getInternalId(rowData));
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => null,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              const shouldDelete = confirm('Do you want to delete ' + (this.state.rowData as KvintaProduct).name + '?');
              if (shouldDelete) {
                this.props.productsStore.deleteProduct(getInternalId(this.state.rowData as KvintaProduct));
              }
              this.onMenuClose();
            }}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
        {productFormOpen && (
          <CreateProductDialog
            formData={productFormData}
            closeProductForm={this.props.productsStore.closeProductForm}
            submitCreateProduct={this.props.productsStore.submitCreateProduct}
            onChange={this.props.productsStore.onChangeProductFormField}
            onBlur={this.props.productsStore.onBlurProductFormField}
          />
        )}
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </div>
    );
  }
}

export const ProductList = injectIntl(
  inject(HISTORY_STORE_ID, PAGE_CONTENT_STORE_ID, PRODUCT_STORE_ID, SIDEBAR_STORE_ID)(observer(ProductsViewImpl)),
);
