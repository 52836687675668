/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSourceElement
 */
export interface KvintaSourceElement {
    /**
     * 
     * @type {string}
     * @memberof KvintaSourceElement
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSourceElement
     */
    source: string;
}

export function KvintaSourceElementFromJSON(json: any): KvintaSourceElement {
    return KvintaSourceElementFromJSONTyped(json, false);
}

export function KvintaSourceElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSourceElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'source': json['source'],
    };
}

export function KvintaSourceElementToJSON(value?: KvintaSourceElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'source': value.source,
    };
}


