import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import styles from './TextInput.module.scss';

type TTextInputProps = {
  intl: any;
  onChange: (id: string, val: string) => void;
  onBlur: (id: string, val: string) => void;
  value: string;
  id: string;
  label: string;
  disabled?: boolean;
  isRequiredField: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
  defaultMessage?: string;
};

const TextInputBase: React.FunctionComponent<TTextInputProps> = (props) => {
  const {
    id,
    value,
    onChange,
    onBlur,
    touched,
    isRequiredField,
    showError,
    label,
    intl,
    style,
    errors,
    disabled = false,
    defaultMessage,
  } = props;

  return (
    <FormControl variant="outlined" fullWidth style={style} id={`${label}-container`}>
      <TextField
        id={label}
        value={value}
        disabled={disabled}
        variant="outlined"
        onBlur={(e) => onBlur(id, e.target.value)}
        onChange={(e) => onChange(id, e.target.value)}
        label={`${intl.formatMessage({ id: label, defaultMessage: defaultMessage })}${isRequiredField ? ' *' : ''}`}
      />
      {showError && touched && <div className={styles.error}>{errors}</div>}
    </FormControl>
  );
};

export const TextInput = injectIntl(observer(TextInputBase));
