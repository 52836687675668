/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaEpcsContentItem
 */
export interface KvintaEpcsContentItem {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItem
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItem
     */
    batch: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcsContentItem
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItem
     */
    expiryDate?: string | null;
}

export function KvintaEpcsContentItemFromJSON(json: any): KvintaEpcsContentItem {
    return KvintaEpcsContentItemFromJSONTyped(json, false);
}

export function KvintaEpcsContentItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcsContentItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': json['gtin'],
        'batch': json['batch'],
        'count': json['count'],
        'expiryDate': !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
    };
}

export function KvintaEpcsContentItemToJSON(value?: KvintaEpcsContentItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'batch': value.batch,
        'count': value.count,
        'expiryDate': value.expiryDate,
    };
}


