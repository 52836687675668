/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAttributeConfiguration,
    KvintaAttributeConfigurationFromJSON,
    KvintaAttributeConfigurationFromJSONTyped,
    KvintaAttributeConfigurationToJSON,
    KvintaDeliveryItem,
    KvintaDeliveryItemFromJSON,
    KvintaDeliveryItemFromJSONTyped,
    KvintaDeliveryItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryResultWithConfigDeliveryItem
 */
export interface KvintaQueryResultWithConfigDeliveryItem {
    /**
     * 
     * @type {Array<KvintaDeliveryItem>}
     * @memberof KvintaQueryResultWithConfigDeliveryItem
     */
    list?: Array<KvintaDeliveryItem> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaQueryResultWithConfigDeliveryItem
     */
    total?: number | null;
    /**
     * 
     * @type {{ [key: string]: KvintaAttributeConfiguration; }}
     * @memberof KvintaQueryResultWithConfigDeliveryItem
     */
    attrConfig?: { [key: string]: KvintaAttributeConfiguration; } | null;
}

export function KvintaQueryResultWithConfigDeliveryItemFromJSON(json: any): KvintaQueryResultWithConfigDeliveryItem {
    return KvintaQueryResultWithConfigDeliveryItemFromJSONTyped(json, false);
}

export function KvintaQueryResultWithConfigDeliveryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryResultWithConfigDeliveryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaDeliveryItemFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'attrConfig': !exists(json, 'attrConfig') ? undefined : (json['attrConfig'] === null ? null : mapValues(json['attrConfig'], KvintaAttributeConfigurationFromJSON)),
    };
}

export function KvintaQueryResultWithConfigDeliveryItemToJSON(value?: KvintaQueryResultWithConfigDeliveryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaDeliveryItemToJSON)),
        'total': value.total,
        'attrConfig': value.attrConfig === undefined ? undefined : (value.attrConfig === null ? null : mapValues(value.attrConfig, KvintaAttributeConfigurationToJSON)),
    };
}


