/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaBusinessPartner,
    KvintaBusinessPartnerFromJSON,
    KvintaBusinessPartnerFromJSONTyped,
    KvintaBusinessPartnerToJSON,
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateRequestBusinessPartnerExternalId
 */
export interface KvintaMutateRequestBusinessPartnerExternalId {
    /**
     * 
     * @type {Array<KvintaBusinessPartner>}
     * @memberof KvintaMutateRequestBusinessPartnerExternalId
     */
    upsert?: Array<KvintaBusinessPartner> | null;
    /**
     * 
     * @type {Array<KvintaExternalId>}
     * @memberof KvintaMutateRequestBusinessPartnerExternalId
     */
    _delete?: Array<KvintaExternalId> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestBusinessPartnerExternalId
     */
    enableBatch?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestBusinessPartnerExternalId
     */
    createOnly?: boolean | null;
}

export function KvintaMutateRequestBusinessPartnerExternalIdFromJSON(json: any): KvintaMutateRequestBusinessPartnerExternalId {
    return KvintaMutateRequestBusinessPartnerExternalIdFromJSONTyped(json, false);
}

export function KvintaMutateRequestBusinessPartnerExternalIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateRequestBusinessPartnerExternalId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upsert': !exists(json, 'upsert') ? undefined : (json['upsert'] === null ? null : (json['upsert'] as Array<any>).map(KvintaBusinessPartnerFromJSON)),
        '_delete': !exists(json, 'delete') ? undefined : (json['delete'] === null ? null : (json['delete'] as Array<any>).map(KvintaExternalIdFromJSON)),
        'enableBatch': !exists(json, 'enableBatch') ? undefined : json['enableBatch'],
        'createOnly': !exists(json, 'createOnly') ? undefined : json['createOnly'],
    };
}

export function KvintaMutateRequestBusinessPartnerExternalIdToJSON(value?: KvintaMutateRequestBusinessPartnerExternalId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upsert': value.upsert === undefined ? undefined : (value.upsert === null ? null : (value.upsert as Array<any>).map(KvintaBusinessPartnerToJSON)),
        'delete': value._delete === undefined ? undefined : (value._delete === null ? null : (value._delete as Array<any>).map(KvintaExternalIdToJSON)),
        'enableBatch': value.enableBatch,
        'createOnly': value.createOnly,
    };
}


