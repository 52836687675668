/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
    KvintaLocationAllOf,
    KvintaLocationAllOfFromJSON,
    KvintaLocationAllOfFromJSONTyped,
    KvintaLocationAllOfToJSON,
    KvintaWithExternalId,
    KvintaWithExternalIdFromJSON,
    KvintaWithExternalIdFromJSONTyped,
    KvintaWithExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaLocation
 */
export interface KvintaLocation {
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    sourceSystem: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    idInSourceSystem: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    gln13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    sgln?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaLocation
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaLocation
     */
    latitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocation
     */
    address3?: string | null;
    /**
     * 
     * @type {KvintaExternalId}
     * @memberof KvintaLocation
     */
    businessPartner: KvintaExternalId;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaLocation
     */
    attributes?: { [key: string]: object; } | null;
}

export function KvintaLocationFromJSON(json: any): KvintaLocation {
    return KvintaLocationFromJSONTyped(json, false);
}

export function KvintaLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceSystem': json['sourceSystem'],
        'idInSourceSystem': json['idInSourceSystem'],
        'name': json['name'],
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'sgln': !exists(json, 'sgln') ? undefined : json['sgln'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'address3': !exists(json, 'address3') ? undefined : json['address3'],
        'businessPartner': KvintaExternalIdFromJSON(json['businessPartner']),
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function KvintaLocationToJSON(value?: KvintaLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceSystem': value.sourceSystem,
        'idInSourceSystem': value.idInSourceSystem,
        'name': value.name,
        'gln13': value.gln13,
        'sgln': value.sgln,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'description': value.description,
        'country': value.country,
        'city': value.city,
        'postalCode': value.postalCode,
        'address1': value.address1,
        'address2': value.address2,
        'address3': value.address3,
        'businessPartner': KvintaExternalIdToJSON(value.businessPartner),
        'attributes': value.attributes,
    };
}


