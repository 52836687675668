import { action, makeObservable, observable, toJS } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { DefaultApi as ECaptureStoreApi, KvintaEpcisDocumentRecord } from 'kvinta/apis/kvinta-epcis-capture';
import {
  DefaultApi as MDDocumentApi,
  KvintaLocation,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-masterdata';
import { NotificationManager } from 'kvinta/modules/main';
import {
  DefaultApi as EpcisStoreApi,
  KvintaEpcisDocument,
  KvintaEpcisDocumentFilters,
  KvintaOperationStatus,
} from 'kvinta/apis/kvinta-epcis-store';
import { EFilterType, IFilter } from '../../../components/FilterBar/FilterBar';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, textInput } from '../../../common/formUtils/formDataGenerators';
import { validateForm } from '../../../common/formUtils/core';
import { isNotEmpty, maxLength } from '../../../common/formUtils/validators';
import { TTextInputData } from '../../../common/formUtils/types';

export enum EButtonState {
  ACTIVE = 'active',
  LOADING = 'loading',
  DISABLED = 'disabled',
}

export type TEpcisEventSetRow = {
  location: string;
  createDate: string;
  recordDate: string;
  earliestEventDate: string;
  latestEventDate: string;
  eventNumber: number;
  id: string;
};

export interface UploadFormDialogData {
  location: string;
  timezone: string;
  timestamp: string;
  bizStep: string;
  selectedFile: any;
  showError: boolean;
}

export type TCurrentEventSetRecord = {
  id: string;
  numberOfEvents: string;
  earliestTime: string;
  latestTime: string;
};

export type TErrorDeclarationFormData = {
  'errorDeclarationForm.reason': TTextInputData;
};

export class EpcisEventSetStore {
  private _config: TAppOptionsConfig;
  private _epcisCaptureApi: ECaptureStoreApi;
  private _epcisStoreApi: EpcisStoreApi;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: TEpcisEventSetRow[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  declareErrorFormOpen: boolean;
  declareErrorButtonState: EButtonState;
  declareErrorFormData: TErrorDeclarationFormData;

  filter: IFilter;

  locations: KvintaLocation[];
  currentEventSet: KvintaEpcisDocument;
  currentEventSetRecord: TCurrentEventSetRecord;
  jsonPayload?: string;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    epcisCaptureApi: ECaptureStoreApi,
    mdApi: MDDocumentApi,
    epcisStoreApi: EpcisStoreApi,
  ) {
    makeObservable(this, {
      fetchPage: action.bound,
      isLoading: observable,
      listData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,
      jsonPayload: observable,
      currentEventSet: observable,
      currentEventSetRecord: observable,

      openDeclareErrorForm: action.bound,
      submitDeclareErrorForm: action.bound,
      closeDeclareErrorForm: action.bound,
      onChangeDeclareErrorFormField: action.bound,
      onBlurDeclareErrorFormField: action.bound,
      declareErrorFormOpen: observable,
      declareErrorFormData: observable,
    });

    this._config = config;
    this._epcisCaptureApi = epcisCaptureApi;
    this._mdApi = mdApi;
    this._epcisStoreApi = epcisStoreApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.declareErrorFormOpen = false;
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'createDate',
          ignoreCase: false,
        },
      ],
    };
    this.filter = new IFilter(
      [
        {
          type: EFilterType.TEXT_FILTER,
          id: 'id',
          label: 'epcis-document.id',
          isActive: true,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'location',
          label: 'epcis-document.location',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );
  }

  doFilter = async () => {
    this.isLoading = true;
    this.page = 0;
    this.fetchEventSetList();
  };

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;
    this.fetchEventSetList();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const dir = getDirection(orderDirection);
    const field = getField(orderBy);
    this.currentSort = {
      expressions: [
        {
          direction: dir,
          property: field,
          ignoreCase: false,
        },
      ],
    };
    this.fetchEventSetList();
  }

  async fetchEventSetList() {
    this.isLoading = true;
    let filters = {} as KvintaEpcisDocumentFilters;

    for (const filter of this.filter.visibleFilters) {
      if (filter.id === 'id' && filter.value !== '') {
        filters = {
          ...filters,
          idLike: filter.value as string,
        };
      }
      if (filter.id === 'location' && filter.value !== '') {
        filters = {
          ...filters,
          locationLike: filter.value as string,
        };
      }
    }

    this._epcisStoreApi
      .listEpcisDocuments({
        kvintaOperationRequestListEpcisDocumentsRequest: {
          input: { paging: { page: this.page, size: this.pageSize, sort: this.currentSort }, filters: filters },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Error) {
          this.listData = [];
          this._notificationManager.sendError(`An error occurred while loading data\n${result.error}`);
        } else {
          this.totalCount = result.data.total || 0;
          this.listData = kepcDocRecToView((result.data.list as any) || []);
        }
      })
      .catch((err) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while loading data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openDeclareErrorForm = (id: string) => {
    this.declareErrorButtonState = EButtonState.LOADING;

    this._epcisCaptureApi
      .canCancelEpcisDocument({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((response) => {
        if (response.status === KvintaOperationStatus.Ok && response.data === true) {
          const initialValues = {
            reason: '',
          };
          this.declareErrorFormData = validateForm<TErrorDeclarationFormData>(
            generateDeclareErrorFormData(ERROR_DECLARATION_FORM_ROOT_ID, initialValues),
          );
          this.declareErrorFormOpen = true;
        } else if (response.status === KvintaOperationStatus.Ok && response.data === false) {
          this._notificationManager.sendError(`It is not possible to declare an error for this event set`);
        } else {
          this._notificationManager.sendError(
            `An error occurred while submitting error declaration\n${response.error}`,
          );
          this.declareErrorButtonState = EButtonState.ACTIVE;
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while submitting error declaration\n${err.message}`);
        this.declareErrorButtonState = EButtonState.ACTIVE;
      });
  };

  closeDeclareErrorForm = () => {
    this.declareErrorFormOpen = false;
    this.declareErrorFormData = null;
  };

  cancelDeclareErrorForm = () => {
    this.closeDeclareErrorForm();
    this.declareErrorButtonState = EButtonState.ACTIVE;
  };

  submitDeclareErrorForm = () => {
    this.isLoading = true;

    this._epcisCaptureApi
      .cancelEpcisMessage({
        kvintaCancelEpcisDocumentRequest: {
          ...transformErrorDeclarationFormData(this.declareErrorFormData),
          documentId: this.currentEventSet.id,
        },
      })
      .then(() => {
        this._notificationManager.sendSuccess(`Error declaration submitted`);
        this.closeDeclareErrorForm();
      })
      .catch(async (response) => {
        const error = await response.json();
        this._notificationManager.sendError(
          `An error occurred while submitting error declaration\n${error?.message || ''}`,
        );
        this.declareErrorButtonState = EButtonState.ACTIVE;
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  onChangeDeclareErrorFormField = (id: string, value: string) => {
    const formData = toJS(this.declareErrorFormData);
    this.declareErrorFormData = handleFormChange(formData, id, value);
  };

  onBlurDeclareErrorFormField = (id: string) => {
    const formData = toJS(this.declareErrorFormData);
    this.declareErrorFormData = handleFormBlur(formData, id);
  };

  fetchEventRecord = (id: string) => {
    this.currentEventSetRecord = undefined;
    this._epcisStoreApi
      .readEpcisDocumentRecordById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this.currentEventSetRecord = transformEventSetRecord(result.data as any);
        } else if (result.error) {
          this._notificationManager.sendError(`An error occurred while loading data\n${result.error}`);
          this.jsonPayload = 'Error';
        } else {
          this.jsonPayload = 'No data available';
        }
      })
      .catch((err) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while loading data\n${err.message}`);
        this.jsonPayload = 'Error';
      });
  };

  fetchEventSet = (id: string) => {
    this.currentEventSet = undefined;
    this._epcisStoreApi
      .readEpcisDocumentById({
        kvintaOperationRequestString: {
          input: id,
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          this.currentEventSet = result.data;

          this.jsonPayload = JSON.stringify(result.data, null, 2);
          this.declareErrorButtonState = EButtonState.ACTIVE;
        } else if (result.error) {
          this._notificationManager.sendError(`An error occurred while loading data\n${result.error}`);
          this.jsonPayload = 'Error';
        } else {
          this.jsonPayload = 'No data available';
        }
      })
      .catch((err) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while loading data\n${err.message}`);
        this.jsonPayload = 'Error';
      });
  };
}

function kepcDocRecToView(list: KvintaEpcisDocumentRecord[]): TEpcisEventSetRow[] {
  const viewItems = list.map<TEpcisEventSetRow>((doc) => {
    return {
      location: doc.location || '',
      createDate:
        doc.createDate !== undefined && doc.createDate !== null
          ? new Date(doc.createDate.epochMillis).toISOString()
          : '',
      recordDate: doc.recordDate !== undefined && doc.recordDate !== null ? new Date(doc.recordDate).toISOString() : '',
      earliestEventDate:
        doc.earliestEventDate !== undefined && doc.earliestEventDate !== null
          ? new Date(doc.earliestEventDate.epochMillis).toISOString()
          : '',
      latestEventDate:
        doc.latestEventDate !== undefined && doc.latestEventDate !== null
          ? new Date(doc.latestEventDate.epochMillis).toISOString()
          : '',
      eventNumber: doc.eventsCount,
      id: doc.id || '',
    };
  });
  return viewItems;
}

export const EPCIS_EVENT_SET_STORE_ID = 'epcisEventSetStore';
export const ERROR_DECLARATION_FORM_ROOT_ID = 'errorDeclarationForm';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'recordDate';
    case 2:
      return 'createDate';
    case 3:
      return 'location';
    case 4:
      return 'earliestEventDate';
    case 5:
      return 'latestEventDate';
    case 6:
      return 'eventsCount';
    case 7:
      return 'id';
    default:
      return 'recordDate';
  }
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const requiredFields = ['reason'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}

function generateDeclareErrorFormData(
  rootFormId: string,
  initialValues: {
    reason: string;
  },
) {
  return formRoot<TErrorDeclarationFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'reason',
        value: initialValues.reason,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 200 characters', maxLength: 200 }),
        ],
        isRequiredField: true,
      }),
    ],
  });
}

function transformErrorDeclarationFormData(errorDeclarationFormData) {
  return {
    reason: errorDeclarationFormData['errorDeclarationForm.reason'].value.trim(),
  };
}

function transformEventSetRecord(data: KvintaEpcisDocumentRecord): TCurrentEventSetRecord {
  return {
    id: data.id ? data.id : '',
    numberOfEvents: data.eventsCount ? data.eventsCount.toString() : '',
    earliestTime: data.earliestEventDate?.epochMillis ? new Date(data.earliestEventDate.epochMillis).toISOString() : '',
    latestTime: data.latestEventDate?.epochMillis ? new Date(data.latestEventDate.epochMillis).toISOString() : '',
  };
}
