/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmStatus,
    KvintaAlarmStatusFromJSON,
    KvintaAlarmStatusFromJSONTyped,
    KvintaAlarmStatusToJSON,
    KvintaDeliveryItemDiffResult,
    KvintaDeliveryItemDiffResultFromJSON,
    KvintaDeliveryItemDiffResultFromJSONTyped,
    KvintaDeliveryItemDiffResultToJSON,
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDeliveryCheckResult
 */
export interface KvintaDeliveryCheckResult {
    /**
     * 
     * @type {KvintaDocumentKey}
     * @memberof KvintaDeliveryCheckResult
     */
    deliveryKey: KvintaDocumentKey;
    /**
     * 
     * @type {KvintaAlarmStatus}
     * @memberof KvintaDeliveryCheckResult
     */
    alarmStatus?: KvintaAlarmStatus | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryCheckResult
     */
    alarmMessage?: string | null;
    /**
     * 
     * @type {{ [key: string]: KvintaAlarmStatus; }}
     * @memberof KvintaDeliveryCheckResult
     */
    itemStatusMap: { [key: string]: KvintaAlarmStatus; };
    /**
     * 
     * @type {Array<KvintaDeliveryItemDiffResult>}
     * @memberof KvintaDeliveryCheckResult
     */
    mismatchDiffList: Array<KvintaDeliveryItemDiffResult>;
}

export function KvintaDeliveryCheckResultFromJSON(json: any): KvintaDeliveryCheckResult {
    return KvintaDeliveryCheckResultFromJSONTyped(json, false);
}

export function KvintaDeliveryCheckResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryCheckResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryKey': KvintaDocumentKeyFromJSON(json['deliveryKey']),
        'alarmStatus': !exists(json, 'alarmStatus') ? undefined : KvintaAlarmStatusFromJSON(json['alarmStatus']),
        'alarmMessage': !exists(json, 'alarmMessage') ? undefined : json['alarmMessage'],
        'itemStatusMap': (mapValues(json['itemStatusMap'], KvintaAlarmStatusFromJSON)),
        'mismatchDiffList': ((json['mismatchDiffList'] as Array<any>).map(KvintaDeliveryItemDiffResultFromJSON)),
    };
}

export function KvintaDeliveryCheckResultToJSON(value?: KvintaDeliveryCheckResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryKey': KvintaDocumentKeyToJSON(value.deliveryKey),
        'alarmStatus': KvintaAlarmStatusToJSON(value.alarmStatus),
        'alarmMessage': value.alarmMessage,
        'itemStatusMap': (mapValues(value.itemStatusMap, KvintaAlarmStatusToJSON)),
        'mismatchDiffList': ((value.mismatchDiffList as Array<any>).map(KvintaDeliveryItemDiffResultToJSON)),
    };
}


