import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { LOCATION_FORM_ROOT_ID, TLocationFormData } from './LocationsStore';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { LocationForm } from './LocationForm';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';

interface DialogCreateLocationProps {
  intl: IntlShape;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string) => void;
  formData: TLocationFormData;
  closeLocationForm: () => void;
  submitCreateLocationForm: () => void;
}

const CreateLocationDialogBase: React.FunctionComponent<DialogCreateLocationProps> = ({
  intl,
  onChange,
  onBlur,
  formData,
  closeLocationForm,
  submitCreateLocationForm,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true}>
    <KDialogTitle id="customized-dialog-title" onClose={closeLocationForm}>
      {intl.formatMessage({ id: 'location-create-form.field.create' })}
    </KDialogTitle>
    <DialogContent>
      <LocationForm formData={formData} onChange={onChange} onBlur={onBlur} />
    </DialogContent>
    <DialogActions>
      <Button onClick={closeLocationForm} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id="submitButton"
        onClick={submitCreateLocationForm}
        label={intl.formatMessage({ id: 'button.submit' })}
        buttonState={formDataToButtonState(formData[LOCATION_FORM_ROOT_ID])}
      />
    </DialogActions>
  </Dialog>
);

export const CreateLocationDialog = injectIntl(observer(CreateLocationDialogBase));
