import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { observer } from 'mobx-react';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { DELIVERY_NOTE_FORM_ROOT_ID, TDeliveryNoteFormData } from './DeliveryNotesStore';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { DeliveryNoteForm, EFormType } from './DeliveryNoteForm';

interface CreateDeliveryNoteDialogProps {
  intl: IntlShape;
  formData: TDeliveryNoteFormData;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  onClose: () => void;
  onSubmit: () => void;
}

const CreateDeliveryNoteFormBase: React.FunctionComponent<CreateDeliveryNoteDialogProps> = ({
  intl,
  formData,
  onBlur,
  onChange,
  onClose,
  onSubmit,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true}>
    <KDialogTitle id="customized-dialog-title" onClose={onClose}>
      {intl.formatMessage({ id: 'delivery-note-form.field.create' })}
    </KDialogTitle>
    <DialogContent>
      <DeliveryNoteForm formData={formData} onChange={onChange} onBlur={onBlur} formType={EFormType.CREATE} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id="submitButton"
        onClick={onSubmit}
        label={intl.formatMessage({ id: 'button.submit' })}
        buttonState={formDataToButtonState(formData[DELIVERY_NOTE_FORM_ROOT_ID])}
      />
    </DialogActions>
  </Dialog>
);

export const CreateDeliveryNoteDialog = injectIntl(observer(CreateDeliveryNoteFormBase));
