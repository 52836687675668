import { NotificationManager } from 'kvinta/modules/main';
import { KSidebarStore, SIDEBAR_STORE_ID as SIDEBAR_STORE_ID } from 'kvinta/components/Sidebar/KSidebarStore';
import { UserStore, USER_STORE_ID } from 'kvinta/modules/main/UserStore';
import { getMenus } from './menu';
import { History } from "history";
import { TAppOptionsConfig } from 'kvinta/common';
import { AuthService } from 'kvinta/service/auth';
import { PageContentStore, PAGE_CONTENT_STORE_ID } from 'kvinta/components';
import {
  createDevicesAPI,
  createEPCISCaptureAPI,
  createEpcisStoreAPI,
  createMasterDataAPI,
  createConfigStoreAPI, createConfigMasterDataAPI
} from 'kvinta/apis/apis';
import {
  BusinessPartnersStore,
  BUSINESS_PARTNER_STORE_ID
} from 'kvinta/modules/master-data/businesspartners/BusinessPartnerStore';
import { LocationsStore, STORE_ID as LOCATIONS_STORE_ID } from 'kvinta/modules/master-data/locations/LocationsStore';
import { ProductsStore, PRODUCT_STORE_ID } from 'kvinta/modules/master-data/products/ProductsStore';
import { TradeItemsStore, TRADE_ITEM_STORE_ID } from 'kvinta/modules/master-data/trade-items/TradeItemsStore';
import { DeliveryNotesStore, DELIVERY_NOTE_STORE_ID } from 'kvinta/modules/documents/deliveries/DeliveryNotesStore';
import { DeliveryItemsStore, DELIVERY_ITEM_STORE_ID } from 'kvinta/modules/documents/deliveries/DeliveryItemsStore';
import { DeviceStore, DEVICE_STORE_ID } from 'kvinta/modules/configuration/devices/DeviceStore';
import { EpcMessagesStore, EPC_MESSAGES_STORE_ID } from 'kvinta/modules/epcis/epcs/EpcMessagesStore';
import { EpcisEventsStore, EPCIS_EVENTS_STORE_ID } from 'kvinta/modules/epcis/events/EpcisEventsStore';
import { EpcisEventSetStore, EPCIS_EVENT_SET_STORE_ID } from 'kvinta/modules/epcis/eventSets/EpcisEventSetStore';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from 'kvinta/common/HistoryStore'
import { EPCIS_MESSAGE_STORE_ID, EpcisMessageStore } from "./kvinta/modules/epcis/messages/EpcisMessageStore";
import {
  NOTIFICATION_STORE_ID,
  NotificationStore
} from "./kvinta/modules/configuration/notifications/NotificationStore";
import { ALARMS_STORE_ID, AlarmsStore } from "./kvinta/modules/configuration/alarms/AlarmsStore";

export enum STORES {
  NOTIFICATION_STORE = 'notificationStore',
}

export function createStores(config: TAppOptionsConfig, authS: AuthService, history: History<unknown>) {
  const deviceStoreApi = createDevicesAPI(config, authS);
  const epcisCaptureApi = createEPCISCaptureAPI(config, authS);
  const configApi = createConfigStoreAPI(config, authS);
  const masterDataApi = createMasterDataAPI(config, authS);
  const masterDataConfigApi = createConfigMasterDataAPI(config, authS);
  const epcisStoreApi = createEpcisStoreAPI(config, authS);

  const notificationManager = new NotificationManager();
  const pageContentStore = new PageContentStore();
  const sidebarStore = new KSidebarStore(getMenus());
  const userStore = new UserStore(config, authS, notificationManager);
  const historyStore = new HistoryStore(history)

  const apis = {
    deviceStoreApi,
    epcisCaptureApi,
    masterDataApi,
    epcisStoreApi,
    notificationManager,
    historyStore
  }

  const companiesStore = new BusinessPartnersStore(config, notificationManager, masterDataApi);
  const locationsStore = new LocationsStore(config, notificationManager, masterDataApi);
  const productsStore = new ProductsStore(config, notificationManager, masterDataApi);
  const tradeItemsStore = new TradeItemsStore(config, notificationManager, masterDataApi);
  const deliveryNotesStore = new DeliveryNotesStore(config, apis);
  const deliveryItemsStore = new DeliveryItemsStore(config, notificationManager, masterDataApi, masterDataConfigApi, historyStore);
  const deviceStore = new DeviceStore(config, notificationManager, deviceStoreApi);
  const epcMessagesStore = new EpcMessagesStore(config, notificationManager, epcisCaptureApi, masterDataApi, epcisStoreApi);
  const epcisEventsStore = new EpcisEventsStore(config, notificationManager, epcisCaptureApi, epcisStoreApi);
  const notificationStore = new NotificationStore(config, notificationManager, configApi,masterDataApi);
  const alarmsStore = new AlarmsStore(config, notificationManager, configApi);
  const epcisEventSetStore = new EpcisEventSetStore(
    config,
    notificationManager,
    epcisCaptureApi,
    masterDataApi,
    epcisStoreApi,
  )
  const epcisMessagesStore = new EpcisMessageStore(
    config,
    notificationManager,
    epcisCaptureApi,
  );

  return {
    [SIDEBAR_STORE_ID]: sidebarStore,
    [USER_STORE_ID]: userStore,
    [DEVICE_STORE_ID]: deviceStore,
    [BUSINESS_PARTNER_STORE_ID]: companiesStore,
    [LOCATIONS_STORE_ID]: locationsStore,
    [PRODUCT_STORE_ID]: productsStore,
    [TRADE_ITEM_STORE_ID]: tradeItemsStore,
    [DELIVERY_NOTE_STORE_ID]: deliveryNotesStore,
    [DELIVERY_ITEM_STORE_ID]: deliveryItemsStore,
    [PAGE_CONTENT_STORE_ID]: pageContentStore,
    [EPC_MESSAGES_STORE_ID]: epcMessagesStore,
    [NOTIFICATION_STORE_ID]: notificationStore,
    [ALARMS_STORE_ID]: alarmsStore,
    [EPCIS_EVENTS_STORE_ID]: epcisEventsStore,
    [EPCIS_EVENT_SET_STORE_ID]: epcisEventSetStore,
    [EPCIS_MESSAGE_STORE_ID]: epcisMessagesStore,
    [HISTORY_STORE_ID]: historyStore,
  };
}
