/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcisEventType {
    AggregationEvent = 'AggregationEvent',
    ObjectEvent = 'ObjectEvent'
}

export function KvintaEpcisEventTypeFromJSON(json: any): KvintaEpcisEventType {
    return KvintaEpcisEventTypeFromJSONTyped(json, false);
}

export function KvintaEpcisEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisEventType {
    return json as KvintaEpcisEventType;
}

export function KvintaEpcisEventTypeToJSON(value?: KvintaEpcisEventType | null): any {
    return value as any;
}

