export const tabsSummary = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'menu.trade-items',
    value: '2',
  },
];

export const tabsTradeItemView = [
  ...tabsSummary,
  {
    label: 'common.summary',
    value: '3',
  },
];
