/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisEvent,
    KvintaEpcisEventFromJSON,
    KvintaEpcisEventFromJSONTyped,
    KvintaEpcisEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisBody
 */
export interface KvintaEpcisBody {
    /**
     * 
     * @type {Array<KvintaEpcisEvent>}
     * @memberof KvintaEpcisBody
     */
    eventList?: Array<KvintaEpcisEvent> | null;
}

export function KvintaEpcisBodyFromJSON(json: any): KvintaEpcisBody {
    return KvintaEpcisBodyFromJSONTyped(json, false);
}

export function KvintaEpcisBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventList': !exists(json, 'eventList') ? undefined : (json['eventList'] === null ? null : (json['eventList'] as Array<any>).map(KvintaEpcisEventFromJSON)),
    };
}

export function KvintaEpcisBodyToJSON(value?: KvintaEpcisBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventList': value.eventList === undefined ? undefined : (value.eventList === null ? null : (value.eventList as Array<any>).map(KvintaEpcisEventToJSON)),
    };
}


