import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

type TTextFieldProps = {
  intl: IntlShape;
  value: string;
  label: string;
  style?: React.CSSProperties;
  defaultMessage?: string;
};

const TextFieldBase: React.FunctionComponent<TTextFieldProps> = ({ intl, value, label, style, defaultMessage }) => (
  <FormControl variant="outlined" fullWidth style={style} id={`${label}-container`}>
    <InputLabel style={{ backgroundColor: 'white', paddingRight: '7px' }}>
      {intl.formatMessage({ id: label, defaultMessage: defaultMessage })}
    </InputLabel>
    <OutlinedInput
      readOnly={true}
      label=" "
      value={value}
      disabled={value === undefined || value.trim() === ''}
      id={label}
    />
  </FormControl>
);

export const TextField = injectIntl(TextFieldBase);
