/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaIdAndErrorTradeItemKey,
    KvintaIdAndErrorTradeItemKeyFromJSON,
    KvintaIdAndErrorTradeItemKeyFromJSONTyped,
    KvintaIdAndErrorTradeItemKeyToJSON,
    KvintaTradeItemKey,
    KvintaTradeItemKeyFromJSON,
    KvintaTradeItemKeyFromJSONTyped,
    KvintaTradeItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateResultTradeItemKey
 */
export interface KvintaMutateResultTradeItemKey {
    /**
     * 
     * @type {Array<KvintaTradeItemKey>}
     * @memberof KvintaMutateResultTradeItemKey
     */
    upserted?: Array<KvintaTradeItemKey> | null;
    /**
     * 
     * @type {Array<KvintaTradeItemKey>}
     * @memberof KvintaMutateResultTradeItemKey
     */
    deleted?: Array<KvintaTradeItemKey> | null;
    /**
     * 
     * @type {Array<KvintaIdAndErrorTradeItemKey>}
     * @memberof KvintaMutateResultTradeItemKey
     */
    errors?: Array<KvintaIdAndErrorTradeItemKey> | null;
}

export function KvintaMutateResultTradeItemKeyFromJSON(json: any): KvintaMutateResultTradeItemKey {
    return KvintaMutateResultTradeItemKeyFromJSONTyped(json, false);
}

export function KvintaMutateResultTradeItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateResultTradeItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upserted': !exists(json, 'upserted') ? undefined : (json['upserted'] === null ? null : (json['upserted'] as Array<any>).map(KvintaTradeItemKeyFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : (json['deleted'] as Array<any>).map(KvintaTradeItemKeyFromJSON)),
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(KvintaIdAndErrorTradeItemKeyFromJSON)),
    };
}

export function KvintaMutateResultTradeItemKeyToJSON(value?: KvintaMutateResultTradeItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upserted': value.upserted === undefined ? undefined : (value.upserted === null ? null : (value.upserted as Array<any>).map(KvintaTradeItemKeyToJSON)),
        'deleted': value.deleted === undefined ? undefined : (value.deleted === null ? null : (value.deleted as Array<any>).map(KvintaTradeItemKeyToJSON)),
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(KvintaIdAndErrorTradeItemKeyToJSON)),
    };
}


