/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationType,
    KvintaOperationTypeFromJSON,
    KvintaOperationTypeFromJSONTyped,
    KvintaOperationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcEvent
 */
export interface KvintaEpcEvent {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcEvent
     */
    epc: string;
    /**
     * 
     * @type {KvintaOperationType}
     * @memberof KvintaEpcEvent
     */
    op: KvintaOperationType;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcEvent
     */
    ts: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcEvent
     */
    idx?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcEvent
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcEvent
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcEvent
     */
    shipDoc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcEvent
     */
    prodBatch?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KvintaEpcEvent
     */
    attr: { [key: string]: string; };
}

export function KvintaEpcEventFromJSON(json: any): KvintaEpcEvent {
    return KvintaEpcEventFromJSONTyped(json, false);
}

export function KvintaEpcEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': json['epc'],
        'op': KvintaOperationTypeFromJSON(json['op']),
        'ts': json['ts'],
        'idx': !exists(json, 'idx') ? undefined : json['idx'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'shipDoc': !exists(json, 'shipDoc') ? undefined : json['shipDoc'],
        'prodBatch': !exists(json, 'prodBatch') ? undefined : json['prodBatch'],
        'attr': json['attr'],
    };
}

export function KvintaEpcEventToJSON(value?: KvintaEpcEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'op': KvintaOperationTypeToJSON(value.op),
        'ts': value.ts,
        'idx': value.idx,
        'parentId': value.parentId,
        'location': value.location,
        'shipDoc': value.shipDoc,
        'prodBatch': value.prodBatch,
        'attr': value.attr,
    };
}


