/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaEventListFilter
 */
export interface KvintaEventListFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaEventListFilter
     */
    epc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEventListFilter
     */
    loc?: string | null;
}

export function KvintaEventListFilterFromJSON(json: any): KvintaEventListFilter {
    return KvintaEventListFilterFromJSONTyped(json, false);
}

export function KvintaEventListFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEventListFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': !exists(json, 'epc') ? undefined : json['epc'],
        'loc': !exists(json, 'loc') ? undefined : json['loc'],
    };
}

export function KvintaEventListFilterToJSON(value?: KvintaEventListFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'loc': value.loc,
    };
}


