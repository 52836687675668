import { action, makeObservable, observable, toJS } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as ECaptureStoreApi,
  KvintaMessageDirection,
  KvintaMessageKey,
  KvintaMessageRecord,
  KvintaMessageStatusType,
  KvintaOperationStatus,
  KvintaResourceType,
} from 'kvinta/apis/kvinta-epcis-capture';
import { KvintaSortDirection, KvintaSortExpressions } from 'kvinta/apis/kvinta-masterdata';
import { NotificationManager } from 'kvinta/modules/main';
import { EFilterType, IFilter, TFilter } from '../../../components/FilterBar/FilterBar';
import { downloadFile } from '../../../service/fileExport';
import { timeFormatting } from '../../../common/utils';

export enum EButtonState {
  ACTIVE = 'active',
  LOADING = 'loading',
  DISABLED = 'disabled',
}

export type TListEpcisMessage = {
  messageKey: KvintaMessageKey;
  id: string;
  created: string;
  direction: string;
  type: KvintaResourceType;
  status: KvintaMessageStatusType;
  updated: string;
  error?: string;
  sender?: string;
  receiver?: string;
  downloadButtonState: EButtonState;
};

export type TSummaryEpcisMessage = {
  messageKey: KvintaMessageKey;
  id: string;
  created: string;
  direction: string;
  type: KvintaResourceType;
  status: KvintaMessageStatusType;
  updated: string;
  error?: string;
  sender?: string;
  receiver?: string;
  triggeringEventId?: string;
  userId?: string;
  workflowId?: string;
  path?: string;
  downloadButtonState: EButtonState;
};

export interface UploadFormDialogData {
  location: string;
  timezone: string;
  timestamp: string;
  bizStep: string;
  selectedFile: any;
  showError: boolean;
}

export class EpcisMessageStore {
  private _config: TAppOptionsConfig;
  private _epcisCaptureApi: ECaptureStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: TListEpcisMessage[];
  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpressions;
  filter: IFilter;
  currentMessage: TSummaryEpcisMessage;
  isFailedMessageLoading = false;
  isFailedMessageForRetryLoading = false;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, epcisCaptureApi: ECaptureStoreApi) {
    makeObservable(this, {
      fetchPage: action.bound,
      downloadMessageFromMessageList: action.bound,
      downloadMessage: action.bound,
      fetchMessageList: action.bound,
      fetchMessageById: action.bound,
      ignoreFailedMessage: action.bound,
      sendFailedMessageForRetry: action.bound,
      setFiltersForRedirect: action.bound,

      isLoading: observable,
      listData: observable,
      page: observable,
      pageSize: observable,
      filter: observable,
      currentMessage: observable,
      isFailedMessageLoading: observable,
      isFailedMessageForRetryLoading: observable,
    });

    this._config = config;
    this._epcisCaptureApi = epcisCaptureApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'timestamp',
          ignoreCase: false,
        },
      ],
    };

    this.filter = new IFilter(getInitialFiltersData(), this.doFilter);
  }

  doFilter = async () => {
    this.isLoading = true;
    this.page = 0;
    this.fetchMessageList();
  };

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;

    this.fetchMessageList();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const dir = getDirection(orderDirection);
    const field = getField(orderBy);
    this.currentSort = {
      expressions: [
        {
          direction: dir,
          property: field,
          ignoreCase: false,
        },
      ],
    };
    this.fetchMessageList();
  }

  async fetchMessageById(id: string) {
    this.currentMessage = undefined;
    this.isLoading = true;

    this._epcisCaptureApi
      .queryMessageRecords({
        kvintaQueryRequestMessageRecordFilter: {
          paging: {
            page: 0,
            size: this.pageSize,
            sort: this.currentSort as any,
          },
          filter: {
            messageId: id,
          },
        },
      })
      .then((result) => {
        if (result.total === 0) {
          this._notificationManager.sendError(`Cannot find message ${id}`);
        } else {
          this.currentMessage = transformMessageForSummaryView(result.list[0]);
          this.totalCount = result.total || 0;
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  setFiltersForRedirect(initData: TInitialDataForFilter) {
    this.filter = new IFilter(getInitialFiltersData(initData), this.doFilter);
    this.page = 0;
  }

  async fetchMessageList() {
    this.isLoading = true;
    const filters = this.filter.visibleFilters.reduce((acc, filter) => {
      if (filter.type === EFilterType.TEXT_FILTER && filter.value.trim() !== '') {
        acc[filter.id] = filter.value;
      } else if (filter.type === EFilterType.SELECT_FILTER && filter.value !== 'default') {
        acc[filter.id] = filter.value;
      }
      return acc;
    }, {});

    this._epcisCaptureApi
      .queryMessageRecords({
        kvintaQueryRequestMessageRecordFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: this.currentSort as any,
          },
          filter: filters,
        },
      })
      .then((result) => {
        this.listData = (result.list || []).map(transformMessageForListView);
        this.totalCount = result.total || 0;
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  downloadMessageFromMessageList(messageKey: KvintaMessageKey) {
    const listData = toJS(this.listData);
    const messageIndex = listData.findIndex(
      (entry) =>
        entry.messageKey.id === messageKey.id &&
        entry.messageKey.type === messageKey.type &&
        entry.messageKey.direction === messageKey.direction,
    );
    if (messageIndex > -1) {
      listData[messageIndex].downloadButtonState = EButtonState.LOADING;
      this.listData = listData;

      this.downloadMessage(messageKey).finally(() => {
        listData[messageIndex].downloadButtonState = EButtonState.ACTIVE;
        this.listData = listData;
      });
    }
  }

  downloadMessageFromMessageSummary(messageKey: KvintaMessageKey) {
    this.currentMessage.downloadButtonState = EButtonState.LOADING;
    this.downloadMessage(messageKey).finally(() => {
      this.currentMessage.downloadButtonState = EButtonState.ACTIVE;
    });
  }

  downloadMessage(messageKey: KvintaMessageKey) {
    return this._epcisCaptureApi
      .readMessageContent({
        kvintaMessageKey: messageKey,
      })
      .then((result) => {
        if (result.status !== KvintaOperationStatus.Ok) {
          this._notificationManager.sendError(
            `an error occurred while downloading the message ${messageKey.id}${
              result.error ? `: ${result.error}` : ''
            }`,
          );
        } else {
          downloadFile(`${messageKey.id}.xml`, 'application/xml', JSON.parse(result.data));
        }
      })
      .catch((error) => {
        this._notificationManager.sendError(
          `an error occurred while downloading the message ${messageKey.id}${
            error.message ? `: ${error.message}` : ''
          }`,
        );
      });
  }

  async ignoreFailedMessage(key: KvintaMessageKey) {
    try {
      this.isFailedMessageLoading = true;
      const reciveData = await this._epcisCaptureApi.ignoreFailedMessage({
        kvintaMessageKey: key,
      });
      if (reciveData.data.status) {
        await this.fetchMessageById(key.id);
      }
    } catch (error) {
      this._notificationManager.sendError(
        `an error occurred while ignore Failed Message ${key.id}${error.message ? `: ${error.message}` : ''}`,
      );
    } finally {
      this.isFailedMessageLoading = false;
    }
  }
  async sendFailedMessageForRetry(key: KvintaMessageKey) {
    try {
      this.isFailedMessageForRetryLoading = true;
      const reciveData = await this._epcisCaptureApi.markFailedMessageForRetry({
        kvintaMessageKey: key,
      });
      if (reciveData.data.status) {
        await this.fetchMessageById(key.id);
      }
    } catch (error) {
      this._notificationManager.sendError(
        `an error occurred while send Failed Message For Retry ${key.id}${error.message ? `: ${error.message}` : ''}`,
      );
    } finally {
      this.isFailedMessageForRetryLoading = false;
    }
  }
}

function transformMessageForListView(message: KvintaMessageRecord) {
  return {
    messageKey: message.key,
    id: message.key.id,
    created: timeFormatting(message.timestamp),
    direction: convertDirection(message.key.direction),
    type: message.key.type,
    status: message.lastStatus.status,
    updated: timeFormatting(message.lastStatus.timestamp),
    error: message.lastStatus.error || '',
    sender: message.attributes.sender || '',
    receiver: message.attributes.receiver || '',
    downloadButtonState: EButtonState.ACTIVE,
  };
}

function transformMessageForSummaryView(message: KvintaMessageRecord) {
  return {
    messageKey: message.key,

    id: message.key.id,
    direction: convertDirection(message.key.direction),
    type: message.key.type,

    created: timeFormatting(message.timestamp),
    path: message.path || '',

    status: message.lastStatus.status,
    updated: timeFormatting(message.lastStatus.timestamp),
    error: message.lastStatus.error,

    triggeringEventId: message.attributes.triggeringEventId || '',
    userId: message.attributes.userId || '',
    workflowId: message.attributes.workflowId || '',
    sender: message.attributes.sender || '',
    receiver: message.attributes.receiver || '',

    downloadButtonState: EButtonState.ACTIVE,
  };
}

function convertDirection(messageDirection: KvintaMessageDirection) {
  switch (messageDirection) {
    case KvintaMessageDirection.I:
      return 'In';
    case KvintaMessageDirection.O:
      return 'Out';
  }
}

export const EPCIS_MESSAGE_STORE_ID = 'epcisMessageStore';

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'timestamp';
    case 2:
      return 'direction';
    case 3:
      return 'type';
    case 4:
      return 'lastStatus';
    case 5:
      return 'lastStatusTimestamp';
    case 6:
      return 'lastStatusError';
    case 7:
      return 'sender';
    case 8:
      return 'receiver';
    default:
      return 'created';
  }
}

const requiredFields = ['reason'];

export function errorRequired(id: string, value: any): boolean {
  if (requiredFields.includes(id) && (value === undefined || value === '')) {
    return true;
  }
  return false;
}

function isNotEmpty() {
  return function (data: string) {
    if (data && typeof data === 'string' && data.trim() !== '') {
      return undefined;
    } else {
      return 'Please fill in this field';
    }
  };
}

function formIsValid(formData): boolean {
  const validations = {
    reason: [isNotEmpty()],
  };

  const validationErrors: { [key: string]: string | undefined } = Object.keys(validations).reduce((acc, key) => {
    acc[key] = validateField(validations[key], formData[key]);
    return acc;
  }, {});
  const formHasErrors = Object.values(validationErrors).reduce((acc: boolean, fieldError: string | undefined) => {
    return acc || fieldError !== undefined;
  }, false);

  return !formHasErrors;
}

function validateField(fieldValidations, fieldData): string | undefined {
  return fieldValidations.reduce((acc, validationFn) => {
    return acc !== undefined ? acc : validationFn(fieldData);
  }, undefined);
}

type TInitialDataForFilter = {
  key: string;
  value?: string | string[];
  from?: number;
  to?: number;
}[];

function getInitialFiltersData(initialDataForFilter: TInitialDataForFilter = []): TFilter[] {
  return initialFiltersData.map((filterData) => {
    const dataForFilter = initialDataForFilter.find((data) => data.key === filterData.id);
    if (dataForFilter) {
      if (filterData.type === EFilterType.MULITPLE_SELECT_FILTER && Array.isArray(dataForFilter.value)) {
        return {
          ...filterData,
          isActive: true,
          value: dataForFilter.value,
        };
      } else if (
        filterData.type === EFilterType.DATE_RANGE_FILTER &&
        typeof dataForFilter.from === 'string' &&
        typeof dataForFilter.to === 'string'
      ) {
        return {
          ...filterData,
          isActive: true,
          value: {
            from: dataForFilter.from,
            to: dataForFilter.to,
          },
        };
      } else if (
        (filterData.type === EFilterType.TEXT_FILTER || filterData.type === EFilterType.SELECT_FILTER) &&
        typeof dataForFilter.value === 'string'
      ) {
        return {
          ...filterData,
          isActive: true,
          value: dataForFilter.value,
        };
      }
    }
    return filterData;
  });
}

const initialFiltersData: TFilter[] = [
  {
    type: EFilterType.TEXT_FILTER,
    id: 'messageId',
    label: 'epcis-document.id',
    isActive: true,
    value: '',
  },
  {
    type: EFilterType.SELECT_FILTER,
    id: 'type',
    label: 'epcis-mesage.type',
    isActive: false,
    value: 'default',
    options: Object.values(KvintaResourceType).map((resourceType) => ({
      key: resourceType,
      label: resourceType,
    })),
  },
  {
    type: EFilterType.SELECT_FILTER,
    id: 'direction',
    label: 'epcis-mesage.direction',
    isActive: false,
    value: 'default',
    options: Object.values(KvintaMessageDirection).map((direction) => ({
      key: direction,
      label: convertDirection(direction),
    })),
  },
  {
    type: EFilterType.TEXT_FILTER,
    id: 'sender',
    label: 'epcis-mesage.sender',
    isActive: false,
    value: '',
  },
  {
    type: EFilterType.TEXT_FILTER,
    id: 'receiver',
    label: 'epcis-mesage.receiver',
    isActive: false,
    value: '',
  },
  {
    type: EFilterType.TEXT_FILTER,
    id: 'triggeringEventId',
    label: 'epcis-mesage.triggeringEventId',
    isActive: false,
    value: '',
  },
  {
    type: EFilterType.SELECT_FILTER,
    id: 'lastStatus',
    label: 'epcis-mesage.status',
    isActive: false,
    value: 'default',
    options: Object.values(KvintaMessageStatusType).map((status) => ({
      key: status,
      label: status,
    })),
  },
];
