/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGetEpcByBizTransactionBizTransaction
 */
export interface KvintaGetEpcByBizTransactionBizTransaction {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcByBizTransactionBizTransaction
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcByBizTransactionBizTransaction
     */
    documentType: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcByBizTransactionBizTransaction
     */
    documentNumber: string;
}

export function KvintaGetEpcByBizTransactionBizTransactionFromJSON(json: any): KvintaGetEpcByBizTransactionBizTransaction {
    return KvintaGetEpcByBizTransactionBizTransactionFromJSONTyped(json, false);
}

export function KvintaGetEpcByBizTransactionBizTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcByBizTransactionBizTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': json['location'],
        'documentType': json['documentType'],
        'documentNumber': json['documentNumber'],
    };
}

export function KvintaGetEpcByBizTransactionBizTransactionToJSON(value?: KvintaGetEpcByBizTransactionBizTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': value.location,
        'documentType': value.documentType,
        'documentNumber': value.documentNumber,
    };
}


