import React from 'react';
import { TDeliveryNoteFormData } from './DeliveryNotesStore';
import { observer } from 'mobx-react';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { TextField } from '../../../components/FormFields/TextField';
import styles from './Deliveries.module.scss';
import { SelectInput } from '../../../components/FormFields/SelectInput';

export enum EFormType {
  UPDATE = 'update',
  CREATE = 'create',
}

export interface DeliveryNoteComponentProps {
  formData: TDeliveryNoteFormData;
  onBlur: (id: string) => void;
  onChange: (id: string, value: any) => void;
  formType: EFormType;
}

const DeliveryNoteFormBase: React.FunctionComponent<DeliveryNoteComponentProps> = ({
  onChange,
  formData,
  formType,
  onBlur,
}) => {
  return (
    <div className={styles.updateFormContainer} id={'DeliveryNoteFormID'}>
      <TextInput
        {...formData['deliveryNoteForm.docLocation']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.document.location"
      />
      <TextInput
        {...formData['deliveryNoteForm.docNumber']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.document.number"
      />
      <SelectInput
        {...formData['deliveryNoteForm.docType']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.document.type"
      />

      <div className={styles.divider} />
      <TextInput
        {...formData['deliveryNoteForm.refLocation']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.referenceDocument.location"
      />
      <TextInput
        {...formData['deliveryNoteForm.refNumber']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.referenceDocument.number"
      />
      <SelectInput
        {...formData['deliveryNoteForm.refType']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.referenceDocument.type"
      />
      <div className={styles.divider} />
      <TextInput
        {...formData['deliveryNoteForm.shipFrom']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.shipFrom"
      />
      <TextInput
        {...formData['deliveryNoteForm.shipTo']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.shipTo"
      />
      <TextInput
        {...formData['deliveryNoteForm.soldFrom']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.soldFrom"
      />
      <TextInput
        {...formData['deliveryNoteForm.soldTo']}
        onBlur={onBlur}
        onChange={onChange}
        label="delivery-note-form.field.soldTo"
      />
      {formType === EFormType.UPDATE && (
        <TextField {...formData['deliveryNoteForm.alarmStatus']} label="delivery-note-form.field.alarmStatus" />
      )}
    </div>
  );
};

export const DeliveryNoteForm = observer(DeliveryNoteFormBase);
