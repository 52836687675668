/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaOperationRequestEpcisDocument,
    KvintaOperationRequestEpcisDocumentFromJSON,
    KvintaOperationRequestEpcisDocumentToJSON,
    KvintaOperationRequestEpcisDocumentRecord,
    KvintaOperationRequestEpcisDocumentRecordFromJSON,
    KvintaOperationRequestEpcisDocumentRecordToJSON,
    KvintaOperationRequestEpcisEvent,
    KvintaOperationRequestEpcisEventFromJSON,
    KvintaOperationRequestEpcisEventToJSON,
    KvintaOperationRequestListEpcisDocumentsRequest,
    KvintaOperationRequestListEpcisDocumentsRequestFromJSON,
    KvintaOperationRequestListEpcisDocumentsRequestToJSON,
    KvintaOperationRequestListEpcisEvent,
    KvintaOperationRequestListEpcisEventFromJSON,
    KvintaOperationRequestListEpcisEventToJSON,
    KvintaOperationRequestListEpcisEventsRequest,
    KvintaOperationRequestListEpcisEventsRequestFromJSON,
    KvintaOperationRequestListEpcisEventsRequestToJSON,
    KvintaOperationRequestString,
    KvintaOperationRequestStringFromJSON,
    KvintaOperationRequestStringToJSON,
    KvintaOperationResponseEpcisDocument,
    KvintaOperationResponseEpcisDocumentFromJSON,
    KvintaOperationResponseEpcisDocumentToJSON,
    KvintaOperationResponseEpcisDocumentRecord,
    KvintaOperationResponseEpcisDocumentRecordFromJSON,
    KvintaOperationResponseEpcisDocumentRecordToJSON,
    KvintaOperationResponseEpcisEvent,
    KvintaOperationResponseEpcisEventFromJSON,
    KvintaOperationResponseEpcisEventToJSON,
    KvintaOperationResponseEpcisEventRecord,
    KvintaOperationResponseEpcisEventRecordFromJSON,
    KvintaOperationResponseEpcisEventRecordToJSON,
    KvintaOperationResponseGetListResultEpcisDocumentRecord,
    KvintaOperationResponseGetListResultEpcisDocumentRecordFromJSON,
    KvintaOperationResponseGetListResultEpcisDocumentRecordToJSON,
    KvintaOperationResponseGetListResultEpcisEventRecord,
    KvintaOperationResponseGetListResultEpcisEventRecordFromJSON,
    KvintaOperationResponseGetListResultEpcisEventRecordToJSON,
    KvintaOperationResponseListEpcisEventRecord,
    KvintaOperationResponseListEpcisEventRecordFromJSON,
    KvintaOperationResponseListEpcisEventRecordToJSON,
} from '../models';

export interface FindEpcisEventRecordsByParentIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ListEpcisDocumentsRequest {
    kvintaOperationRequestListEpcisDocumentsRequest: KvintaOperationRequestListEpcisDocumentsRequest;
}

export interface ListEpcisEventsRequest {
    kvintaOperationRequestListEpcisEventsRequest: KvintaOperationRequestListEpcisEventsRequest;
}

export interface ReadEpcisDocumentByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisDocumentRecordByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisDocumentRecordById0Request {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisEventByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface ReadEpcisEventRecordByIdRequest {
    kvintaOperationRequestString: KvintaOperationRequestString;
}

export interface WriteEpcisDocumentRequest {
    kvintaOperationRequestEpcisDocument: KvintaOperationRequestEpcisDocument;
}

export interface WriteEpcisDocumentRecordRequest {
    kvintaOperationRequestEpcisDocumentRecord: KvintaOperationRequestEpcisDocumentRecord;
}

export interface WriteEpcisEventRequest {
    kvintaOperationRequestEpcisEvent: KvintaOperationRequestEpcisEvent;
}

export interface WriteEpcisEventsRequest {
    kvintaOperationRequestListEpcisEvent: KvintaOperationRequestListEpcisEvent;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     */
    async findEpcisEventRecordsByParentIdRaw(requestParameters: FindEpcisEventRecordsByParentIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling findEpcisEventRecordsByParentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/findEpcisEventRecordsByParentId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     * Tries to find EPCIS events by given parent_id value.   In case there is no such event, the function will return OperationResponse(status = OK, data = [], message = \"No records found\")   Only in case there is an error, e.g. the database cannot be reached, it will return OperationResponse(status = ERROR, error = \"...\")
     */
    async findEpcisEventRecordsByParentId(requestParameters: FindEpcisEventRecordsByParentIdRequest): Promise<KvintaOperationResponseListEpcisEventRecord> {
        const response = await this.findEpcisEventRecordsByParentIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEpcisDocumentsRaw(requestParameters: ListEpcisDocumentsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestListEpcisDocumentsRequest === null || requestParameters.kvintaOperationRequestListEpcisDocumentsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEpcisDocumentsRequest','Required parameter requestParameters.kvintaOperationRequestListEpcisDocumentsRequest was null or undefined when calling listEpcisDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEpcisDocuments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEpcisDocumentsRequestToJSON(requestParameters.kvintaOperationRequestListEpcisDocumentsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async listEpcisDocuments(requestParameters: ListEpcisDocumentsRequest): Promise<KvintaOperationResponseGetListResultEpcisDocumentRecord> {
        const response = await this.listEpcisDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async listEpcisEventsRaw(requestParameters: ListEpcisEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseGetListResultEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestListEpcisEventsRequest === null || requestParameters.kvintaOperationRequestListEpcisEventsRequest === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEpcisEventsRequest','Required parameter requestParameters.kvintaOperationRequestListEpcisEventsRequest was null or undefined when calling listEpcisEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/listEpcisEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEpcisEventsRequestToJSON(requestParameters.kvintaOperationRequestListEpcisEventsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseGetListResultEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async listEpcisEvents(requestParameters: ListEpcisEventsRequest): Promise<KvintaOperationResponseGetListResultEpcisEventRecord> {
        const response = await this.listEpcisEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisDocumentByIdRaw(requestParameters: ReadEpcisDocumentByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocument>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisDocumentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisDocumentById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisDocumentById(requestParameters: ReadEpcisDocumentByIdRequest): Promise<KvintaOperationResponseEpcisDocument> {
        const response = await this.readEpcisDocumentByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisDocumentRecordByIdRaw(requestParameters: ReadEpcisDocumentRecordByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisDocumentRecordById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisDocumentRecordById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisDocumentRecordById(requestParameters: ReadEpcisDocumentRecordByIdRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.readEpcisDocumentRecordByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisDocumentRecordById_1Raw(requestParameters: ReadEpcisDocumentRecordById0Request): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisDocumentRecordById_1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisDocumentRecordBySha256`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisDocumentRecordById_1(requestParameters: ReadEpcisDocumentRecordById0Request): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.readEpcisDocumentRecordById_1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisEventByIdRaw(requestParameters: ReadEpcisEventByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEvent>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisEventById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisEventById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisEventById(requestParameters: ReadEpcisEventByIdRequest): Promise<KvintaOperationResponseEpcisEvent> {
        const response = await this.readEpcisEventByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisEventRecordByIdRaw(requestParameters: ReadEpcisEventRecordByIdRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestString === null || requestParameters.kvintaOperationRequestString === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestString','Required parameter requestParameters.kvintaOperationRequestString was null or undefined when calling readEpcisEventRecordById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisEventRecordById`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestStringToJSON(requestParameters.kvintaOperationRequestString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisEventRecordById(requestParameters: ReadEpcisEventRecordByIdRequest): Promise<KvintaOperationResponseEpcisEventRecord> {
        const response = await this.readEpcisEventRecordByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeEpcisDocumentRaw(requestParameters: WriteEpcisDocumentRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestEpcisDocument === null || requestParameters.kvintaOperationRequestEpcisDocument === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisDocument','Required parameter requestParameters.kvintaOperationRequestEpcisDocument was null or undefined when calling writeEpcisDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeEpcisDocument`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisDocumentToJSON(requestParameters.kvintaOperationRequestEpcisDocument),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async writeEpcisDocument(requestParameters: WriteEpcisDocumentRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.writeEpcisDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeEpcisDocumentRecordRaw(requestParameters: WriteEpcisDocumentRecordRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisDocumentRecord>> {
        if (requestParameters.kvintaOperationRequestEpcisDocumentRecord === null || requestParameters.kvintaOperationRequestEpcisDocumentRecord === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisDocumentRecord','Required parameter requestParameters.kvintaOperationRequestEpcisDocumentRecord was null or undefined when calling writeEpcisDocumentRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeEpcisDocumentRecord`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisDocumentRecordToJSON(requestParameters.kvintaOperationRequestEpcisDocumentRecord),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisDocumentRecordFromJSON(jsonValue));
    }

    /**
     */
    async writeEpcisDocumentRecord(requestParameters: WriteEpcisDocumentRecordRequest): Promise<KvintaOperationResponseEpcisDocumentRecord> {
        const response = await this.writeEpcisDocumentRecordRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeEpcisEventRaw(requestParameters: WriteEpcisEventRequest): Promise<runtime.ApiResponse<KvintaOperationResponseEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestEpcisEvent === null || requestParameters.kvintaOperationRequestEpcisEvent === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestEpcisEvent','Required parameter requestParameters.kvintaOperationRequestEpcisEvent was null or undefined when calling writeEpcisEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeEpcisEvent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestEpcisEventToJSON(requestParameters.kvintaOperationRequestEpcisEvent),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async writeEpcisEvent(requestParameters: WriteEpcisEventRequest): Promise<KvintaOperationResponseEpcisEventRecord> {
        const response = await this.writeEpcisEventRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeEpcisEventsRaw(requestParameters: WriteEpcisEventsRequest): Promise<runtime.ApiResponse<KvintaOperationResponseListEpcisEventRecord>> {
        if (requestParameters.kvintaOperationRequestListEpcisEvent === null || requestParameters.kvintaOperationRequestListEpcisEvent === undefined) {
            throw new runtime.RequiredError('kvintaOperationRequestListEpcisEvent','Required parameter requestParameters.kvintaOperationRequestListEpcisEvent was null or undefined when calling writeEpcisEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeEpcisEvents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaOperationRequestListEpcisEventToJSON(requestParameters.kvintaOperationRequestListEpcisEvent),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseListEpcisEventRecordFromJSON(jsonValue));
    }

    /**
     */
    async writeEpcisEvents(requestParameters: WriteEpcisEventsRequest): Promise<KvintaOperationResponseListEpcisEventRecord> {
        const response = await this.writeEpcisEventsRaw(requestParameters);
        return await response.value();
    }

}
