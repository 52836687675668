import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  DefaultApi as MDDocumentApi,
  KvintaExternalId,
  KvintaProduct,
  KvintaSerializationType,
  KvintaSortDirection,
  KvintaSortExpression,
  KvintaTradeItemInProduct,
} from 'kvinta/apis/kvinta-masterdata';
import {
  convertSerializationTypeToNumber,
  getExternalId,
  getInternalId,
  translateSerializationType,
} from 'kvinta/apis/utils';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, select, textInput } from '../../../common/formUtils/formDataGenerators';
import { validateForm } from '../../../common/formUtils/core';
import { isNotEmpty, maxLength } from '../../../common/formUtils/validators';
import { TSelectInputData, TTextInputData } from '../../../common/formUtils/types';

export type TProductFormData = {
  'productForm.sourceSystem': TTextInputData;
  'productForm.idInSourceSystem': TTextInputData;
  'productForm.sku': TTextInputData;
  'productForm.baseUom': TTextInputData;
  'productForm.name': TTextInputData;
  'productForm.serializationType': TSelectInputData;
  'productForm.businessPartnerId': TSelectInputData;
  'productForm.businessPartnerName': TTextInputData;
};

export type TTradeItemFormData = {
  product: KvintaExternalId;
  baseUomDenominator: number;
  baseUomNominator: number;
  gtin: string;
  uom: string;
  showError: boolean;
};

export type TProduct = {
  sourceSystem: string;
  idInSourceSystem: string;
  sku: string;
  baseUom: string;
  name: string;
  items: KvintaTradeItemInProduct[];
  serializationType: KvintaSerializationType;
  businessPartnerId: string;
  businessPartnerName: string;
};

export type TProductForExport = {
  sourceSystem?: string;
  idInSourceSystem?: string;
  sku?: string;
  baseUom?: string;
  name?: string;
  serializationType?: KvintaSerializationType;
  businessPartner: string;
};

interface IProductRow extends KvintaProduct, ISelectableRow {}

export class ProductsStore extends SelectableStore<IProductRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  currentSort: KvintaSortExpression;
  productFormOpen: boolean;
  productFormData?: TProductFormData;
  currentProduct?: TProduct;

  productId?: string;
  exportActive: boolean;
  exportData?: TProductForExport[];
  autofocusSearchInList: boolean;

  searchValue: string;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      isLoading: observable,

      fetchPage: action.bound,
      fetchProductList: action.bound,
      updateSearch: action.bound,
      searchValue: observable,
      unfocusSearchField: action.bound,
      page: observable,
      pageSize: observable,

      fetchProduct: action.bound,
      currentProduct: observable,

      openUpdateProductForm: action.bound,
      openCreateProductForm: action.bound,
      openProductForm: action.bound,
      productFormOpen: observable,
      productFormData: observable,
      onChangeProductFormField: action.bound,
      submitCreateProduct: action.bound,
      submitUpdateProduct: action.bound,
      closeProductForm: action.bound,
      deleteProduct: action.bound,

      exportActive: observable,
      updateExported: action.bound,
      exportSelected: action.bound,
      exportAll: action.bound,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.autofocusSearchInList = false;

    this.currentSort = {
      direction: KvintaSortDirection.Desc,
      property: 'name',
      ignoreCase: false,
    };
  }

  fetchPage(page: number) {
    this.page = page;

    this.isLoading = true;
    return this.fetchProductList().finally(() => {
      this.isLoading = false;
    });
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const field = getFieldProductFieldList(orderBy);
    this.currentSort = {
      property: field,
      direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
      ignoreCase: false,
    };
    return this.fetchProductList().finally(() => {
      this.isLoading = false;
    });
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    return this.fetchProductList().finally(() => {
      this.isLoading = false;
    });
  }

  fetchProductList = (): Promise<void> => {
    this.listData = [];
    this.totalCount = 0;

    return this._mdApi
      .queryProducts({
        kvintaQueryRequestProductFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: { expressions: [this.currentSort] },
          },
          filter: {
            nameLike: this.searchValue,
          },
        },
      })
      .then((result) => {
        const oldSelection = new Map();
        if (this.listData) {
          this.listData.forEach((row) => {
            oldSelection.set(row.id, row.isSelected);
          });
        }
        this.totalCount = result.total || 0;
        this.listData = (result.list || []).map((comp) => {
          const selected = oldSelection.get(getInternalId(comp)) || false;
          return { ...comp, id: getInternalId(comp), isSelected: selected } as IProductRow;
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(JSON.stringify(err));
      });
  };

  openCreateProductForm() {
    const initialValues = (businessPartners) => ({
      sourceSystem: '',
      idInSourceSystem: '',
      businessPartnerId: getInternalId(businessPartners[0]),
      serializationType: KvintaSerializationType.Unknown,
      sku: '',
      baseUom: '',
      name: '',
      items: [],
      showError: false,
    });
    this.openProductForm(initialValues);
  }

  openProductForm(initialValues) {
    this.isLoading = true;
    this._mdApi
      .queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      })
      .then((result) => {
        const businessPartners = result.list;
        if (businessPartners.length) {
          this.productFormData = validateForm<TProductFormData>(
            generateProductFormData(PRODUCT_FORM_ROOT_ID, businessPartners, initialValues(businessPartners)),
          );
          this.productFormOpen = true;
        } else {
          this._notificationManager.sendError(`Create a Business Partner First`);
        }
      })
      .catch((err: Error) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeProductForm() {
    this.productFormData = undefined;
    this.productFormOpen = false;
  }

  submitProduct(submitData, nextAction) {
    this.isLoading = true;

    this._mdApi
      .mutateProducts({
        kvintaMutateRequestProductExternalId: {
          upsert: [submitData],
        },
      })
      .then(async (result) => {
        if (!result.errors || result.errors.length === 0) {
          this._notificationManager.sendSuccess(`Successfully submitted product data`);
          await nextAction();
          this.productFormOpen = false;
        } else {
          const errors = result.errors.map((err) => err.error).join('\n');
          this._notificationManager.sendError(errors);
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  submitCreateProduct = () => {
    this.submitProduct(
      {
        ...convertProductData(this.productFormData),
        items: [],
      },
      () => this.fetchProductList(),
    );
  };

  openUpdateProductForm() {
    const initialValues = () => ({
      sourceSystem: this.currentProduct.sourceSystem || '',
      idInSourceSystem: this.currentProduct.idInSourceSystem || '',
      businessPartnerId: this.currentProduct.businessPartnerId,
      serializationType: this.currentProduct.serializationType || KvintaSerializationType.Unknown,
      sku: this.currentProduct.sku || '',
      name: this.currentProduct.name || '',
      baseUom: this.currentProduct.baseUom || '',
      items: this.currentProduct.items || [],
      showError: true,
    });
    this.openProductForm(initialValues);
  }

  onChangeProductFormField = (id: string, value: any) => {
    const formData = toJS(this.productFormData);
    this.productFormData = handleFormChange(formData, id, value);
  };

  onBlurProductFormField = (id: string) => {
    const formData = toJS(this.productFormData);
    this.productFormData = handleFormBlur(formData, id);
  };

  submitUpdateProduct = async () => {
    this.submitProduct(
      {
        ...convertProductData(this.productFormData),
        items: this.currentProduct.items || [],
      },
      () => this.fetchProduct(getInternalId(this.currentProduct)),
    );
  };

  deleteProduct = async (id: string) => {
    this.isLoading = true;
    return this._mdApi
      .mutateProducts({
        kvintaMutateRequestProductExternalId: {
          _delete: [getExternalId(id)],
        },
      })
      .then((result) => {
        if (result.errors && result.errors.length) {
          const errors = result.errors.map((err) => err.error).join('\n');
          this._notificationManager.sendError(`Could not delete product ${id}: ${errors}`);
        } else {
          this.fetchProductList();
        }
      })
      .catch((err: Error) => {
        console.log(err);
        this._notificationManager.sendError(`Could not delete product${err.message ? `: ${err.message}` : ''}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    this.exportData = this.listChecked.map((product) => ({
      ...product,
      businessPartner: product.businessPartner.idInSourceSystem,
    })) as any;
    this.exportActive = true;
  }

  exportAll() {
    this.exportActive = false;
    this.exportData = undefined;

    Promise.all([
      this._mdApi.queryProducts({
        kvintaQueryRequestProductFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      }),
      this._mdApi.queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      }),
    ])
      .then(([productsListResult, businessPartnerListResult]) => {
        this.exportData = productsListResult.list.map((product) => {
          const businessPartnerName =
            businessPartnerListResult.list.find(
              (businessPartner) => businessPartner.idInSourceSystem === product.businessPartner.idInSourceSystem,
            )?.name || '';
          return {
            ...product,
            businessPartner: businessPartnerName,
          };
        });
        this.exportActive = true;
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      });
  }

  fetchProduct = async (id: string) => {
    this.isLoading = true;
    this.currentProduct = null;

    Promise.all([
      this._mdApi.queryProducts({
        kvintaQueryRequestProductFilter: {
          paging: {
            page: 0,
            size: 1,
          },
          filter: {
            id: id,
          },
        },
      }),

      this._mdApi.queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      }),
    ])
      .then(([productResult, businessPartnersResult]) => {
        if (productResult.total === 0) {
          this._notificationManager.sendError(`Could not find product with id ${id}`);
        } else {
          const currentProduct = productResult.list[0];
          const businessPartnerId = getInternalId(currentProduct.businessPartner);
          const businessPartnerName =
            businessPartnersResult.list.find((businessPartner) => getInternalId(businessPartner) === businessPartnerId)
              ?.name || '';

          this.currentProduct = {
            sourceSystem: currentProduct.sourceSystem || '',
            idInSourceSystem: currentProduct.idInSourceSystem || '',
            businessPartnerId,
            businessPartnerName,
            serializationType:
              Object.values(KvintaSerializationType)[currentProduct.serializationType] ||
              KvintaSerializationType.Unknown,
            sku: currentProduct.sku || '',
            name: currentProduct.name || '',
            baseUom: currentProduct.baseUom || '',
            items: currentProduct.items || [],
          };
        }
      })
      .catch((e) => {
        console.log(e);
        this._notificationManager.sendError(
          `An error occurred when fetching product data${e.message ? `: ${e.message}` : ''}`,
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}

function convertProductData(productData) {
  return {
    sourceSystem: productData['productForm.sourceSystem'].value,
    idInSourceSystem: productData['productForm.idInSourceSystem'].value,
    businessPartner: getExternalId(productData['productForm.businessPartnerId'].value),
    serializationType: convertSerializationTypeToNumber(productData['productForm.serializationType'].value),
    sku: productData['productForm.sku'].value,
    baseUom: productData['productForm.baseUom'].value,
    name: productData['productForm.name'].value,
  };
}

export const PRODUCT_STORE_ID = 'productsStore';
export const PRODUCT_FORM_ROOT_ID = 'productForm';

function getFieldProductFieldList(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'name';
    case 2:
      return 'sku';
    case 3:
      return 'baseUom';
    default:
      return 'name';
  }
}

function generateProductFormData(rootFormId: string, businessPartners: any[], initialValues: TProduct) {
  return formRoot<TProductFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      select({
        path: 'businessPartnerId',
        value: initialValues.businessPartnerId,
        options: businessPartners.map((businessPartner) => ({
          key: getInternalId(businessPartner),
          label: businessPartner.name,
        })),
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'name',
        value: initialValues.name,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'sourceSystem',
        value: initialValues.sourceSystem,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'idInSourceSystem',
        value: initialValues.idInSourceSystem,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'sku',
        value: initialValues.sku,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),

      textInput({
        path: 'baseUom',
        value: initialValues.baseUom,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      select({
        path: 'serializationType',
        value: initialValues.serializationType,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: true,
        options: Object.values(KvintaSerializationType).map((option) => ({
          key: option,
          label: translateSerializationType(option),
        })),
      }),
    ],
  });
}
