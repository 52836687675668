import { Button, Menu, MenuItem } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { action, computed, makeObservable, observable, set } from 'mobx';
import { TextFilter } from './TextFilter';
import { SelectFilter } from './SelectFilter';
import { MultipleSelectFilter } from './MultipleSelectFilter';
import { DateRangeFilter } from './DateRangeFilter';

export enum EFilterType {
  TEXT_FILTER = 'textFilter',
  SELECT_FILTER = 'selectFilter',
  MULITPLE_SELECT_FILTER = 'mulitpleSelectFilter',
  DATE_RANGE_FILTER = 'dateRangeFilter',
}

export type TTextFilter = {
  type: EFilterType.TEXT_FILTER;
  label: string;
  id: string;
  value: string;
  isActive: boolean;
  isApplied?: boolean;
  isFieldEmpty?: boolean;
};

export type TSelectFilter = {
  type: EFilterType.SELECT_FILTER;
  label: string;
  id: string;
  options: { key: string; label: string }[];
  value: string;
  isActive: boolean;
  isApplied?: boolean;
  isFieldEmpty?: boolean;
};

export type TMultipleSelectFilter = {
  type: EFilterType.MULITPLE_SELECT_FILTER;
  label: string;
  id: string;
  options: { key: string; label: string }[];
  value: string[];
  isActive: boolean;
  isApplied?: boolean;
  isFieldEmpty?: boolean;
};

export type TDateRangeFilter = {
  type: EFilterType.DATE_RANGE_FILTER;
  label: string;
  id: string;
  value: TDateRange;
  isActive: boolean;
  isApplied?: boolean;
  isFieldEmpty?: boolean;
};

type TDateRange = {
  from: string;
  to: string;
};

type TFilterProps = {
  filter: TFilter;
  intl: any;
  showRemoveButton: boolean;
  onChangeValue: (field: string, value: string | string[] | TDateRange) => void;
  onClear: (field: string) => void;
  onFilter: (field: string) => void;
  hideFilter: (field: string) => void;
};

export type TFilter = TTextFilter | TSelectFilter | TMultipleSelectFilter | TDateRangeFilter;

const Filter: React.FunctionComponent<TFilterProps> = ({
  filter,
  showRemoveButton,
  intl,
  onChangeValue,
  onClear,
  onFilter,
  hideFilter,
}) => {
  switch (filter.type) {
    case EFilterType.SELECT_FILTER:
      return (
        <SelectFilter
          key={filter.id}
          filter={filter}
          intl={intl}
          showRemoveButton={showRemoveButton}
          onChangeValue={onChangeValue}
          onClear={onClear}
          onFilter={onFilter}
          hideFilter={hideFilter}
        />
      );
    case EFilterType.TEXT_FILTER:
      return (
        <TextFilter
          key={filter.id}
          filter={filter}
          intl={intl}
          showRemoveButton={showRemoveButton}
          onChangeValue={onChangeValue}
          onClear={onClear}
          onFilter={onFilter}
          hideFilter={hideFilter}
        />
      );
    case EFilterType.MULITPLE_SELECT_FILTER:
      return (
        <MultipleSelectFilter
          key={filter.id}
          filter={filter}
          intl={intl}
          showRemoveButton={showRemoveButton}
          onChangeValue={onChangeValue}
          onClear={onClear}
          onFilter={onFilter}
          hideFilter={hideFilter}
        />
      );
    case EFilterType.DATE_RANGE_FILTER:
      return (
        <DateRangeFilter
          key={filter.id}
          filter={filter}
          intl={intl}
          showRemoveButton={showRemoveButton}
          onChangeValue={onChangeValue}
          onClear={onClear}
          onFilter={onFilter}
          hideFilter={hideFilter}
        />
      );
  }
};

export class IFilter {
  filters: TFilter[];
  private _executeFilter: () => Promise<void>;
  private _hideFilterButton: boolean;

  constructor(columns: TFilter[], executeFilter: () => Promise<void>, hideFilterButton?: boolean) {
    makeObservable(this, {
      filters: observable,

      showAddFilterButton: computed,
      visibleFilters: computed,
      hiddenFilters: computed,
      showDoFilterButton: computed,

      hideFilter: action,
      showFilter: action,
      onChangeValue: action,
      onFilter: action,
      onClear: action,
    });

    this.filters = columns.map((value) => {
      return { ...value, isApplied: true, isFieldEmpty: true } as TTextFilter;
    });
    this._executeFilter = executeFilter;
    this._hideFilterButton = hideFilterButton || false;
  }

  get showDoFilterButton(): boolean {
    if (this._hideFilterButton) {
      return false;
    }
    return this.visibleFilters.length > 0;
  }

  get showAddFilterButton(): boolean {
    for (const filter of this.filters) {
      if (!filter.isActive) {
        return true;
      }
    }
    return false;
  }

  get visibleFilters(): TFilter[] {
    const filters: TFilter[] = new Array<TFilter>();
    for (const filter of this.filters) {
      if (filter.isActive) {
        filters.push(filter);
      }
    }
    return filters;
  }

  get hiddenFilters(): TFilter[] {
    const filters: TFilter[] = new Array<TFilter>();
    for (const filter of this.filters) {
      if (!filter.isActive) {
        filters.push(filter);
      }
    }
    return filters;
  }

  onChangeValue = (filterId: string, value: string | string[] | TDateRange) => {
    const i = this.filters.findIndex((filter) => filter.id === filterId);
    if (i >= 0) {
      const filterData = { ...this.filters[i] };

      switch (filterData.type) {
        case EFilterType.DATE_RANGE_FILTER: {
          filterData.value = value as TDateRange;
          filterData.isApplied = false;
          filterData.isFieldEmpty = value === '';
          break;
        }
        default: {
          filterData.value = value as string | string[];
          filterData.isApplied = false;
          filterData.isFieldEmpty = value === '';
        }
      }
      set(this.filters, i, filterData);
    }
  };

  onClear = (filterId: string) => {
    const i = this.filters.findIndex((filter) => filter.id === filterId);
    if (i >= 0) {
      const filterData = { ...this.filters[i] };
      filterData.value = filterData.type === EFilterType.SELECT_FILTER ? 'default' : '';
      filterData.isApplied = true;
      filterData.isFieldEmpty = true;

      set(this.filters, i, filterData);
      this._executeFilter();
    }
  };

  onFilter = (filterId: string) => {
    const i = this.filters.findIndex((filter) => filter.id === filterId);
    if (i >= 0) {
      const filterData = { ...this.filters[i] };
      filterData.isApplied = true;

      set(this.filters, i, filterData);
      this._executeFilter();
    }
  };

  hideFilter = (filterId: string) => {
    const i = this.filters.findIndex((filter) => filter.id === filterId);
    if (i >= 0) {
      const filterData = { ...this.filters[i] };
      filterData.isActive = false;

      switch (filterData.type) {
        case EFilterType.SELECT_FILTER: {
          filterData.value = 'default';
          break;
        }
        case EFilterType.DATE_RANGE_FILTER: {
          filterData.value = { from: '', to: '' };
          break;
        }
        default:
          filterData.value = '';
      }

      set(this.filters, i, filterData);
      this._executeFilter();
    }
  };

  showFilter = (fieldId: string) => {
    const i = this.filters.findIndex((filter) => filter.id === fieldId);
    if (i >= 0) {
      const filterData = { ...this.filters[i] };
      filterData.isActive = true;

      set(this.filters, i, filterData);
    }
  };
}

interface IFilterBarProps {
  filter: IFilter;
  intl: any;
}

interface IFilterBarState {
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
}

class FilterBarImpl extends Component<IFilterBarProps, IFilterBarState> {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
    };
  }

  onMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
    });
  };

  onMenuClicked = (field: string) => {
    this.props.filter.showFilter(field);
    this.onMenuClose();
  };

  render() {
    const showAddFilterButton = this.props.filter.showAddFilterButton;
    const visibleFilters = this.props.filter.visibleFilters;
    const hiddenFilters = this.props.filter.hiddenFilters;
    const intl = this.props.intl;

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {visibleFilters.map((filter) => (
          <Filter
            key={filter.id}
            filter={filter}
            intl={intl}
            showRemoveButton={visibleFilters.length > 1}
            onChangeValue={this.props.filter.onChangeValue}
            onClear={this.props.filter.onClear}
            onFilter={this.props.filter.onFilter}
            hideFilter={this.props.filter.hideFilter}
          />
        ))}
        {showAddFilterButton && (
          <div style={{ marginLeft: '5px' }}>
            <Button
              id="addFilterButtonID"
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={this.onMenuOpen}
              style={{ whiteSpace: 'nowrap' }}
            >
              {intl.formatMessage({ id: 'button.add-filter' })}
            </Button>
            <Menu
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              id={'filterMenu'}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.isMenuOpen}
              onClose={this.onMenuClose}
            >
              {hiddenFilters.map((filter) => {
                return (
                  <MenuItem onClick={() => this.onMenuClicked(filter.id)} key={filter.id}>
                    <FormattedMessage id={filter.label} />
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export const FilterBar = injectIntl(observer(FilterBarImpl));
