/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTradeItemKey
 */
export interface KvintaTradeItemKey {
    /**
     * 
     * @type {KvintaExternalId}
     * @memberof KvintaTradeItemKey
     */
    product: KvintaExternalId;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemKey
     */
    uom: string;
}

export function KvintaTradeItemKeyFromJSON(json: any): KvintaTradeItemKey {
    return KvintaTradeItemKeyFromJSONTyped(json, false);
}

export function KvintaTradeItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTradeItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': KvintaExternalIdFromJSON(json['product']),
        'uom': json['uom'],
    };
}

export function KvintaTradeItemKeyToJSON(value?: KvintaTradeItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': KvintaExternalIdToJSON(value.product),
        'uom': value.uom,
    };
}


