import { THierarchyHistoryNodeParams, TTimelinePoint } from './types';
import { action, makeObservable, observable, computed } from 'mobx';

export class HierarchyHistoryStore {
  hierarchyHistory: { [timelinePointId: string]: THierarchyHistoryNodeParams[] };
  timelinePoints: TTimelinePoint[];
  activeTimelinePointId: string;
  activePage: number;

  constructor(
    hierarchyHistory: { [timelinePointId: string]: THierarchyHistoryNodeParams[] },
    timelinePoints: TTimelinePoint[],
    activeTimelinePointId: string,
  ) {
    makeObservable(this, {
      hierarchyHistory: observable,
      timelinePoints: observable,
      activeTimelinePointId: observable,
      activePage: observable,

      updateActiveTimelinePoint: action.bound,
      goToNextPage: action.bound,
      goToPrevPage: action.bound,

      currentHierarchyHistory: computed,
    });
    this.hierarchyHistory = hierarchyHistory;
    this.timelinePoints = timelinePoints;
    this.activeTimelinePointId = activeTimelinePointId;
    this.activePage = 0;
  }

  updateActiveTimelinePoint = (timelinePointId: string) => {
    this.activeTimelinePointId = timelinePointId;
  };

  goToNextPage(): void {
    this.activePage = this.activePage + 1;
  }
  goToPrevPage(): void {
    this.activePage = this.activePage - 1;
  }

  get currentHierarchyHistory(): THierarchyHistoryNodeParams {
    const ap = this.activeTimelinePointId;
    const page = this.activePage;
    return this.hierarchyHistory[ap][page];
  }
}
