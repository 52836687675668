/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeliveryItemFilter,
    KvintaDeliveryItemFilterFromJSON,
    KvintaDeliveryItemFilterFromJSONTyped,
    KvintaDeliveryItemFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryRequestDeliveryItemFilter
 */
export interface KvintaQueryRequestDeliveryItemFilter {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaQueryRequestDeliveryItemFilter
     */
    paging: KvintaPaging;
    /**
     * 
     * @type {KvintaDeliveryItemFilter}
     * @memberof KvintaQueryRequestDeliveryItemFilter
     */
    filter?: KvintaDeliveryItemFilter | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaQueryRequestDeliveryItemFilter
     */
    fetchItems?: boolean | null;
}

export function KvintaQueryRequestDeliveryItemFilterFromJSON(json: any): KvintaQueryRequestDeliveryItemFilter {
    return KvintaQueryRequestDeliveryItemFilterFromJSONTyped(json, false);
}

export function KvintaQueryRequestDeliveryItemFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryRequestDeliveryItemFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': KvintaPagingFromJSON(json['paging']),
        'filter': !exists(json, 'filter') ? undefined : KvintaDeliveryItemFilterFromJSON(json['filter']),
        'fetchItems': !exists(json, 'fetchItems') ? undefined : json['fetchItems'],
    };
}

export function KvintaQueryRequestDeliveryItemFilterToJSON(value?: KvintaQueryRequestDeliveryItemFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
        'filter': KvintaDeliveryItemFilterToJSON(value.filter),
        'fetchItems': value.fetchItems,
    };
}


