import { Button, Menu, MenuItem } from '@mui/material';
import GetApp from '@mui/icons-material/GetApp';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

interface IExportButtonProps {
  intl: any;
  onExportAll: () => void;
  onExportSelected: () => void;
  hasSelected: boolean;
}

interface IExportButtonState {
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
}

class ExportButtonImpl extends Component<IExportButtonProps, IExportButtonState> {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
    };
  }
  onMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
    });
  };

  onExportAllClicked = () => {
    this.props.onExportAll();
    this.onMenuClose();
  };

  onExportSelectedClicked = () => {
    this.props.onExportSelected();
    this.onMenuClose();
  };

  render() {
    const { intl, hasSelected } = this.props;
    return (
      <div style={{ marginLeft: '5px' }}>
        <Button variant="outlined" startIcon={<GetApp />} onClick={this.onMenuOpen}>
          <FormattedMessage id="button.export" />
        </Button>
        <Menu
          anchorEl={this.state.anchorEl}
          // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          id={'exportButtonMenu'}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          {hasSelected && (
            <MenuItem onClick={() => this.onExportSelectedClicked()}>
              <FormattedMessage id="button.export-selected"></FormattedMessage>
            </MenuItem>
          )}
          <MenuItem onClick={() => this.onExportAllClicked()}>
            <FormattedMessage id="button.export"></FormattedMessage>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export const ExportButton = injectIntl(observer(ExportButtonImpl));
