/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSearchAttributeType {
    Keyword = 'Keyword',
    Text = 'Text',
    Int = 'Int',
    Double = 'Double',
    Bool = 'Bool',
    DateTime = 'DateTime'
}

export function KvintaSearchAttributeTypeFromJSON(json: any): KvintaSearchAttributeType {
    return KvintaSearchAttributeTypeFromJSONTyped(json, false);
}

export function KvintaSearchAttributeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSearchAttributeType {
    return json as KvintaSearchAttributeType;
}

export function KvintaSearchAttributeTypeToJSON(value?: KvintaSearchAttributeType | null): any {
    return value as any;
}

