import React from 'react';
import { injectIntl } from 'react-intl';
import { KvintaProduct } from 'kvinta/apis/kvinta-masterdata';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { TextFieldWithLink } from '../../../components/FormFields/TextFieldWithLink';
import { observer } from 'mobx-react';
import { TTradeItemFormData } from './TradeItemsStore';
import styles from './TradeItem.module.scss';

export interface TradeItemComponentProps {
  formData: TTradeItemFormData;
  intl: any;
  productData?: KvintaProduct;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  navigateToProduct: () => void;
}

const TradeItemFormBase: React.FunctionComponent<TradeItemComponentProps> = ({
  onChange,
  onBlur,
  formData,
  navigateToProduct,
}) => {
  return (
    <div className={styles.formContainer} id={'TradeItemFormID'}>
      <TextFieldWithLink
        value={formData['tradeItemForm.productName'].value}
        label="trade-item-list.header.productId"
        linkTo={navigateToProduct}
      />
      <TextInput
        {...formData['tradeItemForm.gtin']}
        onChange={onChange}
        onBlur={onBlur}
        label="trade-item-list.header.gtin"
      />
      <TextInput
        {...formData['tradeItemForm.baseUomNominator']}
        onChange={onChange}
        onBlur={onBlur}
        label="trade-item-list.header.baseUomNominator"
      />
      <TextInput
        {...formData['tradeItemForm.baseUomDenominator']}
        onChange={onChange}
        onBlur={onBlur}
        label="trade-item-list.header.baseUomDenominator"
      />
      <TextInput
        {...formData['tradeItemForm.uom']}
        onChange={onChange}
        onBlur={onBlur}
        label="trade-item-list.header.uom"
      />
    </div>
  );
};

export const TradeItemForm = injectIntl(observer(TradeItemFormBase));
