import React, { Component, FC } from 'react';
import { observer } from 'mobx-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Collapse, Divider, List, ListItem } from '@mui/material';
import { matchPath } from 'react-router-dom';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { isSelectedMenu } from './Menus';
import { EMenuItemType, TChildItemMainMenu, TItemMenu, TParentItemMainMenu } from '../../../menu';
import styles from './Sidebar.module.scss';

type TParentItem = {
  intl: IntlShape;
  item: TParentItemMainMenu;
  isExpanded: boolean;
  selectedItem: string;
  clickHandler: () => void;
  paddingLeft: number;
  history: any;
  onClick: (label: string) => void;
};
const ParentItem: FC<TParentItem> = ({
  history,
  intl,
  item,
  isExpanded,
  selectedItem,
  clickHandler,
  onClick,
  paddingLeft,
}) => {
  const expandIcon = isExpanded ? (
    <ExpandLessIcon className={styles.sidebarItemExpandArrowExpanded} />
  ) : (
    <ExpandMoreIcon className={styles.sidebarItemExpandArrow} />
  );
  const iconWithTooltip = (item.Icon && <item.Icon className={styles.sidebarItemIcon} fontSize="small" />) || null;
  return (
    <>
      <ListItem button selected={isSelectedMenu(item, selectedItem)} onClick={clickHandler} id={item.label}>
        <div className={styles.sidebarItemContent} style={{ paddingLeft: paddingLeft }}>
          {iconWithTooltip}
          <div className={styles.sidebarItemText}>
            <FormattedMessage id={item.label} defaultMessage={item.label} />
          </div>
          {expandIcon}
        </div>
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List disablePadding>
          {item.items.map((subItem, index) => {
            const key =
              subItem.type === EMenuItemType.DIVIDER ? subItem.type + '-' + index : `${subItem.label}${index}`;
            return (
              <SidebarItem
                key={key}
                history={history}
                paddingLeft={30}
                item={subItem}
                onClick={() => {
                  if (subItem.type === EMenuItemType.CHILD_ITEM) {
                    onClick(intl.formatMessage({ id: subItem.label }));
                  }
                }}
                isExpanded={false}
                selectedItem={selectedItem}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

type TItemContent = {
  iconWithTooltip: JSX.Element;
  item: TChildItemMainMenu;
  paddingLeft: number;
};

const ItemContent: FC<TItemContent> = ({ paddingLeft, iconWithTooltip, item }) => {
  return (
    <div className={styles.sidebarItemContent} style={{ paddingLeft: paddingLeft }}>
      {iconWithTooltip}
      <div className={styles.sidebarItemText}>
        <FormattedMessage id={item.label} defaultMessage={item.label} />
      </div>
    </div>
  );
};

type TExternalItem = {
  item: TChildItemMainMenu;
  selectedItem: string;
  clickHandler: () => void;
  paddingLeft: number;
};
const ExternalItem: FC<TExternalItem> = ({ item, selectedItem, clickHandler, paddingLeft }) => {
  const iconWithTooltip = (item.Icon && <item.Icon className={styles.sidebarItemIcon} fontSize="small" />) || null;
  return (
    <ListItem button selected={isSelectedMenu(item, selectedItem)} onClick={clickHandler} id={item.label}>
      <a href={item.path} target="_blank" rel="noreferrer" className={styles.sidebarLinkItem}>
        <ItemContent iconWithTooltip={iconWithTooltip} item={item} paddingLeft={paddingLeft} />
      </a>
    </ListItem>
  );
};

type TChildItem = {
  item: TChildItemMainMenu;
  selectedItem: string;
  clickHandler: () => void;
  paddingLeft: number;
};
const ChildItem: FC<TChildItem> = ({ item, selectedItem, clickHandler, paddingLeft }) => {
  const iconWithTooltip = (item.Icon && <item.Icon className={styles.sidebarItemIcon} fontSize="small" />) || null;
  return (
    <ListItem button selected={isSelectedMenu(item, selectedItem)} onClick={clickHandler} id={item.label}>
      <ItemContent iconWithTooltip={iconWithTooltip} item={item} paddingLeft={paddingLeft} />
    </ListItem>
  );
};

interface ISidebarItem {
  history: any;
  isExpanded: boolean;
  item: TItemMenu;
  onClick: (label: string) => void;
  selectedItem: string;
  isExternal?: boolean;
  intl: IntlShape;
  paddingLeft: number;
}

interface ISidebarState {
  isExpanded: boolean;
}

class sidebarItem extends Component<ISidebarItem, ISidebarState> {
  expandIcon: any = null;

  constructor(props) {
    super(props);
    if (this.props.item.type === EMenuItemType.PARENT_ITEM) {
      const isExpanded =
        this.props.item.isClickable === false
          ? isChildSelected(this.props.item.items, this.props.selectedItem)
          : this.props.isExpanded;
      this.state = { isExpanded };
    } else this.state = { isExpanded: false };
  }

  clickHandler() {
    if (this.props.item.type === EMenuItemType.PARENT_ITEM) {
      this.setState({ isExpanded: !this.state.isExpanded });
    } else if (this.props.item.type === EMenuItemType.CHILD_ITEM && this.props.item.isClickable) {
      this.props.onClick(this.props.intl.formatMessage({ id: this.props.item.label }));
      this.props.history.push(this.props.item.path);
    }
  }

  componentDidMount(): void {
    if (isSelectedMenu(this.props.item, this.props.selectedItem) && this.props.item.type === EMenuItemType.CHILD_ITEM) {
      this.props.onClick(this.props.intl.formatMessage({ id: this.props.item.label }));
    }
  }

  render() {
    switch (this.props.item.type) {
      case EMenuItemType.DIVIDER: {
        return <Divider />;
      }
      case EMenuItemType.PARENT_ITEM: {
        return (
          <ParentItem
            intl={this.props.intl}
            item={this.props.item}
            isExpanded={this.state.isExpanded}
            selectedItem={this.props.selectedItem}
            clickHandler={this.clickHandler.bind(this)}
            paddingLeft={this.props.paddingLeft}
            history={this.props.history}
            onClick={this.props.onClick}
          />
        );
      }
      case EMenuItemType.EXTERNAL_ITEM: {
        return (
          <ExternalItem
            item={this.props.item}
            selectedItem={this.props.selectedItem}
            clickHandler={this.clickHandler.bind(this)}
            paddingLeft={this.props.paddingLeft}
          />
        );
      }
      case EMenuItemType.CHILD_ITEM: {
        return (
          <ChildItem
            item={this.props.item}
            selectedItem={this.props.selectedItem}
            clickHandler={this.clickHandler.bind(this)}
            paddingLeft={this.props.paddingLeft}
          />
        );
      }
    }
  }
}

export const SidebarItem = observer(injectIntl(sidebarItem));

function isChildSelected(items: TItemMenu[], selectedItem: string): boolean {
  for (const item of items) {
    if (item.type === EMenuItemType.CHILD_ITEM) {
      if (matchPath(selectedItem, item.path)) {
        return !(item.path === '/' && selectedItem !== '/');
      }
    }
  }
  return false;
}
