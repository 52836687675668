/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaTemporalUnit,
    KvintaTemporalUnitFromJSON,
    KvintaTemporalUnitFromJSONTyped,
    KvintaTemporalUnitToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDuration
 */
export interface KvintaDuration {
    /**
     * 
     * @type {Array<KvintaTemporalUnit>}
     * @memberof KvintaDuration
     */
    units?: Array<KvintaTemporalUnit>;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaDuration
     */
    zero?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaDuration
     */
    negative?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KvintaDuration
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaDuration
     */
    nano?: number;
}

export function KvintaDurationFromJSON(json: any): KvintaDuration {
    return KvintaDurationFromJSONTyped(json, false);
}

export function KvintaDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDuration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'units': !exists(json, 'units') ? undefined : ((json['units'] as Array<any>).map(KvintaTemporalUnitFromJSON)),
        'zero': !exists(json, 'zero') ? undefined : json['zero'],
        'negative': !exists(json, 'negative') ? undefined : json['negative'],
        'seconds': !exists(json, 'seconds') ? undefined : json['seconds'],
        'nano': !exists(json, 'nano') ? undefined : json['nano'],
    };
}

export function KvintaDurationToJSON(value?: KvintaDuration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'units': value.units === undefined ? undefined : ((value.units as Array<any>).map(KvintaTemporalUnitToJSON)),
        'zero': value.zero,
        'negative': value.negative,
        'seconds': value.seconds,
        'nano': value.nano,
    };
}


