import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';

import React, { Component } from 'react';
import { EButtonState, EPCIS_EVENT_SET_STORE_ID, EpcisEventSetStore } from './EpcisEventSetStore';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, PageContentStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { tabBarHeight } from 'kvinta/common';
import { reaction } from 'mobx';
import { Button } from '@mui/material';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './EventSet.module.scss';
import { EpcisErrorDeclarationForm } from './EpcisEventErrorForm';

interface EpcisEventSetJsonProps {
  intl: any;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  epcisEventSetStore: EpcisEventSetStore;
  pageContentStore: PageContentStore;
  id: string;
}

class EpcisEventSetJsonBase extends Component<EpcisEventSetJsonProps> {
  disposer: any;

  componentDidMount() {
    this.props.epcisEventSetStore.fetchEventSet(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisEventSetStore.currentEventSet,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            this.props.historyStore.navigateEpcisEventSetListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const {
      jsonPayload,
      declareErrorFormOpen,
      declareErrorFormData,
      isLoading,
      onBlurDeclareErrorFormField,
      onChangeDeclareErrorFormField,
      cancelDeclareErrorForm,
      openDeclareErrorForm,
      submitDeclareErrorForm,
    } = this.props.epcisEventSetStore;
    if (!jsonPayload) {
      return null;
    }
    const { width, height } = this.props.pageContentStore;
    const newHeight = height - tabBarHeight - 20;
    const newWidth = width - 20;
    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '1') {
              this.props.historyStore.navigateEpcisEventSetSummaryPath(this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <div className={styles.summaryMain} style={{ padding: 0 }}>
          <AceEditor
            mode="json"
            theme="github"
            readOnly={true}
            style={{ width: newWidth, height: newHeight }}
            setOptions={{ useWorker: false }}
            editorProps={{
              $blockScrolling: false,
              $highlightPending: false,
            }}
            enableBasicAutocompletion={false}
            enableLiveAutocompletion={false}
            value={jsonPayload}
          />
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDeclareErrorForm(this.props.id)}
              disabled={this.props.epcisEventSetStore.declareErrorButtonState !== EButtonState.ACTIVE}
            >
              {this.props.intl.formatMessage({ id: 'button.declare-error' })}
            </Button>
          </div>
        </div>
        {declareErrorFormOpen && (
          <EpcisErrorDeclarationForm
            formData={declareErrorFormData}
            isLoading={isLoading}
            onBlurDeclareErrorFormField={onBlurDeclareErrorFormField}
            onChangeDeclareErrorFormField={onChangeDeclareErrorFormField}
            cancelDeclareErrorForm={cancelDeclareErrorForm}
            submitDeclareErrorForm={submitDeclareErrorForm}
          />
        )}
      </div>
    );
  }
}

export const EpcisEventSetJson = injectIntl(
  inject(
    HISTORY_STORE_ID,
    EPCIS_EVENT_SET_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(EpcisEventSetJsonBase)),
);
