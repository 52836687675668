/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSearchAttributeType,
    KvintaSearchAttributeTypeFromJSON,
    KvintaSearchAttributeTypeFromJSONTyped,
    KvintaSearchAttributeTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSearchAttributeConfiguration
 */
export interface KvintaSearchAttributeConfiguration {
    /**
     * 
     * @type {string}
     * @memberof KvintaSearchAttributeConfiguration
     */
    name?: string | null;
    /**
     * 
     * @type {KvintaSearchAttributeType}
     * @memberof KvintaSearchAttributeConfiguration
     */
    type?: KvintaSearchAttributeType | null;
}

export function KvintaSearchAttributeConfigurationFromJSON(json: any): KvintaSearchAttributeConfiguration {
    return KvintaSearchAttributeConfigurationFromJSONTyped(json, false);
}

export function KvintaSearchAttributeConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSearchAttributeConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : KvintaSearchAttributeTypeFromJSON(json['type']),
    };
}

export function KvintaSearchAttributeConfigurationToJSON(value?: KvintaSearchAttributeConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': KvintaSearchAttributeTypeToJSON(value.type),
    };
}


