/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
    KvintaProduct,
    KvintaProductFromJSON,
    KvintaProductFromJSONTyped,
    KvintaProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateRequestProductExternalId
 */
export interface KvintaMutateRequestProductExternalId {
    /**
     * 
     * @type {Array<KvintaProduct>}
     * @memberof KvintaMutateRequestProductExternalId
     */
    upsert?: Array<KvintaProduct> | null;
    /**
     * 
     * @type {Array<KvintaExternalId>}
     * @memberof KvintaMutateRequestProductExternalId
     */
    _delete?: Array<KvintaExternalId> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestProductExternalId
     */
    enableBatch?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestProductExternalId
     */
    createOnly?: boolean | null;
}

export function KvintaMutateRequestProductExternalIdFromJSON(json: any): KvintaMutateRequestProductExternalId {
    return KvintaMutateRequestProductExternalIdFromJSONTyped(json, false);
}

export function KvintaMutateRequestProductExternalIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateRequestProductExternalId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upsert': !exists(json, 'upsert') ? undefined : (json['upsert'] === null ? null : (json['upsert'] as Array<any>).map(KvintaProductFromJSON)),
        '_delete': !exists(json, 'delete') ? undefined : (json['delete'] === null ? null : (json['delete'] as Array<any>).map(KvintaExternalIdFromJSON)),
        'enableBatch': !exists(json, 'enableBatch') ? undefined : json['enableBatch'],
        'createOnly': !exists(json, 'createOnly') ? undefined : json['createOnly'],
    };
}

export function KvintaMutateRequestProductExternalIdToJSON(value?: KvintaMutateRequestProductExternalId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upsert': value.upsert === undefined ? undefined : (value.upsert === null ? null : (value.upsert as Array<any>).map(KvintaProductToJSON)),
        'delete': value._delete === undefined ? undefined : (value._delete === null ? null : (value._delete as Array<any>).map(KvintaExternalIdToJSON)),
        'enableBatch': value.enableBatch,
        'createOnly': value.createOnly,
    };
}


