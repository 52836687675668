import { TAppOptionsConfig } from '../common/Interfaces';
import { EventEmitter } from 'events';
import Keycloak from 'keycloak-js';
import { saveTokenToLocalStorage } from './localStorage';
import { setCookie } from './cookie';

export enum EKeycloakEventTypes {
  TOKEN_UPDATED = 'tokenUpdated',
  ERROR_UPDATING_TOKEN = 'errorUpdatingToken',
  TOKEN_EXPIRED = 'tokenExpired',
  KEYCLOAK_READY = 'keycloakReady',
}

export class AuthService extends EventEmitter {
  private readonly appOptions: TAppOptionsConfig;
  private readonly keycloak: Keycloak.KeycloakInstance | undefined;
  private readonly appStarter;

  constructor(config: TAppOptionsConfig, appStarter) {
    super();
    this.appOptions = config;
    this.appStarter = appStarter;
    this.keycloak = new Keycloak(config.keycloakConfig);
    this.keycloak.onAuthSuccess = this.onKeycloakSuccess;
    this.keycloak.onTokenExpired = () => {
      console.warn('authService: TOKEN_EXPIRED');
      this.emit(EKeycloakEventTypes.TOKEN_EXPIRED);
      this.updateToken();
    };
    this.keycloak.init(config.KEYCLOAK_INIT_CONFIG);
  }

  onKeycloakSuccess = () => {
    if (this.keycloak && this.keycloak.token) {
      saveTokenToLocalStorage(this.appOptions.localStorageAuthKey, this.keycloak.token);
      setCookie(
        {
          baseDomain: this.appOptions.baseDomain,
          cookieName: this.appOptions.cookieName,
        },
        this.keycloak.token,
        1,
      );
      this.appStarter(this.appOptions, this);
    }
  };

  updateToken = () => {
    if (this.keycloak) {
      this.keycloak
        .updateToken(1)
        .then(() => {
          if (this.keycloak && this.keycloak.token) {
            this.emit(EKeycloakEventTypes.TOKEN_UPDATED);
            console.warn('authService: token updated');
            saveTokenToLocalStorage(this.appOptions.localStorageAuthKey, this.keycloak.token);
            setCookie(
              {
                baseDomain: this.appOptions.baseDomain,
                cookieName: this.appOptions.cookieName,
              },
              this.keycloak.token,
              1,
            );
          } else {
            throw new Error('authService: could not get token from keycloak');
          }
        })
        .catch((e) => {
          this.emit(EKeycloakEventTypes.ERROR_UPDATING_TOKEN);
          console.error('authService: token update error', e);
        });
    }
  };

  logout = () => this.keycloak && this.keycloak.logout();

  loadUserInfo = () => {
    return this.keycloak && this.keycloak.loadUserInfo();
  };

  loadUserProfile = () => {
    return this.keycloak && this.keycloak.loadUserProfile();
  };

  getAuthMiddleware = () => {
    return initJwtMiddleware(this.appOptions.localStorageAuthKey);
  };
}

const initJwtMiddleware = (localStorageAuthKey: string) => ({
  pre: (context): Promise<void> => {
    return new Promise((resolve, reject) => {
      const jwt = localStorage.getItem(localStorageAuthKey);
      if (!jwt) {
        reject(new Error('user not logged in'));
      }

      const modifiedContext = {
        ...context,
        init: {
          ...context.init,
          headers: {
            ...context.init.headers,
            Authorization: `Bearer ${jwt}`,
          },
        },
      };
      resolve(modifiedContext);
    });
  },
});
