/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaTradeItemInProduct
 */
export interface KvintaTradeItemInProduct {
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemInProduct
     */
    uom: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItemInProduct
     */
    baseUomNominator: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItemInProduct
     */
    baseUomDenominator: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemInProduct
     */
    gtin: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaTradeItemInProduct
     */
    attributes?: { [key: string]: object; } | null;
}

export function KvintaTradeItemInProductFromJSON(json: any): KvintaTradeItemInProduct {
    return KvintaTradeItemInProductFromJSONTyped(json, false);
}

export function KvintaTradeItemInProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTradeItemInProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uom': json['uom'],
        'baseUomNominator': json['baseUomNominator'],
        'baseUomDenominator': json['baseUomDenominator'],
        'gtin': json['gtin'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function KvintaTradeItemInProductToJSON(value?: KvintaTradeItemInProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uom': value.uom,
        'baseUomNominator': value.baseUomNominator,
        'baseUomDenominator': value.baseUomDenominator,
        'gtin': value.gtin,
        'attributes': value.attributes,
    };
}


