/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaCodeSearchTarget {
    Standard = 'STANDARD',
    Parent = 'PARENT',
    Child = 'CHILD',
    TopParent = 'TOP_PARENT'
}

export function KvintaCodeSearchTargetFromJSON(json: any): KvintaCodeSearchTarget {
    return KvintaCodeSearchTargetFromJSONTyped(json, false);
}

export function KvintaCodeSearchTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCodeSearchTarget {
    return json as KvintaCodeSearchTarget;
}

export function KvintaCodeSearchTargetToJSON(value?: KvintaCodeSearchTarget | null): any {
    return value as any;
}

