/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
    KvintaProductAllOf,
    KvintaProductAllOfFromJSON,
    KvintaProductAllOfFromJSONTyped,
    KvintaProductAllOfToJSON,
    KvintaSerializationType,
    KvintaSerializationTypeFromJSON,
    KvintaSerializationTypeFromJSONTyped,
    KvintaSerializationTypeToJSON,
    KvintaTradeItemInProduct,
    KvintaTradeItemInProductFromJSON,
    KvintaTradeItemInProductFromJSONTyped,
    KvintaTradeItemInProductToJSON,
    KvintaWithExternalId,
    KvintaWithExternalIdFromJSON,
    KvintaWithExternalIdFromJSONTyped,
    KvintaWithExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaProduct
 */
export interface KvintaProduct {
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    sourceSystem: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    idInSourceSystem: string;
    /**
     * 
     * @type {KvintaExternalId}
     * @memberof KvintaProduct
     */
    businessPartner: KvintaExternalId;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    baseUom: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaProduct
     */
    name: string;
    /**
     * 
     * @type {KvintaSerializationType}
     * @memberof KvintaProduct
     */
    serializationType?: KvintaSerializationType | null;
    /**
     * 
     * @type {Array<KvintaTradeItemInProduct>}
     * @memberof KvintaProduct
     */
    items?: Array<KvintaTradeItemInProduct> | null;
}

export function KvintaProductFromJSON(json: any): KvintaProduct {
    return KvintaProductFromJSONTyped(json, false);
}

export function KvintaProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceSystem': json['sourceSystem'],
        'idInSourceSystem': json['idInSourceSystem'],
        'businessPartner': KvintaExternalIdFromJSON(json['businessPartner']),
        'sku': json['sku'],
        'baseUom': json['baseUom'],
        'name': json['name'],
        'serializationType': !exists(json, 'serializationType') ? undefined : KvintaSerializationTypeFromJSON(json['serializationType']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(KvintaTradeItemInProductFromJSON)),
    };
}

export function KvintaProductToJSON(value?: KvintaProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceSystem': value.sourceSystem,
        'idInSourceSystem': value.idInSourceSystem,
        'businessPartner': KvintaExternalIdToJSON(value.businessPartner),
        'sku': value.sku,
        'baseUom': value.baseUom,
        'name': value.name,
        'serializationType': KvintaSerializationTypeToJSON(value.serializationType),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(KvintaTradeItemInProductToJSON)),
    };
}


