import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { EPC_MESSAGES_STORE_ID, EpcMessagesStore } from './EpcMessagesStore';
import { tabs } from './tabs';
import { reaction } from 'mobx';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { unescapeId } from '../../../common/utils';
import { TextFieldWithLink } from '../../../components/FormFields/TextFieldWithLink';
import { TextField } from '../../../components/FormFields/TextField';
import { LoadingBackground } from '../../../components/LoadingBackground';
import styles from './Epcs.module.scss';

interface ViewEpcSummaryProps {
  intl: any;
  id: string;
  ts: number;
  index?: number;
  sidebarStore?: KSidebarStore;
  historyStore: HistoryStore;
  epcMessagesStore: EpcMessagesStore;
}

class EpcMessageSummaryBase extends Component<ViewEpcSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.epcMessagesStore.fetchEpc(unescapeId(this.props.id), this.props.ts, this.props.index);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcMessagesStore.currentEpc,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            this.props.historyStore.navigateEpcMessagesPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const currentEpc = this.props.epcMessagesStore.currentEpc;

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            this.props.historyStore.navigateEpcTab(
              newValue as string,
              unescapeId(this.props.id),
              this.props.ts,
              this.props.index,
            );
          }}
          tabId={'1'}
          tabs={tabs}
        />
        {this.props.epcMessagesStore.isLoading || !currentEpc ? (
          <LoadingBackground />
        ) : (
          <div className={styles.messageSummaryMain} id={'EpcMessageSummaryID'}>
            <TextField value={currentEpc.epc} label="epc.epc" />
            <TextField value={currentEpc.timestamp} label="epc.timestamp" />
            <TextField value={currentEpc.disposition} label="epc.disposition" />
            <TextField value={currentEpc.errorDeclaration} label="epc.errorDeclaration" />
            <TextFieldWithLink
              value={currentEpc.attrId}
              label="epc.eventId"
              linkTo={() => this.props.historyStore.navigateEpcisEventPath(currentEpc.attrId)}
            />
            <TextField value={currentEpc.readPoint} label="epc.readPoint" />
            <TextField value={currentEpc.location} label="epc.location" />
            <TextField value={currentEpc.operation} label="epc.operation" />
            <TextField value={currentEpc.action} label="epc.action" />
            <TextField value={currentEpc.createdByTaskId} label="epc.createdByTaskId" />
            <TextField value={currentEpc.messageId} label="epc.messageId" />
            <TextField
              value={currentEpc.recordTime && new Date(parseInt(currentEpc.recordTime)).toISOString()}
              label="epc.recordTime"
            />
          </div>
        )}
      </div>
    );
  }
}

export const EpcMessageSummary = inject(
  HISTORY_STORE_ID,
  EPC_MESSAGES_STORE_ID,
  PAGE_CONTENT_STORE_ID,
  SIDEBAR_STORE_ID,
)(observer(EpcMessageSummaryBase));
