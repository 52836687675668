import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExportButton,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SearchField,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { LocationsStore, STORE_ID } from './LocationsStore';
import { CreateLocationDialog } from './CreateLocationDialog';
import { CSVDownload } from 'react-csv';
import { ITableActionsState } from 'kvinta/common';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import MaterialTable from 'material-table';
import { getInternalId } from '../../../apis/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KvintaLocation } from '../../../apis/kvinta-masterdata';
import styles from './Location.module.scss';
import { defineSorting } from '../../../common/utils';

interface ViewLocationsProps {
  locationsStore?: LocationsStore;
  sidebarStore?: KSidebarStore;
  historyStore: HistoryStore;
  intl: any;
  pageContentStore?: PageContentStore;
}

class LocationListBase extends Component<ViewLocationsProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewLocationsProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.locationsStore.fetchLocationList();
    this.props.sidebarStore.clearMainTitle();
  }

  navigateLocationSummary = (id: string) => {
    this.props.historyStore.navigateLocationPath(id);
  };

  render() {
    const intl = this.props.intl;
    const {
      locationFormOpen,
      exportActive,
      exportData,
      searchValue,
      autofocusSearchInList,
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
    } = this.props.locationsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    this.props.locationsStore.updateExported();
    return (
      <div className={styles.mainContainer}>
        <div className={styles.locationListHeader}>
          <SearchField
            intl={this.props.intl}
            searchValue={searchValue}
            onChange={(value: string) => {
              this.props.locationsStore.updateSearch(value);
            }}
            onBlur={(ev) => {
              this.props.locationsStore.unfocusSearchField();
            }}
            autofocus={autofocusSearchInList}
          />
          <div className={styles.locationListButtonContainer}>
            <ExportButton
              onExportAll={() => this.props.locationsStore.exportAll()}
              onExportSelected={() => this.props.locationsStore.exportSelected()}
              hasSelected={this.props.locationsStore.checked > 0}
            />

            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.locationsStore.openCreateLocationForm();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        </div>
        <div id="LocationListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'location.list.name' }),
                field: 'name',
                defaultSort: defineSorting(this.props.locationsStore.currentSort, 'name'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'location.list.gln13' }),
                field: 'gln13',
                defaultSort: defineSorting(this.props.locationsStore.currentSort, 'gln13'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                  width: '10%',
                },
              },
              {
                title: intl.formatMessage({ id: 'location.list.sgln' }),
                field: 'sgln',
                defaultSort: defineSorting(this.props.locationsStore.currentSort, 'sgln'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'location.list.country' }),
                field: 'country',
                defaultSort: defineSorting(this.props.locationsStore.currentSort, 'country'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'location.list.description' }),
                field: 'description',
                defaultSort: defineSorting(this.props.locationsStore.currentSort, 'description'),
                cellStyle: {
                  fontSize: null,
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.locationsStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.locationsStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.navigateLocationSummary(getInternalId(rowData));
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => null,
            }}
          />
        </div>

        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              const shouldDelete = confirm(
                'Do you want to delete ' + (this.state.rowData as KvintaLocation).name + '?',
              );
              if (shouldDelete) {
                this.props.locationsStore.deleteLocation(getInternalId(this.state.rowData as KvintaLocation));
              }
              this.onMenuClose();
            }}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
        {locationFormOpen && (
          <CreateLocationDialog
            onChange={this.props.locationsStore.onChangeLocationField}
            onBlur={this.props.locationsStore.onBlurLocationField}
            formData={this.props.locationsStore.locationFormData}
            submitCreateLocationForm={this.props.locationsStore.submitCreateLocationForm}
            closeLocationForm={this.props.locationsStore.closeLocationForm}
          />
        )}
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </div>
    );
  }
}

export const LocationList = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(LocationListBase)),
);
