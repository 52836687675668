import { TAppOptionsConfig } from '../../../common';
import {
  ConfigApi as ECaptureStoreApi,
  KvintaEpcisBizStep,
  KvintaEpcisOutboundConfig,
} from 'kvinta/apis/kvinta-epcis-capture';
import { NotificationManager } from '../../main';
import { action, makeObservable, observable, toJS } from 'mobx';
import { EButtonState } from '../../../common/formUtils/types';
import { DefaultApi as MDDocumentApi } from '../../../apis/kvinta-masterdata';

export enum ENotificationFilterList {
  TRIGGERING = 'triggering',
  BLOCKED = 'blocked',
  NONE = 'none',
}

export type TBizStepListByGln13 = {
  id: string;
  list: ENotificationFilterList;
};

export class NotificationStore {
  private _config: TAppOptionsConfig;
  private _epcisCaptureApi: ECaptureStoreApi;
  private _masterdataApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  filterListFormIsOpen: boolean;
  listData: { id: string; name: string }[];
  //cachedOutboundConfig: { gln13: string; triggeringBizSteps: string[]; blockedBizSteps: [] }[];
  cachedOutboundConfig: KvintaEpcisOutboundConfig;
  filterListFormData?: { gln13: string; options: { id: string; gln13: string; name: string }[] };
  bizStepListSubmitButtonState: EButtonState = EButtonState.DISABLED;
  bizStepListData: TBizStepListByGln13[];
  cachedBizStepListData: TBizStepListByGln13[];
  notificationsByGln13: { [key in KvintaEpcisBizStep]: ENotificationFilterList };
  businessPartners: { id: string; gln13: string; name: string }[];

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    epcisCaptureApi: ECaptureStoreApi,
    _masterdataApi: MDDocumentApi,
  ) {
    makeObservable(this, {
      openFilterListForm: action.bound,
      closeFilterListForm: action.bound,
      fetchNotificationList: action.bound,
      fetchNotificationsByGln13: action.bound,
      saveBizStepListData: action.bound,

      saveChangesInBizStepList: action.bound,
      toggleBizStepList: action.bound,
      onChangeGln13: action.bound,
      onChangeNotificationStepForm: action.bound,
      onChangeNotificationStepSummary: action.bound,
      deleteNotificationSettingForGln13: action.bound,

      notificationsByGln13: observable,
      isLoading: observable,
      bizStepListData: observable,
      bizStepListSubmitButtonState: observable,
      filterListFormIsOpen: observable,
      filterListFormData: observable,
    });

    this._config = config;
    this._epcisCaptureApi = epcisCaptureApi;
    this._masterdataApi = _masterdataApi;
    this._notificationManager = notificationManager;
  }

  openFilterListForm() {
    this.filterListFormData = { gln13: '', options: this.businessPartners };
    this.bizStepListData = Object.values(KvintaEpcisBizStep).map((bizStep) => ({
      id: bizStep,
      list: ENotificationFilterList.NONE,
    }));
    this.bizStepListSubmitButtonState = EButtonState.DISABLED;
    this.filterListFormIsOpen = true;
  }

  closeFilterListForm() {
    this.filterListFormData = undefined;
    this.filterListFormIsOpen = false;
  }

  async fetchNotificationList() {
    this.isLoading = true;

    this.cachedOutboundConfig = undefined;
    this.listData = undefined;
    this.businessPartners = undefined;

    return Promise.all([
      this._epcisCaptureApi.readOutboundConfig({ body: {} }),
      this._masterdataApi.queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: { paging: { page: 0, size: 1000 } },
      }),
    ])

      .then(([epcisCaptureResult, masterdataResult]) => {
        this.listData = (epcisCaptureResult.filters || []).map((filter) => ({
          id: filter.gln13,
          name: masterdataResult.list.find((businessPartner) => businessPartner.gln13 === filter.gln13)?.name || '',
        }));
        this.businessPartners = masterdataResult.list.map(({ gln13, name }) => ({
          id: Math.random().toString(),
          gln13,
          name,
        }));
        this.cachedOutboundConfig = epcisCaptureResult;
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async fetchNotificationsByGln13(gln13: string) {
    this.isLoading = true;

    this.bizStepListData = undefined;
    this.cachedBizStepListData = undefined;

    this._epcisCaptureApi
      .readOutboundConfig({ body: {} })
      .then((result) => {
        const gln13Filters = result.filters.find((filters) => filters.gln13 === gln13);

        if (gln13Filters) {
          this.bizStepListData = this.cachedBizStepListData = convertListsToSteps(gln13Filters);
          this.cachedOutboundConfig = result;
        } else {
          this._notificationManager.sendError(`Could not find filters for gln13 \n${gln13}`);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async saveChangesInBizStepList(gln13) {
    this.saveBizStepListData(gln13).then(() => {
      this.fetchNotificationList().then(() => this.closeFilterListForm());
    });
  }

  async saveBizStepListData(gln13ToChange: string) {
    this.isLoading = true;
    const { triggeringBizSteps, blockedBizSteps } = convertStepsToLists(this.bizStepListData);

    this._epcisCaptureApi
      .writeOutboundConfig({
        kvintaEpcisOutboundConfig: {
          filters: [
            ...((this.cachedOutboundConfig.filters || []).filter(({ gln13 }) => gln13 !== gln13ToChange) as any),
            {
              gln13: gln13ToChange,
              triggeringBizSteps,
              blockedBizSteps,
            },
          ],
          ownerNotification: this.cachedOutboundConfig.ownerNotification,
          queued: this.cachedOutboundConfig.queued,
          workflowType: this.cachedOutboundConfig.workflowType,
          includeLocationAndDisposition: this.cachedOutboundConfig.includeLocationAndDisposition,
        },
      })
      .then(() => {
        this.fetchNotificationsByGln13(gln13ToChange);
      })
      .catch(async (err) => {
        let errMessage = '';

        if (err instanceof Response) {
          const responseParsed = await err.json();
          const errors = responseParsed._embedded.errors.reduce((acc, err) => {
            return `${acc}\n${err.message}`;
          }, '');
          errMessage = `${responseParsed.message}\n${errors}`;
        } else {
          errMessage = err.message;
        }
        this.bizStepListData = this.cachedBizStepListData;
        this._notificationManager.sendError(`An error occurred while submitting data\n${errMessage}`);
      })
      .finally(() => {
        this.isLoading = false;
        this.bizStepListSubmitButtonState = EButtonState.DISABLED;
      });
  }

  onChangeNotificationStepForm(id: string, list: ENotificationFilterList) {
    this.toggleBizStepList(id, list);
    this.bizStepListSubmitButtonState =
      this.filterListFormData.gln13 !== '' ? EButtonState.ACTIVE : EButtonState.DISABLED;
  }

  onChangeNotificationStepSummary(id: string, list: ENotificationFilterList) {
    this.toggleBizStepList(id, list);
    this.bizStepListSubmitButtonState = this.bizStepListData.reduce((acc: EButtonState, step, i) => {
      if (acc === EButtonState.DISABLED) {
        acc = step.list !== this.cachedBizStepListData[i].list ? EButtonState.ACTIVE : EButtonState.DISABLED;
      }
      return acc;
    }, EButtonState.DISABLED);
  }

  toggleBizStepList(id: string, list: ENotificationFilterList) {
    const bizStepListData = toJS(this.bizStepListData);
    const stepIdx = bizStepListData.findIndex((step) => step.id === id);
    const step = bizStepListData[stepIdx];
    bizStepListData[stepIdx] = { ...step, list: step.list === list ? ENotificationFilterList.NONE : list };

    this.bizStepListData = bizStepListData;
  }

  onChangeGln13(gln13: string) {
    this.filterListFormData.gln13 = gln13;
    this.bizStepListSubmitButtonState = EButtonState.ACTIVE;
  }

  deleteNotificationSettingForGln13(gln13ToRemove: string) {
    this.isLoading = true;

    this._epcisCaptureApi
      .writeOutboundConfig({
        kvintaEpcisOutboundConfig: {
          filters: (this.cachedOutboundConfig.filters || []).filter(({ gln13 }) => gln13 !== gln13ToRemove) as any,
          ownerNotification: this.cachedOutboundConfig.ownerNotification,
          queued: this.cachedOutboundConfig.queued,
          workflowType: this.cachedOutboundConfig.workflowType,
          includeLocationAndDisposition: this.cachedOutboundConfig.includeLocationAndDisposition,
        },
      })
      .then(() => {
        return this.fetchNotificationList();
      })
      .catch(async (err) => {
        let errMessage = '';

        if (err instanceof Response) {
          const responseParsed = await err.json();
          const errors = responseParsed._embedded.errors.reduce((acc, err) => {
            return `${acc}\n${err.message}`;
          }, '');
          errMessage = `${responseParsed.message}\n${errors}`;
        } else {
          errMessage = err.message;
        }
        this._notificationManager.sendError(`An error occurred while submitting data\n${errMessage}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

function urnToBizStep(urn: string) {
  switch (urn) {
    case 'urn:epcglobal:cbv:bizstep:commissioning':
      return KvintaEpcisBizStep.Commissioning;
    case 'urn:epcglobal:cbv:bizstep:decommissioning':
      return KvintaEpcisBizStep.Decommissioning;
    case 'urn:epcglobal:cbv:bizstep:packing':
      return KvintaEpcisBizStep.Packing;
    case 'urn:epcglobal:cbv:bizstep:unpacking':
      return KvintaEpcisBizStep.Unpacking;
    case 'urn:epcglobal:cbv:bizstep:receiving':
      return KvintaEpcisBizStep.Receiving;
    case 'urn:epcglobal:cbv:bizstep:shipping':
      return KvintaEpcisBizStep.Shipping;
    case 'urn:saga:cbv:bizstep:verification':
      return KvintaEpcisBizStep.Verification;
    case 'urn:epcglobal:cbv:bizstep:inspecting':
    default:
      return KvintaEpcisBizStep.Inspecting;
  }
}

function bizStepToUrn(urn: string) {
  switch (urn) {
    case KvintaEpcisBizStep.Commissioning:
      return 'urn:epcglobal:cbv:bizstep:commissioning';
    case KvintaEpcisBizStep.Decommissioning:
      return 'urn:epcglobal:cbv:bizstep:decommissioning';
    case KvintaEpcisBizStep.Packing:
      return 'urn:epcglobal:cbv:bizstep:packing';
    case KvintaEpcisBizStep.Unpacking:
      return 'urn:epcglobal:cbv:bizstep:unpacking';
    case KvintaEpcisBizStep.Receiving:
      return 'urn:epcglobal:cbv:bizstep:receiving';
    case KvintaEpcisBizStep.Shipping:
      return 'urn:epcglobal:cbv:bizstep:shipping';
    case KvintaEpcisBizStep.Verification:
      return 'urn:saga:cbv:bizstep:verification';
    case KvintaEpcisBizStep.Inspecting:
    default:
      return 'urn:epcglobal:cbv:bizstep:inspecting';
  }
}

function convertListsToSteps(gln13Filters) {
  const bizSteps = Object.values(KvintaEpcisBizStep).reduce((acc, bizStep) => {
    acc[bizStep] = ENotificationFilterList.NONE;
    return acc;
  }, {});
  const triggeringBizSteps = (gln13Filters.triggeringBizSteps || []).reduce((acc, bizStep) => {
    acc[urnToBizStep(bizStep)] = ENotificationFilterList.TRIGGERING;
    return acc;
  }, {});
  const blockedBizSteps = (gln13Filters.blockedBizSteps || []).reduce((acc, bizStep) => {
    acc[urnToBizStep(bizStep)] = ENotificationFilterList.BLOCKED;
    return acc;
  }, {});

  const bizStepList = Object.assign(bizSteps, triggeringBizSteps, blockedBizSteps);

  return Object.entries(bizStepList as { [key in KvintaEpcisBizStep]: ENotificationFilterList }).map(
    ([key, val]: [string, ENotificationFilterList]) => ({
      id: key,
      list: val,
    }),
  );
}

function convertStepsToLists(bizStepListData) {
  return bizStepListData.reduce(
    (acc, step) => {
      if (step.list === ENotificationFilterList.BLOCKED) {
        acc.blockedBizSteps.push(bizStepToUrn(step.id));
      } else if (step.list === ENotificationFilterList.TRIGGERING) {
        acc.triggeringBizSteps.push(bizStepToUrn(step.id));
      }
      return acc;
    },
    { triggeringBizSteps: [], blockedBizSteps: [] },
  );
}

export const NOTIFICATION_STORE_ID = 'notificationStore';
