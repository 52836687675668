/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAllowRequireType,
    KvintaAllowRequireTypeFromJSON,
    KvintaAllowRequireTypeFromJSONTyped,
    KvintaAllowRequireTypeToJSON,
    KvintaEpcisAction,
    KvintaEpcisActionFromJSON,
    KvintaEpcisActionFromJSONTyped,
    KvintaEpcisActionToJSON,
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
    KvintaEpcisDisposition,
    KvintaEpcisDispositionFromJSON,
    KvintaEpcisDispositionFromJSONTyped,
    KvintaEpcisDispositionToJSON,
    KvintaGenericObjectTransactionReasonCode,
    KvintaGenericObjectTransactionReasonCodeFromJSON,
    KvintaGenericObjectTransactionReasonCodeFromJSONTyped,
    KvintaGenericObjectTransactionReasonCodeToJSON,
    KvintaGs1DocumentType,
    KvintaGs1DocumentTypeFromJSON,
    KvintaGs1DocumentTypeFromJSONTyped,
    KvintaGs1DocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSingleMobileTransactionConfig
 */
export interface KvintaSingleMobileTransactionConfig {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    epcExistenceCheck: boolean;
    /**
     * 
     * @type {KvintaEpcisAction}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    eventAction: KvintaEpcisAction;
    /**
     * 
     * @type {KvintaEpcisBizStep}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    businessStep: KvintaEpcisBizStep;
    /**
     * 
     * @type {KvintaEpcisDisposition}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    disposition?: KvintaEpcisDisposition | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    copyReadPointToBusinessLocation: boolean;
    /**
     * 
     * @type {KvintaAllowRequireType}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    allowRequireOrderReference: KvintaAllowRequireType;
    /**
     * 
     * @type {KvintaGs1DocumentType}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    orderReferenceType?: KvintaGs1DocumentType | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    orderReferenceLocation?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    reasonCodeEnforced: boolean;
    /**
     * 
     * @type {Array<KvintaGenericObjectTransactionReasonCode>}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    reasonCodes?: Array<KvintaGenericObjectTransactionReasonCode> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    icon?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSingleMobileTransactionConfig
     */
    showConfirmDialog: boolean;
}

export function KvintaSingleMobileTransactionConfigFromJSON(json: any): KvintaSingleMobileTransactionConfig {
    return KvintaSingleMobileTransactionConfigFromJSONTyped(json, false);
}

export function KvintaSingleMobileTransactionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSingleMobileTransactionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'name': json['name'],
        'description': json['description'],
        'epcExistenceCheck': json['epcExistenceCheck'],
        'eventAction': KvintaEpcisActionFromJSON(json['eventAction']),
        'businessStep': KvintaEpcisBizStepFromJSON(json['businessStep']),
        'disposition': !exists(json, 'disposition') ? undefined : KvintaEpcisDispositionFromJSON(json['disposition']),
        'copyReadPointToBusinessLocation': json['copyReadPointToBusinessLocation'],
        'allowRequireOrderReference': KvintaAllowRequireTypeFromJSON(json['allowRequireOrderReference']),
        'orderReferenceType': !exists(json, 'orderReferenceType') ? undefined : KvintaGs1DocumentTypeFromJSON(json['orderReferenceType']),
        'orderReferenceLocation': !exists(json, 'orderReferenceLocation') ? undefined : json['orderReferenceLocation'],
        'reasonCodeEnforced': json['reasonCodeEnforced'],
        'reasonCodes': !exists(json, 'reasonCodes') ? undefined : (json['reasonCodes'] === null ? null : (json['reasonCodes'] as Array<any>).map(KvintaGenericObjectTransactionReasonCodeFromJSON)),
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'showConfirmDialog': json['showConfirmDialog'],
    };
}

export function KvintaSingleMobileTransactionConfigToJSON(value?: KvintaSingleMobileTransactionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'name': value.name,
        'description': value.description,
        'epcExistenceCheck': value.epcExistenceCheck,
        'eventAction': KvintaEpcisActionToJSON(value.eventAction),
        'businessStep': KvintaEpcisBizStepToJSON(value.businessStep),
        'disposition': KvintaEpcisDispositionToJSON(value.disposition),
        'copyReadPointToBusinessLocation': value.copyReadPointToBusinessLocation,
        'allowRequireOrderReference': KvintaAllowRequireTypeToJSON(value.allowRequireOrderReference),
        'orderReferenceType': KvintaGs1DocumentTypeToJSON(value.orderReferenceType),
        'orderReferenceLocation': value.orderReferenceLocation,
        'reasonCodeEnforced': value.reasonCodeEnforced,
        'reasonCodes': value.reasonCodes === undefined ? undefined : (value.reasonCodes === null ? null : (value.reasonCodes as Array<any>).map(KvintaGenericObjectTransactionReasonCodeToJSON)),
        'icon': value.icon,
        'showConfirmDialog': value.showConfirmDialog,
    };
}


