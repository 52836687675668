/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaWorkflowIdReusePolicy {
    WorkflowIdReusePolicyUnspecified = 'WORKFLOW_ID_REUSE_POLICY_UNSPECIFIED',
    WorkflowIdReusePolicyAllowDuplicate = 'WORKFLOW_ID_REUSE_POLICY_ALLOW_DUPLICATE',
    WorkflowIdReusePolicyAllowDuplicateFailedOnly = 'WORKFLOW_ID_REUSE_POLICY_ALLOW_DUPLICATE_FAILED_ONLY',
    WorkflowIdReusePolicyRejectDuplicate = 'WORKFLOW_ID_REUSE_POLICY_REJECT_DUPLICATE',
    WorkflowIdReusePolicyTerminateIfRunning = 'WORKFLOW_ID_REUSE_POLICY_TERMINATE_IF_RUNNING',
    Unrecognized = 'UNRECOGNIZED'
}

export function KvintaWorkflowIdReusePolicyFromJSON(json: any): KvintaWorkflowIdReusePolicy {
    return KvintaWorkflowIdReusePolicyFromJSONTyped(json, false);
}

export function KvintaWorkflowIdReusePolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaWorkflowIdReusePolicy {
    return json as KvintaWorkflowIdReusePolicy;
}

export function KvintaWorkflowIdReusePolicyToJSON(value?: KvintaWorkflowIdReusePolicy | null): any {
    return value as any;
}

