/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDocumentIdendification
 */
export interface KvintaDocumentIdendification {
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentIdendification
     */
    standard?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentIdendification
     */
    typeVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentIdendification
     */
    instanceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentIdendification
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentIdendification
     */
    creationDateAndTime?: string;
}

export function KvintaDocumentIdendificationFromJSON(json: any): KvintaDocumentIdendification {
    return KvintaDocumentIdendificationFromJSONTyped(json, false);
}

export function KvintaDocumentIdendificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDocumentIdendification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'standard': !exists(json, 'standard') ? undefined : json['standard'],
        'typeVersion': !exists(json, 'typeVersion') ? undefined : json['typeVersion'],
        'instanceIdentifier': !exists(json, 'instanceIdentifier') ? undefined : json['instanceIdentifier'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'creationDateAndTime': !exists(json, 'creationDateAndTime') ? undefined : json['creationDateAndTime'],
    };
}

export function KvintaDocumentIdendificationToJSON(value?: KvintaDocumentIdendification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'standard': value.standard,
        'typeVersion': value.typeVersion,
        'instanceIdentifier': value.instanceIdentifier,
        'type': value.type,
        'creationDateAndTime': value.creationDateAndTime,
    };
}


