/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaWriteResultType,
    KvintaWriteResultTypeFromJSON,
    KvintaWriteResultTypeFromJSONTyped,
    KvintaWriteResultTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaWriteObjectResponse
 */
export interface KvintaWriteObjectResponse {
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectResponse
     */
    sha256?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectResponse
     */
    duplicateObjectId?: string | null;
    /**
     * 
     * @type {KvintaWriteResultType}
     * @memberof KvintaWriteObjectResponse
     */
    result: KvintaWriteResultType;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectResponse
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectResponse
     */
    errorCode?: string | null;
}

export function KvintaWriteObjectResponseFromJSON(json: any): KvintaWriteObjectResponse {
    return KvintaWriteObjectResponseFromJSONTyped(json, false);
}

export function KvintaWriteObjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaWriteObjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sha256': !exists(json, 'sha256') ? undefined : json['sha256'],
        'duplicateObjectId': !exists(json, 'duplicateObjectId') ? undefined : json['duplicateObjectId'],
        'result': KvintaWriteResultTypeFromJSON(json['result']),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
    };
}

export function KvintaWriteObjectResponseToJSON(value?: KvintaWriteObjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sha256': value.sha256,
        'duplicateObjectId': value.duplicateObjectId,
        'result': KvintaWriteResultTypeToJSON(value.result),
        'errorMessage': value.errorMessage,
        'errorCode': value.errorCode,
    };
}


