import React from 'react';
import { Provider } from 'mobx-react';
import { createStores } from './stores';
import { createRoot } from 'react-dom/client';
import { AuthService } from 'kvinta/service/auth';
import { TAppOptionsConfig } from 'kvinta/common';
import * as config from './config';
import { App } from './app';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';
import { createBrowserHistory } from "history";

declare const window: any;

if (!('ResizeObserver' in window)) {
  window.ResizeObserver = ResizeObserver;
  // Only use it when you have this trouble: https://github.com/wellyshen/react-cool-dimensions/issues/45
  // window.ResizeObserverEntry = ResizeObserverEntry;
}

function appStarter(config: TAppOptionsConfig, authS: AuthService) {
  const history = createBrowserHistory();
  const store = createStores(config, authS, history);
  const wrappedApp = (
    <Provider {...store}>
      <App history={history}/>
    </Provider>
  );
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(wrappedApp);
}

new AuthService(config, appStarter);
