/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisEventRecord,
    KvintaEpcisEventRecordFromJSON,
    KvintaEpcisEventRecordFromJSONTyped,
    KvintaEpcisEventRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetListResultEpcisEventRecord
 */
export interface KvintaGetListResultEpcisEventRecord {
    /**
     * 
     * @type {Array<KvintaEpcisEventRecord>}
     * @memberof KvintaGetListResultEpcisEventRecord
     */
    list?: Array<KvintaEpcisEventRecord> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetListResultEpcisEventRecord
     */
    total?: number | null;
}

export function KvintaGetListResultEpcisEventRecordFromJSON(json: any): KvintaGetListResultEpcisEventRecord {
    return KvintaGetListResultEpcisEventRecordFromJSONTyped(json, false);
}

export function KvintaGetListResultEpcisEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetListResultEpcisEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaEpcisEventRecordFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaGetListResultEpcisEventRecordToJSON(value?: KvintaGetListResultEpcisEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaEpcisEventRecordToJSON)),
        'total': value.total,
    };
}


