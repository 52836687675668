import { Button, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { TTextFilter } from './FilterBar';
import { observer } from 'mobx-react';
import { injectIntl } from 'react-intl';

export type TTextFilterProps = {
  filter: TTextFilter;
  showRemoveButton: boolean;
  intl: any;
  onChangeValue: (field: string, value: string) => void;
  onClear: (field: string) => void;
  onFilter: (field: string) => void;
  hideFilter: (field: string) => void;
};

export const RemoveFilterButton = ({ onClick, id }) => (
  <IconButton id={`remove-${id}`} style={{ marginLeft: 6 }} className="removeFilter" onClick={onClick}>
    <CancelIcon />
  </IconButton>
);

export const ClearFilterButton = ({ onClick, id }) => (
  <IconButton id={`clear-${id}`} className="clearFilter" onClick={onClick}>
    <ClearIcon />
  </IconButton>
);

const ApplyFilterButtonBase = ({ onClick, intl, id }) => (
  <Button id={`apply-${id}`} variant="contained" color="primary" className="applyFilter" onClick={onClick}>
    {intl.formatMessage({ id: 'button.apply' })}
  </Button>
);

export const ApplyFilterButton = injectIntl(ApplyFilterButtonBase);

const FilterAdornment = ({ filter, showRemoveButton, hideFilter, onClear, onFilter, id }) => {
  if (filter.value === '' && showRemoveButton) {
    return <RemoveFilterButton id={id} onClick={() => hideFilter(filter.id)} />;
  } else if (filter.isApplied) {
    return (
      <>
        {filter.value !== '' && <ClearFilterButton id={id} onClick={() => onClear(filter.id)} />}
        {showRemoveButton && <RemoveFilterButton id={id} onClick={() => hideFilter(filter.id)} />}
      </>
    );
  } else {
    return (
      <>
        {filter.value !== '' && (
          <>
            <ClearFilterButton id={id} onClick={() => onClear(filter.id)} />
            <ApplyFilterButton id={id} onClick={() => onFilter(filter.id)} />
          </>
        )}
        {showRemoveButton && <RemoveFilterButton id={id} onClick={() => hideFilter(filter.id)} />}
      </>
    );
  }
};

const TextFilterBase: React.FunctionComponent<TTextFilterProps> = ({
  filter,
  showRemoveButton,
  intl,
  onChangeValue,
  hideFilter,
  onClear,
  onFilter,
}) => {
  const filterFieldId = 'filter-' + filter.id;
  const translatedLabel = intl.formatMessage({ id: 'filter.by' }) + ' ' + intl.formatMessage({ id: filter.label });

  return (
    <div className="ZstFilter-root" style={{ marginLeft: '5px' }}>
      <FormControl variant="outlined" key={filter.id}>
        <TextField
          sx={{ width: 280 }}
          size={'small'}
          id={filterFieldId}
          value={filter.value}
          placeholder={translatedLabel}
          onChange={(e) => onChangeValue(filter.id, e.target.value)}
          label={translatedLabel}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FilterAdornment
                  id={filterFieldId}
                  filter={filter}
                  hideFilter={hideFilter}
                  onFilter={onFilter}
                  onClear={onClear}
                  showRemoveButton={showRemoveButton}
                />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </div>
  );
};

export const TextFilter = observer(TextFilterBase);
