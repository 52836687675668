/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaLocationFilter
 */
export interface KvintaLocationFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationFilter
     */
    nameLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationFilter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationFilter
     */
    gln13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaLocationFilter
     */
    sgln?: string | null;
}

export function KvintaLocationFilterFromJSON(json: any): KvintaLocationFilter {
    return KvintaLocationFilterFromJSONTyped(json, false);
}

export function KvintaLocationFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaLocationFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nameLike': !exists(json, 'nameLike') ? undefined : json['nameLike'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'sgln': !exists(json, 'sgln') ? undefined : json['sgln'],
    };
}

export function KvintaLocationFilterToJSON(value?: KvintaLocationFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameLike': value.nameLike,
        'name': value.name,
        'gln13': value.gln13,
        'sgln': value.sgln,
    };
}


