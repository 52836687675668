import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { DeliveryNotesStore, DELIVERY_NOTE_STORE_ID } from './DeliveryNotesStore';
import { CreateDeliveryNoteDialog } from './CreateDeliveryNoteDialog';
import { ITableActionsState } from 'kvinta/common';
import GetApp from '@mui/icons-material/GetApp';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { FilterBar } from '../../../components/FilterBar/FilterBar';
import MaterialTable from 'material-table';
import { getInternalDocumentKey } from '../../../apis/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KvintaDelivery } from '../../../apis/kvinta-masterdata';
import styles from './Deliveries.module.scss';
import { defineSorting } from '../../../common/utils';

interface DeliveryNotesViewProps {
  deliveryNotesStore?: DeliveryNotesStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  historyStore: HistoryStore;
  pageContentStore?: PageContentStore;
}

class DeliveryNotesViewImpl extends Component<DeliveryNotesViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: DeliveryNotesViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.deliveryNotesStore.fetchDeliveryNoteList();
    this.props.sidebarStore.clearMainTitle();
  }

  navigateDeliveryNoteSummary = (id: string) => {
    this.props.historyStore.navigateDeliveryNotePath(id);
  };

  render() {
    const intl = this.props.intl;
    const {
      openCreateDeliveryNoteForm,
      deliveryNoteFormOpen,
      deliveryNoteFormData,
      onChangeDeliveryNoteFormField,
      onBlurDeliveryNoteFormField,
      closeDeliveryNoteForm,
      submitCreateDeliveryNote,
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
      filter,
      deleteDeliveryNote,
      exportAll,
    } = this.props.deliveryNotesStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    this.props.deliveryNotesStore.updateExported();

    if (!this.props.deliveryNotesStore.filter) {
      return null;
    }

    return (
      <div className={styles.summaryContainer}>
        <div className={styles.deliveryNoteListHeader}>
          <FilterBar filter={filter} />
          <div className={styles.deliveryNoteListButtonContainer}>
            <Button variant="outlined" startIcon={<GetApp />} onClick={exportAll}>
              <FormattedMessage id="button.export" />
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(_e) => openCreateDeliveryNoteForm()}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        </div>
        <div id="DeliveryNotesListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            title={intl.formatMessage({ id: 'menu.delivery-notes' })}
            columns={[
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.document.type' }),
                field: 'document.type',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'documentType'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.document.location' }),
                field: 'document.location',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'documentLocation'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.document.number' }),
                field: 'document.number',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'documentNumber'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.referenceDocument.type' }),
                field: 'referenceDocument.type',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'referenceDocType'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.referenceDocument.location' }),
                field: 'referenceDocument.location',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'referenceDocLoc'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.referenceDocument.number' }),
                field: 'referenceDocument.number',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'referenceDocNum'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-note-form.field.alarmStatus' }),
                field: 'alarmStatus',
                defaultSort: defineSorting(this.props.deliveryNotesStore.currentSort, 'alarmStatus'),
                sorting: true,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            isLoading={isLoading}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={async (page: number) => {
              await this.props.deliveryNotesStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.deliveryNotesStore.changeOrder(orderBy, orderDirection);
            }}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.navigateDeliveryNoteSummary(getInternalDocumentKey((rowData as any).document));
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: (_props) => <></>,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              const shouldDelete = confirm(
                'Do you want to delete ' + (this.state.rowData as KvintaDelivery).document.number + '?',
              );
              if (shouldDelete) {
                deleteDeliveryNote(this.state.rowData.document);
              }
              this.onMenuClose();
            }}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
        {deliveryNoteFormOpen && (
          <CreateDeliveryNoteDialog
            formData={deliveryNoteFormData}
            onChange={onChangeDeliveryNoteFormField}
            onBlur={onBlurDeliveryNoteFormField}
            onClose={closeDeliveryNoteForm}
            onSubmit={submitCreateDeliveryNote}
          />
        )}
      </div>
    );
  }
}

export const DeliveryNoteList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    DELIVERY_NOTE_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(DeliveryNotesViewImpl)),
);
