/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaOperationRequestGetRelevantChangesRequest,
    KvintaOperationRequestGetRelevantChangesRequestFromJSON,
    KvintaOperationRequestGetRelevantChangesRequestFromJSONTyped,
    KvintaOperationRequestGetRelevantChangesRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaInlineObject2
 */
export interface KvintaInlineObject2 {
    /**
     * 
     * @type {KvintaOperationRequestGetRelevantChangesRequest}
     * @memberof KvintaInlineObject2
     */
    request: KvintaOperationRequestGetRelevantChangesRequest;
}

export function KvintaInlineObject2FromJSON(json: any): KvintaInlineObject2 {
    return KvintaInlineObject2FromJSONTyped(json, false);
}

export function KvintaInlineObject2FromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInlineObject2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': KvintaOperationRequestGetRelevantChangesRequestFromJSON(json['request']),
    };
}

export function KvintaInlineObject2ToJSON(value?: KvintaInlineObject2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': KvintaOperationRequestGetRelevantChangesRequestToJSON(value.request),
    };
}


