import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { injectIntl } from 'react-intl';
import styles from './TextInput.module.scss';

type TSelectInput = {
  intl: any;
  id: string;
  value: string | number;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string, value: string) => void;
  options: { key: string; label: string }[];
  label?: string;
  disabled?: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
};

const TranslatedSelectInputBase: React.FunctionComponent<TSelectInput> = (props) => {
  const { id, value, onChange, onBlur, options, touched, showError, label, intl, style, disabled = false } = props;

  return (
    <FormControl variant="outlined" style={style} fullWidth id={`${label}-container`}>
      {label && (
        <InputLabel style={{ backgroundColor: 'white' }} htmlFor={id}>
          {intl.formatMessage({ id: label })}
        </InputLabel>
      )}

      <Select
        id={id}
        value={value}
        style={style}
        onChange={(e) => onChange(id, e.target.value as string)}
        onBlur={(e) => onBlur(id, e.target.value as string)}
      >
        {options.map(({ key, label }) => (
          <MenuItem key={key} value={key}>
            {intl.formatMessage({ id: label })}
          </MenuItem>
        ))}
      </Select>
      {showError && touched && <div className={styles.error}>{props.errors}</div>}
    </FormControl>
  );
};

export const TranslatedSelectInput = injectIntl(TranslatedSelectInputBase);
