import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { summaryTabs } from './tabs';
import { reaction } from 'mobx';
import { DEVICE_FORM_ROOT_ID, DEVICE_STORE_ID, DeviceStore, TDevice } from './DeviceStore';
import { DeviceForm } from './DeviceForm';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Device.module.scss';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { TextField } from '../../../components/FormFields/TextField';
import { USER_STORE_ID, UserStore } from '../../main';

interface DeviceSummaryViewProps {
  intl: any;
  userStore: UserStore;
  deviceStore?: DeviceStore;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  id: string;
  currentDevice?: any;
}

const DeviceSummaryContent: React.FunctionComponent<{ deiceData: TDevice }> = ({ deiceData }) => (
  <div className={styles.formContainer} id={'DeviceSummaryID'}>
    <TextField value={deiceData.email} label="device.email" />
    <TextField value={deiceData.serialNumber} label="device.serialNumber" />
    <TextField value={deiceData.locationGln13} label="device.locationgln13" />
  </div>
);

class DeviceSummaryBase extends Component<DeviceSummaryViewProps> {
  disposer: any;

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.deviceStore.fetchDevice(this.props.id);
    this.disposer = reaction(
      () => this.props.deviceStore.currentDevice,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id as any, () => {
            this.props.historyStore.navigateDevicesPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
    this.props.deviceStore.closeDeviceForm();
  }

  render() {
    if (!this.props.deviceStore.currentDevice) {
      return null;
    }
    const intl = this.props.intl;
    const { canAccess } = this.props.userStore;
    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateDevicesPath();
            }
            if (newValue === '2') {
              this.props.historyStore.navigateDeviceAssignmentsPath(this.props.id);
            }
          }}
          tabId={'1'}
          tabs={summaryTabs}
        />
        <div className={styles.summaryMain}>
          {canAccess('Notification.delete') && (
            <div className={styles.summaryButtonContainer}>
              {this.props.deviceStore.deviceFormOpen ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CloseIcon color="primary" />}
                    onClick={this.props.deviceStore.closeDeviceForm}
                  >
                    {intl.formatMessage({ id: 'button.cancel' })}
                  </Button>
                  <ButtonGreen
                    label={intl.formatMessage({ id: 'button.confirm' })}
                    icon={<DoneIcon color="secondary" />}
                    onClick={this.props.deviceStore.submitUpdateDeviceForm}
                    buttonState={formDataToButtonState(this.props.deviceStore.deviceFormData[DEVICE_FORM_ROOT_ID])}
                    id={'openOrSubmitButton'}
                  />
                </>
              ) : (
                <ButtonGreen
                  label={intl.formatMessage({ id: 'button.edit' })}
                  icon={<EditIcon color="secondary" />}
                  onClick={this.props.deviceStore.openUpdateDeviceForm}
                  buttonState={EButtonState.ACTIVE}
                  id={'openOrSubmitButton'}
                />
              )}
            </div>
          )}
          {this.props.deviceStore.deviceFormOpen ? (
            <DeviceForm
              formData={this.props.deviceStore.deviceFormData}
              onChange={this.props.deviceStore.onChangeDeviceFormField}
              onBlur={this.props.deviceStore.onBlurDeviceFormField}
            />
          ) : (
            <DeviceSummaryContent deiceData={this.props.deviceStore.currentDevice} />
          )}
        </div>
      </div>
    );
  }
}

export const DeviceSummary = injectIntl(
  inject(USER_STORE_ID, HISTORY_STORE_ID, DEVICE_STORE_ID, SIDEBAR_STORE_ID)(observer(DeviceSummaryBase)),
);
