/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
    KvintaIdAndErrorExternalId,
    KvintaIdAndErrorExternalIdFromJSON,
    KvintaIdAndErrorExternalIdFromJSONTyped,
    KvintaIdAndErrorExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateResultExternalId
 */
export interface KvintaMutateResultExternalId {
    /**
     * 
     * @type {Array<KvintaExternalId>}
     * @memberof KvintaMutateResultExternalId
     */
    upserted?: Array<KvintaExternalId> | null;
    /**
     * 
     * @type {Array<KvintaExternalId>}
     * @memberof KvintaMutateResultExternalId
     */
    deleted?: Array<KvintaExternalId> | null;
    /**
     * 
     * @type {Array<KvintaIdAndErrorExternalId>}
     * @memberof KvintaMutateResultExternalId
     */
    errors?: Array<KvintaIdAndErrorExternalId> | null;
}

export function KvintaMutateResultExternalIdFromJSON(json: any): KvintaMutateResultExternalId {
    return KvintaMutateResultExternalIdFromJSONTyped(json, false);
}

export function KvintaMutateResultExternalIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateResultExternalId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upserted': !exists(json, 'upserted') ? undefined : (json['upserted'] === null ? null : (json['upserted'] as Array<any>).map(KvintaExternalIdFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : (json['deleted'] as Array<any>).map(KvintaExternalIdFromJSON)),
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(KvintaIdAndErrorExternalIdFromJSON)),
    };
}

export function KvintaMutateResultExternalIdToJSON(value?: KvintaMutateResultExternalId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upserted': value.upserted === undefined ? undefined : (value.upserted === null ? null : (value.upserted as Array<any>).map(KvintaExternalIdToJSON)),
        'deleted': value.deleted === undefined ? undefined : (value.deleted === null ? null : (value.deleted as Array<any>).map(KvintaExternalIdToJSON)),
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(KvintaIdAndErrorExternalIdToJSON)),
    };
}


