/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
    KvintaSerializationType,
    KvintaSerializationTypeFromJSON,
    KvintaSerializationTypeFromJSONTyped,
    KvintaSerializationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDeliveryItem
 */
export interface KvintaDeliveryItem {
    /**
     * 
     * @type {KvintaDocumentKey}
     * @memberof KvintaDeliveryItem
     */
    delivery: KvintaDocumentKey;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItem
     */
    itemNumber: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItem
     */
    productCode: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItem
     */
    batchNumber: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaDeliveryItem
     */
    qtyExpected: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaDeliveryItem
     */
    qtyConfirmed: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItem
     */
    dateExpected: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItem
     */
    dateConfirmed: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItem
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {KvintaSerializationType}
     * @memberof KvintaDeliveryItem
     */
    serializationType?: KvintaSerializationType | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaDeliveryItem
     */
    attributes?: { [key: string]: object; } | null;
}

export function KvintaDeliveryItemFromJSON(json: any): KvintaDeliveryItem {
    return KvintaDeliveryItemFromJSONTyped(json, false);
}

export function KvintaDeliveryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delivery': KvintaDocumentKeyFromJSON(json['delivery']),
        'itemNumber': json['itemNumber'],
        'productCode': json['productCode'],
        'batchNumber': json['batchNumber'],
        'qtyExpected': json['qtyExpected'],
        'qtyConfirmed': json['qtyConfirmed'],
        'dateExpected': json['dateExpected'],
        'dateConfirmed': json['dateConfirmed'],
        'expiryDate': !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
        'serializationType': !exists(json, 'serializationType') ? undefined : KvintaSerializationTypeFromJSON(json['serializationType']),
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function KvintaDeliveryItemToJSON(value?: KvintaDeliveryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery': KvintaDocumentKeyToJSON(value.delivery),
        'itemNumber': value.itemNumber,
        'productCode': value.productCode,
        'batchNumber': value.batchNumber,
        'qtyExpected': value.qtyExpected,
        'qtyConfirmed': value.qtyConfirmed,
        'dateExpected': value.dateExpected,
        'dateConfirmed': value.dateConfirmed,
        'expiryDate': value.expiryDate,
        'serializationType': KvintaSerializationTypeToJSON(value.serializationType),
        'attributes': value.attributes,
    };
}


