import { tabs } from './tabs';

import React, { Component } from 'react';
import { EPCIS_EVENT_SET_STORE_ID, EpcisEventSetStore } from './EpcisEventSetStore';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, PageContentStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { reaction } from 'mobx';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './EventSet.module.scss';
import { TextField } from '../../../components/FormFields/TextField';

interface EpcisEventSetSummaryProps {
  intl: any;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  epcisEventSetStore: EpcisEventSetStore;
  pageContentStore: PageContentStore;
  id: string;
}

class EpcisEventSetSummaryBase extends Component<EpcisEventSetSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.epcisEventSetStore.fetchEventRecord(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisEventSetStore.currentEventSet,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            this.props.historyStore.navigateEpcisEventSetListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { currentEventSetRecord, isLoading } = this.props.epcisEventSetStore;

    if (!currentEventSetRecord) {
      return null;
    }

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '2') {
              this.props.historyStore.navigateEpcisEventSetJsonPath(this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <div className={styles.summaryMain} id={'EpcisEventSetSummaryID'}>
          <TextField value={currentEventSetRecord.id} label="epcis-event-set.id" />
          <TextField value={currentEventSetRecord.numberOfEvents} label="epcis-event-set.number-of-events" />
          <TextField value={currentEventSetRecord.earliestTime} label="epcis-event-set.event-time-earliest" />
          <TextField value={currentEventSetRecord.latestTime} label="epcis-event-set.event-time-latest" />
        </div>
      </div>
    );
  }
}

export const EpcisEventSetSummary = injectIntl(
  inject(
    HISTORY_STORE_ID,
    EPCIS_EVENT_SET_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(EpcisEventSetSummaryBase)),
);
