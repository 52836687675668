import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { TRADE_ITEM_FORM_ROOT_ID, TRADE_ITEM_STORE_ID, TradeItemsStore, TTradeItem } from './TradeItemsStore';
import EditIcon from '@mui/icons-material/Edit';
import { TradeItemForm } from './TradeItemForm';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { reaction } from 'mobx';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { tabsSummary } from './tabs';
import styles from './TradeItem.module.scss';
import { TextFieldWithLink } from '../../../components/FormFields/TextFieldWithLink';
import { TextField } from '../../../components/FormFields/TextField';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';

interface TradeItemSummaryViewProps {
  intl: any;
  tradeItemsStore: TradeItemsStore;
  historyStore: HistoryStore;
  sidebarStore: KSidebarStore;
  id: string;
}

export const TradeItemSummaryData: React.FunctionComponent<{ formData: TTradeItem; navigateToProduct: () => void }> = ({
  formData,
  navigateToProduct,
}) => (
  <div className={styles.formContainer} id={'TradeItemSummaryID'}>
    <TextFieldWithLink
      value={formData.productName}
      label="trade-item-list.header.productId"
      linkTo={navigateToProduct}
    />
    <TextField value={formData.gtin} label="trade-item-list.header.gtin" />
    <TextField value={formData.baseUomNominator} label="trade-item-list.header.baseUomNominator" />
    <TextField value={formData.baseUomDenominator} label="trade-item-list.header.baseUomDenominator" />
    <TextField value={formData.uom} label="trade-item-list.header.uom" />
  </div>
);

class TradeItemSummaryBase extends Component<TradeItemSummaryViewProps> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.fetchTradeItem(decodeURIComponent(this.props.id));
    this.disposer = reaction(
      () => this.props.tradeItemsStore.currentTradeItem,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.gtin, () => {
            this.props.historyStore.navigateTradeItemsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
    this.props.tradeItemsStore.closeTradeItemForm();
  }

  render() {
    if (
      !(
        this.props.tradeItemsStore.currentTradeItem
        // && this.props.tradeItemsStore.currentProduct
      )
    ) {
      return null;
    }

    const intl = this.props.intl;

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateTradeItemsPath();
            }
          }}
          tabId={'1'}
          tabs={tabsSummary}
        />

        <div className={styles.summaryMain}>
          <div className={styles.summaryButtonContainer}>
            {this.props.tradeItemsStore.tradeItemFormOpen && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                onClick={this.props.tradeItemsStore.closeTradeItemForm}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            )}
            {this.props.tradeItemsStore.tradeItemFormOpen ? (
              <ButtonGreen
                id="editOrSubmitButton"
                onClick={this.props.tradeItemsStore.submitUpdateTradeItem}
                icon={<DoneIcon color="secondary" />}
                label={intl.formatMessage({ id: 'button.confirm' })}
                buttonState={formDataToButtonState(
                  this.props.tradeItemsStore.tradeItemFormData[TRADE_ITEM_FORM_ROOT_ID],
                )}
              />
            ) : (
              <ButtonGreen
                id="editOrSubmitButton"
                onClick={this.props.tradeItemsStore.openUpdateTradeItemForm}
                icon={<EditIcon color="secondary" />}
                label={intl.formatMessage({ id: 'button.edit' })}
                buttonState={EButtonState.ACTIVE}
              />
            )}
          </div>
          {this.props.tradeItemsStore.tradeItemFormOpen ? (
            <TradeItemForm
              formData={this.props.tradeItemsStore.tradeItemFormData}
              onChange={this.props.tradeItemsStore.onChangeTradeItemField}
              onBlur={this.props.tradeItemsStore.onBlurTradeItemField}
              navigateToProduct={() =>
                this.props.historyStore.navigateProductPath(this.props.tradeItemsStore.currentTradeItem.productId)
              }
            />
          ) : (
            <TradeItemSummaryData
              formData={this.props.tradeItemsStore.currentTradeItem}
              navigateToProduct={() =>
                this.props.historyStore.navigateProductPath(this.props.tradeItemsStore.currentTradeItem.productId)
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export const TradeItemSummary = injectIntl(
  inject(HISTORY_STORE_ID, TRADE_ITEM_STORE_ID, SIDEBAR_STORE_ID)(observer(TradeItemSummaryBase)),
);
