import { Button, Paper } from '@mui/material';
import { PAGE_CONTENT_STORE_ID, PageContentStore, TabBar, tableIcons } from 'kvinta/components';

import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { DEVICE_STORE_ID, DeviceStore } from './DeviceStore';
import { CreateDeviceAssignmentDialog } from './CreateDeviceAssignmentDialog';
import { summaryTabs } from './tabs';
import AddIcon from '@mui/icons-material/Add';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Device.module.scss';
import { defineSorting } from '../../../common/utils';
import { USER_STORE_ID, UserStore } from '../../main';

interface DeviceAssignmentsViewProps {
  userStore: UserStore;
  deviceStore?: DeviceStore;
  historyStore: HistoryStore;
  pageContentStore?: PageContentStore;
  intl: any;
  id: string;
}

class DeviceAssignmentListBase extends Component<DeviceAssignmentsViewProps> {
  async componentDidMount() {
    this.props.deviceStore.fetchDeviceAssignmentList(this.props.id);
  }

  componentWillUnmount() {
    this.props.deviceStore.cleanCurrentSort();
  }

  render() {
    const intl = this.props.intl;
    const {
      deviceAssignmentFormOpen,
      deviceAssignmentList,
      isLoading,
      page,
      pageSize,
      totalCount,
      submitCreateDeviceAssignmentForm,
      onChangeDeviceAssignmentFormField,
      onBlurDeviceAssignmentFormField,
      closeDeviceAssignmentForm,
      deviceAssignmentFormData,
      apps,
    } = this.props.deviceStore;

    if (!deviceAssignmentList) {
      return null;
    }
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault - 50;
    const { canAccess } = this.props.userStore;
    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateDevicesPath();
            }
            if (newValue === '1') {
              this.props.historyStore.navigateDeviceSummaryPath(this.props.id);
            }
          }}
          tabId={'2'}
          tabs={summaryTabs}
        />
        {canAccess('Device.edit') && (
          <div className={styles.assignmentListToolbar}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={this.props.deviceStore.openCreateDeviceAssignmentForm}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        )}
        <div id="DeviceAssignmentListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: intl.formatMessage({ id: 'device-assignment.appName' }),
                field: 'appName',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'appName'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'device-assignment.appVersion' }),
                field: 'appVersion',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'appVersion'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'device-assignment.deviceId' }),
                field: 'deviceId',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'deviceId'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'device-assignment.email' }),
                field: 'email',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'email'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={deviceAssignmentList}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.deviceStore.fetchDeviceAssignmentPage(page, this.props.id);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.deviceStore.changeDeviceAssignmentOrder(orderBy, orderDirection, this.props.id);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              thirdSortClick: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            components={{
              Toolbar: () => null,
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateDeviceAssignmentSummaryPath(this.props.id, rowData.id);
              }
            }}
          />
        </div>
        {deviceAssignmentFormOpen && (
          <CreateDeviceAssignmentDialog
            onSubmit={submitCreateDeviceAssignmentForm}
            onChange={onChangeDeviceAssignmentFormField}
            onBlur={onBlurDeviceAssignmentFormField}
            onClose={closeDeviceAssignmentForm}
            formData={deviceAssignmentFormData}
            apps={apps}
          />
        )}
      </div>
    );
  }
}

export const DeviceAssignmentList = injectIntl(
  inject(USER_STORE_ID, HISTORY_STORE_ID, DEVICE_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(DeviceAssignmentListBase)),
);
