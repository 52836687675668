import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PAGE_CONTENT_STORE_ID, TabBar } from 'kvinta/components';
import { EPC_MESSAGES_STORE_ID, EpcMessagesStore } from './EpcMessagesStore';
import { MovementMap } from './Map/MovementMap';
import { tabs } from './tabs';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { unescapeId } from '../../../common/utils';
import styles from './Epcs.module.scss';

interface ViewEpcMapProps {
  intl: any;
  id: string;
  ts: number;
  index?: number;
  historyStore: HistoryStore;
  epcMessagesStore: EpcMessagesStore;
}

class ViewEpcMapImpl extends Component<ViewEpcMapProps> {
  componentDidMount() {
    this.props.epcMessagesStore.fetchMap(unescapeId(this.props.id));
  }

  render() {
    const movementMapStore = this.props.epcMessagesStore.movementMapStore;
    if (!movementMapStore) {
      return null;
    }
    return (
      <div className={styles.messageMapContainer}>
        <TabBar
          onChange={(newValue: any) => {
            this.props.historyStore.navigateEpcTab(
              newValue as string,
              unescapeId(this.props.id),
              this.props.ts,
              this.props.index,
            );
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <MovementMap movementMapStore={movementMapStore} />
      </div>
    );
  }
}

export const EpcMessageMap = injectIntl(
  inject(HISTORY_STORE_ID, EPC_MESSAGES_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewEpcMapImpl)),
);
