/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaWriteObjectRequest
 */
export interface KvintaWriteObjectRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectRequest
     */
    objectType: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectRequest
     */
    objectId: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaWriteObjectRequest
     */
    ttl: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectRequest
     */
    payload: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaWriteObjectRequest
     */
    sha256?: string | null;
}

export function KvintaWriteObjectRequestFromJSON(json: any): KvintaWriteObjectRequest {
    return KvintaWriteObjectRequestFromJSONTyped(json, false);
}

export function KvintaWriteObjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaWriteObjectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectType': json['objectType'],
        'objectId': json['objectId'],
        'ttl': json['ttl'],
        'payload': json['payload'],
        'sha256': !exists(json, 'sha256') ? undefined : json['sha256'],
    };
}

export function KvintaWriteObjectRequestToJSON(value?: KvintaWriteObjectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectType': value.objectType,
        'objectId': value.objectId,
        'ttl': value.ttl,
        'payload': value.payload,
        'sha256': value.sha256,
    };
}


