/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
    KvintaProductFilter,
    KvintaProductFilterFromJSON,
    KvintaProductFilterFromJSONTyped,
    KvintaProductFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryRequestProductFilter
 */
export interface KvintaQueryRequestProductFilter {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaQueryRequestProductFilter
     */
    paging: KvintaPaging;
    /**
     * 
     * @type {KvintaProductFilter}
     * @memberof KvintaQueryRequestProductFilter
     */
    filter?: KvintaProductFilter | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaQueryRequestProductFilter
     */
    fetchItems?: boolean | null;
}

export function KvintaQueryRequestProductFilterFromJSON(json: any): KvintaQueryRequestProductFilter {
    return KvintaQueryRequestProductFilterFromJSONTyped(json, false);
}

export function KvintaQueryRequestProductFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryRequestProductFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': KvintaPagingFromJSON(json['paging']),
        'filter': !exists(json, 'filter') ? undefined : KvintaProductFilterFromJSON(json['filter']),
        'fetchItems': !exists(json, 'fetchItems') ? undefined : json['fetchItems'],
    };
}

export function KvintaQueryRequestProductFilterToJSON(value?: KvintaQueryRequestProductFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
        'filter': KvintaProductFilterToJSON(value.filter),
        'fetchItems': value.fetchItems,
    };
}


