/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmType,
    KvintaAlarmTypeFromJSON,
    KvintaAlarmTypeFromJSONTyped,
    KvintaAlarmTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAlarmRecord
 */
export interface KvintaAlarmRecord {
    /**
     * 
     * @type {string}
     * @memberof KvintaAlarmRecord
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaAlarmRecord
     */
    alarmTime: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaAlarmRecord
     */
    objectType: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAlarmRecord
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAlarmRecord
     */
    message: string;
    /**
     * 
     * @type {KvintaAlarmType}
     * @memberof KvintaAlarmRecord
     */
    alarmType: KvintaAlarmType;
}

export function KvintaAlarmRecordFromJSON(json: any): KvintaAlarmRecord {
    return KvintaAlarmRecordFromJSONTyped(json, false);
}

export function KvintaAlarmRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAlarmRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'alarmTime': json['alarmTime'],
        'objectType': json['objectType'],
        'objectId': json['objectId'],
        'message': json['message'],
        'alarmType': KvintaAlarmTypeFromJSON(json['alarmType']),
    };
}

export function KvintaAlarmRecordToJSON(value?: KvintaAlarmRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'alarmTime': value.alarmTime,
        'objectType': value.objectType,
        'objectId': value.objectId,
        'message': value.message,
        'alarmType': KvintaAlarmTypeToJSON(value.alarmType),
    };
}


