/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaAttributeConfiguration
 */
export interface KvintaAttributeConfiguration {
    /**
     * 
     * @type {string}
     * @memberof KvintaAttributeConfiguration
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAttributeConfiguration
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAttributeConfiguration
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaAttributeConfiguration
     */
    regExp?: string | null;
}

export function KvintaAttributeConfigurationFromJSON(json: any): KvintaAttributeConfiguration {
    return KvintaAttributeConfigurationFromJSONTyped(json, false);
}

export function KvintaAttributeConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAttributeConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'description': json['description'],
        'regExp': !exists(json, 'regExp') ? undefined : json['regExp'],
    };
}

export function KvintaAttributeConfigurationToJSON(value?: KvintaAttributeConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'description': value.description,
        'regExp': value.regExp,
    };
}


