/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaTradeItem,
    KvintaTradeItemFromJSON,
    KvintaTradeItemFromJSONTyped,
    KvintaTradeItemToJSON,
    KvintaTradeItemKey,
    KvintaTradeItemKeyFromJSON,
    KvintaTradeItemKeyFromJSONTyped,
    KvintaTradeItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateRequestTradeItemTradeItemKey
 */
export interface KvintaMutateRequestTradeItemTradeItemKey {
    /**
     * 
     * @type {Array<KvintaTradeItem>}
     * @memberof KvintaMutateRequestTradeItemTradeItemKey
     */
    upsert?: Array<KvintaTradeItem> | null;
    /**
     * 
     * @type {Array<KvintaTradeItemKey>}
     * @memberof KvintaMutateRequestTradeItemTradeItemKey
     */
    _delete?: Array<KvintaTradeItemKey> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestTradeItemTradeItemKey
     */
    enableBatch?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestTradeItemTradeItemKey
     */
    createOnly?: boolean | null;
}

export function KvintaMutateRequestTradeItemTradeItemKeyFromJSON(json: any): KvintaMutateRequestTradeItemTradeItemKey {
    return KvintaMutateRequestTradeItemTradeItemKeyFromJSONTyped(json, false);
}

export function KvintaMutateRequestTradeItemTradeItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateRequestTradeItemTradeItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upsert': !exists(json, 'upsert') ? undefined : (json['upsert'] === null ? null : (json['upsert'] as Array<any>).map(KvintaTradeItemFromJSON)),
        '_delete': !exists(json, 'delete') ? undefined : (json['delete'] === null ? null : (json['delete'] as Array<any>).map(KvintaTradeItemKeyFromJSON)),
        'enableBatch': !exists(json, 'enableBatch') ? undefined : json['enableBatch'],
        'createOnly': !exists(json, 'createOnly') ? undefined : json['createOnly'],
    };
}

export function KvintaMutateRequestTradeItemTradeItemKeyToJSON(value?: KvintaMutateRequestTradeItemTradeItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upsert': value.upsert === undefined ? undefined : (value.upsert === null ? null : (value.upsert as Array<any>).map(KvintaTradeItemToJSON)),
        'delete': value._delete === undefined ? undefined : (value._delete === null ? null : (value._delete as Array<any>).map(KvintaTradeItemKeyToJSON)),
        'enableBatch': value.enableBatch,
        'createOnly': value.createOnly,
    };
}


