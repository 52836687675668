/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaSerializationType {
    Unknown = 'UNKNOWN',
    SerializedOnly = 'SERIALIZED_ONLY',
    Serialized = 'SERIALIZED'
}

export function KvintaSerializationTypeFromJSON(json: any): KvintaSerializationType {
    return KvintaSerializationTypeFromJSONTyped(json, false);
}

export function KvintaSerializationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSerializationType {
    return json as KvintaSerializationType;
}

export function KvintaSerializationTypeToJSON(value?: KvintaSerializationType | null): any {
    return value as any;
}

