import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import {
  BUSINESS_PARTNER_ROOT_FORM_ID,
  BUSINESS_PARTNER_STORE_ID,
  BusinessPartnersStore,
  TBusinessPartner,
} from './BusinessPartnerStore';
import { BusinessPartnerForm, EBusinessPartnerFormType } from './BusinessPartnerForm';
import EditIcon from '@mui/icons-material/Edit';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { reaction } from 'mobx';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { tabsSummary } from './tabs';
import { LoadingBackground } from '../../../components/LoadingBackground';
import styles from './BusinessPartner.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { formDataToButtonState } from '../../../common/formUtils/utils';

interface BusinessPartnerSummaryViewProps {
  intl: any;
  historyStore: HistoryStore;
  businessPartnersStore: BusinessPartnersStore;
  sidebarStore: KSidebarStore;
  id: string;
}

const BusinessPartnerSummaryData: React.FunctionComponent<{ formData: TBusinessPartner; intl: IntlShape }> = ({
  intl,
  formData,
}) => (
  <div className={styles.formContainer} id={'BusinessPartnerSummaryID'}>
    <TextField
      value={formData.idInSourceSystem}
      label="business-partner-create-form.field.idInSourceSystem"
      style={{ gridColumn: '1 / span 2' }}
    />
    <TextField value={formData.name} label="business-partner.name" />
    <TextField value={formData.gln13} label="location-view.field.gln13" />
    <TextField value={formData.longitude} label="business-partner.longitude" />
    <TextField value={formData.latitude} label="location-view.field.latitude" />
    <TextField
      value={intl.formatMessage({ id: `country.${formData.country.toLowerCase()}` })}
      label="business-partner.country"
    />
    <TextField value={formData.city} label="location-view.field.city" />
    <TextField value={formData.postalCode} label="business-partner.postalCode" />
    <TextField value={formData.address1} label="business-partner.address1" />
    <TextField value={formData.address2} label="business-partner.address2" />
    <TextField value={formData.address3} label="business-partner.address3" />
    <TextField value={formData.description} label="business-partner.description" style={{ gridColumn: '1 / span 2' }} />
  </div>
);

class BusinessPartnerSummaryBase extends Component<BusinessPartnerSummaryViewProps> {
  disposer: any;

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    await this.props.businessPartnersStore.fetchBusinessPartner(this.props.id);
    this.disposer = reaction(
      () => this.props.businessPartnersStore.currentBusinessPartner,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateBusinessPartnersPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
    this.props.businessPartnersStore.closeBusinessPartnerForm();
  }

  render() {
    const intl = this.props.intl;

    return (
      <div className={styles.mainContainer}>
        <TabBar onChange={(_newValue: any) => undefined} tabId={'1'} tabs={tabsSummary} />
        {this.props.businessPartnersStore.isLoading || !this.props.businessPartnersStore.currentBusinessPartner ? (
          <LoadingBackground />
        ) : (
          <div className={styles.summaryMain}>
            <div className={styles.summaryButtonContainer}>
              {this.props.businessPartnersStore.businessPartnerFormOpen && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloseIcon color="primary" />}
                  onClick={this.props.businessPartnersStore.closeBusinessPartnerForm}
                >
                  {intl.formatMessage({ id: 'button.cancel' })}
                </Button>
              )}
              {this.props.businessPartnersStore.businessPartnerFormOpen ? (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.businessPartnersStore.submitUpdateBusinessPartnerForm}
                  icon={<DoneIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.confirm' })}
                  buttonState={formDataToButtonState(
                    this.props.businessPartnersStore.businessPartnerFormData[BUSINESS_PARTNER_ROOT_FORM_ID],
                  )}
                />
              ) : (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.businessPartnersStore.openUpdateBusinessPartnerForm}
                  icon={<EditIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.edit' })}
                  buttonState={EButtonState.ACTIVE}
                />
              )}
            </div>
            {this.props.businessPartnersStore.businessPartnerFormOpen ? (
              <BusinessPartnerForm
                formData={this.props.businessPartnersStore.businessPartnerFormData}
                onBlur={this.props.businessPartnersStore.onBlurBusinessPartnerFormField}
                onChange={this.props.businessPartnersStore.onChangeBusinessPartnerFormField}
                formType={EBusinessPartnerFormType.UPDATE}
              />
            ) : (
              <BusinessPartnerSummaryData
                formData={this.props.businessPartnersStore.currentBusinessPartner}
                intl={intl}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export const BusinessPartnerSummary = injectIntl(
  inject(HISTORY_STORE_ID, BUSINESS_PARTNER_STORE_ID, SIDEBAR_STORE_ID)(observer(BusinessPartnerSummaryBase)),
);
