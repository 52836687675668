import {
  KvintaDeliveryItemKey,
  KvintaDocumentKey,
  KvintaExternalId,
  KvintaSerializationType,
  KvintaTradeItemKey
} from "./kvinta-masterdata";
import { EFilterType, TFilter } from "../components/FilterBar/FilterBar";
import { TTradeItem } from "../modules/master-data/trade-items/TradeItemsStore";

export type TDeliveryItemKey  = {
  delivery?: KvintaDocumentKey;
  itemNumber?: string;
}


export function getInternalId(idWithSystem: KvintaExternalId): string {
  return idWithSystem.idInSourceSystem + "@" + idWithSystem.sourceSystem;
}

export function getExternalId(composedId: string): KvintaExternalId {
  const [idInSourceSystem, sourceSystem] = composedId.split("@");
  return { idInSourceSystem, sourceSystem } as KvintaExternalId;
}

export function getInternalTradeItemKey(key: KvintaTradeItemKey): string {
  return getInternalId(key.product) + "#" + key.uom;
}

export function getInternalTradeItemKeyFromProductId(tradeItem: TTradeItem): string {
  return tradeItem.productId + "#" + tradeItem.uom;
}

export function getExternalTradeItemKey(composedKey: string): KvintaTradeItemKey {
  const [product, uom] = composedKey.split("#");
  return { product: getExternalId(product), uom } as KvintaTradeItemKey;
}

export function getInternalDocumentKey(key: KvintaDocumentKey): string {
  return key.type + ":" + key.location + ":" + key.number;
}

export function getExternalDocumentKey(composedKey: string): KvintaDocumentKey {
  const [type, location, number] = composedKey.split(":");
  return { type: type, location: location, number: number } as KvintaDocumentKey;
}

export function getInternalDeliveryItemKey(key: TDeliveryItemKey): string {
  return getInternalDocumentKey(key.delivery) + "#" + key.itemNumber;
}

export function getExternalDeliveryItemKey(composedKey: string): KvintaDeliveryItemKey {
  const [delivery, itemNumber] = composedKey.split("#");
  return { delivery: getExternalDocumentKey(delivery), itemNumber:itemNumber } as KvintaDeliveryItemKey;
}

export function convertSerializationTypeToNumber(serializationType: KvintaSerializationType): number {
  switch (serializationType) {
    case KvintaSerializationType.SerializedOnly:
      return 1;
    case KvintaSerializationType.Serialized:
      return 2;
    case KvintaSerializationType.Unknown:
    default:
      return 0;
  }
}
export function convertSerializationType(serializationTypeAsNumber: number): KvintaSerializationType {
  switch (serializationTypeAsNumber) {
    case 1:
      return KvintaSerializationType.SerializedOnly;
    case 2:
      return KvintaSerializationType.Serialized;
    case 0:
    default:
      return KvintaSerializationType.Unknown;
  }
}

export function translateSerializationType(serializationType: KvintaSerializationType):string {
  switch (serializationType) {
    case KvintaSerializationType.Unknown:
      return '0 – unknown type'
    case KvintaSerializationType.SerializedOnly:
      return '1 – serialized only (non-aggregated)'
    case KvintaSerializationType.Serialized:
      return '2 – serialized incl. aggregation'
  }
}

export function validateFormData(formData, requiredFields): boolean {
  let hasError = false;
  for (const field of requiredFields) {
    if (formData[field] === undefined || formData[field] === '' || formData[field] === 0) {
      hasError = true;
      break;
    }
  }
  return hasError
}

export function normalizeFilters(filters: TFilter[]) {
  return filters.reduce((normalizedFilters, filter) => {
    switch(filter.type){
      case EFilterType.SELECT_FILTER: {
        if (filter.value !== 'default') {
            normalizedFilters[filter.id] = filter.value;
        }
        break;
      }
      case EFilterType.MULITPLE_SELECT_FILTER:{
        if(filter.value.length !== 0){
          normalizedFilters[filter.id] = filter.value;
        }
        break;
      }
      case EFilterType.DATE_RANGE_FILTER:{
        const normalizedDateRange = Object.entries(filter.value).reduce((dateRangeAcc, [key, value]) => {
          if(key === 'from' && value){
            const dateFrom = (new Date(`${value}T00:00:00.000Z`))
            dateRangeAcc[key] = new Date(dateFrom).getTime()
          } else if(key === 'to' && value){
            const dateTo = (new Date(`${value}T23:59:59.999Z`))
            dateRangeAcc[key] = new Date(dateTo).getTime()
          }
          return dateRangeAcc
        },{})

        if(Object.keys(normalizedDateRange).length){
          normalizedFilters[filter.id] = normalizedDateRange
        }
        break;
      }
      default:{
        if (filter.isActive && filter.isApplied && !filter.isFieldEmpty) {
          normalizedFilters[filter.id] = filter.value;
        }
      }
    }
    return normalizedFilters;
  }, {});
}

export const deliveryItemFilterIds = ["itemProductCode",  "itemBatchNumber",  "itemDateExpected",  "itemDateConfirmed"]