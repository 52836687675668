import { Button, Menu, MenuItem, Paper } from '@mui/material';
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  TabBar,
  tableIcons,
} from 'kvinta/components';
import { CreateDeliveryItemDialog } from './CreateDeliveryItemDialog';
import { tabsSummary } from './tabs';
import { DELIVERY_NOTE_STORE_ID, DeliveryNotesStore, toKvintaDocument } from './DeliveryNotesStore';
import { reaction } from 'mobx';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITableActionsState } from 'kvinta/common';
import { KvintaDeliveryItem } from 'kvinta/apis/kvinta-masterdata';
import { DELIVERY_ITEM_STORE_ID, DeliveryItemsStore } from './DeliveryItemsStore';
import { getInternalDeliveryItemKey } from 'kvinta/apis/utils';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Deliveries.module.scss';
import { defineSorting } from '../../../common/utils';

interface DeliveryNoteDeliveryItemsViewProps {
  intl: any;
  id: string;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  historyStore: HistoryStore;
  deliveryNotesStore?: DeliveryNotesStore;
  deliveryItemsStore?: DeliveryItemsStore;
}

class DeliveryNoteDeliveryItemsViewImpl extends Component<DeliveryNoteDeliveryItemsViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;
  disposer: any;

  constructor(props: DeliveryNoteDeliveryItemsViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.deliveryItemsStore.fetchDeliveryItemList(this.props.id);
    this.disposer = reaction(
      () => this.props.deliveryNotesStore.currentDeliveryNote,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.docNumber, () => {
            this.props.historyStore.navigateDeliveryNotesPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    if (!this.props.deliveryItemsStore.listData) {
      return null;
    }
    const {
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
      deliveryItemFormOpen,
      deliveryItemFormData,
      closeDeliveryItemForm,
      submitCreateDeliveryItemForm,
      onBlurDeliveryItemFormField,
      onChangeDeliveryItemFormField,
      currentDeliveryItem,
    } = this.props.deliveryItemsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault - 50;
    return (
      <div>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateDeliveryNotesPath();
            }
            if (newValue === '1') {
              this.props.historyStore.navigateDeliveryNotePath(this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabsSummary}
        />
        <div id="DeliveryNoteDeliveryItemsListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            title={intl.formatMessage({ id: 'menu.delivery-items' })}
            columns={[
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.itemNumber' }),
                field: 'itemNumber',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'itemNumber'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.productCode' }),
                field: 'productCode',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'productCode'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.qtyExpected' }),
                field: 'qtyExpected',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'qtyExpected'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.qtyConfirmed' }),
                field: 'qtyConfirmed',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'qtyConfirmed'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.dateExpected' }),
                field: 'dateExpected',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'dateExpected'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.dateConfirmed' }),
                field: 'dateConfirmed',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'dateConfirmed'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'delivery-item-list.header.batchNumber' }),
                field: 'batchNumber',
                defaultSort: defineSorting(this.props.deliveryItemsStore.currentSort, 'batchNumber'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.deliveryItemsStore.fetchPage(page, this.props.id);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.deliveryItemsStore.changeOrder(orderBy, orderDirection, this.props.id);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateDeliveryNoteDeliveryItemPath(
                  this.props.id,
                  getInternalDeliveryItemKey({
                    delivery: toKvintaDocument(this.props.deliveryItemsStore.currentDeliveryNote),
                    itemNumber: rowData.id,
                  }),
                );
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => (
                <div className={styles.deliveriesToolbarContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon color="secondary" />}
                    onClick={this.props.deliveryItemsStore.openCreateDeliveryItemForm}
                  >
                    {intl.formatMessage({ id: 'button.create' })}
                  </Button>
                </div>
              ),
            }}
          />
        </div>
        {deliveryItemFormOpen && (
          <CreateDeliveryItemDialog
            deliveryId={this.props.id}
            formData={deliveryItemFormData}
            onBlur={onBlurDeliveryItemFormField}
            onClose={closeDeliveryItemForm}
            onChange={onChangeDeliveryItemFormField}
            onSubmit={submitCreateDeliveryItemForm}
            currentDeliveryItem={currentDeliveryItem}
          />
        )}
        <>
          <Menu
            id="row-action-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.isMenuOpen}
            onClose={this.onMenuClose}
          >
            <MenuItem
              onClick={() => {
                const shouldDelete = confirm(
                  'Do you want to delete ' + (this.state.rowData as KvintaDeliveryItem).itemNumber + '?',
                );
                if (shouldDelete) {
                  this.props.deliveryItemsStore.deleteDeliveryItem(
                    (this.state.rowData as KvintaDeliveryItem).delivery,
                    this.state.rowData.itemNumber,
                    this.props.id,
                  );
                }
                this.onMenuClose();
              }}
            >
              {intl.formatMessage({ id: 'button.delete' })}
            </MenuItem>
          </Menu>
        </>
      </div>
    );
  }
}

export const DeliveryItemList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    DELIVERY_ITEM_STORE_ID,
    DELIVERY_NOTE_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(DeliveryNoteDeliveryItemsViewImpl)),
);
