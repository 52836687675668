/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaQueryRequestBusinessPartnerFilter,
    KvintaQueryRequestBusinessPartnerFilterFromJSON,
    KvintaQueryRequestBusinessPartnerFilterFromJSONTyped,
    KvintaQueryRequestBusinessPartnerFilterToJSON,
    KvintaQueryRequestLocationFilter,
    KvintaQueryRequestLocationFilterFromJSON,
    KvintaQueryRequestLocationFilterFromJSONTyped,
    KvintaQueryRequestLocationFilterToJSON,
    KvintaQueryRequestProductFilter,
    KvintaQueryRequestProductFilterFromJSON,
    KvintaQueryRequestProductFilterFromJSONTyped,
    KvintaQueryRequestProductFilterToJSON,
    KvintaQueryRequestTradeItemFilter,
    KvintaQueryRequestTradeItemFilterFromJSON,
    KvintaQueryRequestTradeItemFilterFromJSONTyped,
    KvintaQueryRequestTradeItemFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryMasterdataRequest
 */
export interface KvintaQueryMasterdataRequest {
    /**
     * 
     * @type {KvintaQueryRequestBusinessPartnerFilter}
     * @memberof KvintaQueryMasterdataRequest
     */
    bp?: KvintaQueryRequestBusinessPartnerFilter | null;
    /**
     * 
     * @type {KvintaQueryRequestLocationFilter}
     * @memberof KvintaQueryMasterdataRequest
     */
    location?: KvintaQueryRequestLocationFilter | null;
    /**
     * 
     * @type {KvintaQueryRequestProductFilter}
     * @memberof KvintaQueryMasterdataRequest
     */
    product?: KvintaQueryRequestProductFilter | null;
    /**
     * 
     * @type {KvintaQueryRequestTradeItemFilter}
     * @memberof KvintaQueryMasterdataRequest
     */
    tradeItem?: KvintaQueryRequestTradeItemFilter | null;
}

export function KvintaQueryMasterdataRequestFromJSON(json: any): KvintaQueryMasterdataRequest {
    return KvintaQueryMasterdataRequestFromJSONTyped(json, false);
}

export function KvintaQueryMasterdataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryMasterdataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bp': !exists(json, 'bp') ? undefined : KvintaQueryRequestBusinessPartnerFilterFromJSON(json['bp']),
        'location': !exists(json, 'location') ? undefined : KvintaQueryRequestLocationFilterFromJSON(json['location']),
        'product': !exists(json, 'product') ? undefined : KvintaQueryRequestProductFilterFromJSON(json['product']),
        'tradeItem': !exists(json, 'tradeItem') ? undefined : KvintaQueryRequestTradeItemFilterFromJSON(json['tradeItem']),
    };
}

export function KvintaQueryMasterdataRequestToJSON(value?: KvintaQueryMasterdataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bp': KvintaQueryRequestBusinessPartnerFilterToJSON(value.bp),
        'location': KvintaQueryRequestLocationFilterToJSON(value.location),
        'product': KvintaQueryRequestProductFilterToJSON(value.product),
        'tradeItem': KvintaQueryRequestTradeItemFilterToJSON(value.tradeItem),
    };
}


