/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeRange,
    KvintaDateTimeRangeFromJSON,
    KvintaDateTimeRangeFromJSONTyped,
    KvintaDateTimeRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisDocumentFilters
 */
export interface KvintaEpcisDocumentFilters {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentFilters
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentFilters
     */
    idLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentFilters
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentFilters
     */
    locationLike?: string | null;
    /**
     * 
     * @type {KvintaDateTimeRange}
     * @memberof KvintaEpcisDocumentFilters
     */
    recordDateTimeRange?: KvintaDateTimeRange | null;
}

export function KvintaEpcisDocumentFiltersFromJSON(json: any): KvintaEpcisDocumentFilters {
    return KvintaEpcisDocumentFiltersFromJSONTyped(json, false);
}

export function KvintaEpcisDocumentFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisDocumentFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idLike': !exists(json, 'idLike') ? undefined : json['idLike'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'locationLike': !exists(json, 'locationLike') ? undefined : json['locationLike'],
        'recordDateTimeRange': !exists(json, 'recordDateTimeRange') ? undefined : KvintaDateTimeRangeFromJSON(json['recordDateTimeRange']),
    };
}

export function KvintaEpcisDocumentFiltersToJSON(value?: KvintaEpcisDocumentFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idLike': value.idLike,
        'location': value.location,
        'locationLike': value.locationLike,
        'recordDateTimeRange': KvintaDateTimeRangeToJSON(value.recordDateTimeRange),
    };
}


