import { tabs } from './tabs';
import React, { Component } from 'react';
import { EPCIS_EVENTS_STORE_ID, EpcisEventsStore } from './EpcisEventsStore';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  TabBar,
  tableIcons,
} from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { reaction, toJS } from 'mobx';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { TextField } from '../../../components/FormFields/TextField';
import styles from './Event.module.scss';
import { defineSorting } from '../../../common/utils';
import MaterialTable from 'material-table';
import { Paper } from '@mui/material';

interface ViewEpcisEventSummaryProps {
  intl: any;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  epcisEventsStore: EpcisEventsStore;
  pageContentStore?: PageContentStore;
  id: string;
}

class ViewEpcisEventEpcListBase extends Component<ViewEpcisEventSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.epcisEventsStore.loadEvent(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisEventsStore.currentEvent,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            this.props.historyStore.navigateEpcisEventsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const { currentEvent, isLoading } = this.props.epcisEventsStore;
    if (!currentEvent) {
      return null;
    }

    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault - 50;
    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '1') {
              this.props.historyStore.navigateEpcisEventPath(this.props.id);
            } else if (newValue === '3') {
              this.props.historyStore.navigateEpcisEventJsonPath(this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />
        <div id="EpcisEventEpcListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: intl.formatMessage({ id: 'epcis-event.list.epcs' }),
                field: 'id',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={
              currentEvent.epcList
                ? currentEvent.epcList.map((epc) => ({ id: epc }))
                : currentEvent.childEPCs
                ? currentEvent.childEPCs.map((epc) => ({ id: epc }))
                : []
            }
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: false,
              sorting: false,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              thirdSortClick: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            components={{
              Toolbar: () => null,
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateEpcMessagePath(rowData.id, 0);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export const EpcisEventEpcList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    EPCIS_EVENTS_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(ViewEpcisEventEpcListBase)),
);
