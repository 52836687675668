/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaErrorDeclaration
 */
export interface KvintaErrorDeclaration {
    /**
     * 
     * @type {number}
     * @memberof KvintaErrorDeclaration
     */
    declarationTime: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaErrorDeclaration
     */
    reason: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaErrorDeclaration
     */
    correctiveEventIDs?: Array<string> | null;
}

export function KvintaErrorDeclarationFromJSON(json: any): KvintaErrorDeclaration {
    return KvintaErrorDeclarationFromJSONTyped(json, false);
}

export function KvintaErrorDeclarationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaErrorDeclaration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'declarationTime': json['declarationTime'],
        'reason': json['reason'],
        'correctiveEventIDs': !exists(json, 'correctiveEventIDs') ? undefined : json['correctiveEventIDs'],
    };
}

export function KvintaErrorDeclarationToJSON(value?: KvintaErrorDeclaration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declarationTime': value.declarationTime,
        'reason': value.reason,
        'correctiveEventIDs': value.correctiveEventIDs,
    };
}


