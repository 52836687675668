/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSftpInboundConfig,
    KvintaSftpInboundConfigFromJSON,
    KvintaSftpInboundConfigFromJSONTyped,
    KvintaSftpInboundConfigToJSON,
    KvintaSftpOutboundConfig,
    KvintaSftpOutboundConfigFromJSON,
    KvintaSftpOutboundConfigFromJSONTyped,
    KvintaSftpOutboundConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSftpGatewayConfig
 */
export interface KvintaSftpGatewayConfig {
    /**
     * 
     * @type {KvintaSftpOutboundConfig}
     * @memberof KvintaSftpGatewayConfig
     */
    outbound?: KvintaSftpOutboundConfig | null;
    /**
     * 
     * @type {KvintaSftpInboundConfig}
     * @memberof KvintaSftpGatewayConfig
     */
    inbound?: KvintaSftpInboundConfig | null;
}

export function KvintaSftpGatewayConfigFromJSON(json: any): KvintaSftpGatewayConfig {
    return KvintaSftpGatewayConfigFromJSONTyped(json, false);
}

export function KvintaSftpGatewayConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSftpGatewayConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outbound': !exists(json, 'outbound') ? undefined : KvintaSftpOutboundConfigFromJSON(json['outbound']),
        'inbound': !exists(json, 'inbound') ? undefined : KvintaSftpInboundConfigFromJSON(json['inbound']),
    };
}

export function KvintaSftpGatewayConfigToJSON(value?: KvintaSftpGatewayConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outbound': KvintaSftpOutboundConfigToJSON(value.outbound),
        'inbound': KvintaSftpInboundConfigToJSON(value.inbound),
    };
}


