/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmStatus,
    KvintaAlarmStatusFromJSON,
    KvintaAlarmStatusFromJSONTyped,
    KvintaAlarmStatusToJSON,
    KvintaDeliveryItem,
    KvintaDeliveryItemFromJSON,
    KvintaDeliveryItemFromJSONTyped,
    KvintaDeliveryItemToJSON,
    KvintaEpcsContentItem,
    KvintaEpcsContentItemFromJSON,
    KvintaEpcsContentItemFromJSONTyped,
    KvintaEpcsContentItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDeliveryItemDiffResult
 */
export interface KvintaDeliveryItemDiffResult {
    /**
     * 
     * @type {KvintaDeliveryItem}
     * @memberof KvintaDeliveryItemDiffResult
     */
    deliveryItem?: KvintaDeliveryItem | null;
    /**
     * 
     * @type {KvintaEpcsContentItem}
     * @memberof KvintaDeliveryItemDiffResult
     */
    shipmentContent?: KvintaEpcsContentItem | null;
    /**
     * 
     * @type {KvintaAlarmStatus}
     * @memberof KvintaDeliveryItemDiffResult
     */
    deliveryItemStatus: KvintaAlarmStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItemDiffResult
     */
    alarmMessage?: string | null;
}

export function KvintaDeliveryItemDiffResultFromJSON(json: any): KvintaDeliveryItemDiffResult {
    return KvintaDeliveryItemDiffResultFromJSONTyped(json, false);
}

export function KvintaDeliveryItemDiffResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryItemDiffResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryItem': !exists(json, 'deliveryItem') ? undefined : KvintaDeliveryItemFromJSON(json['deliveryItem']),
        'shipmentContent': !exists(json, 'shipmentContent') ? undefined : KvintaEpcsContentItemFromJSON(json['shipmentContent']),
        'deliveryItemStatus': KvintaAlarmStatusFromJSON(json['deliveryItemStatus']),
        'alarmMessage': !exists(json, 'alarmMessage') ? undefined : json['alarmMessage'],
    };
}

export function KvintaDeliveryItemDiffResultToJSON(value?: KvintaDeliveryItemDiffResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryItem': KvintaDeliveryItemToJSON(value.deliveryItem),
        'shipmentContent': KvintaEpcsContentItemToJSON(value.shipmentContent),
        'deliveryItemStatus': KvintaAlarmStatusToJSON(value.deliveryItemStatus),
        'alarmMessage': value.alarmMessage,
    };
}


