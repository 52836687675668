import { AuthService } from 'kvinta/service/auth';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';

import { Configuration as DevicesConf, DefaultApi as DevicesStoreApi } from './kvinta-devices-store';
import { ConfigApi, Configuration as ECaptureConf, DefaultApi as ECaptureStoreApi } from './kvinta-epcis-capture';
import { Configuration as EpcisStoreConf, DefaultApi as EpcisStoreApi } from './kvinta-epcis-store';
import { Configuration as MDConf, DefaultApi as MDStoreApi, ConfigApi as MDConfigApi } from './kvinta-masterdata';
import {
  devicesApiPath,
  epcisCaptureApiPath,
  epcisStoreApiPath,
  masterdataApiPath,
} from 'config';


export function createDevicesAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new DevicesStoreApi(
    new DevicesConf({
      basePath: devicesApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createEPCISCaptureAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new ECaptureStoreApi(
    new ECaptureConf({
      basePath: epcisCaptureApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createConfigStoreAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new ConfigApi(
    new ECaptureConf({
      basePath: epcisCaptureApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createEpcisStoreAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new EpcisStoreApi(
    new EpcisStoreConf({
      basePath: epcisStoreApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}

export function createMasterDataAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new MDStoreApi(
    new MDConf({
      basePath: masterdataApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}
export function createConfigMasterDataAPI(config: TAppOptionsConfig, authS: AuthService) {
  return new MDConfigApi(
    new MDConf({
      basePath: masterdataApiPath,
      middleware: [authS.getAuthMiddleware()],
    }),
  );
}