/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmStatus,
    KvintaAlarmStatusFromJSON,
    KvintaAlarmStatusFromJSONTyped,
    KvintaAlarmStatusToJSON,
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
    KvintaEpcsContentDiff,
    KvintaEpcsContentDiffFromJSON,
    KvintaEpcsContentDiffFromJSONTyped,
    KvintaEpcsContentDiffToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaConsistencyCheckResult
 */
export interface KvintaConsistencyCheckResult {
    /**
     * 
     * @type {KvintaAlarmStatus}
     * @memberof KvintaConsistencyCheckResult
     */
    alarmStatus: KvintaAlarmStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaConsistencyCheckResult
     */
    message: string;
    /**
     * 
     * @type {Array<KvintaDocumentKey>}
     * @memberof KvintaConsistencyCheckResult
     */
    deliveryKeys?: Array<KvintaDocumentKey> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaConsistencyCheckResult
     */
    shipmentEventId?: string | null;
    /**
     * 
     * @type {KvintaEpcsContentDiff}
     * @memberof KvintaConsistencyCheckResult
     */
    diff?: KvintaEpcsContentDiff | null;
}

export function KvintaConsistencyCheckResultFromJSON(json: any): KvintaConsistencyCheckResult {
    return KvintaConsistencyCheckResultFromJSONTyped(json, false);
}

export function KvintaConsistencyCheckResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaConsistencyCheckResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alarmStatus': KvintaAlarmStatusFromJSON(json['alarmStatus']),
        'message': json['message'],
        'deliveryKeys': !exists(json, 'deliveryKeys') ? undefined : (json['deliveryKeys'] === null ? null : (json['deliveryKeys'] as Array<any>).map(KvintaDocumentKeyFromJSON)),
        'shipmentEventId': !exists(json, 'shipmentEventId') ? undefined : json['shipmentEventId'],
        'diff': !exists(json, 'diff') ? undefined : KvintaEpcsContentDiffFromJSON(json['diff']),
    };
}

export function KvintaConsistencyCheckResultToJSON(value?: KvintaConsistencyCheckResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alarmStatus': KvintaAlarmStatusToJSON(value.alarmStatus),
        'message': value.message,
        'deliveryKeys': value.deliveryKeys === undefined ? undefined : (value.deliveryKeys === null ? null : (value.deliveryKeys as Array<any>).map(KvintaDocumentKeyToJSON)),
        'shipmentEventId': value.shipmentEventId,
        'diff': KvintaEpcsContentDiffToJSON(value.diff),
    };
}


