/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSftpBusinessPartnerConfig,
    KvintaSftpBusinessPartnerConfigFromJSON,
    KvintaSftpBusinessPartnerConfigFromJSONTyped,
    KvintaSftpBusinessPartnerConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSftpOutboundConfig
 */
export interface KvintaSftpOutboundConfig {
    /**
     * 
     * @type {Array<KvintaSftpBusinessPartnerConfig>}
     * @memberof KvintaSftpOutboundConfig
     */
    bps?: Array<KvintaSftpBusinessPartnerConfig> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSftpOutboundConfig
     */
    disabled: boolean;
}

export function KvintaSftpOutboundConfigFromJSON(json: any): KvintaSftpOutboundConfig {
    return KvintaSftpOutboundConfigFromJSONTyped(json, false);
}

export function KvintaSftpOutboundConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSftpOutboundConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bps': !exists(json, 'bps') ? undefined : (json['bps'] === null ? null : (json['bps'] as Array<any>).map(KvintaSftpBusinessPartnerConfigFromJSON)),
        'disabled': json['disabled'],
    };
}

export function KvintaSftpOutboundConfigToJSON(value?: KvintaSftpOutboundConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bps': value.bps === undefined ? undefined : (value.bps === null ? null : (value.bps as Array<any>).map(KvintaSftpBusinessPartnerConfigToJSON)),
        'disabled': value.disabled,
    };
}


