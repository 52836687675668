/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaIdAndErrorExternalId
 */
export interface KvintaIdAndErrorExternalId {
    /**
     * 
     * @type {KvintaExternalId}
     * @memberof KvintaIdAndErrorExternalId
     */
    id: KvintaExternalId;
    /**
     * 
     * @type {string}
     * @memberof KvintaIdAndErrorExternalId
     */
    error: string;
}

export function KvintaIdAndErrorExternalIdFromJSON(json: any): KvintaIdAndErrorExternalId {
    return KvintaIdAndErrorExternalIdFromJSONTyped(json, false);
}

export function KvintaIdAndErrorExternalIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaIdAndErrorExternalId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': KvintaExternalIdFromJSON(json['id']),
        'error': json['error'],
    };
}

export function KvintaIdAndErrorExternalIdToJSON(value?: KvintaIdAndErrorExternalId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': KvintaExternalIdToJSON(value.id),
        'error': value.error,
    };
}


