/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcisSourceDb {
    Postgres = 'Postgres',
    Cassandra = 'Cassandra',
    CassandraAndPostgres = 'CassandraAndPostgres'
}

export function KvintaEpcisSourceDbFromJSON(json: any): KvintaEpcisSourceDb {
    return KvintaEpcisSourceDbFromJSONTyped(json, false);
}

export function KvintaEpcisSourceDbFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisSourceDb {
    return json as KvintaEpcisSourceDb;
}

export function KvintaEpcisSourceDbToJSON(value?: KvintaEpcisSourceDb | null): any {
    return value as any;
}

