/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaGetEpcByBizTransactionEpcInfo
 */
export interface KvintaGetEpcByBizTransactionEpcInfo {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcByBizTransactionEpcInfo
     */
    epc: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcByBizTransactionEpcInfo
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcByBizTransactionEpcInfo
     */
    batchNumber: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetEpcByBizTransactionEpcInfo
     */
    quantity: number;
}

export function KvintaGetEpcByBizTransactionEpcInfoFromJSON(json: any): KvintaGetEpcByBizTransactionEpcInfo {
    return KvintaGetEpcByBizTransactionEpcInfoFromJSONTyped(json, false);
}

export function KvintaGetEpcByBizTransactionEpcInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcByBizTransactionEpcInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': json['epc'],
        'gtin': json['gtin'],
        'batchNumber': json['batchNumber'],
        'quantity': json['quantity'],
    };
}

export function KvintaGetEpcByBizTransactionEpcInfoToJSON(value?: KvintaGetEpcByBizTransactionEpcInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'gtin': value.gtin,
        'batchNumber': value.batchNumber,
        'quantity': value.quantity,
    };
}


