/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaReadObjectResponseStatus,
    KvintaReadObjectResponseStatusFromJSON,
    KvintaReadObjectResponseStatusFromJSONTyped,
    KvintaReadObjectResponseStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaReadObjectResponse
 */
export interface KvintaReadObjectResponse {
    /**
     * 
     * @type {string}
     * @memberof KvintaReadObjectResponse
     */
    payload: string;
    /**
     * 
     * @type {KvintaReadObjectResponseStatus}
     * @memberof KvintaReadObjectResponse
     */
    status: KvintaReadObjectResponseStatus;
}

export function KvintaReadObjectResponseFromJSON(json: any): KvintaReadObjectResponse {
    return KvintaReadObjectResponseFromJSONTyped(json, false);
}

export function KvintaReadObjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaReadObjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payload': json['payload'],
        'status': KvintaReadObjectResponseStatusFromJSON(json['status']),
    };
}

export function KvintaReadObjectResponseToJSON(value?: KvintaReadObjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payload': value.payload,
        'status': KvintaReadObjectResponseStatusToJSON(value.status),
    };
}


