import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import styles from './EventSet.module.scss';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ERROR_DECLARATION_FORM_ROOT_ID, TErrorDeclarationFormData } from './EpcisEventSetStore';
import { EButtonState } from '../messages/EpcisMessageStore';

type EpcisErrorDeclarationFormProps = {
  intl: IntlShape;
  formData: TErrorDeclarationFormData;
  submitDeclareErrorForm: () => void;
  onChangeDeclareErrorFormField: (id: string, value: string) => void;
  onBlurDeclareErrorFormField: (id: string) => void;
  cancelDeclareErrorForm: () => void;
  isLoading: boolean;
};

const EpcisErrorDeclarationFormBase: React.FunctionComponent<EpcisErrorDeclarationFormProps> = ({
  formData,
  onChangeDeclareErrorFormField,
  onBlurDeclareErrorFormField,
  cancelDeclareErrorForm,
  submitDeclareErrorForm,
  isLoading,
  intl,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true} onClose={cancelDeclareErrorForm}>
    <DialogTitle>{intl.formatMessage({ id: 'epcis-mesage.declare-error-form.title' })}</DialogTitle>
    <DialogContent>
      <p>{intl.formatMessage({ id: 'epcis-mesage.declare-error-form.disclaimer' })}</p>
      <TextInput
        {...formData['errorDeclarationForm.reason']}
        onChange={onChangeDeclareErrorFormField}
        onBlur={onBlurDeclareErrorFormField}
        label={'epcis-mesage.declare-error-form.reason'}
      />
    </DialogContent>
    <div className={styles.buttonFormContainer}>
      <Button onClick={cancelDeclareErrorForm} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id="submitForm"
        label={intl.formatMessage({ id: 'button.submit' })}
        onClick={submitDeclareErrorForm}
        buttonState={isLoading ? EButtonState.LOADING : formDataToButtonState(formData[ERROR_DECLARATION_FORM_ROOT_ID])}
      />
    </div>
  </Dialog>
);

export const EpcisErrorDeclarationForm = injectIntl(EpcisErrorDeclarationFormBase);
