import React from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { TextField } from '../../../components/FormFields/TextField';
import { TBusinessPartner, TBusinessPartnerFormData } from './BusinessPartnerStore';
import styles from './BusinessPartner.module.scss';
import { TranslatedSelectInput } from '../../../components/FormFields/TranslatedSelectInput';

export enum EBusinessPartnerFormType {
  CREATE = 'create',
  UPDATE = 'update',
}

export type BusinessPartnerComponentProps = {
  formType: EBusinessPartnerFormType;
  formData: TBusinessPartner | TBusinessPartnerFormData;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string) => void;
};

const BusinessPartnerFormBase: React.FunctionComponent<BusinessPartnerComponentProps> = ({
  formData,
  onChange,
  onBlur,
  formType,
}) => {
  return (
    <div className={styles.formContainer} id={'BusinessPartnerFormID'}>
      {formType === EBusinessPartnerFormType.CREATE ? (
        <TextInput
          {...formData['businessPartnerForm.idInSourceSystem']}
          onBlur={onBlur}
          onChange={onChange}
          label="business-partner-create-form.field.idInSourceSystem"
          style={{ gridColumn: '1 / span 2' }}
        />
      ) : (
        <TextField
          value={formData['businessPartnerForm.idInSourceSystem'].value}
          label="business-partner-create-form.field.idInSourceSystem"
          style={{ gridColumn: '1 / span 2' }}
        />
      )}
      <TextInput
        {...formData['businessPartnerForm.name']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.name"
      />
      <TextInput
        {...formData['businessPartnerForm.gln13']}
        onBlur={onBlur}
        onChange={onChange}
        label="location-view.field.gln13"
      />
      <TextInput
        {...formData['businessPartnerForm.longitude']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.longitude"
      />
      <TextInput
        {...formData['businessPartnerForm.latitude']}
        onBlur={onBlur}
        onChange={onChange}
        label="location-view.field.latitude"
      />
      <TranslatedSelectInput
        {...formData['businessPartnerForm.country']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.country"
      />
      <TextInput
        {...formData['businessPartnerForm.city']}
        onBlur={onBlur}
        onChange={onChange}
        label="location-view.field.city"
      />
      <TextInput
        {...formData['businessPartnerForm.postalCode']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.postalCode"
      />
      <TextInput
        {...formData['businessPartnerForm.address1']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.address1"
      />
      <TextInput
        {...formData['businessPartnerForm.address2']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.address2"
      />
      <TextInput
        {...formData['businessPartnerForm.address3']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.address3"
      />
      <TextInput
        {...formData['businessPartnerForm.description']}
        onBlur={onBlur}
        onChange={onChange}
        label="business-partner.description"
        style={{ gridColumn: '1 / span 2' }}
      />
    </div>
  );
};

export const BusinessPartnerForm = observer(BusinessPartnerFormBase);
