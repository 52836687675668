/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcPackStatus,
    KvintaEpcPackStatusFromJSON,
    KvintaEpcPackStatusFromJSONTyped,
    KvintaEpcPackStatusToJSON,
    KvintaGetEpcDirectChildrenContent,
    KvintaGetEpcDirectChildrenContentFromJSON,
    KvintaGetEpcDirectChildrenContentFromJSONTyped,
    KvintaGetEpcDirectChildrenContentToJSON,
    KvintaResponseMessage,
    KvintaResponseMessageFromJSON,
    KvintaResponseMessageFromJSONTyped,
    KvintaResponseMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcDirectChildren
 */
export interface KvintaGetEpcDirectChildren {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcDirectChildren
     */
    epc: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcDirectChildren
     */
    disposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcDirectChildren
     */
    bizLocation?: string | null;
    /**
     * 
     * @type {KvintaEpcPackStatus}
     * @memberof KvintaGetEpcDirectChildren
     */
    packStatus: KvintaEpcPackStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcDirectChildren
     */
    batchNumber: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcDirectChildren
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaGetEpcDirectChildren
     */
    attributes?: { [key: string]: object; } | null;
    /**
     * 
     * @type {Array<KvintaGetEpcDirectChildrenContent>}
     * @memberof KvintaGetEpcDirectChildren
     */
    content?: Array<KvintaGetEpcDirectChildrenContent> | null;
    /**
     * 
     * @type {Array<KvintaResponseMessage>}
     * @memberof KvintaGetEpcDirectChildren
     */
    messages?: Array<KvintaResponseMessage> | null;
}

export function KvintaGetEpcDirectChildrenFromJSON(json: any): KvintaGetEpcDirectChildren {
    return KvintaGetEpcDirectChildrenFromJSONTyped(json, false);
}

export function KvintaGetEpcDirectChildrenFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcDirectChildren {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': json['epc'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'bizLocation': !exists(json, 'bizLocation') ? undefined : json['bizLocation'],
        'packStatus': KvintaEpcPackStatusFromJSON(json['packStatus']),
        'batchNumber': json['batchNumber'],
        'expiryDate': !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(KvintaGetEpcDirectChildrenContentFromJSON)),
        'messages': !exists(json, 'messages') ? undefined : (json['messages'] === null ? null : (json['messages'] as Array<any>).map(KvintaResponseMessageFromJSON)),
    };
}

export function KvintaGetEpcDirectChildrenToJSON(value?: KvintaGetEpcDirectChildren | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'disposition': value.disposition,
        'bizLocation': value.bizLocation,
        'packStatus': KvintaEpcPackStatusToJSON(value.packStatus),
        'batchNumber': value.batchNumber,
        'expiryDate': value.expiryDate,
        'attributes': value.attributes,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(KvintaGetEpcDirectChildrenContentToJSON)),
        'messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(KvintaResponseMessageToJSON)),
    };
}


