/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaIdAndErrorDocumentKey
 */
export interface KvintaIdAndErrorDocumentKey {
    /**
     * 
     * @type {KvintaDocumentKey}
     * @memberof KvintaIdAndErrorDocumentKey
     */
    id: KvintaDocumentKey;
    /**
     * 
     * @type {string}
     * @memberof KvintaIdAndErrorDocumentKey
     */
    error: string;
}

export function KvintaIdAndErrorDocumentKeyFromJSON(json: any): KvintaIdAndErrorDocumentKey {
    return KvintaIdAndErrorDocumentKeyFromJSONTyped(json, false);
}

export function KvintaIdAndErrorDocumentKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaIdAndErrorDocumentKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': KvintaDocumentKeyFromJSON(json['id']),
        'error': json['error'],
    };
}

export function KvintaIdAndErrorDocumentKeyToJSON(value?: KvintaIdAndErrorDocumentKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': KvintaDocumentKeyToJSON(value.id),
        'error': value.error,
    };
}


