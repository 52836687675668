/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaExecutionContext
 */
export interface KvintaExecutionContext {
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionContext
     */
    executionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionContext
     */
    parentExecutionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionContext
     */
    rootExecutionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionContext
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionContext
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaExecutionContext
     */
    sgln?: string | null;
}

export function KvintaExecutionContextFromJSON(json: any): KvintaExecutionContext {
    return KvintaExecutionContextFromJSONTyped(json, false);
}

export function KvintaExecutionContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExecutionContext {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'executionId': !exists(json, 'executionId') ? undefined : json['executionId'],
        'parentExecutionId': !exists(json, 'parentExecutionId') ? undefined : json['parentExecutionId'],
        'rootExecutionId': !exists(json, 'rootExecutionId') ? undefined : json['rootExecutionId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'sgln': !exists(json, 'sgln') ? undefined : json['sgln'],
    };
}

export function KvintaExecutionContextToJSON(value?: KvintaExecutionContext | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'executionId': value.executionId,
        'parentExecutionId': value.parentExecutionId,
        'rootExecutionId': value.rootExecutionId,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'sgln': value.sgln,
    };
}


