/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaOperationType {
    C = 'C',
    P = 'P',
    U = 'U',
    S = 'S',
    V = 'V',
    R = 'R',
    D = 'D',
    Ds = 'DS',
    I = 'I',
    O = 'O'
}

export function KvintaOperationTypeFromJSON(json: any): KvintaOperationType {
    return KvintaOperationTypeFromJSONTyped(json, false);
}

export function KvintaOperationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationType {
    return json as KvintaOperationType;
}

export function KvintaOperationTypeToJSON(value?: KvintaOperationType | null): any {
    return value as any;
}

