/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGetEpcByBizTransactionEpcInfo,
    KvintaGetEpcByBizTransactionEpcInfoFromJSON,
    KvintaGetEpcByBizTransactionEpcInfoFromJSONTyped,
    KvintaGetEpcByBizTransactionEpcInfoToJSON,
    KvintaResponseMessage,
    KvintaResponseMessageFromJSON,
    KvintaResponseMessageFromJSONTyped,
    KvintaResponseMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcByBizTransactionResponse
 */
export interface KvintaGetEpcByBizTransactionResponse {
    /**
     * 
     * @type {Array<KvintaGetEpcByBizTransactionEpcInfo>}
     * @memberof KvintaGetEpcByBizTransactionResponse
     */
    epcs: Array<KvintaGetEpcByBizTransactionEpcInfo>;
    /**
     * 
     * @type {Array<KvintaResponseMessage>}
     * @memberof KvintaGetEpcByBizTransactionResponse
     */
    messages?: Array<KvintaResponseMessage> | null;
}

export function KvintaGetEpcByBizTransactionResponseFromJSON(json: any): KvintaGetEpcByBizTransactionResponse {
    return KvintaGetEpcByBizTransactionResponseFromJSONTyped(json, false);
}

export function KvintaGetEpcByBizTransactionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcByBizTransactionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epcs': ((json['epcs'] as Array<any>).map(KvintaGetEpcByBizTransactionEpcInfoFromJSON)),
        'messages': !exists(json, 'messages') ? undefined : (json['messages'] === null ? null : (json['messages'] as Array<any>).map(KvintaResponseMessageFromJSON)),
    };
}

export function KvintaGetEpcByBizTransactionResponseToJSON(value?: KvintaGetEpcByBizTransactionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epcs': ((value.epcs as Array<any>).map(KvintaGetEpcByBizTransactionEpcInfoToJSON)),
        'messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(KvintaResponseMessageToJSON)),
    };
}


