/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcisDisposition {
    Active = 'ACTIVE',
    Destroyed = 'DESTROYED',
    Inactive = 'INACTIVE',
    InTransit = 'IN_TRANSIT',
    InProgress = 'IN_PROGRESS',
    Encoded = 'ENCODED',
    Expired = 'EXPIRED',
    Defect = 'DEFECT',
    QaSamples = 'QA_SAMPLES',
    NonSellableOther = 'NON_SELLABLE_OTHER',
    Other = 'OTHER',
    Recalled = 'RECALLED',
    Returned = 'RETURNED',
    Stolen = 'STOLEN'
}

export function KvintaEpcisDispositionFromJSON(json: any): KvintaEpcisDisposition {
    return KvintaEpcisDispositionFromJSONTyped(json, false);
}

export function KvintaEpcisDispositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisDisposition {
    return json as KvintaEpcisDisposition;
}

export function KvintaEpcisDispositionToJSON(value?: KvintaEpcisDisposition | null): any {
    return value as any;
}

