import Spinner from '../../assets/img/spinner.svg';
import React from 'react';
import styles from '../../assets/css/partials/modules/LoadingBackground.module.scss';

export const LoadingBackground = () => (
  <div className={styles.container}>
    <img alt="" className={styles.spinner} src={Spinner} />
    <div className={styles.block} />
  </div>
);
