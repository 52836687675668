/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMutateResultExternalId,
    KvintaMutateResultExternalIdFromJSON,
    KvintaMutateResultExternalIdFromJSONTyped,
    KvintaMutateResultExternalIdToJSON,
    KvintaMutateResultTradeItemKey,
    KvintaMutateResultTradeItemKeyFromJSON,
    KvintaMutateResultTradeItemKeyFromJSONTyped,
    KvintaMutateResultTradeItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateMasterdataResult
 */
export interface KvintaMutateMasterdataResult {
    /**
     * 
     * @type {KvintaMutateResultExternalId}
     * @memberof KvintaMutateMasterdataResult
     */
    businessPartner?: KvintaMutateResultExternalId | null;
    /**
     * 
     * @type {KvintaMutateResultExternalId}
     * @memberof KvintaMutateMasterdataResult
     */
    location?: KvintaMutateResultExternalId | null;
    /**
     * 
     * @type {KvintaMutateResultExternalId}
     * @memberof KvintaMutateMasterdataResult
     */
    product?: KvintaMutateResultExternalId | null;
    /**
     * 
     * @type {KvintaMutateResultTradeItemKey}
     * @memberof KvintaMutateMasterdataResult
     */
    tradeItem?: KvintaMutateResultTradeItemKey | null;
}

export function KvintaMutateMasterdataResultFromJSON(json: any): KvintaMutateMasterdataResult {
    return KvintaMutateMasterdataResultFromJSONTyped(json, false);
}

export function KvintaMutateMasterdataResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateMasterdataResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessPartner': !exists(json, 'businessPartner') ? undefined : KvintaMutateResultExternalIdFromJSON(json['businessPartner']),
        'location': !exists(json, 'location') ? undefined : KvintaMutateResultExternalIdFromJSON(json['location']),
        'product': !exists(json, 'product') ? undefined : KvintaMutateResultExternalIdFromJSON(json['product']),
        'tradeItem': !exists(json, 'tradeItem') ? undefined : KvintaMutateResultTradeItemKeyFromJSON(json['tradeItem']),
    };
}

export function KvintaMutateMasterdataResultToJSON(value?: KvintaMutateMasterdataResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessPartner': KvintaMutateResultExternalIdToJSON(value.businessPartner),
        'location': KvintaMutateResultExternalIdToJSON(value.location),
        'product': KvintaMutateResultExternalIdToJSON(value.product),
        'tradeItem': KvintaMutateResultTradeItemKeyToJSON(value.tradeItem),
    };
}


