/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaReturnEpcFormat,
    KvintaReturnEpcFormatFromJSON,
    KvintaReturnEpcFormatFromJSONTyped,
    KvintaReturnEpcFormatToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcDirectChildrenRequest
 */
export interface KvintaGetEpcDirectChildrenRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcDirectChildrenRequest
     */
    epc: string;
    /**
     * 
     * @type {KvintaReturnEpcFormat}
     * @memberof KvintaGetEpcDirectChildrenRequest
     */
    format: KvintaReturnEpcFormat;
    /**
     * 
     * @type {number}
     * @memberof KvintaGetEpcDirectChildrenRequest
     */
    limit?: number | null;
}

export function KvintaGetEpcDirectChildrenRequestFromJSON(json: any): KvintaGetEpcDirectChildrenRequest {
    return KvintaGetEpcDirectChildrenRequestFromJSONTyped(json, false);
}

export function KvintaGetEpcDirectChildrenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcDirectChildrenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': json['epc'],
        'format': KvintaReturnEpcFormatFromJSON(json['format']),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function KvintaGetEpcDirectChildrenRequestToJSON(value?: KvintaGetEpcDirectChildrenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'format': KvintaReturnEpcFormatToJSON(value.format),
        'limit': value.limit,
    };
}


