import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PAGE_CONTENT_STORE_ID, PageContentStore, TabBar } from 'kvinta/components';
import { tabs } from './tabs';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Notifications.module.scss';
import { NOTIFICATION_STORE_ID, NotificationStore } from './NotificationStore';
import { BizStepListForm } from './BizStepListForm';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { EUserRole, USER_STORE_ID, UserStore } from '../../main';

type TNotificationsByGln13Props = {
  intl: any;
  userStore: UserStore;
  notificationStore?: NotificationStore;
  pageContentStore: PageContentStore;
  historyStore: HistoryStore;
  id: string;
  gln13: string;
  currentAssignment?: any;
};

class NotificationsByGln13Base extends Component<TNotificationsByGln13Props> {
  componentDidMount() {
    this.props.notificationStore.fetchNotificationsByGln13(this.props.gln13);
  }

  render() {
    const { intl, gln13 } = this.props;
    const {
      bizStepListData,
      isLoading,
      saveBizStepListData,
      bizStepListSubmitButtonState,
      onChangeNotificationStepSummary,
    } = this.props.notificationStore;
    const { canAccess } = this.props.userStore;
    if (!bizStepListData) return null;
    return (
      <div className={styles.mainContainer}>
        <TabBar onChange={() => null} tabId={'1'} tabs={tabs} />
        <BizStepListForm
          userIsAdmin={this.props.userStore.userInfo.role === EUserRole.ADMIN}
          bizStepListData={bizStepListData}
          isLoading={isLoading}
          toggleBizStepList={onChangeNotificationStepSummary}
          intl={intl}
        />
        {canAccess('Notification.edit') && (
          <div className={styles.buttonContainer}>
            <ButtonGreen
              label={intl.formatMessage({ id: 'button.submit' })}
              onClick={() => saveBizStepListData(gln13)}
              buttonState={bizStepListSubmitButtonState}
              id={'openOrSubmitButton'}
            />
          </div>
        )}
      </div>
    );
  }
}

export const NotificationsByGln13 = injectIntl(
  inject(
    USER_STORE_ID,
    HISTORY_STORE_ID,
    NOTIFICATION_STORE_ID,
    PAGE_CONTENT_STORE_ID,
  )(observer(NotificationsByGln13Base)),
);
