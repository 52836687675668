import React from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import styles from './Device.module.scss';

export interface DeviceComponentProps {
  formData: any;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string, value: string) => void;
}

const DeviceFormBase: React.FunctionComponent<DeviceComponentProps> = ({ formData, onBlur, onChange }) => {
  return (
    <div className={styles.formContainer} id={'deviceFormID'}>
      <TextInput {...formData['deviceForm.email']} onBlur={onBlur} onChange={onChange} label="device.email" />
      <TextInput
        {...formData['deviceForm.serialNumber']}
        onBlur={onBlur}
        onChange={onChange}
        label="device.serialNumber"
      />
      <TextInput
        {...formData['deviceForm.locationGln13']}
        onBlur={onBlur}
        onChange={onChange}
        label="device.locationgln13"
      />
    </div>
  );
};

export const DeviceForm = observer(DeviceFormBase);
