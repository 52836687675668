import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExportButton,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { FilterBar } from '../../../components/FilterBar/FilterBar';
import { DEVICE_STORE_ID, DeviceStore } from './DeviceStore';
import { CSVDownload } from 'react-csv';
import { CreateDeviceDialog } from './CreateDeviceDialog';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Device.module.scss';
import { ITableActionsState } from '../../../common';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { defineSorting } from '../../../common/utils';
import { USER_STORE_ID, UserStore } from '../../main';

interface ListDevicesViewProps {
  userStore: UserStore;
  deviceStore?: DeviceStore;
  pageContentStore?: PageContentStore;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  history?: any;
}

class DeviceListBase extends Component<ListDevicesViewProps, ITableActionsState> {
  constructor(props: ListDevicesViewProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.deviceStore.fetchDeviceList();
    this.props.sidebarStore.clearMainTitle();
  }

  componentWillUnmount() {
    this.props.deviceStore.cleanCurrentSort();
  }

  render() {
    const intl = this.props.intl;
    const {
      listData,
      isLoading,
      deleteDevice,
      deviceFormOpen,
      exportActive,
      exportData,
      page,
      totalCount,
      pageSize,
      filter,
    } = this.props.deviceStore;
    const { canAccess } = this.props.userStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    this.props.deviceStore.updateExported();
    return (
      <div className={styles.mainContainer}>
        <div className={styles.toolbarContainer}>
          <FilterBar filter={filter} />
          {canAccess('Device.create') && (
            <div className={styles.buttonContainer}>
              <ExportButton
                onExportAll={this.props.deviceStore.exportAll}
                onExportSelected={this.props.deviceStore.exportSelected}
                hasSelected={this.props.deviceStore.checked > 0}
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon color="secondary" />}
                onClick={(e) => {
                  this.props.deviceStore.openCreateDeviceForm();
                }}
              >
                {intl.formatMessage({ id: 'button.create' })}
              </Button>
            </div>
          )}
        </div>
        <div id="DeviceListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: intl.formatMessage({ id: 'devices-list.id' }),
                field: 'id',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'id'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'devices-list.email' }),
                field: 'email',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'email'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'devices-list.locationGln13' }),
                field: 'locationGln13',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'locationGln13'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'devices-list.serialNumber' }),
                field: 'serialNumber',
                defaultSort: defineSorting(this.props.deviceStore.currentSort?.expressions[0], 'serialNumber'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.deviceStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.deviceStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              thirdSortClick: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateDeviceSummaryPath(rowData.id);
              }
            }}
            actions={[
              canAccess('Device.delete') && {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => null,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          {canAccess('Device.delete') && (
            <MenuItem
              onClick={() => {
                const shouldDelete = confirm(`Do you want to delete device ${this.state.rowData.id}?`);
                if (shouldDelete) {
                  deleteDevice(this.state.rowData.id);
                }
                this.onMenuClose();
              }}
            >
              {intl.formatMessage({ id: 'button.delete' })}
            </MenuItem>
          )}
        </Menu>
        {deviceFormOpen && (
          <CreateDeviceDialog
            formData={this.props.deviceStore.deviceFormData}
            onClose={this.props.deviceStore.closeDeviceForm}
            onChange={this.props.deviceStore.onChangeDeviceFormField}
            onBlur={this.props.deviceStore.onBlurDeviceFormField}
            onSubmit={this.props.deviceStore.submitCreateDeviceForm}
          />
        )}
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </div>
    );
  }
}

export const DeviceList = injectIntl(
  inject(
    USER_STORE_ID,
    HISTORY_STORE_ID,
    DEVICE_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(DeviceListBase)),
);
