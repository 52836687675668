import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { TRADE_ITEM_FORM_ROOT_ID, TRADE_ITEM_STORE_ID, TTradeItemFormData } from './TradeItemsStore';
import { TradeItemForm } from './TradeItemForm';
import { STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ButtonGreen } from '../../../components/ButtonGreen';

interface CreateTradeItemDialogProps {
  formData: TTradeItemFormData;
  closeTradeItemForm: () => void;
  submitCreateTradeItemForm: () => void;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  navigateToProduct: () => void;
  intl: IntlShape;
}

const CreateTradeItemDialogBase: React.FunctionComponent<CreateTradeItemDialogProps> = ({
  intl,
  closeTradeItemForm,
  onChange,
  onBlur,
  formData,
  navigateToProduct,
  submitCreateTradeItemForm,
}) => {
  return (
    <Dialog fullWidth={true} maxWidth="md" open={true}>
      <KDialogTitle id="customized-dialog-title" onClose={() => closeTradeItemForm()}>
        {intl.formatMessage({ id: 'trade-item-create-form.field.create' })}
      </KDialogTitle>
      <DialogContent>
        <TradeItemForm formData={formData} onChange={onChange} onBlur={onBlur} navigateToProduct={navigateToProduct} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeTradeItemForm()} color="primary" variant="outlined">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <ButtonGreen
          id="submitButton"
          onClick={submitCreateTradeItemForm}
          label={intl.formatMessage({ id: 'button.submit' })}
          buttonState={formDataToButtonState(formData[TRADE_ITEM_FORM_ROOT_ID])}
        />
      </DialogActions>
    </Dialog>
  );
};

export const CreateTradeItemDialog = injectIntl(
  inject(HISTORY_STORE_ID, TRADE_ITEM_STORE_ID)(observer(CreateTradeItemDialogBase)),
);
