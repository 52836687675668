/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGetEpcContentSummary,
    KvintaGetEpcContentSummaryFromJSON,
    KvintaGetEpcContentSummaryFromJSONTyped,
    KvintaGetEpcContentSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcContentSummaryResponse
 */
export interface KvintaGetEpcContentSummaryResponse {
    /**
     * 
     * @type {Array<KvintaGetEpcContentSummary>}
     * @memberof KvintaGetEpcContentSummaryResponse
     */
    list: Array<KvintaGetEpcContentSummary>;
}

export function KvintaGetEpcContentSummaryResponseFromJSON(json: any): KvintaGetEpcContentSummaryResponse {
    return KvintaGetEpcContentSummaryResponseFromJSONTyped(json, false);
}

export function KvintaGetEpcContentSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcContentSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(KvintaGetEpcContentSummaryFromJSON)),
    };
}

export function KvintaGetEpcContentSummaryResponseToJSON(value?: KvintaGetEpcContentSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(KvintaGetEpcContentSummaryToJSON)),
    };
}


