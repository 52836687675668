/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeOpenRange,
    KvintaDateTimeOpenRangeFromJSON,
    KvintaDateTimeOpenRangeFromJSONTyped,
    KvintaDateTimeOpenRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaBusinessPartnerFilter
 */
export interface KvintaBusinessPartnerFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaBusinessPartnerFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaBusinessPartnerFilter
     */
    nameLike?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaBusinessPartnerFilter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaBusinessPartnerFilter
     */
    gln13Like?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaBusinessPartnerFilter
     */
    gln13?: string | null;
    /**
     * 
     * @type {KvintaDateTimeOpenRange}
     * @memberof KvintaBusinessPartnerFilter
     */
    created?: KvintaDateTimeOpenRange | null;
}

export function KvintaBusinessPartnerFilterFromJSON(json: any): KvintaBusinessPartnerFilter {
    return KvintaBusinessPartnerFilterFromJSONTyped(json, false);
}

export function KvintaBusinessPartnerFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaBusinessPartnerFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nameLike': !exists(json, 'nameLike') ? undefined : json['nameLike'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gln13Like': !exists(json, 'gln13Like') ? undefined : json['gln13Like'],
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'created': !exists(json, 'created') ? undefined : KvintaDateTimeOpenRangeFromJSON(json['created']),
    };
}

export function KvintaBusinessPartnerFilterToJSON(value?: KvintaBusinessPartnerFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameLike': value.nameLike,
        'name': value.name,
        'gln13Like': value.gln13Like,
        'gln13': value.gln13,
        'created': KvintaDateTimeOpenRangeToJSON(value.created),
    };
}


