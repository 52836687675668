/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcEvent,
    KvintaEpcEventFromJSON,
    KvintaEpcEventFromJSONTyped,
    KvintaEpcEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListEpcEventsResponse
 */
export interface KvintaListEpcEventsResponse {
    /**
     * 
     * @type {Array<KvintaEpcEvent>}
     * @memberof KvintaListEpcEventsResponse
     */
    events: Array<KvintaEpcEvent>;
    /**
     * 
     * @type {string}
     * @memberof KvintaListEpcEventsResponse
     */
    nextpage?: string | null;
}

export function KvintaListEpcEventsResponseFromJSON(json: any): KvintaListEpcEventsResponse {
    return KvintaListEpcEventsResponseFromJSONTyped(json, false);
}

export function KvintaListEpcEventsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListEpcEventsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': ((json['events'] as Array<any>).map(KvintaEpcEventFromJSON)),
        'nextpage': !exists(json, 'nextpage') ? undefined : json['nextpage'],
    };
}

export function KvintaListEpcEventsResponseToJSON(value?: KvintaListEpcEventsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': ((value.events as Array<any>).map(KvintaEpcEventToJSON)),
        'nextpage': value.nextpage,
    };
}


