/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisEventFilters,
    KvintaEpcisEventFiltersFromJSON,
    KvintaEpcisEventFiltersFromJSONTyped,
    KvintaEpcisEventFiltersToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaListEpcisEventsRequest
 */
export interface KvintaListEpcisEventsRequest {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaListEpcisEventsRequest
     */
    paging?: KvintaPaging;
    /**
     * 
     * @type {KvintaEpcisEventFilters}
     * @memberof KvintaListEpcisEventsRequest
     */
    filters?: KvintaEpcisEventFilters;
}

export function KvintaListEpcisEventsRequestFromJSON(json: any): KvintaListEpcisEventsRequest {
    return KvintaListEpcisEventsRequestFromJSONTyped(json, false);
}

export function KvintaListEpcisEventsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaListEpcisEventsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': !exists(json, 'paging') ? undefined : KvintaPagingFromJSON(json['paging']),
        'filters': !exists(json, 'filters') ? undefined : KvintaEpcisEventFiltersFromJSON(json['filters']),
    };
}

export function KvintaListEpcisEventsRequestToJSON(value?: KvintaListEpcisEventsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
        'filters': KvintaEpcisEventFiltersToJSON(value.filters),
    };
}


