/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDateTimeOpenRange,
    KvintaDateTimeOpenRangeFromJSON,
    KvintaDateTimeOpenRangeFromJSONTyped,
    KvintaDateTimeOpenRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryDeliveryItemFilter
 */
export interface KvintaQueryDeliveryItemFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaQueryDeliveryItemFilter
     */
    itemProductCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaQueryDeliveryItemFilter
     */
    itemBatchNumber?: string | null;
    /**
     * 
     * @type {KvintaDateTimeOpenRange}
     * @memberof KvintaQueryDeliveryItemFilter
     */
    itemDateExpected?: KvintaDateTimeOpenRange | null;
    /**
     * 
     * @type {KvintaDateTimeOpenRange}
     * @memberof KvintaQueryDeliveryItemFilter
     */
    itemDateConfirmed?: KvintaDateTimeOpenRange | null;
}

export function KvintaQueryDeliveryItemFilterFromJSON(json: any): KvintaQueryDeliveryItemFilter {
    return KvintaQueryDeliveryItemFilterFromJSONTyped(json, false);
}

export function KvintaQueryDeliveryItemFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryDeliveryItemFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemProductCode': !exists(json, 'itemProductCode') ? undefined : json['itemProductCode'],
        'itemBatchNumber': !exists(json, 'itemBatchNumber') ? undefined : json['itemBatchNumber'],
        'itemDateExpected': !exists(json, 'itemDateExpected') ? undefined : KvintaDateTimeOpenRangeFromJSON(json['itemDateExpected']),
        'itemDateConfirmed': !exists(json, 'itemDateConfirmed') ? undefined : KvintaDateTimeOpenRangeFromJSON(json['itemDateConfirmed']),
    };
}

export function KvintaQueryDeliveryItemFilterToJSON(value?: KvintaQueryDeliveryItemFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemProductCode': value.itemProductCode,
        'itemBatchNumber': value.itemBatchNumber,
        'itemDateExpected': KvintaDateTimeOpenRangeToJSON(value.itemDateExpected),
        'itemDateConfirmed': KvintaDateTimeOpenRangeToJSON(value.itemDateConfirmed),
    };
}


