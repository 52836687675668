/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaTradeItem,
    KvintaTradeItemFromJSON,
    KvintaTradeItemFromJSONTyped,
    KvintaTradeItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryResultTradeItem
 */
export interface KvintaQueryResultTradeItem {
    /**
     * 
     * @type {Array<KvintaTradeItem>}
     * @memberof KvintaQueryResultTradeItem
     */
    list?: Array<KvintaTradeItem> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaQueryResultTradeItem
     */
    total?: number | null;
}

export function KvintaQueryResultTradeItemFromJSON(json: any): KvintaQueryResultTradeItem {
    return KvintaQueryResultTradeItemFromJSONTyped(json, false);
}

export function KvintaQueryResultTradeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryResultTradeItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaTradeItemFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaQueryResultTradeItemToJSON(value?: KvintaQueryResultTradeItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaTradeItemToJSON)),
        'total': value.total,
    };
}


