import { Link, Paper } from '@mui/material';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { EPC_MESSAGES_STORE_ID, EpcMessagesStore } from './EpcMessagesStore';
import { Waypoint } from 'react-waypoint';
import { FilterBar } from '../../../components/FilterBar/FilterBar';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { defineSorting, generateLinkEpcMessagePath } from '../../../common/utils';
import styles from './Epcs.module.scss';

interface ViewListEpcMessagesProps {
  epcMessagesStore?: EpcMessagesStore;
  historyStore: HistoryStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
}

class ViewListEpcMessagesImpl extends Component<ViewListEpcMessagesProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: ViewListEpcMessagesProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcMessagesStore.fetchInitialData();
  }

  navigateMessageSummary = (history: any, id: string, ts: number, idx?: number) => {
    this.props.historyStore.navigateEpcMessagePath(id, ts, idx);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, totalCount, filter } = this.props.epcMessagesStore;
    const { height } = this.props.pageContentStore;
    if (!height || height === NaN || height === 0) {
      return null;
    }
    const bodyHeight = this.props.pageContentStore.tableContentHeightThin;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <div className={styles.mainContainer}>
        <div className={styles.toolbarContainer}>
          <FilterBar filter={filter} />
        </div>
        <div id="EpcMessagesListID">
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'epc-list.code' }),
                field: 'id',
                defaultSort: defineSorting(this.props.epcMessagesStore.currentSort?.expressions[0], 'id'),
                sorting: false,
                render: (data) => {
                  const rowIndex = data.idx;
                  return (
                    <>
                      {rowIndex === totalCount - 10 && (
                        <Waypoint
                          onEnter={() => {
                            this.props.epcMessagesStore.fetchNextPage();
                          }}
                        />
                      )}
                      <Link href={generateLinkEpcMessagePath(data.id, data.ts, data.tsIdx)} onClick={preventDefault}>
                        {data.id}
                      </Link>
                    </>
                  );
                },
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'epc-list.location' }),
                field: 'location',
                defaultSort: defineSorting(this.props.epcMessagesStore.currentSort?.expressions[0], 'location'),
                sorting: false,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'epc-list.operation' }),
                field: 'op',
                defaultSort: defineSorting(this.props.epcMessagesStore.currentSort?.expressions[0], 'op'),
                sorting: false,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            totalCount={totalCount}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.epcMessagesStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              sorting: true,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              loadingType: 'overlay',
              showTitle: false,
            }}
            onRowClick={(event, rowData) =>
              this.props.historyStore.navigateEpcMessagePath(rowData.id, rowData.ts, rowData.tsIdx)
            }
            components={{
              Toolbar: (_props) => <></>,
            }}
          />
        </div>
      </div>
    );
  }
}

export const EpcMessageList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    EPC_MESSAGES_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(ViewListEpcMessagesImpl)),
);
