import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { DeviceAssignmentForm } from './DeviceAssignmentForm';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { DEVICE_ASSIGNMENT_FORM_ROOT_ID } from './DeviceStore';

interface DialogCreateAssignmentProps {
  apps: any[];
  onClose: () => void;
  onSubmit: () => void;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string) => void;
  formData;
  intl: any;
}

const CreateDeviceAssignmentDialogBase: React.FunctionComponent<DialogCreateAssignmentProps> = ({
  intl,
  onClose,
  onSubmit,
  onChange,
  onBlur,
  formData,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true}>
    <KDialogTitle id="customized-dialog-title" onClose={onClose}>
      {intl.formatMessage({ id: 'device-assignment-form.create' })}
    </KDialogTitle>
    <DialogContent>
      <DeviceAssignmentForm formData={formData} onChange={onChange} onBlur={onBlur} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id={'submit'}
        label={intl.formatMessage({ id: 'button.submit' })}
        buttonState={formDataToButtonState(formData[DEVICE_ASSIGNMENT_FORM_ROOT_ID])}
        onClick={onSubmit}
      />
    </DialogActions>
  </Dialog>
);

export const CreateDeviceAssignmentDialog = injectIntl(observer(CreateDeviceAssignmentDialogBase));
