import { action, computed, makeObservable, observable } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common';
import { NotificationManager } from 'kvinta/modules/main';
import { TSimpleHierarchyHistoryPoint } from './Hierarchy/types';
import { MovementMapStore } from './Map/MovementMapStore';
import { pointsDataToGeoJSON } from './Map/mapUtils';
import { TCoordinates } from './Map/types';
import { KvintaEpcEvent } from 'kvinta/apis/kvinta-epcis-capture/models/KvintaEpcEvent';
import {
  DefaultApi as ECaptureStoreApi,
  KvintaEventListFilter,
  KvintaGetEpcEventListRequest,
  KvintaOperationStatus,
  KvintaOperationType,
} from 'kvinta/apis/kvinta-epcis-capture';
import {
  DefaultApi as MDDocumentApi,
  KvintaLocation,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-masterdata';
import { KvintaDateTimeRange } from 'kvinta/apis/kvinta-devices-store';
import { getInternalId } from 'kvinta/apis/utils';
import { DefaultApi as EpcisStoreApi } from 'kvinta/apis/kvinta-epcis-store';
import { EFilterType, IFilter } from '../../../components/FilterBar/FilterBar';

type TEventData = { ts: number; parentId?: string; op: 'U' | 'P' | 'C' };

export interface VEpcMessageRow {
  location: string;
  op: string;
  id: string;
  ts: number;
  tsIdx?: number;
  idx: number;
}

export interface ExtKvintaEpcEvent extends KvintaEpcEvent {
  attrId: string;
  id: string;
  timestamp?: string;
  operation: string;
  action?: string;
  disposition?: string;
  errorDeclaration?: string;
  createdByTaskId?: string;
  messageId?: string;
  readPoint?: string;
  recordTime?: string;
}

export class EpcMessagesStore {
  private _config: TAppOptionsConfig;
  private _epcCaptureApi: ECaptureStoreApi;
  private _mdApi: MDDocumentApi;
  private _epcisStoreApi: EpcisStoreApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  listData: VEpcMessageRow[];
  page: string | undefined;
  range: KvintaDateTimeRange;
  currentSort: KvintaSortExpressions;

  filter: IFilter;
  locations: KvintaLocation[];
  currentEpc?: ExtKvintaEpcEvent;
  hierarchyHistory: TSimpleHierarchyHistoryPoint[];
  movementMapStore?: MovementMapStore;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    epcisCaptureAPI: ECaptureStoreApi,
    mdApi: MDDocumentApi,
    epcisStoreApi: EpcisStoreApi,
  ) {
    makeObservable(this, {
      fetchNextPage: action.bound,
      fetchInitialData: action.bound,
      loadHierarchy: action.bound,
      fetchEpc: action.bound,
      fetchMap: action.bound,

      isLoading: observable,
      listData: observable,
      page: observable,
      filter: observable,
      currentEpc: observable,

      totalCount: computed,

      hierarchyHistory: observable,

      movementMapStore: observable,
    });

    this._config = config;
    this._epcCaptureApi = epcisCaptureAPI;
    this._mdApi = mdApi;
    this._epcisStoreApi = epcisStoreApi;
    this._notificationManager = notificationManager;
    this.page = undefined;
    this.listData = new Array<VEpcMessageRow>();
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'createDate',
          ignoreCase: false,
        },
      ],
    };
    this.filter = new IFilter(
      [
        {
          type: EFilterType.TEXT_FILTER,
          id: 'id',
          label: 'epcis-document.id',
          isActive: true,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'location',
          label: 'epcis-document.location',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );

    this.hierarchyHistory = undefined;

    this.currentEpc = undefined;
  }

  doFilter = async () => {
    this.isLoading = true;
    this.page = undefined;
    this.listData = [];
    this.fetchInitialData();
  };

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const dir = getDirection(orderDirection);
    const field = getField(orderBy);
    this.currentSort = {
      expressions: [
        {
          direction: dir,
          property: field,
          ignoreCase: false,
        },
      ],
    };
    this.fetchInitialData();
  }

  get totalCount() {
    return this.listData ? this.listData.length : 0;
  }

  async fetchInitialData() {
    this.page = undefined;
    this.isLoading = true;
    this.listData = [];
    this.fetchData(this.page);
  }

  async fetchNextPage() {
    if (this.page) {
      this.isLoading = true;
      this.fetchData(this.page);
    }
  }

  async fetchData(page?: string) {
    let filters = {} as KvintaEventListFilter;

    for (const filter of this.filter.visibleFilters) {
      if (filter.id === 'id' && filter.value !== '') {
        filters = {
          ...filters,
          epc: filter.value as string,
        };
      }
      if (filter.id === 'location' && filter.value !== '') {
        filters = {
          ...filters,
          loc: filter.value as string,
        };
      }
    }
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);
    let reqData = {
      range: { from: fromDate.getTime(), to: new Date().getTime() } as KvintaDateTimeRange,
      filter: filters,
    } as KvintaGetEpcEventListRequest;
    reqData = {
      ...reqData,
      page: this.page,
    };

    this._epcCaptureApi
      .listEpcEvents({
        kvintaOperationRequestGetEpcEventListRequest: {
          input: {
            ...reqData,
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Error) {
          this.listData = [];
          this._notificationManager.sendError(`An error occurred while loading data\n${result.error}`);
        } else {
          if (result.data.events) {
            this.listData = this.listData.concat(kepcApiRowToView(result.data.events, this.totalCount));
            this.page = result.data.nextpage;
          }
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while loading data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async fetchEpc(id: string, ts: number, idx?: number) {
    this.currentEpc = undefined;
    const filterIndex = idx ? idx : 0;
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);
    let reqData = {
      range: { from: fromDate.getTime(), to: new Date().getTime() } as KvintaDateTimeRange,
      filter: { epc: id },
    } as KvintaGetEpcEventListRequest;
    if (this.page) {
      reqData = {
        ...reqData,
        page: this.page,
      };
    }
    this._epcCaptureApi
      .listEpcEvents({
        kvintaOperationRequestGetEpcEventListRequest: {
          input: {
            ...reqData,
          },
        },
      })
      .then((result) => {
        if (result.status !== KvintaOperationStatus.Error) {
          if (result.data.events) {
            for (const ev of result.data.events) {
              if ((ev.ts == ts || ts == 0) && (ev.idx == filterIndex || (ev.idx === undefined && filterIndex == 0))) {
                this.currentEpc = kvintaToExtEvent(ev);
                break;
              }
            }
            if (this.currentEpc === undefined && result.data.events.length > 0) {
              this.currentEpc = kvintaToExtEvent(result.data.events[0]);
            }
          }
        } else {
          this._notificationManager.sendError(`An error occurred while laoding data\n${result.error}`);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while laoding data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async fetchMap(id: string) {
    this.isLoading = true;
    this.movementMapStore = undefined;
    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 1);

    Promise.all([
      this._epcCaptureApi.readEpcEventById({
        kvintaOperationRequestString: {
          input: id,
        },
      }),
      this._epcCaptureApi.readRelevantChangesLocations({
        kvintaOperationRequestGetRelevantChangesRequest: {
          input: {
            epc: id,
            range: { from: fromDate.getTime(), to: new Date().getTime() },
          },
        },
      }),
    ])
      .then(([epcEventResult, relevantChangesResult]) => {
        if (
          epcEventResult.status === KvintaOperationStatus.Ok &&
          relevantChangesResult.status === KvintaOperationStatus.Ok
        ) {
          this.currentEpc = kvintaToExtEvent(epcEventResult.data);
          const locations = relevantChangesResult.data.relevantChanges
            .map((change) => {
              if (change.location) {
                return {
                  id: getInternalId(change.location),
                  name: change.location.name,
                  gln13: change.location.gln13,
                  coordinates: [change.location.longitude, change.location.latitude] as TCoordinates,
                  timestamp: new Date(change.ts).toISOString(),
                  description: '',
                  type: '',
                };
              } else {
                return undefined;
              }
            })
            .filter((location) => Boolean(location));
          if (locations.length) {
            const points = pointsDataToGeoJSON(locations);
            this.movementMapStore = new MovementMapStore(points, locations, locations[locations.length - 1].id);
          } else {
            this._notificationManager.sendError(`No valid locations found`);
            this.movementMapStore = new MovementMapStore([], [], undefined);
          }
        } else {
          const errorMessage = [epcEventResult.error, relevantChangesResult.error]
            .filter((errMessage) => Boolean(errMessage))
            .join('\n');
          this._notificationManager.sendError(`An error occurred while fetching map data\n${errorMessage}`);
        }
      })
      .catch((err) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while fetching map data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async loadHierarchy(id: string) {
    this.hierarchyHistory = undefined;

    const hierarchyEvents = await this._epcCaptureApi.readHierarchyEvents({
      kvintaInlineObject2: {
        request: {
          input: {
            epc: id,
            range: {
              to: Date.now(),
            },
          },
        },
      },
    });

    const filteredEvents = hierarchyEvents.data.events
      ? hierarchyEvents.data.events.reduce((acc: { [key: string]: any }, event: { ts?: number }) => {
          if (acc[event.ts]) {
            const packingEvent = [acc[event.ts], event].find((ev) => ev.op === 'P');
            acc[event.ts] = packingEvent;
          } else {
            acc[event.ts] = event;
          }
          return acc;
        }, {})
      : [];

    const hierarchyHistoryData = [] as TSimpleHierarchyHistoryPoint[];
    for (const event of Object.values(filteredEvents)) {
      const childEvents = await this.getChildEvents(event, id);
      hierarchyHistoryData.push(childEvents);
    }

    this.hierarchyHistory = hierarchyHistoryData;
  }

  async getChildEvents({ ts, parentId, op }: TEventData, id: string): Promise<TSimpleHierarchyHistoryPoint> {
    const eventDate = new Date(ts);
    const children = await this._epcCaptureApi.readChildren({
      kvintaOperationRequestGetRelativesRequest: { input: { epc: id, ts: ts } },
    });

    return {
      timestamp: eventDate,
      hierarchy: {
        parent: parentId && op !== 'U' ? { id: parentId } : undefined,
        tracked: { id },
        children: (children.data || []).map((child: string) => ({ id: child })),
      },
    };
  }
}

export const EPC_MESSAGES_STORE_ID = 'epcMessagesStore';

function kepcApiRowToView(list: KvintaEpcEvent[], startIndex: number): VEpcMessageRow[] {
  let index = startIndex;
  const viewItems = list.map<VEpcMessageRow>((doc) => {
    return {
      location: doc.location,
      id: doc.epc,
      op: opToOperation(doc.op),
      idx: index++,
      ts: doc.ts,
      tsIdx: doc.idx,
    };
  });
  return viewItems;
}

function getDirection(orderDirection: string): KvintaSortDirection {
  if (orderDirection === 'asc') {
    return KvintaSortDirection.Asc;
  } else {
    return KvintaSortDirection.Desc;
  }
}

function getField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'id';
    case 2:
      return 'location';
    case 3:
      return 'op';
    default:
      return 'id';
  }
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

enum EOperationType {
  COMMISSIONING = 'commissioning',
  PACKING = 'packing',
  UNPACKING = 'unpacking',
  SHIPMENT = 'shipment',
  RECEIVING = 'receiving',
  DECOMMISSIONING = 'decommissioning',
  INSPECTING = 'inspecting',
  OTHER = 'other',
  DESTROYING = 'destroying',
}

function opToOperation(op: KvintaOperationType) {
  switch (op) {
    case KvintaOperationType.C:
      return EOperationType.COMMISSIONING;
    case KvintaOperationType.P:
      return EOperationType.PACKING;
    case KvintaOperationType.U:
      return EOperationType.UNPACKING;
    case KvintaOperationType.D:
      return EOperationType.DECOMMISSIONING;
    case KvintaOperationType.O:
      return EOperationType.OTHER;
    case KvintaOperationType.R:
      return EOperationType.RECEIVING;
    case KvintaOperationType.S:
      return EOperationType.SHIPMENT;
    case KvintaOperationType.V:
      return EOperationType.OTHER;
    case KvintaOperationType.I:
      return EOperationType.INSPECTING;
    case KvintaOperationType.Ds:
      return EOperationType.DESTROYING;
  }
}

function kvintaToExtEvent(record: KvintaEpcEvent): ExtKvintaEpcEvent {
  return {
    ...record,
    ...record.attr,
    attrId: record.attr?.id,
    id: record.epc,
    timestamp: record.ts ? new Date(record.ts).toISOString() : undefined,
    operation: record.op && opToOperation(record.op),
  };
}
