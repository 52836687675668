import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  DELIVERY_NOTE_FORM_ROOT_ID,
  DELIVERY_NOTE_STORE_ID,
  DeliveryNotesStore,
  TDeliveryNote,
} from './DeliveryNotesStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsSummary } from './tabs';
import EditIcon from '@mui/icons-material/Edit';
import { reaction } from 'mobx';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { DeliveryNoteForm, EFormType } from './DeliveryNoteForm';
import GetApp from '@mui/icons-material/GetApp';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { KvintaAlarmStatus } from '../../../apis/kvinta-masterdata';
import styles from './Deliveries.module.scss';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { TextField } from '../../../components/FormFields/TextField';

interface DeliveryNoteSummaryViewProps {
  intl: any;
  deliveryNotesStore?: DeliveryNotesStore;
  sidebarStore?: KSidebarStore;
  id: string;
  historyStore: HistoryStore;
}

const DeliveryNoteSummaryView: React.FunctionComponent<{ formData: TDeliveryNote }> = ({ formData }) => {
  return (
    <div className={styles.updateFormContainer} id={'DeliveryNoteSummaryID'}>
      <TextField value={formData.docLocation} label="delivery-note-form.field.document.location" />
      <TextField value={formData.docNumber} label="delivery-note-form.field.document.number" />
      <TextField value={formData.docType} label="delivery-note-form.field.document.type" />
      <div className={styles.divider} />
      <TextField value={formData.refLocation} label="delivery-note-form.field.referenceDocument.location" />
      <TextField value={formData.refNumber} label="delivery-note-form.field.referenceDocument.number" />
      <TextField value={formData.refType} label="delivery-note-form.field.referenceDocument.type" />
      <div className={styles.divider} />

      <TextField value={formData.shipFrom} label="delivery-note-form.field.shipFrom" />
      <TextField value={formData.shipTo} label="delivery-note-form.field.shipTo" />
      <TextField value={formData.soldFrom} label="delivery-note-form.field.soldFrom" />
      <TextField value={formData.soldTo} label="delivery-note-form.field.soldTo" />
      <TextField value={formData.alarmStatus} label="delivery-note-form.field.alarmStatus" />
    </div>
  );
};

class DeliveryNoteSummaryViewImpl extends Component<DeliveryNoteSummaryViewProps> {
  disposer: any;

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.deliveryNotesStore.fetchDeliveryNote(this.props.id);
    this.disposer = reaction(
      () => this.props.deliveryNotesStore.currentDeliveryNote,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.docNumber, () => {
            this.props.historyStore.navigateDeliveryNotesPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
    this.props.deliveryNotesStore.closeDeliveryNoteForm();
  }

  render() {
    if (!this.props.deliveryNotesStore.currentDeliveryNote) {
      return null;
    }

    const intl = this.props.intl;

    const enableEdit = Boolean(
      this.props.deliveryNotesStore.currentDeliveryNote.docLocation &&
        this.props.deliveryNotesStore.currentDeliveryNote.docNumber &&
        this.props.deliveryNotesStore.currentDeliveryNote.docType,
    );
    return (
      <div className={styles.summaryContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateDeliveryNotesPath();
            }
            if (newValue === '2') {
              this.props.historyStore.navigateDeliveryNoteDeliveryItemsPath(this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabsSummary}
        />
        <div className={styles.summaryMain}>
          <div className={styles.summaryButtonContainer}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GetApp color="primary" />}
              disabled={this.props.deliveryNotesStore.isLoading || this.props.deliveryNotesStore.deliveryNoteFormOpen}
              onClick={this.props.deliveryNotesStore.exportOne}
            >
              {intl.formatMessage({ id: 'button.export' })}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<MailOutlineIcon color="primary" />}
              disabled={
                this.props.deliveryNotesStore.isLoading ||
                this.props.deliveryNotesStore.deliveryNoteFormOpen ||
                this.props.deliveryNotesStore.currentDeliveryNote.alarmStatus === KvintaAlarmStatus.Finalized
              }
              onClick={this.props.deliveryNotesStore.checkConsistency}
            >
              {intl.formatMessage({ id: 'button.checkConsistency' })}
            </Button>

            {this.props.deliveryNotesStore.deliveryNoteFormOpen && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                onClick={this.props.deliveryNotesStore.closeDeliveryNoteForm}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            )}
            {enableEdit &&
              (this.props.deliveryNotesStore.deliveryNoteFormOpen ? (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.deliveryNotesStore.submitUpdateDeliveryNote}
                  icon={<DoneIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.confirm' })}
                  buttonState={formDataToButtonState(
                    this.props.deliveryNotesStore.deliveryNoteFormData[DELIVERY_NOTE_FORM_ROOT_ID],
                  )}
                />
              ) : (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.deliveryNotesStore.openUpdateDeliveryNoteForm}
                  icon={<EditIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.edit' })}
                  buttonState={EButtonState.ACTIVE}
                />
              ))}
          </div>
          {this.props.deliveryNotesStore.deliveryNoteFormOpen ? (
            <DeliveryNoteForm
              formData={this.props.deliveryNotesStore.deliveryNoteFormData}
              onChange={this.props.deliveryNotesStore.onChangeDeliveryNoteFormField}
              onBlur={this.props.deliveryNotesStore.onBlurDeliveryNoteFormField}
              formType={EFormType.UPDATE}
            />
          ) : (
            <DeliveryNoteSummaryView formData={this.props.deliveryNotesStore.currentDeliveryNote} />
          )}
        </div>
      </div>
    );
  }
}

export const DeliveryNoteSummary = injectIntl(
  inject(HISTORY_STORE_ID, DELIVERY_NOTE_STORE_ID, SIDEBAR_STORE_ID)(observer(DeliveryNoteSummaryViewImpl)),
);
