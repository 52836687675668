/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaReturnEpcFormat {
    Bracketed = 'Bracketed',
    Unbracketed = 'Unbracketed',
    Urn = 'URN'
}

export function KvintaReturnEpcFormatFromJSON(json: any): KvintaReturnEpcFormat {
    return KvintaReturnEpcFormatFromJSONTyped(json, false);
}

export function KvintaReturnEpcFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaReturnEpcFormat {
    return json as KvintaReturnEpcFormat;
}

export function KvintaReturnEpcFormatToJSON(value?: KvintaReturnEpcFormat | null): any {
    return value as any;
}

