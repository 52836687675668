/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaTaskQueueConfiguration
 */
export interface KvintaTaskQueueConfiguration {
    /**
     * 
     * @type {string}
     * @memberof KvintaTaskQueueConfiguration
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTaskQueueConfiguration
     */
    taskQueueActivitiesPerSecond?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTaskQueueConfiguration
     */
    maxActivitiesPerSecond?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTaskQueueConfiguration
     */
    maxConcurrentActivityExecutionSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTaskQueueConfiguration
     */
    workflowPollThreadCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTaskQueueConfiguration
     */
    activityPollThreadCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTaskQueueConfiguration
     */
    disabled: boolean;
}

export function KvintaTaskQueueConfigurationFromJSON(json: any): KvintaTaskQueueConfiguration {
    return KvintaTaskQueueConfigurationFromJSONTyped(json, false);
}

export function KvintaTaskQueueConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTaskQueueConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'taskQueueActivitiesPerSecond': !exists(json, 'taskQueueActivitiesPerSecond') ? undefined : json['taskQueueActivitiesPerSecond'],
        'maxActivitiesPerSecond': !exists(json, 'maxActivitiesPerSecond') ? undefined : json['maxActivitiesPerSecond'],
        'maxConcurrentActivityExecutionSize': !exists(json, 'maxConcurrentActivityExecutionSize') ? undefined : json['maxConcurrentActivityExecutionSize'],
        'workflowPollThreadCount': !exists(json, 'workflowPollThreadCount') ? undefined : json['workflowPollThreadCount'],
        'activityPollThreadCount': !exists(json, 'activityPollThreadCount') ? undefined : json['activityPollThreadCount'],
        'disabled': json['disabled'],
    };
}

export function KvintaTaskQueueConfigurationToJSON(value?: KvintaTaskQueueConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'taskQueueActivitiesPerSecond': value.taskQueueActivitiesPerSecond,
        'maxActivitiesPerSecond': value.maxActivitiesPerSecond,
        'maxConcurrentActivityExecutionSize': value.maxConcurrentActivityExecutionSize,
        'workflowPollThreadCount': value.workflowPollThreadCount,
        'activityPollThreadCount': value.activityPollThreadCount,
        'disabled': value.disabled,
    };
}


