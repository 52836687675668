/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalId,
    KvintaExternalIdFromJSON,
    KvintaExternalIdFromJSONTyped,
    KvintaExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTradeItem
 */
export interface KvintaTradeItem {
    /**
     * 
     * @type {KvintaExternalId}
     * @memberof KvintaTradeItem
     */
    product: KvintaExternalId;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    uom: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItem
     */
    baseUomNominator: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaTradeItem
     */
    baseUomDenominator: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItem
     */
    gtin: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KvintaTradeItem
     */
    attributes?: { [key: string]: object; } | null;
}

export function KvintaTradeItemFromJSON(json: any): KvintaTradeItem {
    return KvintaTradeItemFromJSONTyped(json, false);
}

export function KvintaTradeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTradeItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': KvintaExternalIdFromJSON(json['product']),
        'uom': json['uom'],
        'baseUomNominator': json['baseUomNominator'],
        'baseUomDenominator': json['baseUomDenominator'],
        'gtin': json['gtin'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function KvintaTradeItemToJSON(value?: KvintaTradeItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': KvintaExternalIdToJSON(value.product),
        'uom': value.uom,
        'baseUomNominator': value.baseUomNominator,
        'baseUomDenominator': value.baseUomDenominator,
        'gtin': value.gtin,
        'attributes': value.attributes,
    };
}


