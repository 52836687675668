/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageRecordFilter,
    KvintaMessageRecordFilterFromJSON,
    KvintaMessageRecordFilterFromJSONTyped,
    KvintaMessageRecordFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryRequestMessageRecordFilter
 */
export interface KvintaQueryRequestMessageRecordFilter {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaQueryRequestMessageRecordFilter
     */
    paging: KvintaPaging;
    /**
     * 
     * @type {KvintaMessageRecordFilter}
     * @memberof KvintaQueryRequestMessageRecordFilter
     */
    filter?: KvintaMessageRecordFilter | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaQueryRequestMessageRecordFilter
     */
    fetchItems?: boolean | null;
}

export function KvintaQueryRequestMessageRecordFilterFromJSON(json: any): KvintaQueryRequestMessageRecordFilter {
    return KvintaQueryRequestMessageRecordFilterFromJSONTyped(json, false);
}

export function KvintaQueryRequestMessageRecordFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryRequestMessageRecordFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': KvintaPagingFromJSON(json['paging']),
        'filter': !exists(json, 'filter') ? undefined : KvintaMessageRecordFilterFromJSON(json['filter']),
        'fetchItems': !exists(json, 'fetchItems') ? undefined : json['fetchItems'],
    };
}

export function KvintaQueryRequestMessageRecordFilterToJSON(value?: KvintaQueryRequestMessageRecordFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
        'filter': KvintaMessageRecordFilterToJSON(value.filter),
        'fetchItems': value.fetchItems,
    };
}


