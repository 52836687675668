/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaKvintaEventExtension
 */
export interface KvintaKvintaEventExtension {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KvintaKvintaEventExtension
     */
    rawCodes?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof KvintaKvintaEventExtension
     */
    aivalues?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaKvintaEventExtension
     */
    autoEvent?: boolean | null;
}

export function KvintaKvintaEventExtensionFromJSON(json: any): KvintaKvintaEventExtension {
    return KvintaKvintaEventExtensionFromJSONTyped(json, false);
}

export function KvintaKvintaEventExtensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaKvintaEventExtension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rawCodes': !exists(json, 'rawCodes') ? undefined : json['rawCodes'],
        'aivalues': !exists(json, 'aivalues') ? undefined : json['aivalues'],
        'autoEvent': !exists(json, 'autoEvent') ? undefined : json['autoEvent'],
    };
}

export function KvintaKvintaEventExtensionToJSON(value?: KvintaKvintaEventExtension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rawCodes': value.rawCodes,
        'aivalues': value.aivalues,
        'autoEvent': value.autoEvent,
    };
}


