/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSftpBusinessPartnerConfig
 */
export interface KvintaSftpBusinessPartnerConfig {
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpBusinessPartnerConfig
     */
    gln13?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpBusinessPartnerConfig
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpBusinessPartnerConfig
     */
    sapQueueMessageSender?: string | null;
}

export function KvintaSftpBusinessPartnerConfigFromJSON(json: any): KvintaSftpBusinessPartnerConfig {
    return KvintaSftpBusinessPartnerConfigFromJSONTyped(json, false);
}

export function KvintaSftpBusinessPartnerConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSftpBusinessPartnerConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'sapQueueMessageSender': !exists(json, 'sapQueueMessageSender') ? undefined : json['sapQueueMessageSender'],
    };
}

export function KvintaSftpBusinessPartnerConfigToJSON(value?: KvintaSftpBusinessPartnerConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gln13': value.gln13,
        'path': value.path,
        'sapQueueMessageSender': value.sapQueueMessageSender,
    };
}


