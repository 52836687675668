/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGetEpcDirectChildren,
    KvintaGetEpcDirectChildrenFromJSON,
    KvintaGetEpcDirectChildrenFromJSONTyped,
    KvintaGetEpcDirectChildrenToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcDirectChildrenResponse
 */
export interface KvintaGetEpcDirectChildrenResponse {
    /**
     * 
     * @type {Array<KvintaGetEpcDirectChildren>}
     * @memberof KvintaGetEpcDirectChildrenResponse
     */
    list: Array<KvintaGetEpcDirectChildren>;
}

export function KvintaGetEpcDirectChildrenResponseFromJSON(json: any): KvintaGetEpcDirectChildrenResponse {
    return KvintaGetEpcDirectChildrenResponseFromJSONTyped(json, false);
}

export function KvintaGetEpcDirectChildrenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcDirectChildrenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(KvintaGetEpcDirectChildrenFromJSON)),
    };
}

export function KvintaGetEpcDirectChildrenResponseToJSON(value?: KvintaGetEpcDirectChildrenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(KvintaGetEpcDirectChildrenToJSON)),
    };
}


