/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeliveryItem,
    KvintaDeliveryItemFromJSON,
    KvintaDeliveryItemFromJSONTyped,
    KvintaDeliveryItemToJSON,
    KvintaDeliveryItemKey,
    KvintaDeliveryItemKeyFromJSON,
    KvintaDeliveryItemKeyFromJSONTyped,
    KvintaDeliveryItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateRequestDeliveryItemDeliveryItemKey
 */
export interface KvintaMutateRequestDeliveryItemDeliveryItemKey {
    /**
     * 
     * @type {Array<KvintaDeliveryItem>}
     * @memberof KvintaMutateRequestDeliveryItemDeliveryItemKey
     */
    upsert?: Array<KvintaDeliveryItem> | null;
    /**
     * 
     * @type {Array<KvintaDeliveryItemKey>}
     * @memberof KvintaMutateRequestDeliveryItemDeliveryItemKey
     */
    _delete?: Array<KvintaDeliveryItemKey> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestDeliveryItemDeliveryItemKey
     */
    enableBatch?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestDeliveryItemDeliveryItemKey
     */
    createOnly?: boolean | null;
}

export function KvintaMutateRequestDeliveryItemDeliveryItemKeyFromJSON(json: any): KvintaMutateRequestDeliveryItemDeliveryItemKey {
    return KvintaMutateRequestDeliveryItemDeliveryItemKeyFromJSONTyped(json, false);
}

export function KvintaMutateRequestDeliveryItemDeliveryItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateRequestDeliveryItemDeliveryItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upsert': !exists(json, 'upsert') ? undefined : (json['upsert'] === null ? null : (json['upsert'] as Array<any>).map(KvintaDeliveryItemFromJSON)),
        '_delete': !exists(json, 'delete') ? undefined : (json['delete'] === null ? null : (json['delete'] as Array<any>).map(KvintaDeliveryItemKeyFromJSON)),
        'enableBatch': !exists(json, 'enableBatch') ? undefined : json['enableBatch'],
        'createOnly': !exists(json, 'createOnly') ? undefined : json['createOnly'],
    };
}

export function KvintaMutateRequestDeliveryItemDeliveryItemKeyToJSON(value?: KvintaMutateRequestDeliveryItemDeliveryItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upsert': value.upsert === undefined ? undefined : (value.upsert === null ? null : (value.upsert as Array<any>).map(KvintaDeliveryItemToJSON)),
        'delete': value._delete === undefined ? undefined : (value._delete === null ? null : (value._delete as Array<any>).map(KvintaDeliveryItemKeyToJSON)),
        'enableBatch': value.enableBatch,
        'createOnly': value.createOnly,
    };
}


