import { ISelectableRow } from './Interfaces';
import { action, computed, makeObservable, observable } from 'mobx';

export class SelectableStore<T extends ISelectableRow> {
  listData: T[];

  constructor() {
    makeObservable(this, {
      toggleRow: action.bound,
      toggleAll: action.bound,
      toggleIndex: action.bound,
      listData: observable,
      checked: computed,
      listChecked: computed,
    });
  }

  toggleIndex(idx: number) {
    this.listData[idx].isSelected = !this.listData[idx].isSelected;
  }

  toggleRow(id: string) {
    for (let idx = 0; idx < this.listData.length; idx++) {
      if (this.listData[idx].id === id) {
        this.toggleIndex(idx);
        return;
      }
    }
  }

  toggleAll() {
    const checked = this.checked;
    this.listData.forEach((val, idx) => {
      this.listData[idx].isSelected = !(checked > 0);
    });
  }

  // Returns:
  //  0 - no items selected
  //  1 - some items selected
  //  2 - all items selected
  get checked() {
    if (this.listData) {
      let allChecked = true;
      let hasChecked = false;

      for (const row of this.listData) {
        allChecked = allChecked && row.isSelected;
        hasChecked = hasChecked || row.isSelected;
      }
      if (allChecked) {
        return 2;
      } else if (hasChecked) {
        return 1;
      }
    }
    return 0;
  }

  get listChecked() {
    if (this.listData) {
      const checkedRows = new Array<T>();
      for (const row of this.listData) {
        if (row.isSelected) {
          checkedRows.push(row);
        }
      }
      return checkedRows;
    }
    return [] as T[];
  }
}
