/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeliveryItemKey,
    KvintaDeliveryItemKeyFromJSON,
    KvintaDeliveryItemKeyFromJSONTyped,
    KvintaDeliveryItemKeyToJSON,
    KvintaIdAndErrorDeliveryItemKey,
    KvintaIdAndErrorDeliveryItemKeyFromJSON,
    KvintaIdAndErrorDeliveryItemKeyFromJSONTyped,
    KvintaIdAndErrorDeliveryItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateResultDeliveryItemKey
 */
export interface KvintaMutateResultDeliveryItemKey {
    /**
     * 
     * @type {Array<KvintaDeliveryItemKey>}
     * @memberof KvintaMutateResultDeliveryItemKey
     */
    upserted?: Array<KvintaDeliveryItemKey> | null;
    /**
     * 
     * @type {Array<KvintaDeliveryItemKey>}
     * @memberof KvintaMutateResultDeliveryItemKey
     */
    deleted?: Array<KvintaDeliveryItemKey> | null;
    /**
     * 
     * @type {Array<KvintaIdAndErrorDeliveryItemKey>}
     * @memberof KvintaMutateResultDeliveryItemKey
     */
    errors?: Array<KvintaIdAndErrorDeliveryItemKey> | null;
}

export function KvintaMutateResultDeliveryItemKeyFromJSON(json: any): KvintaMutateResultDeliveryItemKey {
    return KvintaMutateResultDeliveryItemKeyFromJSONTyped(json, false);
}

export function KvintaMutateResultDeliveryItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateResultDeliveryItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upserted': !exists(json, 'upserted') ? undefined : (json['upserted'] === null ? null : (json['upserted'] as Array<any>).map(KvintaDeliveryItemKeyFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : (json['deleted'] as Array<any>).map(KvintaDeliveryItemKeyFromJSON)),
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(KvintaIdAndErrorDeliveryItemKeyFromJSON)),
    };
}

export function KvintaMutateResultDeliveryItemKeyToJSON(value?: KvintaMutateResultDeliveryItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upserted': value.upserted === undefined ? undefined : (value.upserted === null ? null : (value.upserted as Array<any>).map(KvintaDeliveryItemKeyToJSON)),
        'deleted': value.deleted === undefined ? undefined : (value.deleted === null ? null : (value.deleted as Array<any>).map(KvintaDeliveryItemKeyToJSON)),
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(KvintaIdAndErrorDeliveryItemKeyToJSON)),
    };
}


