import React from 'react';
import { FormControl, InputAdornment, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';
import { TSelectFilter } from './FilterBar';
import { RemoveFilterButton } from './TextFilter';

export type TSelectFilterProps = {
  filter: TSelectFilter;
  showRemoveButton: boolean;
  intl: any;
  onChangeValue: (field: string, value: string) => void;
  onClear: (field: string) => void;
  onFilter: (field: string) => void;
  hideFilter: (field: string) => void;
};

const SelectFilterBase: React.FunctionComponent<TSelectFilterProps> = ({
  filter,
  showRemoveButton,
  intl,
  onChangeValue,
  hideFilter,
  onClear,
  onFilter,
}) => {
  const filterFieldId = 'filter-' + filter.id;
  const translatedLabel = intl.formatMessage({ id: 'filter.by' }) + ' ' + intl.formatMessage({ id: filter.label });

  return (
    <div className="ZstFilter-root" style={{ marginLeft: '5px' }}>
      <FormControl variant="outlined" key={filter.id} style={{ height: '40px' }}>
        <Select
          id={filterFieldId}
          value={filter.value}
          style={{ height: '40px' }}
          onChange={(e) => {
            if (e.target.value === 'default') {
              onClear(filter.id);
            } else {
              onChangeValue(filter.id, e.target.value as string);
              onFilter(filter.id);
            }
          }}
          endAdornment={
            showRemoveButton && (
              <InputAdornment position="end">
                <RemoveFilterButton id={filterFieldId} onClick={() => hideFilter(filter.id)} />
              </InputAdornment>
            )
          }
        >
          {[{ key: 'default', label: translatedLabel }].concat(filter.options).map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const SelectFilter = observer(SelectFilterBase);
