/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDeliveryItemFilter
 */
export interface KvintaDeliveryItemFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItemFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItemFilter
     */
    deliveryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItemFilter
     */
    itemNumber?: string | null;
}

export function KvintaDeliveryItemFilterFromJSON(json: any): KvintaDeliveryItemFilter {
    return KvintaDeliveryItemFilterFromJSONTyped(json, false);
}

export function KvintaDeliveryItemFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryItemFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deliveryId': !exists(json, 'deliveryId') ? undefined : json['deliveryId'],
        'itemNumber': !exists(json, 'itemNumber') ? undefined : json['itemNumber'],
    };
}

export function KvintaDeliveryItemFilterToJSON(value?: KvintaDeliveryItemFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'deliveryId': value.deliveryId,
        'itemNumber': value.itemNumber,
    };
}


