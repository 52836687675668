/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDocumentIdendification,
    KvintaDocumentIdendificationFromJSON,
    KvintaDocumentIdendificationFromJSONTyped,
    KvintaDocumentIdendificationToJSON,
    KvintaIdentifier,
    KvintaIdentifierFromJSON,
    KvintaIdentifierFromJSONTyped,
    KvintaIdentifierToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaStandardBusinessDocumentHeader
 */
export interface KvintaStandardBusinessDocumentHeader {
    /**
     * 
     * @type {number}
     * @memberof KvintaStandardBusinessDocumentHeader
     */
    headerVersion?: number | null;
    /**
     * 
     * @type {KvintaIdentifier}
     * @memberof KvintaStandardBusinessDocumentHeader
     */
    sender?: KvintaIdentifier;
    /**
     * 
     * @type {KvintaIdentifier}
     * @memberof KvintaStandardBusinessDocumentHeader
     */
    receiver?: KvintaIdentifier;
    /**
     * 
     * @type {KvintaDocumentIdendification}
     * @memberof KvintaStandardBusinessDocumentHeader
     */
    documentIdentification?: KvintaDocumentIdendification;
}

export function KvintaStandardBusinessDocumentHeaderFromJSON(json: any): KvintaStandardBusinessDocumentHeader {
    return KvintaStandardBusinessDocumentHeaderFromJSONTyped(json, false);
}

export function KvintaStandardBusinessDocumentHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaStandardBusinessDocumentHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headerVersion': !exists(json, 'headerVersion') ? undefined : json['headerVersion'],
        'sender': !exists(json, 'sender') ? undefined : KvintaIdentifierFromJSON(json['sender']),
        'receiver': !exists(json, 'receiver') ? undefined : KvintaIdentifierFromJSON(json['receiver']),
        'documentIdentification': !exists(json, 'documentIdentification') ? undefined : KvintaDocumentIdendificationFromJSON(json['documentIdentification']),
    };
}

export function KvintaStandardBusinessDocumentHeaderToJSON(value?: KvintaStandardBusinessDocumentHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headerVersion': value.headerVersion,
        'sender': KvintaIdentifierToJSON(value.sender),
        'receiver': KvintaIdentifierToJSON(value.receiver),
        'documentIdentification': KvintaDocumentIdendificationToJSON(value.documentIdentification),
    };
}


