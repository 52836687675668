/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisBizStep,
    KvintaEpcisBizStepFromJSON,
    KvintaEpcisBizStepFromJSONTyped,
    KvintaEpcisBizStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisOutboundFilter
 */
export interface KvintaEpcisOutboundFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisOutboundFilter
     */
    gln13?: string | null;
    /**
     * 
     * @type {Array<KvintaEpcisBizStep>}
     * @memberof KvintaEpcisOutboundFilter
     */
    triggeringBizSteps?: Array<KvintaEpcisBizStep> | null;
    /**
     * 
     * @type {Array<KvintaEpcisBizStep>}
     * @memberof KvintaEpcisOutboundFilter
     */
    blockedBizSteps?: Array<KvintaEpcisBizStep> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisOutboundFilter
     */
    sapQueueMessageSender?: string | null;
}

export function KvintaEpcisOutboundFilterFromJSON(json: any): KvintaEpcisOutboundFilter {
    return KvintaEpcisOutboundFilterFromJSONTyped(json, false);
}

export function KvintaEpcisOutboundFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisOutboundFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gln13': !exists(json, 'gln13') ? undefined : json['gln13'],
        'triggeringBizSteps': !exists(json, 'triggeringBizSteps') ? undefined : (json['triggeringBizSteps'] === null ? null : (json['triggeringBizSteps'] as Array<any>).map(KvintaEpcisBizStepFromJSON)),
        'blockedBizSteps': !exists(json, 'blockedBizSteps') ? undefined : (json['blockedBizSteps'] === null ? null : (json['blockedBizSteps'] as Array<any>).map(KvintaEpcisBizStepFromJSON)),
        'sapQueueMessageSender': !exists(json, 'sapQueueMessageSender') ? undefined : json['sapQueueMessageSender'],
    };
}

export function KvintaEpcisOutboundFilterToJSON(value?: KvintaEpcisOutboundFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gln13': value.gln13,
        'triggeringBizSteps': value.triggeringBizSteps === undefined ? undefined : (value.triggeringBizSteps === null ? null : (value.triggeringBizSteps as Array<any>).map(KvintaEpcisBizStepToJSON)),
        'blockedBizSteps': value.blockedBizSteps === undefined ? undefined : (value.blockedBizSteps === null ? null : (value.blockedBizSteps as Array<any>).map(KvintaEpcisBizStepToJSON)),
        'sapQueueMessageSender': value.sapQueueMessageSender,
    };
}


