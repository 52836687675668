import React from 'react';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { SelectInput } from '../../../components/FormFields/SelectInput';
import { observer } from 'mobx-react';
import styles from './Device.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { TDeviceAssignmentForm } from './DeviceStore';

export interface DeviceComponentProps {
  formData: TDeviceAssignmentForm;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string, value: string) => void;
}

const DeviceAssignmentFormBase: React.FunctionComponent<DeviceComponentProps> = ({ formData, onChange, onBlur }) => (
  <div className={styles.formContainer} id={'DeviceAssignmentFormID'}>
    <TextField value={formData['deviceAssignmentForm.deviceId'].value} label="assignment.deviceId" />
    <TextInput
      {...formData['deviceAssignmentForm.email']}
      label="assignment.email"
      onBlur={onBlur}
      onChange={onChange}
    />
    <SelectInput
      {...formData['deviceAssignmentForm.appName']}
      onBlur={onBlur}
      onChange={onChange}
      label="assignment.appName"
    />
    <SelectInput
      {...formData['deviceAssignmentForm.appVersion']}
      onBlur={onBlur}
      onChange={onChange}
      label="assignment.appVersion"
    />
  </div>
);

export const DeviceAssignmentForm = observer(DeviceAssignmentFormBase);
