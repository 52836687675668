import { action, makeObservable, observable, runInAction } from 'mobx';
import { TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { AuthService, EKeycloakEventTypes } from 'kvinta/service/auth';
import { NotificationManager } from './NotificationManager';

export enum EUserRole {
  USER = 'user',
  ADMIN = 'admin',
}

type TAccessKey =
  | 'Alarms.delete'
  | 'Alarms.create'
  | 'Notification.delete'
  | 'Notification.create'
  | 'Notification.edit'
  | 'Device.delete'
  | 'Device.create'
  | 'Device.edit'
  | 'Device.assignment.edit'
  | 'Device.assignment.create'
  | 'EpcisMessages.IgnoreFailedMessage';

type TAccess = {
  [key in EUserRole]: TAccessKey[] | null;
};

const Access: TAccess = {
  [EUserRole.ADMIN]: [
    'Alarms.delete',
    'Alarms.create',
    'Notification.delete',
    'Notification.create',
    'Notification.edit',
    'Device.delete',
    'Device.create',
    'Device.edit',
    'Device.assignment.edit',
    'Device.assignment.create',
    'EpcisMessages.IgnoreFailedMessage',
  ],
  [EUserRole.USER]: [],
};

export interface TUserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  lang?: string;
  logoutUrl?: string;
  role: EUserRole;
}

export class UserStore {
  private _authService: AuthService;
  private _config: TAppOptionsConfig;
  private _notificationManager: NotificationManager;

  userInfo: TUserInfo;

  constructor(config: TAppOptionsConfig, authS: AuthService, notificationManager: NotificationManager) {
    makeObservable(this, {
      userInfo: observable,
      updateInfo: action.bound,
      canAccess: action.bound,
    });
    this._config = config;
    this._authService = authS;
    this._notificationManager = notificationManager;
    this.updateInfo();
    authS.on(EKeycloakEventTypes.TOKEN_EXPIRED, () => {
      this._authService.updateToken();
    });
    authS.on(EKeycloakEventTypes.TOKEN_UPDATED, () => {
      this.updateInfo();
    });
  }

  updateInfo = () => {
    this._authService
      .loadUserProfile()
      .then((user) => {
        const usr = user as any;
        const lang =
          (this._config.availableLanguages || []).find(
            (lang) => lang === (Array.isArray(usr.attributes?.lang) && usr.attributes.lang[0]),
          ) || 'en';
        const role = usr.attributes?.role?.includes('admin') ? EUserRole.ADMIN : EUserRole.USER;

        this.userInfo = {
          role,
          lang: lang,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email,
        };
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while fetching data${err.message}`);
      });
  };

  logout = () => {
    this._authService.logout();
  };

  canAccess(role: TAccessKey) {
    return Access[this.userInfo.role] && Access[this.userInfo.role].includes(role);
  }
}

export const USER_STORE_ID = 'userStore';
