/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaAutoCommissionMode {
    None = 'none',
    ParentOnly = 'parentOnly',
    AllCodes = 'allCodes'
}

export function KvintaAutoCommissionModeFromJSON(json: any): KvintaAutoCommissionMode {
    return KvintaAutoCommissionModeFromJSONTyped(json, false);
}

export function KvintaAutoCommissionModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAutoCommissionMode {
    return json as KvintaAutoCommissionMode;
}

export function KvintaAutoCommissionModeToJSON(value?: KvintaAutoCommissionMode | null): any {
    return value as any;
}

