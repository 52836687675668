/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSftpDeliveryInboundConfig
 */
export interface KvintaSftpDeliveryInboundConfig {
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpDeliveryInboundConfig
     */
    inboxDir: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpDeliveryInboundConfig
     */
    archiveDir: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpDeliveryInboundConfig
     */
    errorDir: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaSftpDeliveryInboundConfig
     */
    processingBatchSize: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpDeliveryInboundConfig
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSftpDeliveryInboundConfig
     */
    disabled: boolean;
}

export function KvintaSftpDeliveryInboundConfigFromJSON(json: any): KvintaSftpDeliveryInboundConfig {
    return KvintaSftpDeliveryInboundConfigFromJSONTyped(json, false);
}

export function KvintaSftpDeliveryInboundConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSftpDeliveryInboundConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inboxDir': json['inboxDir'],
        'archiveDir': json['archiveDir'],
        'errorDir': json['errorDir'],
        'processingBatchSize': json['processingBatchSize'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'disabled': json['disabled'],
    };
}

export function KvintaSftpDeliveryInboundConfigToJSON(value?: KvintaSftpDeliveryInboundConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inboxDir': value.inboxDir,
        'archiveDir': value.archiveDir,
        'errorDir': value.errorDir,
        'processingBatchSize': value.processingBatchSize,
        'name': value.name,
        'disabled': value.disabled,
    };
}


