import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { EButtonState, EPCIS_MESSAGE_STORE_ID, EpcisMessageStore } from './EpcisMessageStore';
import { FilterBar } from '../../../components/FilterBar/FilterBar';
import styles from './EpcisMessage.module.scss';
import { ListButton } from '../../../components/ListButton';
import { defineSorting } from '../../../common/utils';
import { Paper } from '@mui/material';

interface EpcisMessageListProps {
  epcisMessageStore?: EpcisMessageStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  historyStore: HistoryStore;
}

class EpcisMessageListBase extends Component<EpcisMessageListProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: EpcisMessageListProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcisMessageStore.fetchMessageList();
  }

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.epcisMessageStore;
    const { height } = this.props.pageContentStore;
    if (!height || height === NaN || height === 0) {
      return null;
    }

    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    return (
      <div id="EpcisMessageListID" className={styles.muiTableContainer}>
        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          columns={[
            {
              title: intl.formatMessage({ id: 'epcis-mesage.created' }),
              field: 'created',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'timestamp'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.direction' }),
              field: 'direction',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'direction'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.type' }),
              field: 'type',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'type'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.status' }),
              field: 'status',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'lastStatus'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.updated' }),
              field: 'updated',
              defaultSort: defineSorting(
                this.props.epcisMessageStore.currentSort?.expressions[0],
                'lastStatusTimestamp',
              ),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.error' }),
              field: 'error',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'lastStatusError'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.sender' }),
              field: 'sender',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'sender'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-mesage.receiver' }),
              field: 'receiver',
              defaultSort: defineSorting(this.props.epcisMessageStore.currentSort?.expressions[0], 'receiver'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },

            {
              sorting: false,
              render: ({ downloadButtonState, messageKey }) => {
                return (
                  <ListButton
                    buttonState={downloadButtonState}
                    label={intl.formatMessage({ id: 'epcis-mesage.download-button' })}
                    id={`${messageKey.type}-${messageKey.direction}-${messageKey.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (downloadButtonState === EButtonState.ACTIVE) {
                        this.props.epcisMessageStore.downloadMessageFromMessageList(messageKey);
                      }
                    }}
                  />
                );
              },
            },
          ]}
          data={listData}
          page={page}
          totalCount={totalCount}
          onChangePage={(page: number) => {
            this.props.epcisMessageStore.fetchPage(page);
          }}
          onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
            this.props.epcisMessageStore.changeOrder(orderBy, orderDirection);
          }}
          isLoading={isLoading}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            sorting: true,
            draggable: false,
            search: false,
            pageSize: pageSize,
            pageSizeOptions: [pageSize],
            minBodyHeight: bodyHeight,
            maxBodyHeight: bodyHeight,
            showTitle: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
          }}
          onRowClick={(event, rowData) => {
            this.props.historyStore.navigateEpcisMessageSummaryPath(rowData.id);
          }}
          components={{
            Toolbar: (props) => (
              <div className={styles.toolbarContainer}>
                <FilterBar filter={filter} />
              </div>
            ),
          }}
        />
      </div>
    );
  }
}

export const EpcisMessageList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    EPCIS_MESSAGE_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(EpcisMessageListBase)),
);
