import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  DefaultApi as MDDocumentApi,
  KvintaBusinessPartner,
  KvintaProduct,
  KvintaSortDirection,
  KvintaSortExpression,
  KvintaTradeItem,
} from 'kvinta/apis/kvinta-masterdata';
import {
  getExternalId,
  getInternalId,
  getInternalTradeItemKey,
  getInternalTradeItemKeyFromProductId,
} from 'kvinta/apis/utils';
import { TProduct } from '../products/ProductsStore';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, textField, textInput } from '../../../common/formUtils/formDataGenerators';
import { isGreaterThan, isInteger, isNotEmpty, maxLength } from '../../../common/formUtils/validators';
import { validateForm } from '../../../common/formUtils/core';
import { TTextInputData } from '../../../common/formUtils/types';

export type TTradeItem = {
  productId: string;
  productName: string;
  uom: string;
  baseUomNominator: string;
  baseUomDenominator: string;
  gtin: string;
};

export type TTradeItemFormData = {
  'tradeItemForm.productId': TTextInputData;
  'tradeItemForm.productName': TTextInputData;
  'tradeItemForm.uom': TTextInputData;
  'tradeItemForm.baseUomNominator': TTextInputData;
  'tradeItemForm.baseUomDenominator': TTextInputData;
  'tradeItemForm.gtin': TTextInputData;
};

interface ITradeItemRow extends KvintaTradeItem, ISelectableRow {}

export class TradeItemsStore extends SelectableStore<ITradeItemRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  businessPartners: KvintaBusinessPartner[];
  currentSort: KvintaSortExpression;

  tradeItemFormData?: TTradeItemFormData;
  tradeItemFormOpen: boolean;

  productId?: string;
  currentProduct?: KvintaProduct;
  currentTradeItem?: TTradeItem;

  exportActive: boolean;
  exportData: ITradeItemRow[] | KvintaTradeItem[] | undefined;
  autofocusSearchInList: boolean;
  searchValue: string;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchTradeItemList: action.bound,
      fetchProductTradeItemList: action.bound,

      fetchTradeItem: action.bound,
      currentTradeItem: observable,
      openCreateTradeItemForm: action.bound,
      openUpdateTradeItemForm: action.bound,
      closeTradeItemForm: action.bound,
      updateSearch: action.bound,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      tradeItemFormData: observable,
      tradeItemFormOpen: observable,
      productId: observable,
      currentProduct: observable,
      onChangeTradeItemField: action.bound,
      onBlurTradeItemField: action.bound,
      submitTradeItem: action.bound,
      submitCreateTradeItemForm: action.bound,
      submitUpdateTradeItem: action.bound,
      searchValue: observable,

      unfocusSearchField: action.bound,
      exportActive: observable,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      direction: KvintaSortDirection.Desc,
      property: 'gtin',
      ignoreCase: false,
    };
  }

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;

    this.fetchTradeItemList();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const field = getField(orderBy);
    this.currentSort = {
      property: field,
      direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
      ignoreCase: false,
    };
    this.fetchTradeItemList();
  }

  unsetProductId() {
    this.productId = undefined;
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.page = 0;
    this.fetchTradeItemList();
  }

  resetFilter = () => {
    this.searchValue = '';
  };

  fetchTradeItemList() {
    this.isLoading = true;
    this.listData = [];
    this.totalCount = 0;

    let filters: { [key: string]: string } = {
      gtinLike: this.searchValue,
    };

    if (this.productId) {
      filters = {
        ...filters,
        productId: this.productId,
      };
    }
    this._mdApi
      .queryTradeItems({
        kvintaQueryRequestTradeItemFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: { expressions: [this.currentSort] },
          },
          filter: filters,
        },
      })
      .then((result) => {
        this.totalCount = result.total || 0;
        this.listData = (result.list || []).map((tradeItem) => {
          return { ...tradeItem, id: getInternalTradeItemKey(tradeItem), isSelected: false };
        });
      })
      .catch((err: Error) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  fetchProductTradeItemList(id: string) {
    this.productId = id;
    this.fetchTradeItemList();
  }

  openCreateTradeItemForm(productId: string) {
    const initialValues = (currentProduct: TProduct) => ({
      productName: currentProduct.name,
      productId: getInternalId(currentProduct),
      baseUomDenominator: '1',
      baseUomNominator: '1',
      gtin: '',
      uom: '',
    });

    this.openTradeItemForm(productId, initialValues);
  }

  openTradeItemForm(productId: string, initialValues: (currentProduct) => TTradeItem) {
    this._mdApi
      .queryProducts({
        kvintaQueryRequestProductFilter: {
          paging: {
            page: 0,
            size: 1,
          },
          filter: {
            id: productId,
          },
        },
      })
      .then((result) => {
        this.currentProduct = result.list[0];

        this.tradeItemFormData = validateForm<TTradeItemFormData>(
          generateTradeItemFormData(TRADE_ITEM_FORM_ROOT_ID, initialValues(this.currentProduct)),
        );
        this.tradeItemFormOpen = true;
      });
  }

  openUpdateTradeItemForm() {
    const initialValues = () => ({
      productName: this.currentTradeItem.productName,
      productId: this.currentTradeItem.productId,
      baseUomDenominator: this.currentTradeItem.baseUomDenominator,
      baseUomNominator: this.currentTradeItem.baseUomNominator,
      gtin: this.currentTradeItem.gtin,
      uom: this.currentTradeItem.uom,
    });
    this.openTradeItemForm(this.currentTradeItem.productId, initialValues);
  }

  closeTradeItemForm = () => {
    this.tradeItemFormOpen = false;
    this.tradeItemFormData = undefined;
  };

  onChangeTradeItemField(id: string, value: any) {
    const formData = toJS(this.tradeItemFormData);
    this.tradeItemFormData = handleFormChange(formData, id, value);
  }

  onBlurTradeItemField(id: string) {
    const formData = toJS(this.tradeItemFormData);
    this.tradeItemFormData = handleFormBlur(formData, id);
  }

  submitTradeItem(nextAction) {
    this.isLoading = true;

    const submitData = {
      ...transformTradeItem(this.tradeItemFormData),
      product: this.currentProduct,
    };
    this._mdApi
      .mutateTradeItem({
        kvintaMutateRequestTradeItemTradeItemKey: {
          upsert: [submitData],
        },
      })
      .then(async (result) => {
        if (result.errors && result.errors.length) {
          const errors = result.errors.map((err) => err.error).join('\n');
          this._notificationManager.sendError(`An error occurred while submitting data\n${errors}`);
        } else {
          this._notificationManager.sendSuccess(`Successfully updated Trade Item ${submitData.gtin}`);
          await nextAction();
        }
      })
      .catch((err: Error) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.closeTradeItemForm();
        this.isLoading = false;
      });
  }

  submitCreateTradeItemForm = () => {
    this.submitTradeItem(this.fetchTradeItemList);
  };

  submitUpdateTradeItem = async () => {
    this.submitTradeItem(() => this.fetchTradeItem(getInternalTradeItemKeyFromProductId(this.currentTradeItem)));
  };

  deleteTradeItem = async (productId: string, uom: string) => {
    this.isLoading = true;
    this._mdApi
      .mutateTradeItem({
        kvintaMutateRequestTradeItemTradeItemKey: {
          _delete: [
            {
              product: getExternalId(productId),
              uom: uom,
            },
          ],
        },
      })
      .then(async () => {
        await this.fetchTradeItemList();
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while trying to delete ${productId}\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  /**
   * Export functionallity
   */
  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    this.exportActive = false;
    this.exportData = undefined;
    this.exportActive = true;
    this.exportData = this.listChecked.map((tradeItem) => ({
      sourceSystem: tradeItem.product.sourceSystem,
      idInSourceSystem: tradeItem.product.idInSourceSystem,
      uom: tradeItem.uom,
      baseUomNominator: tradeItem.baseUomNominator,
      baseUomDenominator: tradeItem.baseUomDenominator,
      gtin: tradeItem.gtin,
    })) as any;
  }

  async exportAll() {
    this.exportActive = false;
    this.exportData = undefined;
    let filters = {} as { [key: string]: string } | null;

    if (this.productId) {
      filters = {
        ...filters,
        productId: this.productId,
      };
    }
    try {
      const tradeItemsResult = await this._mdApi.queryTradeItems({
        kvintaQueryRequestTradeItemFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: { expressions: [this.currentSort] },
          },
          filter: {
            ...filters,
          },
        },
      });
      this.exportActive = true;
      this.exportData = tradeItemsResult.list.map((tradeItem) => ({
        sourceSystem: tradeItem.product.sourceSystem,
        idInSourceSystem: tradeItem.product.idInSourceSystem,
        uom: tradeItem.uom,
        baseUomNominator: tradeItem.baseUomNominator,
        baseUomDenominator: tradeItem.baseUomDenominator,
        gtin: tradeItem.gtin,
      })) as any;
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }

  fetchTradeItem = async (id: string) => {
    this.isLoading = true;
    this.currentTradeItem = null;
    this.currentProduct = null;

    this._mdApi
      .queryTradeItems({
        kvintaQueryRequestTradeItemFilter: {
          paging: {
            page: 0,
            size: 1,
          },
          filter: {
            id,
          },
        },
      })
      .then(async (result) => {
        if (result.list && result.list.length) {
          const product = result.list[0].product;
          const currentProductResult = await this._mdApi.queryProducts({
            kvintaQueryRequestProductFilter: {
              paging: {
                page: 0,
                size: 1,
              },
              filter: {
                id: getInternalId(product),
              },
            },
          });
          const currentProduct = currentProductResult.list[0];
          const currentTradeItem = result.list[0];

          this.currentTradeItem = {
            productId: getInternalId(product),
            productName: currentProduct.name,
            baseUomDenominator: currentTradeItem.baseUomDenominator.toString() || '',
            baseUomNominator: currentTradeItem.baseUomNominator.toString() || '',
            gtin: currentTradeItem.gtin || '',
            uom: currentTradeItem.uom || '',
          };
        } else {
          this._notificationManager.sendError(`Could not find trade item ${id}`);
        }
      })
      .catch(() => {
        this._notificationManager.sendError(`An error occurred while fetching trade item ${id}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}

export const TRADE_ITEM_STORE_ID = 'tradeItemsStore';
export const TRADE_ITEM_FORM_ROOT_ID = 'tradeItemForm';

function getField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'gtin';
    case 2:
      return 'uom';
    default:
      return 'gtin';
  }
}

function generateTradeItemFormData(rootFormId: string, initialValues: TTradeItem) {
  return formRoot<TTradeItemFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textField({
        path: 'productName',
        value: initialValues.productName,
      }),
      textInput({
        path: 'gtin',
        value: initialValues.gtin,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'baseUomNominator',
        value: initialValues.baseUomNominator,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isInteger({ errorMessage: 'please provide a number' }),
          isGreaterThan({ errorMessage: 'this value must be greater than 0', greaterThan: 0 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'baseUomDenominator',
        value: initialValues.baseUomDenominator,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isInteger({ errorMessage: 'please provide a number' }),
          isGreaterThan({ errorMessage: 'this value must be greater than 0', greaterThan: 0 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'uom',
        value: initialValues.uom,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
    ],
  });
}

function transformTradeItem(tradeItemFormData) {
  return {
    baseUomDenominator: parseInt(tradeItemFormData['tradeItemForm.baseUomDenominator'].value),
    baseUomNominator: parseInt(tradeItemFormData['tradeItemForm.baseUomNominator'].value),
    gtin: tradeItemFormData['tradeItemForm.gtin'].value,
    uom: tradeItemFormData['tradeItemForm.uom'].value,
  };
}
