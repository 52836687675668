/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageDirection,
    KvintaMessageDirectionFromJSON,
    KvintaMessageDirectionFromJSONTyped,
    KvintaMessageDirectionToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMessageKey
 */
export interface KvintaMessageKey {
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaMessageKey
     */
    type: KvintaResourceType;
    /**
     * 
     * @type {KvintaMessageDirection}
     * @memberof KvintaMessageKey
     */
    direction: KvintaMessageDirection;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageKey
     */
    id: string;
}

export function KvintaMessageKeyFromJSON(json: any): KvintaMessageKey {
    return KvintaMessageKeyFromJSONTyped(json, false);
}

export function KvintaMessageKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMessageKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': KvintaResourceTypeFromJSON(json['type']),
        'direction': KvintaMessageDirectionFromJSON(json['direction']),
        'id': json['id'],
    };
}

export function KvintaMessageKeyToJSON(value?: KvintaMessageKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': KvintaResourceTypeToJSON(value.type),
        'direction': KvintaMessageDirectionToJSON(value.direction),
        'id': value.id,
    };
}


