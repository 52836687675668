import React, { FC } from 'react';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { TLocation, TLocationFormData } from './LocationsStore';
import { SelectInput } from '../../../components/FormFields/SelectInput';
import { observer } from 'mobx-react';
import styles from './Location.module.scss';
import { TranslatedSelectInput } from '../../../components/FormFields/TranslatedSelectInput';

type LocationComponentProps = {
  formData: TLocationFormData | TLocation;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string) => void;
  isUpdateLocation?: boolean;
};

const LocationFormBase: FC<LocationComponentProps> = ({ isUpdateLocation = false, formData, onChange, onBlur }) => {
  return (
    <div className={styles.formContainer} id={'LocationFormID'}>
      <SelectInput
        {...formData['locationForm.businessPartnerId']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.businessPartner"
      />

      <TextInput
        {...formData['locationForm.name']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.name"
      />
      <TextInput
        {...formData['locationForm.sourceSystem']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.sourceSystem"
        disabled={isUpdateLocation}
      />
      <TextInput
        {...formData['locationForm.idInSourceSystem']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.idInSourceSystem"
        disabled={isUpdateLocation}
      />
      <TextInput
        {...formData['locationForm.gln13']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.gln13"
      />
      <TextInput
        {...formData['locationForm.sgln']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.sgln"
      />
      <TextInput
        {...formData['locationForm.latitude']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.latitude"
      />
      <TextInput
        {...formData['locationForm.longitude']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.longitude"
      />
      <TranslatedSelectInput
        {...formData['locationForm.country']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-create-form.field.country"
      />
      <TextInput
        {...formData['locationForm.city']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.city"
      />
      <TextInput
        {...formData['locationForm.postalCode']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.postalCode"
      />
      <TextInput
        {...formData['locationForm.address1']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.address1"
      />
      <TextInput
        {...formData['locationForm.address2']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.address2"
      />
      <TextInput
        {...formData['locationForm.address3']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.address3"
      />
      <TextInput
        {...formData['locationForm.description']}
        onChange={onChange}
        onBlur={onBlur}
        label="location-view.field.description"
        style={{ gridColumn: '1 / span 2' }}
      />
    </div>
  );
};

export const LocationForm = observer(LocationFormBase);
