import { tabs } from './tabs';
import React, { Component } from 'react';
import { EPCIS_EVENTS_STORE_ID, EpcisEventsStore } from './EpcisEventsStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { reaction } from 'mobx';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { TextField } from '../../../components/FormFields/TextField';
import styles from './Event.module.scss';
import { ButtonGreen } from '../../../components/ButtonGreen';
import Directions from '@mui/icons-material/Directions';
import { EButtonState, EPCIS_MESSAGE_STORE_ID, EpcisMessageStore } from '../messages/EpcisMessageStore';

interface ViewEpcisEventSummaryProps {
  intl: any;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  epcisEventsStore: EpcisEventsStore;
  epcisMessageStore?: EpcisMessageStore;
  id: string;
}

class ViewEpcisEventSummaryBase extends Component<ViewEpcisEventSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.epcisEventsStore.loadEvent(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisEventsStore.currentEvent,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            this.props.historyStore.navigateEpcisEventsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  goToMessages = () => {
    const currentEvent = this.props.epcisEventsStore.currentEvent;
    this.props.epcisMessageStore.setFiltersForRedirect([
      {
        key: 'triggeringEventId',
        value: currentEvent?.id || '',
      },
    ]);
    this.props.historyStore.navigateEpcisMessageListPath();
  };

  render() {
    const intl = this.props.intl;
    const currentEvent = this.props.epcisEventsStore.currentEvent;
    if (!currentEvent) {
      return null;
    }
    const executionId = this.props.epcisEventsStore.currentExecutionId;
    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '2') {
              this.props.historyStore.navigateEpcisEventEpcListPath(this.props.id);
            } else if (newValue === '3') {
              this.props.historyStore.navigateEpcisEventJsonPath(this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />
        <div className={styles.summaryMain} id={'EpcisEventSummaryID'}>
          <div className={styles.summaryButtonContainer}>
            <ButtonGreen
              id="redirectOnMessages"
              onClick={this.goToMessages}
              icon={<Directions color="secondary" />}
              label={intl.formatMessage({ id: 'button.goToMessages' })}
              buttonState={EButtonState.ACTIVE}
            />
          </div>
          <div className={styles.formContainer} id={'EpcisEventFormID'}>
            <TextField
              value={
                currentEvent.eventTime !== undefined ? new Date(currentEvent.eventTime.epochMillis).toISOString() : ''
              }
              label="epcis-event.view.event-time"
            />
            <TextField
              value={currentEvent.recordTime !== undefined ? new Date(currentEvent.recordTime).toISOString() : ''}
              label="epcis-event.view.record-time"
            />
            <TextField value={currentEvent.bizStep} label="epcis-event.view.biz-step" />
            <TextField value={currentEvent.disposition} label="epcis-event.view.disposition" />
            <TextField value={currentEvent.readPoint} label="epcis-event.view.read-point" />
            <TextField value={currentEvent.bizLocation} label="epcis-event.view.location" />
            <TextField value={currentEvent.parentID} label="epcis-event.parent-id" />
            <TextField
              value={
                currentEvent.epcList?.length
                  ? currentEvent.epcList[0]
                  : currentEvent.childEPCs?.length
                  ? currentEvent.childEPCs[0]
                  : ''
              }
              label="epcis-event.sample-epc"
            />
            <TextField
              value={currentEvent.bizTransactionList?.length ? currentEvent.bizTransactionList[0].bizTransaction : ''}
              label="epcis-event.view.business-transaction-type"
            />
            <TextField
              value={currentEvent.bizTransactionList?.length ? currentEvent.bizTransactionList[0].bizTransaction : ''}
              label="epcis-event.view.business-transaction-id"
            />
            <TextField
              value={currentEvent.errorDeclaration?.reason}
              label="epcis-event.view.error-declaration-reason"
            />
            <TextField value={'false'} label="epcis-event.view.canceled-event" />
            <TextField value={currentEvent.id} label="epcis-event.view.id" />
            <TextField value={executionId} label="epcis-event.view.executionId" />
          </div>
        </div>
      </div>
    );
  }
}

export const EpcisEventSummary = injectIntl(
  inject(
    HISTORY_STORE_ID,
    EPCIS_MESSAGE_STORE_ID,
    EPCIS_EVENTS_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(ViewEpcisEventSummaryBase)),
);
