/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisDocumentRecord
 */
export interface KvintaEpcisDocumentRecord {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentRecord
     */
    sha256: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisDocumentRecord
     */
    eventsCount: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocumentRecord
     */
    location?: string | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisDocumentRecord
     */
    createDate?: KvintaTimestamp | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisDocumentRecord
     */
    recordDate: number;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisDocumentRecord
     */
    earliestEventDate?: KvintaTimestamp | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisDocumentRecord
     */
    latestEventDate?: KvintaTimestamp | null;
}

export function KvintaEpcisDocumentRecordFromJSON(json: any): KvintaEpcisDocumentRecord {
    return KvintaEpcisDocumentRecordFromJSONTyped(json, false);
}

export function KvintaEpcisDocumentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisDocumentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sha256': json['sha256'],
        'eventsCount': json['eventsCount'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'createDate': !exists(json, 'createDate') ? undefined : KvintaTimestampFromJSON(json['createDate']),
        'recordDate': json['recordDate'],
        'earliestEventDate': !exists(json, 'earliestEventDate') ? undefined : KvintaTimestampFromJSON(json['earliestEventDate']),
        'latestEventDate': !exists(json, 'latestEventDate') ? undefined : KvintaTimestampFromJSON(json['latestEventDate']),
    };
}

export function KvintaEpcisDocumentRecordToJSON(value?: KvintaEpcisDocumentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sha256': value.sha256,
        'eventsCount': value.eventsCount,
        'location': value.location,
        'createDate': KvintaTimestampToJSON(value.createDate),
        'recordDate': value.recordDate,
        'earliestEventDate': KvintaTimestampToJSON(value.earliestEventDate),
        'latestEventDate': KvintaTimestampToJSON(value.latestEventDate),
    };
}


