/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaEpcisBizStep {
    Commissioning = 'COMMISSIONING',
    Decommissioning = 'DECOMMISSIONING',
    Destroying = 'DESTROYING',
    Packing = 'PACKING',
    Unpacking = 'UNPACKING',
    Receiving = 'RECEIVING',
    Shipping = 'SHIPPING',
    Verification = 'VERIFICATION',
    Inspecting = 'INSPECTING'
}

export function KvintaEpcisBizStepFromJSON(json: any): KvintaEpcisBizStep {
    return KvintaEpcisBizStepFromJSONTyped(json, false);
}

export function KvintaEpcisBizStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisBizStep {
    return json as KvintaEpcisBizStep;
}

export function KvintaEpcisBizStepToJSON(value?: KvintaEpcisBizStep | null): any {
    return value as any;
}

