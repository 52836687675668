/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMutateRequestBusinessPartnerExternalId,
    KvintaMutateRequestBusinessPartnerExternalIdFromJSON,
    KvintaMutateRequestBusinessPartnerExternalIdFromJSONTyped,
    KvintaMutateRequestBusinessPartnerExternalIdToJSON,
    KvintaMutateRequestLocationExternalId,
    KvintaMutateRequestLocationExternalIdFromJSON,
    KvintaMutateRequestLocationExternalIdFromJSONTyped,
    KvintaMutateRequestLocationExternalIdToJSON,
    KvintaMutateRequestProductExternalId,
    KvintaMutateRequestProductExternalIdFromJSON,
    KvintaMutateRequestProductExternalIdFromJSONTyped,
    KvintaMutateRequestProductExternalIdToJSON,
    KvintaMutateRequestTradeItemTradeItemKey,
    KvintaMutateRequestTradeItemTradeItemKeyFromJSON,
    KvintaMutateRequestTradeItemTradeItemKeyFromJSONTyped,
    KvintaMutateRequestTradeItemTradeItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateMasterdataRequest
 */
export interface KvintaMutateMasterdataRequest {
    /**
     * 
     * @type {KvintaMutateRequestBusinessPartnerExternalId}
     * @memberof KvintaMutateMasterdataRequest
     */
    businessPartner?: KvintaMutateRequestBusinessPartnerExternalId | null;
    /**
     * 
     * @type {KvintaMutateRequestLocationExternalId}
     * @memberof KvintaMutateMasterdataRequest
     */
    location?: KvintaMutateRequestLocationExternalId | null;
    /**
     * 
     * @type {KvintaMutateRequestProductExternalId}
     * @memberof KvintaMutateMasterdataRequest
     */
    product?: KvintaMutateRequestProductExternalId | null;
    /**
     * 
     * @type {KvintaMutateRequestTradeItemTradeItemKey}
     * @memberof KvintaMutateMasterdataRequest
     */
    tradeItem?: KvintaMutateRequestTradeItemTradeItemKey | null;
}

export function KvintaMutateMasterdataRequestFromJSON(json: any): KvintaMutateMasterdataRequest {
    return KvintaMutateMasterdataRequestFromJSONTyped(json, false);
}

export function KvintaMutateMasterdataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateMasterdataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessPartner': !exists(json, 'businessPartner') ? undefined : KvintaMutateRequestBusinessPartnerExternalIdFromJSON(json['businessPartner']),
        'location': !exists(json, 'location') ? undefined : KvintaMutateRequestLocationExternalIdFromJSON(json['location']),
        'product': !exists(json, 'product') ? undefined : KvintaMutateRequestProductExternalIdFromJSON(json['product']),
        'tradeItem': !exists(json, 'tradeItem') ? undefined : KvintaMutateRequestTradeItemTradeItemKeyFromJSON(json['tradeItem']),
    };
}

export function KvintaMutateMasterdataRequestToJSON(value?: KvintaMutateMasterdataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'businessPartner': KvintaMutateRequestBusinessPartnerExternalIdToJSON(value.businessPartner),
        'location': KvintaMutateRequestLocationExternalIdToJSON(value.location),
        'product': KvintaMutateRequestProductExternalIdToJSON(value.product),
        'tradeItem': KvintaMutateRequestTradeItemTradeItemKeyToJSON(value.tradeItem),
    };
}


