/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaQuantityCalculationConfig
 */
export interface KvintaQuantityCalculationConfig {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaQuantityCalculationConfig
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof KvintaQuantityCalculationConfig
     */
    delayInSeconds: number;
}

export function KvintaQuantityCalculationConfigFromJSON(json: any): KvintaQuantityCalculationConfig {
    return KvintaQuantityCalculationConfigFromJSONTyped(json, false);
}

export function KvintaQuantityCalculationConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQuantityCalculationConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'delayInSeconds': json['delayInSeconds'],
    };
}

export function KvintaQuantityCalculationConfigToJSON(value?: KvintaQuantityCalculationConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'delayInSeconds': value.delayInSeconds,
    };
}


