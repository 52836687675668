/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExternalIdAllOf,
    KvintaExternalIdAllOfFromJSON,
    KvintaExternalIdAllOfFromJSONTyped,
    KvintaExternalIdAllOfToJSON,
    KvintaWithExternalId,
    KvintaWithExternalIdFromJSON,
    KvintaWithExternalIdFromJSONTyped,
    KvintaWithExternalIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaExternalId
 */
export interface KvintaExternalId {
    /**
     * 
     * @type {string}
     * @memberof KvintaExternalId
     */
    sourceSystem: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaExternalId
     */
    idInSourceSystem: string;
}

export function KvintaExternalIdFromJSON(json: any): KvintaExternalId {
    return KvintaExternalIdFromJSONTyped(json, false);
}

export function KvintaExternalIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaExternalId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceSystem': json['sourceSystem'],
        'idInSourceSystem': json['idInSourceSystem'],
    };
}

export function KvintaExternalIdToJSON(value?: KvintaExternalId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceSystem': value.sourceSystem,
        'idInSourceSystem': value.idInSourceSystem,
    };
}


