/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListEpcisDocumentsRequest,
    KvintaListEpcisDocumentsRequestFromJSON,
    KvintaListEpcisDocumentsRequestFromJSONTyped,
    KvintaListEpcisDocumentsRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListEpcisDocumentsRequest
 */
export interface KvintaOperationRequestListEpcisDocumentsRequest {
    /**
     * 
     * @type {KvintaListEpcisDocumentsRequest}
     * @memberof KvintaOperationRequestListEpcisDocumentsRequest
     */
    input?: KvintaListEpcisDocumentsRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListEpcisDocumentsRequest
     */
    context?: KvintaExecutionContext;
}

export function KvintaOperationRequestListEpcisDocumentsRequestFromJSON(json: any): KvintaOperationRequestListEpcisDocumentsRequest {
    return KvintaOperationRequestListEpcisDocumentsRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListEpcisDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListEpcisDocumentsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': !exists(json, 'input') ? undefined : KvintaListEpcisDocumentsRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestListEpcisDocumentsRequestToJSON(value?: KvintaOperationRequestListEpcisDocumentsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaListEpcisDocumentsRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


