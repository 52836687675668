import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { countries, SelectableStore } from 'kvinta/common';
import {
  DefaultApi as MDDocumentApi,
  KvintaBusinessPartner,
  KvintaLocation,
  KvintaSortDirection,
  KvintaSortExpression,
} from 'kvinta/apis/kvinta-masterdata';
import { getExternalId, getInternalId } from 'kvinta/apis/utils';
import { translateCountries } from '../../../common/utils';
import { TFormFieldData } from 'kvinta/common/formUtils/types';
import { validateForm } from 'kvinta/common/formUtils/core';
import { handleFormBlur, handleFormChange } from 'kvinta/common/formUtils/handlers';
import { formRoot, select, textInput } from 'kvinta/common/formUtils/formDataGenerators';
import { isGeoCoordinate, isNotEmpty, maxLength, valueAlreadyExistsInList } from '../../../common/formUtils/validators';

export type TBusinessPartner = {
  name: string;
  latitude: string;
  longitude: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  country: string;
  description: string;
  gln13: string;
  postalCode: string;
  idInSourceSystem: string;
};

export type TBusinessPartnerFormData = {
  'businessPartnerForm.name': TFormFieldData;
  'businessPartnerForm.latitude': TFormFieldData;
  'businessPartnerForm.longitude': TFormFieldData;
  'businessPartnerForm.address1': TFormFieldData;
  'businessPartnerForm.address2': TFormFieldData;
  'businessPartnerForm.address3': TFormFieldData;
  'businessPartnerForm.city': TFormFieldData;
  'businessPartnerForm.country': TFormFieldData;
  'businessPartnerForm.description': TFormFieldData;
  'businessPartnerForm.gln13': TFormFieldData;
  'businessPartnerForm.postalCode': TFormFieldData;
  'businessPartnerForm.idInSourceSystem': TFormFieldData;
};

interface IBusinessPartnerRow extends KvintaBusinessPartner, ISelectableRow {}

export class BusinessPartnersStore extends SelectableStore<IBusinessPartnerRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  locations: KvintaLocation[];
  currentSort: KvintaSortExpression;

  businessPartnerFormOpen: boolean;

  businessPartnerFormData?: TBusinessPartnerFormData;

  currentBusinessPartner?: TBusinessPartner;

  exportActive: boolean;
  exportData: IBusinessPartnerRow[] | KvintaBusinessPartner[] | undefined;
  searchValue: string;
  autofocusSearchInList: boolean;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchBusinessPartnerList: action.bound,
      updateSearch: action.bound,
      isLoading: observable,
      page: observable,
      pageSize: observable,
      searchValue: observable,
      unfocusSearchField: action.bound,

      fetchBusinessPartner: action.bound,
      currentBusinessPartner: observable,

      businessPartnerFormOpen: observable,
      businessPartnerFormData: observable,
      openCreateBusinessPartnerForm: action.bound,
      openUpdateBusinessPartnerForm: action.bound,
      closeBusinessPartnerForm: action.bound,
      onChangeBusinessPartnerFormField: action.bound,
      onBlurBusinessPartnerFormField: action.bound,
      submitBusinessPartnerForm: action.bound,
      submitCreateBusinessPartnerForm: action.bound,
      submitUpdateBusinessPartnerForm: action.bound,

      exportActive: observable,
      updateExported: action.bound,
      exportAll: action.bound,
      exportSelected: action.bound,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      direction: KvintaSortDirection.Desc,
      property: 'name',
      ignoreCase: false,
    };
  }

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;
    this.fetchBusinessPartnerList();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const field = getField(orderBy);
    console.log('orderBy:', orderBy, 'field:', field);
    console.log('orderDirection:', orderDirection);
    this.currentSort = {
      property: field,
      direction: orderDirection == 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
      ignoreCase: false,
    };
    this.fetchBusinessPartnerList();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchBusinessPartnerList();
  }

  fetchAllBusinessPartnerNameList = async () => {
    const businessPartners = await this._mdApi.queryBusinessPartner({
      kvintaQueryRequestBusinessPartnerFilter: {
        paging: {
          page: 0,
          size: 10000,
        },
      },
    });
    if (businessPartners) {
      return (businessPartners.list || []).map((businessPartner) => businessPartner.name).filter((name) => name);
    } else {
      return [];
    }
  };

  fetchBusinessPartnerList = async () => {
    this.isLoading = true;
    this.listData = [];
    this.totalCount = 0;

    let filters = {} as { [key: string]: string } | null;

    for (const filter of searchedColumns) {
      filters = {
        ...filters,
        nameLike: this.searchValue,
      };
    }

    this._mdApi
      .queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: { expressions: [this.currentSort] },
          },
          filter: {
            ...filters,
          },
        },
      })
      .then((result) => {
        const oldSelection = new Map();
        if (this.listData) {
          this.listData.forEach((row) => {
            oldSelection.set(row.id, row.isSelected);
          });
          this.totalCount = result.total || 0;
          this.listData = (result.list || []).map((comp) => {
            const selected = oldSelection.get(getInternalId(comp)) || false;
            return { ...comp, isSelected: selected, id: getInternalId(comp) } as IBusinessPartnerRow;
          });
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  openCreateBusinessPartnerForm = async () => {
    const initialValues = {
      name: '',
      latitude: '',
      longitude: '',
      address1: '',
      address2: '',
      address3: '',
      city: '',
      country: '',
      description: '',
      gln13: '',
      postalCode: '',
      idInSourceSystem: '',
    };

    const businessPartnersNameList = await this.fetchAllBusinessPartnerNameList();
    this.businessPartnerFormData = validateForm<TBusinessPartnerFormData>(
      generateBusinessPartnerFormData(BUSINESS_PARTNER_ROOT_FORM_ID, initialValues, businessPartnersNameList),
    );
    this.businessPartnerFormOpen = true;
  };

  openUpdateBusinessPartnerForm = async () => {
    const businessPartnersNameList = await this.fetchAllBusinessPartnerNameList();
    this.businessPartnerFormData = validateForm(
      generateBusinessPartnerFormData(
        BUSINESS_PARTNER_ROOT_FORM_ID,
        this.currentBusinessPartner,
        businessPartnersNameList.filter((name) => name !== this.currentBusinessPartner.name),
      ),
    );
    this.businessPartnerFormOpen = true;
  };

  closeBusinessPartnerForm() {
    this.businessPartnerFormData = undefined;
    this.businessPartnerFormOpen = false;
  }

  onChangeBusinessPartnerFormField = (id: string, value: any) => {
    const formData = toJS(this.businessPartnerFormData);
    this.businessPartnerFormData = handleFormChange(formData, id, value);
  };

  onBlurBusinessPartnerFormField = (id: string) => {
    const formData = toJS(this.businessPartnerFormData);
    this.businessPartnerFormData = handleFormBlur(formData, id);
  };

  submitBusinessPartnerForm(nextAction) {
    this.isLoading = true;

    return this._mdApi
      .mutateBusinessPartner({
        kvintaMutateRequestBusinessPartnerExternalId: {
          upsert: [
            {
              ...transformBusinessPartnerFormData(this.businessPartnerFormData),
              sourceSystem: 'KVINTA',
            },
          ],
        },
      })
      .then(async (response) => {
        if (response.errors && response.errors.length) {
          const errors = response.errors.map((err) => err.error).join('\n');
          this._notificationManager.sendError(`An error occurred while submitting data\n${errors}`);
        } else {
          this._notificationManager.sendSuccess(`Successfully submitted data`);
          await nextAction(response.upserted[0]);
          this.closeBusinessPartnerForm();
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  submitCreateBusinessPartnerForm() {
    return this.submitBusinessPartnerForm(() => this.fetchBusinessPartnerList());
  }

  submitUpdateBusinessPartnerForm() {
    return this.submitBusinessPartnerForm((currentBusinessPartner) =>
      this.fetchBusinessPartner(getInternalId(currentBusinessPartner)),
    );
  }

  deleteBusinessPartner = async (BusinessPartnerID: string) => {
    this.isLoading = true;
    this._mdApi
      .mutateBusinessPartner({
        kvintaMutateRequestBusinessPartnerExternalId: {
          _delete: [getExternalId(BusinessPartnerID)],
        },
      })
      .then((response) => {
        if (response.errors && response.errors.length) {
          this._notificationManager.sendError(
            `An error occurred while trying to delete ${BusinessPartnerID}\n${response.errors[0].error}`,
          );
        }
        this.fetchBusinessPartnerList();
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(
          `An error occurred while trying to delete ${BusinessPartnerID}\n${err.message}`,
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  fetchBusinessPartner = (id: string) => {
    this.isLoading = true;
    this.currentBusinessPartner = null;

    this._mdApi
      .queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: 0,
            size: 1,
          },
          filter: {
            id: id,
          },
        },
      })
      .then((result) => {
        const currentBusinessPartner = result.list[0];
        this.currentBusinessPartner = {
          name: currentBusinessPartner.name || '',
          latitude: currentBusinessPartner.latitude?.toString() || '',
          longitude: currentBusinessPartner.longitude?.toString() || '',
          address1: currentBusinessPartner.address1 || '',
          address2: currentBusinessPartner.address2 || '',
          address3: currentBusinessPartner.address3 || '',
          city: currentBusinessPartner.city || '',
          country: currentBusinessPartner.country || '',
          description: currentBusinessPartner.description || '',
          gln13: currentBusinessPartner.gln13 || '',
          postalCode: currentBusinessPartner.postalCode || '',
          idInSourceSystem: currentBusinessPartner.idInSourceSystem || '',
        };
      })
      .catch((e) => {
        this._notificationManager.sendError(`An error occurred while retrieving data\n${e.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    this.exportActive = true;
    this.exportData = this.listChecked;
  }

  async exportAll() {
    this.exportActive = false;
    this.exportData = undefined;

    try {
      const BusinessPartnerListResult = await this._mdApi.queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
          },
        },
      });
      this.exportActive = true;
      this.exportData = BusinessPartnerListResult.list;
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }
}

export const BUSINESS_PARTNER_STORE_ID = 'businessPartnersStore';
export const BUSINESS_PARTNER_ROOT_FORM_ID = 'businessPartnerForm';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'name';
    case 1:
      return 'gln13';
    case 2:
      return 'country';
    case 3:
      return 'city';
    default:
      return 'name';
  }
}

const searchedColumns = ['name']; //, 'description', 'gln13', 'id']; // TODO: API is not adopted to search multiple columns

function generateBusinessPartnerFormData(
  rootFormId: string,
  initialValues: {
    idInSourceSystem: string;
    name: string;
    gln13: string;
    longitude: string;
    latitude: string;
    country: string;
    city: string;
    postalCode: string;
    address1: string;
    address2: string;
    address3: string;
    description: string;
  },
  businessPartnersNameList: string[],
) {
  return formRoot<TBusinessPartnerFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'idInSourceSystem',
        value: initialValues.idInSourceSystem,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'name',
        value: initialValues.name,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
          valueAlreadyExistsInList({
            errorMessage: 'A business partner with the same name already exists',
            list: businessPartnersNameList,
          }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'gln13',
        value: initialValues.gln13,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'longitude',
        value: initialValues.longitude,
        validations: [isGeoCoordinate({ errorMessage: 'please provide a valid decimal longitude' })],
        isRequiredField: false,
      }),
      textInput({
        path: 'latitude',
        value: initialValues.latitude,
        validations: [isGeoCoordinate({ errorMessage: 'please provide a valid decimal latitude' })],
        isRequiredField: false,
      }),
      select({
        path: 'country',
        value: initialValues.country,
        options: translateCountries(countries),
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'city',
        value: initialValues.city,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'postalCode',
        value: initialValues.postalCode,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'address1',
        value: initialValues.address1,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'address2',
        value: initialValues.address2,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'address3',
        value: initialValues.address3,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'description',
        value: initialValues.description,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 200 })],
        isRequiredField: false,
      }),
    ],
  });
}

function transformBusinessPartnerFormData(businessPartnerFormData) {
  return {
    name: businessPartnerFormData['businessPartnerForm.name'].value,
    latitude: parseFloat(businessPartnerFormData['businessPartnerForm.latitude'].value),
    longitude: parseFloat(businessPartnerFormData['businessPartnerForm.longitude'].value),
    address1: businessPartnerFormData['businessPartnerForm.address1'].value,
    address2: businessPartnerFormData['businessPartnerForm.address2'].value,
    address3: businessPartnerFormData['businessPartnerForm.address3'].value,
    city: businessPartnerFormData['businessPartnerForm.city'].value,
    country: businessPartnerFormData['businessPartnerForm.country'].value,
    description: businessPartnerFormData['businessPartnerForm.description'].value,
    gln13: businessPartnerFormData['businessPartnerForm.gln13'].value,
    postalCode: businessPartnerFormData['businessPartnerForm.postalCode'].value,
    sourceSystem: 'KVINTA',
    idInSourceSystem: businessPartnerFormData['businessPartnerForm.idInSourceSystem'].value,
  };
}
