import { Button, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { TDateRangeFilter } from './FilterBar';
import { observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import styles from '../../../assets/css/partials/modules/DateRange.module.scss';
import cn from 'classnames';

export type TDateTimeRangeFilterProps = {
  filter: TDateRangeFilter;
  showRemoveButton: boolean;
  intl: any;
  onChangeValue: (field: string, value: { from: string; to: string }) => void;
  onClear: (field: string) => void;
  onFilter: (field: string) => void;
  hideFilter: (field: string) => void;
};

type TDateRangeInputProps = {
  onChangeValue: (field: string, value: { from: string; to: string }) => void;
  value: string;
  htmlElementId: string;
  label: string;
  id: string;
  createOnChangeValue: (val: string) => { from: string; to: string };
};

const RemoveFilterButton = ({ onClick, id }) => (
  <IconButton
    id={`remove-${id}`}
    style={{ marginLeft: 6 }}
    className={cn('removeFilter', styles.removeFilterButton)}
    onClick={onClick}
  >
    <CancelIcon />
  </IconButton>
);

const ApplyFilterButtonBase = ({ onClick, intl, disabled, id }) => (
  <Button
    id={`apply-${id}`}
    variant="contained"
    color="primary"
    className={cn('applyFilter', styles.applyFilterButton)}
    onClick={onClick}
    disabled={disabled}
  >
    {intl.formatMessage({ id: 'button.apply' })}
  </Button>
);

const ApplyFilterButton = injectIntl(ApplyFilterButtonBase);

const DateRangeInput: React.FunctionComponent<TDateRangeInputProps> = ({
  onChangeValue,
  value,
  htmlElementId,
  id,
  createOnChangeValue,
  label,
}) => {
  const ref = React.createRef<HTMLInputElement & { showPicker: () => void }>();
  return (
    <>
      <span className={styles.dateRangeInputLabel}>{label}:</span>
      <input
        ref={ref}
        className={cn(styles.dateRangeInput, value === '' ? '' : styles.dateRangeInputActive)}
        id={htmlElementId}
        value={value}
        type="date"
        onChange={(e) => onChangeValue(id, createOnChangeValue(e.target.value))}
        onKeyDown={(e) => {
          e.preventDefault();
          ref.current && ref.current.showPicker();
        }}
        onClick={(e) => {
          ref.current && ref.current.showPicker();
        }}
      />
    </>
  );
};

const DateRangeFilterBase: React.FunctionComponent<TDateTimeRangeFilterProps> = ({
  filter,
  showRemoveButton,
  intl,
  onChangeValue,
  hideFilter,
  onFilter,
}) => {
  const filterFieldId = `filter-${filter.id}`;
  const translatedLabel = `${intl.formatMessage({ id: 'filter.by' })} ${intl.formatMessage({ id: filter.label })}`;

  return (
    <div className="ZstFilter-root" style={{ marginLeft: '5px' }}>
      <div className={styles.dateRangeWrapper}>
        <div className={styles.filterLabel}>{translatedLabel}</div>
        <DateRangeInput
          id={filter.id}
          value={filter.value.from}
          createOnChangeValue={(val) => ({ ...filter.value, from: val })}
          onChangeValue={onChangeValue}
          htmlElementId={`from-${filterFieldId}`}
          label={'from'}
        />
        <DateRangeInput
          id={filter.id}
          value={filter.value.to}
          createOnChangeValue={(val) => ({ ...filter.value, to: val })}
          onChangeValue={onChangeValue}
          htmlElementId={`to-${filterFieldId}`}
          label={'to'}
        />

        <ApplyFilterButton id={filterFieldId} onClick={(e) => onFilter(filter.id)} disabled={filter.isApplied} />

        {showRemoveButton && <RemoveFilterButton id={filterFieldId} onClick={() => hideFilter(filter.id)} />}
      </div>
    </div>
  );
};

export const DateRangeFilter = observer(DateRangeFilterBase);
