/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDuration,
    KvintaDurationFromJSON,
    KvintaDurationFromJSONTyped,
    KvintaDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaRetryConfiguration
 */
export interface KvintaRetryConfiguration {
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaRetryConfiguration
     */
    initialInterval?: KvintaDuration | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaRetryConfiguration
     */
    backoffCoefficient: number;
    /**
     * 
     * @type {number}
     * @memberof KvintaRetryConfiguration
     */
    maximumAttempts: number;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaRetryConfiguration
     */
    maximumInterval?: KvintaDuration | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaRetryConfiguration
     */
    doNotRetry?: Array<string> | null;
}

export function KvintaRetryConfigurationFromJSON(json: any): KvintaRetryConfiguration {
    return KvintaRetryConfigurationFromJSONTyped(json, false);
}

export function KvintaRetryConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaRetryConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initialInterval': !exists(json, 'initialInterval') ? undefined : KvintaDurationFromJSON(json['initialInterval']),
        'backoffCoefficient': json['backoffCoefficient'],
        'maximumAttempts': json['maximumAttempts'],
        'maximumInterval': !exists(json, 'maximumInterval') ? undefined : KvintaDurationFromJSON(json['maximumInterval']),
        'doNotRetry': !exists(json, 'doNotRetry') ? undefined : json['doNotRetry'],
    };
}

export function KvintaRetryConfigurationToJSON(value?: KvintaRetryConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initialInterval': KvintaDurationToJSON(value.initialInterval),
        'backoffCoefficient': value.backoffCoefficient,
        'maximumAttempts': value.maximumAttempts,
        'maximumInterval': KvintaDurationToJSON(value.maximumInterval),
        'doNotRetry': value.doNotRetry,
    };
}


