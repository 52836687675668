/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaContentDifferenceType,
    KvintaContentDifferenceTypeFromJSON,
    KvintaContentDifferenceTypeFromJSONTyped,
    KvintaContentDifferenceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcsContentItemDiff
 */
export interface KvintaEpcsContentItemDiff {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItemDiff
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItemDiff
     */
    batch: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcsContentItemDiff
     */
    countDelivery: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItemDiff
     */
    expiryDateDelivery?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcsContentItemDiff
     */
    countShipment: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcsContentItemDiff
     */
    expiryDateShipment?: string | null;
    /**
     * 
     * @type {KvintaContentDifferenceType}
     * @memberof KvintaEpcsContentItemDiff
     */
    differenceType: KvintaContentDifferenceType;
}

export function KvintaEpcsContentItemDiffFromJSON(json: any): KvintaEpcsContentItemDiff {
    return KvintaEpcsContentItemDiffFromJSONTyped(json, false);
}

export function KvintaEpcsContentItemDiffFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcsContentItemDiff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gtin': json['gtin'],
        'batch': json['batch'],
        'countDelivery': json['countDelivery'],
        'expiryDateDelivery': !exists(json, 'expiryDateDelivery') ? undefined : json['expiryDateDelivery'],
        'countShipment': json['countShipment'],
        'expiryDateShipment': !exists(json, 'expiryDateShipment') ? undefined : json['expiryDateShipment'],
        'differenceType': KvintaContentDifferenceTypeFromJSON(json['differenceType']),
    };
}

export function KvintaEpcsContentItemDiffToJSON(value?: KvintaEpcsContentItemDiff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gtin': value.gtin,
        'batch': value.batch,
        'countDelivery': value.countDelivery,
        'expiryDateDelivery': value.expiryDateDelivery,
        'countShipment': value.countShipment,
        'expiryDateShipment': value.expiryDateShipment,
        'differenceType': KvintaContentDifferenceTypeToJSON(value.differenceType),
    };
}


