import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type TTextFieldProps = {
  intl: IntlShape;
  value: string;
  label: string;
  style?: React.CSSProperties;
  linkTo: () => void;
};

const TextFieldWithLinkBase: React.FunctionComponent<TTextFieldProps> = ({ linkTo, intl, value, label, style }) => (
  <FormControl variant="outlined" fullWidth style={style} id={`${label}-container`}>
    <InputLabel style={{ backgroundColor: 'white' }}>{intl.formatMessage({ id: label })}</InputLabel>
    <OutlinedInput
      id={label}
      readOnly={true}
      label=" "
      value={value}
      disabled={value === undefined || value.trim() === ''}
      endAdornment={
        value && (
          <InputAdornment position="end">
            <IconButton onClick={(_e) => linkTo()}>
              <OpenInNewIcon />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  </FormControl>
);

export const TextFieldWithLink = injectIntl(TextFieldWithLinkBase);
