/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaLocationFilter,
    KvintaLocationFilterFromJSON,
    KvintaLocationFilterFromJSONTyped,
    KvintaLocationFilterToJSON,
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryRequestLocationFilter
 */
export interface KvintaQueryRequestLocationFilter {
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaQueryRequestLocationFilter
     */
    paging: KvintaPaging;
    /**
     * 
     * @type {KvintaLocationFilter}
     * @memberof KvintaQueryRequestLocationFilter
     */
    filter?: KvintaLocationFilter | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaQueryRequestLocationFilter
     */
    fetchItems?: boolean | null;
}

export function KvintaQueryRequestLocationFilterFromJSON(json: any): KvintaQueryRequestLocationFilter {
    return KvintaQueryRequestLocationFilterFromJSONTyped(json, false);
}

export function KvintaQueryRequestLocationFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryRequestLocationFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': KvintaPagingFromJSON(json['paging']),
        'filter': !exists(json, 'filter') ? undefined : KvintaLocationFilterFromJSON(json['filter']),
        'fetchItems': !exists(json, 'fetchItems') ? undefined : json['fetchItems'],
    };
}

export function KvintaQueryRequestLocationFilterToJSON(value?: KvintaQueryRequestLocationFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': KvintaPagingToJSON(value.paging),
        'filter': KvintaLocationFilterToJSON(value.filter),
        'fetchItems': value.fetchItems,
    };
}


