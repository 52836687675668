/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeliveryItemKey,
    KvintaDeliveryItemKeyFromJSON,
    KvintaDeliveryItemKeyFromJSONTyped,
    KvintaDeliveryItemKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaIdAndErrorDeliveryItemKey
 */
export interface KvintaIdAndErrorDeliveryItemKey {
    /**
     * 
     * @type {KvintaDeliveryItemKey}
     * @memberof KvintaIdAndErrorDeliveryItemKey
     */
    id: KvintaDeliveryItemKey;
    /**
     * 
     * @type {string}
     * @memberof KvintaIdAndErrorDeliveryItemKey
     */
    error: string;
}

export function KvintaIdAndErrorDeliveryItemKeyFromJSON(json: any): KvintaIdAndErrorDeliveryItemKey {
    return KvintaIdAndErrorDeliveryItemKeyFromJSONTyped(json, false);
}

export function KvintaIdAndErrorDeliveryItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaIdAndErrorDeliveryItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': KvintaDeliveryItemKeyFromJSON(json['id']),
        'error': json['error'],
    };
}

export function KvintaIdAndErrorDeliveryItemKeyToJSON(value?: KvintaIdAndErrorDeliveryItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': KvintaDeliveryItemKeyToJSON(value.id),
        'error': value.error,
    };
}


