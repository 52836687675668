/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaResourceChanges
 */
export interface KvintaResourceChanges {
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    createQueued?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    createOk?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    createError?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    releaseQueued?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    releaseOk?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    releaseError?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    deleteQueued?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    deleteOk?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    deleteError?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    updateQueued?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    updateOk?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    updateError?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    done?: Array<KvintaResource> | null;
    /**
     * 
     * @type {Array<KvintaResource>}
     * @memberof KvintaResourceChanges
     */
    rejected?: Array<KvintaResource> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaResourceChanges
     */
    statusEmitted?: boolean;
}

export function KvintaResourceChangesFromJSON(json: any): KvintaResourceChanges {
    return KvintaResourceChangesFromJSONTyped(json, false);
}

export function KvintaResourceChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaResourceChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createQueued': !exists(json, 'createQueued') ? undefined : (json['createQueued'] === null ? null : (json['createQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'createOk': !exists(json, 'createOk') ? undefined : (json['createOk'] === null ? null : (json['createOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'createError': !exists(json, 'createError') ? undefined : (json['createError'] === null ? null : (json['createError'] as Array<any>).map(KvintaResourceFromJSON)),
        'releaseQueued': !exists(json, 'releaseQueued') ? undefined : (json['releaseQueued'] === null ? null : (json['releaseQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'releaseOk': !exists(json, 'releaseOk') ? undefined : (json['releaseOk'] === null ? null : (json['releaseOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'releaseError': !exists(json, 'releaseError') ? undefined : (json['releaseError'] === null ? null : (json['releaseError'] as Array<any>).map(KvintaResourceFromJSON)),
        'deleteQueued': !exists(json, 'deleteQueued') ? undefined : (json['deleteQueued'] === null ? null : (json['deleteQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'deleteOk': !exists(json, 'deleteOk') ? undefined : (json['deleteOk'] === null ? null : (json['deleteOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'deleteError': !exists(json, 'deleteError') ? undefined : (json['deleteError'] === null ? null : (json['deleteError'] as Array<any>).map(KvintaResourceFromJSON)),
        'updateQueued': !exists(json, 'updateQueued') ? undefined : (json['updateQueued'] === null ? null : (json['updateQueued'] as Array<any>).map(KvintaResourceFromJSON)),
        'updateOk': !exists(json, 'updateOk') ? undefined : (json['updateOk'] === null ? null : (json['updateOk'] as Array<any>).map(KvintaResourceFromJSON)),
        'updateError': !exists(json, 'updateError') ? undefined : (json['updateError'] === null ? null : (json['updateError'] as Array<any>).map(KvintaResourceFromJSON)),
        'done': !exists(json, 'done') ? undefined : (json['done'] === null ? null : (json['done'] as Array<any>).map(KvintaResourceFromJSON)),
        'rejected': !exists(json, 'rejected') ? undefined : (json['rejected'] === null ? null : (json['rejected'] as Array<any>).map(KvintaResourceFromJSON)),
        'statusEmitted': !exists(json, 'statusEmitted') ? undefined : json['statusEmitted'],
    };
}

export function KvintaResourceChangesToJSON(value?: KvintaResourceChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createQueued': value.createQueued === undefined ? undefined : (value.createQueued === null ? null : (value.createQueued as Array<any>).map(KvintaResourceToJSON)),
        'createOk': value.createOk === undefined ? undefined : (value.createOk === null ? null : (value.createOk as Array<any>).map(KvintaResourceToJSON)),
        'createError': value.createError === undefined ? undefined : (value.createError === null ? null : (value.createError as Array<any>).map(KvintaResourceToJSON)),
        'releaseQueued': value.releaseQueued === undefined ? undefined : (value.releaseQueued === null ? null : (value.releaseQueued as Array<any>).map(KvintaResourceToJSON)),
        'releaseOk': value.releaseOk === undefined ? undefined : (value.releaseOk === null ? null : (value.releaseOk as Array<any>).map(KvintaResourceToJSON)),
        'releaseError': value.releaseError === undefined ? undefined : (value.releaseError === null ? null : (value.releaseError as Array<any>).map(KvintaResourceToJSON)),
        'deleteQueued': value.deleteQueued === undefined ? undefined : (value.deleteQueued === null ? null : (value.deleteQueued as Array<any>).map(KvintaResourceToJSON)),
        'deleteOk': value.deleteOk === undefined ? undefined : (value.deleteOk === null ? null : (value.deleteOk as Array<any>).map(KvintaResourceToJSON)),
        'deleteError': value.deleteError === undefined ? undefined : (value.deleteError === null ? null : (value.deleteError as Array<any>).map(KvintaResourceToJSON)),
        'updateQueued': value.updateQueued === undefined ? undefined : (value.updateQueued === null ? null : (value.updateQueued as Array<any>).map(KvintaResourceToJSON)),
        'updateOk': value.updateOk === undefined ? undefined : (value.updateOk === null ? null : (value.updateOk as Array<any>).map(KvintaResourceToJSON)),
        'updateError': value.updateError === undefined ? undefined : (value.updateError === null ? null : (value.updateError as Array<any>).map(KvintaResourceToJSON)),
        'done': value.done === undefined ? undefined : (value.done === null ? null : (value.done as Array<any>).map(KvintaResourceToJSON)),
        'rejected': value.rejected === undefined ? undefined : (value.rejected === null ? null : (value.rejected as Array<any>).map(KvintaResourceToJSON)),
        'statusEmitted': value.statusEmitted,
    };
}


