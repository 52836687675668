/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaCodeLastXAuditRequest,
    KvintaCodeLastXAuditRequestFromJSON,
    KvintaCodeLastXAuditRequestToJSON,
    KvintaOperationResponseString,
    KvintaOperationResponseStringFromJSON,
    KvintaOperationResponseStringToJSON,
} from '../models';

export interface StartLastXAuditRequest {
    kvintaCodeLastXAuditRequest: KvintaCodeLastXAuditRequest;
}

/**
 * 
 */
export class AuditApi extends runtime.BaseAPI {

    /**
     * Start audit.  If checkOnly = true then it returns top 200 diffs and total diffs count.  If checkOnly = false, then topParentCode is required, and  it fixed all diffs for topParentCode and returned rechecked diffs for these codes (usually it should be empty list)
     * Start audit.  If checkOnly = true then it returns top 200 diffs and total diffs count.  If checkOnly = false, then topParentCode is required, and  it fixed all diffs for topParentCode and returned rechecked diffs for these codes (usually it should be empty list)
     */
    async startLastXAuditRaw(requestParameters: StartLastXAuditRequest): Promise<runtime.ApiResponse<KvintaOperationResponseString>> {
        if (requestParameters.kvintaCodeLastXAuditRequest === null || requestParameters.kvintaCodeLastXAuditRequest === undefined) {
            throw new runtime.RequiredError('kvintaCodeLastXAuditRequest','Required parameter requestParameters.kvintaCodeLastXAuditRequest was null or undefined when calling startLastXAudit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/db/audit/startLastXAudit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaCodeLastXAuditRequestToJSON(requestParameters.kvintaCodeLastXAuditRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaOperationResponseStringFromJSON(jsonValue));
    }

    /**
     * Start audit.  If checkOnly = true then it returns top 200 diffs and total diffs count.  If checkOnly = false, then topParentCode is required, and  it fixed all diffs for topParentCode and returned rechecked diffs for these codes (usually it should be empty list)
     * Start audit.  If checkOnly = true then it returns top 200 diffs and total diffs count.  If checkOnly = false, then topParentCode is required, and  it fixed all diffs for topParentCode and returned rechecked diffs for these codes (usually it should be empty list)
     */
    async startLastXAudit(requestParameters: StartLastXAuditRequest): Promise<KvintaOperationResponseString> {
        const response = await this.startLastXAuditRaw(requestParameters);
        return await response.value();
    }

}
