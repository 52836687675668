/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCaptureSensorReportElement
 */
export interface KvintaCaptureSensorReportElement {
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    deviceID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    deviceMetaData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    rawData?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    dataProcessingMethod?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureSensorReportElement
     */
    time?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    microorganism?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    chemicalSubstance?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    value?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    component?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    stringValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaCaptureSensorReportElement
     */
    booleanValue?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    hexBinaryValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    uriValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    minValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    maxValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    meanValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    sDev?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    percRank?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureSensorReportElement
     */
    percValue?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureSensorReportElement
     */
    uom?: string | null;
}

export function KvintaCaptureSensorReportElementFromJSON(json: any): KvintaCaptureSensorReportElement {
    return KvintaCaptureSensorReportElementFromJSONTyped(json, false);
}

export function KvintaCaptureSensorReportElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureSensorReportElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'deviceID': !exists(json, 'deviceID') ? undefined : json['deviceID'],
        'deviceMetaData': !exists(json, 'deviceMetaData') ? undefined : json['deviceMetaData'],
        'rawData': !exists(json, 'rawData') ? undefined : json['rawData'],
        'dataProcessingMethod': !exists(json, 'dataProcessingMethod') ? undefined : json['dataProcessingMethod'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'microorganism': !exists(json, 'microorganism') ? undefined : json['microorganism'],
        'chemicalSubstance': !exists(json, 'chemicalSubstance') ? undefined : json['chemicalSubstance'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'component': !exists(json, 'component') ? undefined : json['component'],
        'stringValue': !exists(json, 'stringValue') ? undefined : json['stringValue'],
        'booleanValue': !exists(json, 'booleanValue') ? undefined : json['booleanValue'],
        'hexBinaryValue': !exists(json, 'hexBinaryValue') ? undefined : json['hexBinaryValue'],
        'uriValue': !exists(json, 'uriValue') ? undefined : json['uriValue'],
        'minValue': !exists(json, 'minValue') ? undefined : json['minValue'],
        'maxValue': !exists(json, 'maxValue') ? undefined : json['maxValue'],
        'meanValue': !exists(json, 'meanValue') ? undefined : json['meanValue'],
        'sDev': !exists(json, 'sDev') ? undefined : json['sDev'],
        'percRank': !exists(json, 'percRank') ? undefined : json['percRank'],
        'percValue': !exists(json, 'percValue') ? undefined : json['percValue'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
    };
}

export function KvintaCaptureSensorReportElementToJSON(value?: KvintaCaptureSensorReportElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'deviceID': value.deviceID,
        'deviceMetaData': value.deviceMetaData,
        'rawData': value.rawData,
        'dataProcessingMethod': value.dataProcessingMethod,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'microorganism': value.microorganism,
        'chemicalSubstance': value.chemicalSubstance,
        'value': value.value,
        'component': value.component,
        'stringValue': value.stringValue,
        'booleanValue': value.booleanValue,
        'hexBinaryValue': value.hexBinaryValue,
        'uriValue': value.uriValue,
        'minValue': value.minValue,
        'maxValue': value.maxValue,
        'meanValue': value.meanValue,
        'sDev': value.sDev,
        'percRank': value.percRank,
        'percValue': value.percValue,
        'uom': value.uom,
    };
}


