/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaAlarmType {
    DeliveryConsistency = 'DELIVERY_CONSISTENCY',
    Unspecified = 'UNSPECIFIED'
}

export function KvintaAlarmTypeFromJSON(json: any): KvintaAlarmType {
    return KvintaAlarmTypeFromJSONTyped(json, false);
}

export function KvintaAlarmTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAlarmType {
    return json as KvintaAlarmType;
}

export function KvintaAlarmTypeToJSON(value?: KvintaAlarmType | null): any {
    return value as any;
}

