import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsTradeItemView } from './tabs';
import EditIcon from '@mui/icons-material/Edit';
import { reaction } from 'mobx';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { PRODUCT_STORE_ID, ProductsStore } from './ProductsStore';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { TradeItemForm } from '../trade-items/TradeItemForm';
import { TRADE_ITEM_FORM_ROOT_ID, TRADE_ITEM_STORE_ID, TradeItemsStore } from '../trade-items/TradeItemsStore';
import styles from './Products.module.scss';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { TradeItemSummaryData } from '../trade-items/TradeItemSummary';

interface ProductTradeItemSummaryViewProps {
  intl: any;
  productsStore: ProductsStore;
  historyStore: HistoryStore;
  sidebarStore: KSidebarStore;
  tradeItemsStore: TradeItemsStore;
  tradeId: string;
  id: string;
}

class ProductTradeItemSummaryBase extends Component<ProductTradeItemSummaryViewProps> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.fetchTradeItem(decodeURIComponent(this.props.tradeId));
    this.disposer = reaction(
      () => this.props.productsStore.currentProduct,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateProductsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
    this.props.tradeItemsStore.closeTradeItemForm();
  }

  render() {
    if (!this.props.tradeItemsStore.currentTradeItem) {
      return null;
    }
    const intl = this.props.intl;

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateProductsPath();
            }
            if (newValue === '1') {
              this.props.historyStore.navigateProductPath(this.props.id);
            }
            if (newValue === '2') {
              this.props.historyStore.navigateProductTradeItemsPath(this.props.id);
            }
          }}
          tabId={'3'}
          tabs={tabsTradeItemView}
        />

        <div className={styles.summaryMain}>
          <div className={styles.summaryButtonContainer}>
            {this.props.tradeItemsStore.tradeItemFormOpen && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                onClick={this.props.tradeItemsStore.closeTradeItemForm}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            )}
            {this.props.tradeItemsStore.tradeItemFormOpen ? (
              <ButtonGreen
                id="editOrSubmitButton"
                onClick={this.props.tradeItemsStore.submitUpdateTradeItem}
                icon={<DoneIcon color="secondary" />}
                label={intl.formatMessage({ id: 'button.confirm' })}
                buttonState={formDataToButtonState(
                  this.props.tradeItemsStore.tradeItemFormData[TRADE_ITEM_FORM_ROOT_ID],
                )}
              />
            ) : (
              <ButtonGreen
                id="editOrSubmitButton"
                onClick={this.props.tradeItemsStore.openUpdateTradeItemForm}
                icon={<EditIcon color="secondary" />}
                label={intl.formatMessage({ id: 'button.edit' })}
                buttonState={EButtonState.ACTIVE}
              />
            )}
          </div>
          {this.props.tradeItemsStore.tradeItemFormOpen ? (
            <TradeItemForm
              formData={this.props.tradeItemsStore.tradeItemFormData}
              onChange={this.props.tradeItemsStore.onChangeTradeItemField}
              onBlur={this.props.tradeItemsStore.onBlurTradeItemField}
              navigateToProduct={() =>
                this.props.historyStore.navigateProductPath(this.props.tradeItemsStore.currentTradeItem.productId)
              }
            />
          ) : (
            <TradeItemSummaryData
              formData={this.props.tradeItemsStore.currentTradeItem}
              navigateToProduct={() =>
                this.props.historyStore.navigateProductPath(this.props.tradeItemsStore.currentTradeItem.productId)
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export const ProductTradeItemSummary = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PRODUCT_STORE_ID,
    TRADE_ITEM_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(ProductTradeItemSummaryBase)),
);
