export const summaryTabs = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'menu.deviceAssignments',
    value: '2',
  },
];

export const assignmentSummaryTabs = [
  ...summaryTabs,
  {
    label: 'common.assignment-summary',
    value: '3',
  },
];
