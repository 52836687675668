/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmStatus,
    KvintaAlarmStatusFromJSON,
    KvintaAlarmStatusFromJSONTyped,
    KvintaAlarmStatusToJSON,
    KvintaDeliveryItemInDelivery,
    KvintaDeliveryItemInDeliveryFromJSON,
    KvintaDeliveryItemInDeliveryFromJSONTyped,
    KvintaDeliveryItemInDeliveryToJSON,
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
    KvintaReferenceDocumentKey,
    KvintaReferenceDocumentKeyFromJSON,
    KvintaReferenceDocumentKeyFromJSONTyped,
    KvintaReferenceDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDelivery
 */
export interface KvintaDelivery {
    /**
     * 
     * @type {KvintaDocumentKey}
     * @memberof KvintaDelivery
     */
    document: KvintaDocumentKey;
    /**
     * 
     * @type {KvintaReferenceDocumentKey}
     * @memberof KvintaDelivery
     */
    referenceDocument?: KvintaReferenceDocumentKey | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDelivery
     */
    shipFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDelivery
     */
    shipTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDelivery
     */
    soldFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaDelivery
     */
    soldTo?: string | null;
    /**
     * 
     * @type {KvintaAlarmStatus}
     * @memberof KvintaDelivery
     */
    alarmStatus?: KvintaAlarmStatus | null;
    /**
     * 
     * @type {Array<KvintaDeliveryItemInDelivery>}
     * @memberof KvintaDelivery
     */
    items?: Array<KvintaDeliveryItemInDelivery> | null;
}

export function KvintaDeliveryFromJSON(json: any): KvintaDelivery {
    return KvintaDeliveryFromJSONTyped(json, false);
}

export function KvintaDeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDelivery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': KvintaDocumentKeyFromJSON(json['document']),
        'referenceDocument': !exists(json, 'referenceDocument') ? undefined : KvintaReferenceDocumentKeyFromJSON(json['referenceDocument']),
        'shipFrom': !exists(json, 'shipFrom') ? undefined : json['shipFrom'],
        'shipTo': !exists(json, 'shipTo') ? undefined : json['shipTo'],
        'soldFrom': !exists(json, 'soldFrom') ? undefined : json['soldFrom'],
        'soldTo': !exists(json, 'soldTo') ? undefined : json['soldTo'],
        'alarmStatus': !exists(json, 'alarmStatus') ? undefined : KvintaAlarmStatusFromJSON(json['alarmStatus']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(KvintaDeliveryItemInDeliveryFromJSON)),
    };
}

export function KvintaDeliveryToJSON(value?: KvintaDelivery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': KvintaDocumentKeyToJSON(value.document),
        'referenceDocument': KvintaReferenceDocumentKeyToJSON(value.referenceDocument),
        'shipFrom': value.shipFrom,
        'shipTo': value.shipTo,
        'soldFrom': value.soldFrom,
        'soldTo': value.soldTo,
        'alarmStatus': KvintaAlarmStatusToJSON(value.alarmStatus),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(KvintaDeliveryItemInDeliveryToJSON)),
    };
}


