/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageStatusType,
    KvintaMessageStatusTypeFromJSON,
    KvintaMessageStatusTypeFromJSONTyped,
    KvintaMessageStatusTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMessageStatusRecord
 */
export interface KvintaMessageStatusRecord {
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageStatusRecord
     */
    messageRecordId: string;
    /**
     * 
     * @type {KvintaMessageStatusType}
     * @memberof KvintaMessageStatusRecord
     */
    status: KvintaMessageStatusType;
    /**
     * 
     * @type {number}
     * @memberof KvintaMessageStatusRecord
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageStatusRecord
     */
    error?: string | null;
}

export function KvintaMessageStatusRecordFromJSON(json: any): KvintaMessageStatusRecord {
    return KvintaMessageStatusRecordFromJSONTyped(json, false);
}

export function KvintaMessageStatusRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMessageStatusRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageRecordId': json['messageRecordId'],
        'status': KvintaMessageStatusTypeFromJSON(json['status']),
        'timestamp': json['timestamp'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function KvintaMessageStatusRecordToJSON(value?: KvintaMessageStatusRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageRecordId': value.messageRecordId,
        'status': KvintaMessageStatusTypeToJSON(value.status),
        'timestamp': value.timestamp,
        'error': value.error,
    };
}


