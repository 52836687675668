/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaExecutionContext,
    KvintaExecutionContextFromJSON,
    KvintaExecutionContextFromJSONTyped,
    KvintaExecutionContextToJSON,
    KvintaListEpcisEventsRequest,
    KvintaListEpcisEventsRequestFromJSON,
    KvintaListEpcisEventsRequestFromJSONTyped,
    KvintaListEpcisEventsRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationRequestListEpcisEventsRequest
 */
export interface KvintaOperationRequestListEpcisEventsRequest {
    /**
     * 
     * @type {KvintaListEpcisEventsRequest}
     * @memberof KvintaOperationRequestListEpcisEventsRequest
     */
    input: KvintaListEpcisEventsRequest;
    /**
     * 
     * @type {KvintaExecutionContext}
     * @memberof KvintaOperationRequestListEpcisEventsRequest
     */
    context?: KvintaExecutionContext | null;
}

export function KvintaOperationRequestListEpcisEventsRequestFromJSON(json: any): KvintaOperationRequestListEpcisEventsRequest {
    return KvintaOperationRequestListEpcisEventsRequestFromJSONTyped(json, false);
}

export function KvintaOperationRequestListEpcisEventsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationRequestListEpcisEventsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'input': KvintaListEpcisEventsRequestFromJSON(json['input']),
        'context': !exists(json, 'context') ? undefined : KvintaExecutionContextFromJSON(json['context']),
    };
}

export function KvintaOperationRequestListEpcisEventsRequestToJSON(value?: KvintaOperationRequestListEpcisEventsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'input': KvintaListEpcisEventsRequestToJSON(value.input),
        'context': KvintaExecutionContextToJSON(value.context),
    };
}


