import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { LOCATION_FORM_ROOT_ID, LocationsStore, STORE_ID } from './LocationsStore';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { reaction } from 'mobx';
import { LoadingBackground } from '../../../components/LoadingBackground';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { tabs } from './tabs';
import styles from './Location.module.scss';
import { TextFieldWithLink } from '../../../components/FormFields/TextFieldWithLink';
import { TextField } from '../../../components/FormFields/TextField';
import { LocationForm } from './LocationForm';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';

interface ViewLocationSummaryProps {
  intl: any;
  locationsStore?: LocationsStore;
  sidebarStore?: KSidebarStore;
  id: string;
  historyStore: HistoryStore;
}

const LocationSummaryData = ({ formData, navigateBusinessPartnerPath }) => (
  <div className={styles.formContainer} id={'LocationSummaryID'}>
    <TextFieldWithLink
      value={formData.businessPartnerId}
      label="location-view.field.businessPartner"
      linkTo={() => navigateBusinessPartnerPath(formData.businessPartnerId)}
    />

    <TextField value={formData.name} label="location-view.field.name" />
    <TextField value={formData.sourceSystem} label="location-view.field.sourceSystem" />
    <TextField value={formData.idInSourceSystem} label="location-view.field.idInSourceSystem" />
    <TextField value={formData.gln13} label="location-view.field.gln13" />
    <TextField value={formData.sgln} label="location-view.field.sgln" />
    <TextField value={formData.latitude} label="location-view.field.latitude" />
    <TextField value={formData.longitude} label="location-view.field.longitude" />
    <TextField value={formData.country} label="location-create-form.field.country" />
    <TextField value={formData.city} label="location-view.field.city" />
    <TextField value={formData.postalCode} label="location-view.field.postalCode" />
    <TextField value={formData.address1} label="location-view.field.address1" />
    <TextField value={formData.address2} label="location-view.field.address2" />
    <TextField value={formData.address3} label="location-view.field.address3" />
    <TextField
      value={formData.description}
      label="location-view.field.description"
      style={{ gridColumn: '1 / span 2' }}
    />
  </div>
);

class ViewLocationSummaryImpl extends Component<ViewLocationSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.fetchLocation(this.props.id);
    this.disposer = reaction(
      () => this.props.locationsStore.currentLocation,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateLocationsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.locationsStore.closeLocationForm();
    this.disposer();
  }

  render() {
    const intl = this.props.intl;

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue) => {
            if (newValue == '0') {
              this.props.historyStore.navigateLocationsPath();
            } else if (newValue == '2') {
              this.props.historyStore.navigateLocationMapPath(this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabs}
        />

        {this.props.locationsStore.isLoading || !this.props.locationsStore.currentLocation ? (
          <LoadingBackground />
        ) : (
          <div className={styles.summaryMain}>
            <div className={styles.summaryButtonContainer}>
              {this.props.locationsStore.locationFormOpen && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloseIcon color="primary" />}
                  onClick={this.props.locationsStore.closeLocationForm}
                >
                  {intl.formatMessage({ id: 'button.cancel' })}
                </Button>
              )}
              {this.props.locationsStore.locationFormOpen ? (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.locationsStore.submitUpdateLocationForm}
                  icon={<DoneIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.confirm' })}
                  buttonState={formDataToButtonState(this.props.locationsStore.locationFormData[LOCATION_FORM_ROOT_ID])}
                />
              ) : (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.locationsStore.openUpdateLocationForm}
                  icon={<EditIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.edit' })}
                  buttonState={EButtonState.ACTIVE}
                />
              )}
            </div>
            {this.props.locationsStore.locationFormOpen ? (
              <LocationForm
                formData={this.props.locationsStore.locationFormData}
                onBlur={this.props.locationsStore.onBlurLocationField}
                onChange={this.props.locationsStore.onChangeLocationField}
                isUpdateLocation={true}
              />
            ) : (
              <LocationSummaryData
                formData={this.props.locationsStore.currentLocation}
                navigateBusinessPartnerPath={this.props.historyStore.navigateBusinessPartnerPath}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export const LocationSummary = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, SIDEBAR_STORE_ID)(observer(ViewLocationSummaryImpl)),
);
