import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { LocationsStore, STORE_ID } from './LocationsStore';
import styles from './Location.module.scss';

import {
  KSidebarStore,
  Map,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  TabBar,
} from 'kvinta/components';
import { reaction } from 'mobx';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { tabs } from './tabs';

interface ViewLocationMapProps {
  intl: any;
  locationsStore?: LocationsStore;
  pageContentStore: PageContentStore;
  sidebarStore?: KSidebarStore;
  historyStore: HistoryStore;
  id: string;
}

class LocationMapBase extends Component<ViewLocationMapProps> {
  disposer: any;
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.locationsStore.fetchLocation(this.props.id);
    this.disposer = reaction(
      () => this.props.locationsStore.currentLocation,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateLocationsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    if (!this.props.locationsStore.currentLocation) {
      return null;
    }
    const formData = this.props.locationsStore.currentLocation;

    const height = this.props.pageContentStore.height;
    if (!height) {
      return null;
    }
    const lat = formData.latitude;
    const long = formData.longitude;

    return (
      <div>
        <TabBar
          onChange={(newValue) => {
            if (newValue == '0') {
              this.props.historyStore.navigateLocationsPath();
            } else if (newValue == '1') {
              this.props.historyStore.navigateLocationPath(this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabs}
        />

        <div className={styles.mapContainer}>
          <Map
            size={{ height: this.props.pageContentStore.height + 'px', width: '100%' }}
            targetId={formData.gln13}
            markerLocation={[parseInt(long) || 0, parseInt(lat) || 0]}
          />
        </div>
      </div>
    );
  }
}

export const LocationMap = injectIntl(
  inject(HISTORY_STORE_ID, STORE_ID, PAGE_CONTENT_STORE_ID, SIDEBAR_STORE_ID)(observer(LocationMapBase)),
);
