import { action, makeObservable, observable } from 'mobx';
import { TItemMenu } from '../../../menu';

export type NavFunction = () => void;
export class KSidebarStore {
  isOpen = true;

  mainTitle: string | undefined;
  hasNavigation = false;
  navigateFunc: NavFunction | undefined;

  menuItems: TItemMenu[] = [];
  currentTitle = '';

  constructor(menuItems: TItemMenu[]) {
    makeObservable(this, {
      isOpen: observable,
      currentTitle: observable,
      mainTitle: observable,
      hasNavigation: observable,

      toggleOpen: action.bound,
      updateTitle: action.bound,
      updateMainTitle: action.bound,
      clearMainTitle: action.bound,
    });
    this.menuItems = menuItems;
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  updateTitle(title: string) {
    this.currentTitle = title;
  }

  updateMainTitle(title: string, navFunction: NavFunction) {
    this.mainTitle = title;
    this.hasNavigation = navFunction !== undefined;
    this.navigateFunc = navFunction;
  }
  clearMainTitle() {
    this.mainTitle = undefined;
    this.hasNavigation = false;
    this.navigateFunc = undefined;
  }
}

export const SIDEBAR_STORE_ID = 'sidebarStore';
