/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaAttributeConfiguration,
    KvintaAttributeConfigurationFromJSON,
    KvintaAttributeConfigurationToJSON,
} from '../models';

export interface ReadDeliveryItemAttributesConfigRequest {
    body: object;
}

export interface ReadLocationAttributesConfigRequest {
    body: object;
}

export interface ReadTradeItemAttributesConfigRequest {
    body: object;
}

export interface WriteDeliveryItemAttributesConfigRequest {
    requestBody: { [key: string]: KvintaAttributeConfiguration; };
}

export interface WriteLocationAttributesConfigRequest {
    requestBody: { [key: string]: KvintaAttributeConfiguration; };
}

export interface WriteTradeItemAttributesConfigRequest {
    requestBody: { [key: string]: KvintaAttributeConfiguration; };
}

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     */
    async readDeliveryItemAttributesConfigRaw(requestParameters: ReadDeliveryItemAttributesConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: KvintaAttributeConfiguration; }>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readDeliveryItemAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readDeliveryItemAttributesConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, KvintaAttributeConfigurationFromJSON));
    }

    /**
     */
    async readDeliveryItemAttributesConfig(requestParameters: ReadDeliveryItemAttributesConfigRequest): Promise<{ [key: string]: KvintaAttributeConfiguration; }> {
        const response = await this.readDeliveryItemAttributesConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readLocationAttributesConfigRaw(requestParameters: ReadLocationAttributesConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: KvintaAttributeConfiguration; }>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readLocationAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readLocationAttributesConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, KvintaAttributeConfigurationFromJSON));
    }

    /**
     */
    async readLocationAttributesConfig(requestParameters: ReadLocationAttributesConfigRequest): Promise<{ [key: string]: KvintaAttributeConfiguration; }> {
        const response = await this.readLocationAttributesConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readTradeItemAttributesConfigRaw(requestParameters: ReadTradeItemAttributesConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: KvintaAttributeConfiguration; }>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readTradeItemAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readTradeItemAttributesConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, KvintaAttributeConfigurationFromJSON));
    }

    /**
     */
    async readTradeItemAttributesConfig(requestParameters: ReadTradeItemAttributesConfigRequest): Promise<{ [key: string]: KvintaAttributeConfiguration; }> {
        const response = await this.readTradeItemAttributesConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeDeliveryItemAttributesConfigRaw(requestParameters: WriteDeliveryItemAttributesConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: KvintaAttributeConfiguration; }>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling writeDeliveryItemAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeDeliveryItemAttributesConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, KvintaAttributeConfigurationFromJSON));
    }

    /**
     */
    async writeDeliveryItemAttributesConfig(requestParameters: WriteDeliveryItemAttributesConfigRequest): Promise<{ [key: string]: KvintaAttributeConfiguration; }> {
        const response = await this.writeDeliveryItemAttributesConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeLocationAttributesConfigRaw(requestParameters: WriteLocationAttributesConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: KvintaAttributeConfiguration; }>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling writeLocationAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeLocationAttributesConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, KvintaAttributeConfigurationFromJSON));
    }

    /**
     */
    async writeLocationAttributesConfig(requestParameters: WriteLocationAttributesConfigRequest): Promise<{ [key: string]: KvintaAttributeConfiguration; }> {
        const response = await this.writeLocationAttributesConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeTradeItemAttributesConfigRaw(requestParameters: WriteTradeItemAttributesConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: KvintaAttributeConfiguration; }>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling writeTradeItemAttributesConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeTradeItemAttributesConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, KvintaAttributeConfigurationFromJSON));
    }

    /**
     */
    async writeTradeItemAttributesConfig(requestParameters: WriteTradeItemAttributesConfigRequest): Promise<{ [key: string]: KvintaAttributeConfiguration; }> {
        const response = await this.writeTradeItemAttributesConfigRaw(requestParameters);
        return await response.value();
    }

}
