import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import {
  DefaultApi as DevicesApi,
  KvintaAppVersion,
  KvintaDevice,
  KvintaMobileAppAssignment,
  KvintaOperationStatus,
  KvintaSortDirection,
  KvintaSortExpressions,
} from 'kvinta/apis/kvinta-devices-store';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import { EFilterType, IFilter } from '../../../components/FilterBar/FilterBar';
import { unique } from '../../../common/utils';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, select, textInput } from '../../../common/formUtils/formDataGenerators';
import { validateForm } from '../../../common/formUtils/core';
import { isNotEmpty, isValidEmailAddress, maxLength } from '../../../common/formUtils/validators';
import { TSelectInputData, TTextInputData } from '../../../common/formUtils/types';

export type TDeviceForm = {
  'deviceForm.email': TTextInputData;
  'deviceForm.locationGln13': TTextInputData;
  'deviceForm.serialNumber': TTextInputData;
};

export type TDeviceAssignmentForm = {
  'deviceAssignmentForm.appName': TSelectInputData;
  'deviceAssignmentForm.appVersion': TSelectInputData;
  'deviceAssignmentForm.email': TTextInputData;
  'deviceAssignmentForm.deviceId': TTextInputData;
};

export type TDevice = {
  id: string;
  email: string;
  locationGln13: string;
  serialNumber: string;
};

export type TDeviceAssignment = {
  appName: string;
  appVersion: string;
  email: string;
  deviceId: string;
  id: string;
};

interface IDeviceRow extends KvintaDevice, ISelectableRow {
  id: string;
}

export class DeviceStore extends SelectableStore<IDeviceRow> {
  private _config: TAppOptionsConfig;
  private _devicesApi: DevicesApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;
  page: number;
  totalCount: number;
  pageSize: number;
  searchValue: string;
  filter: IFilter;
  currentSort: KvintaSortExpressions;

  currentDevice?: TDevice;
  deviceFormOpen: boolean;
  deviceFormData?: TDeviceForm;

  deviceAssignmentList?: TDeviceAssignment[];
  currentDeviceAssignment?: TDeviceAssignment | null = null;
  apps: KvintaAppVersion[];
  deviceAssignmentFormData?: TDeviceAssignmentForm;
  deviceAssignmentFormOpen: boolean;

  exportActive: boolean;
  exportData: IDeviceRow[] | KvintaDevice[] | undefined;
  autofocusSearchInList: boolean;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, devicesApi: DevicesApi) {
    super();
    makeObservable(this, {
      filter: observable,
      fetchPage: action.bound,
      fetchDeviceList: action.bound,
      updateSearch: action.bound,
      unfocusSearchField: action.bound,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      searchValue: observable,

      deleteDevice: action.bound,

      fetchDevice: action.bound,
      currentDevice: observable,
      openCreateDeviceForm: action.bound,
      openUpdateDeviceForm: action.bound,
      deviceFormOpen: observable,
      deviceFormData: observable,
      onChangeDeviceFormField: action.bound,
      onBlurDeviceFormField: action.bound,
      submitDeviceForm: action.bound,
      submitUpdateDeviceForm: action.bound,
      submitCreateDeviceForm: action.bound,
      closeDeviceForm: action.bound,

      deviceAssignmentList: observable,
      fetchDeviceAssignmentList: action.bound,
      fetchDeviceAssignmentPage: action.bound,

      fetchDeviceAssignment: action.bound,
      currentDeviceAssignment: observable,
      deviceAssignmentFormData: observable,
      openCreateDeviceAssignmentForm: action.bound,
      openUpdateDeviceAssignmentForm: action.bound,
      submitDeviceAssignmentForm: action.bound,
      submitUpdateDeviceAssignmentForm: action.bound,
      submitCreateDeviceAssignmentForm: action.bound,
      closeDeviceAssignmentForm: action.bound,
      onChangeDeviceAssignmentFormField: action.bound,
      cleanCurrentSort: action.bound,
      deviceAssignmentFormOpen: observable,

      exportActive: observable,
      updateExported: action,
      exportAll: action.bound,
      exportSelected: action.bound,
    });

    this._config = config;
    this._devicesApi = devicesApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;
    this.currentSort = {
      expressions: [
        {
          direction: KvintaSortDirection.Desc,
          property: 'created',
        },
      ],
    };

    this.filter = new IFilter(
      [
        {
          type: EFilterType.TEXT_FILTER,
          id: 'email',
          label: 'devices-list.email', // TODO: List
          isActive: false,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'locationGln13',
          label: 'devices-list.locationGln13', // TODO: List
          isActive: true,
          value: '',
        },
        {
          type: EFilterType.TEXT_FILTER,
          id: 'serialNumber',
          label: 'devices-list.serialNumber',
          isActive: false,
          value: '',
        },
      ],
      this.doFilter,
    );
  }

  cleanCurrentSort() {
    this.currentSort = undefined;
  }

  doFilter = async () => {
    this.isLoading = true;
    this.page = 0;

    this.fetchDeviceList();
  };

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;
    this.fetchDeviceList();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchDeviceList();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const field = getField(orderBy);
    this.currentSort = {
      expressions: [
        {
          property: field,
          direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
        },
      ],
    };
    this.fetchDeviceList();
  }

  async fetchDeviceList() {
    this.isLoading = true;
    this.currentDevice = undefined;
    this.listData = [];
    this.totalCount = 0;
    const filters = this.filter.visibleFilters.reduce((acc, filter) => {
      if (filter.type === EFilterType.TEXT_FILTER && filter.value.trim() !== '') {
        acc[filter.id] = filter.value;
      } else if (filter.type === EFilterType.SELECT_FILTER && filter.value !== 'default') {
        acc[filter.id] = filter.value;
      }
      return acc;
    }, {});
    this._devicesApi
      .listDevices({
        kvintaOperationRequestListDevicesRequest: {
          input: {
            pagination: { page: this.page, size: this.pageSize },
            sort: this.currentSort,
            filter: filters,
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Error) {
          this.listData = [];
          this._notificationManager.sendError(result.error);
        } else {
          this.totalCount = result.data.total || 0;
          this.listData = (result.data.list || []).map((comp) => {
            return { ...comp } as IDeviceRow;
          });
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openCreateDeviceForm() {
    const initialValues = { email: '', locationGln13: '', serialNumber: '', id: '' };
    this.deviceFormData = validateForm<TDeviceForm>(generateDeviceFormData(DEVICE_FORM_ROOT_ID, initialValues));

    this.deviceFormOpen = true;
  }

  submitCreateDeviceForm = () => {
    return this.submitDeviceForm(
      () =>
        this._devicesApi.createDevice({
          kvintaOperationRequestCreateDeviceRequest: {
            input: {
              ...transformDeviceFormData(this.deviceFormData),
            },
          },
        }),
      this.fetchDeviceList,
    );
  };

  openUpdateDeviceForm() {
    this.deviceFormData = validateForm(generateDeviceFormData(DEVICE_FORM_ROOT_ID, this.currentDevice));

    this.deviceFormOpen = true;
  }

  closeDeviceForm() {
    this.deviceFormData = undefined;
    this.deviceFormOpen = false;
  }

  onChangeDeviceFormField(id: string, value: any) {
    const formData = toJS(this.deviceFormData);
    this.deviceFormData = handleFormChange(formData, id, value);
  }

  onBlurDeviceFormField(id: string) {
    const formData = toJS(this.deviceFormData);
    this.deviceFormData = handleFormBlur(formData, id);
  }

  submitUpdateDeviceForm = (): Promise<unknown> => {
    return this.submitDeviceForm(
      () =>
        this._devicesApi.updateDevice({
          kvintaOperationRequestDevice: {
            input: {
              id: this.currentDevice.id,
              ...transformDeviceFormData(this.deviceFormData),
            },
          },
        }),
      () => this.fetchDevice(this.currentDevice.id),
    );
  };

  submitDeviceForm = (action, nextAction): Promise<unknown> => {
    this.isLoading = true;

    return action()
      .then(async (response) => {
        if (response.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendSuccess(`Successfully submitted device data`);
          await nextAction();
          this.closeDeviceForm();
        } else {
          this._notificationManager.sendError(`An error occurred while submitting data\n${response.error}`);
        }
      })
      .catch((err: Error) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  deleteDevice(deviceId) {
    this.isLoading = true;
    return this._devicesApi
      .deleteDevice({
        kvintaOperationRequestString: {
          input: deviceId,
        },
      })
      .then(async (response) => {
        if (response.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendSuccess(`Device ${deviceId} deleted`);
          await this.fetchDeviceList();
        } else {
          this._notificationManager.sendError(`An error occurred while deleting device ${deviceId}\n${response.error}`);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while deleting device ${deviceId}\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    this.exportActive = true;
    this.exportData = this.listChecked;
  }

  async exportAll() {
    this.exportActive = false;
    this.exportData = undefined;

    try {
      const deviceListResult = await this._devicesApi.listDevices({
        kvintaOperationRequestListDevicesRequest: {
          input: {
            pagination: { page: this.page, size: this.pageSize },
          },
        },
      });
      this.exportActive = true;
      this.exportData = deviceListResult.data.list;
    } catch (err) {
      this._notificationManager.sendError(err.message);
    }
  }

  async fetchDevice(id: string) {
    this.isLoading = true;
    this.currentDevice = null;

    this._devicesApi
      .getDevice({
        kvintaOperationRequestString: { input: id },
      })
      .then((result) => {
        if (result.status !== KvintaOperationStatus.Ok) {
          this._notificationManager.sendError(`An error occurred while loading data\n${result.error}`);
        } else if (!result.data) {
          this._notificationManager.sendError('Device not found');
        } else {
          this.currentDevice = {
            id: result.data.id || '',
            email: result.data.email || '',
            locationGln13: result.data.locationGln13 || '',
            serialNumber: result.data.serialNumber || '',
          };
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while loading data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async fetchDeviceAssignmentPage(page: number, curDeviceId: string) {
    this.isLoading = true;
    this.page = page;

    this.fetchDeviceAssignmentList(curDeviceId);
  }

  async changeDeviceAssignmentOrder(orderBy: number, orderDirection: 'asc' | 'desc', curDeviceId: string) {
    this.isLoading = true;
    const field = getAssignmentField(orderBy);
    this.currentSort = {
      expressions: [
        {
          property: field,
          direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
        },
      ],
    };
    this.fetchDeviceAssignmentList(curDeviceId);
  }

  async fetchDeviceAssignmentList(curDeviceId: string) {
    this.isLoading = true;
    this.deviceAssignmentList = [];

    await this.fetchDevice(curDeviceId);

    await this._devicesApi
      .listMobileAppAssignments({
        kvintaOperationRequestListMobileAppAssignmentRequest: {
          input: {
            filter: {
              deviceId: curDeviceId,
            },
            pagination: { page: this.page, size: this.pageSize },
            sort: this.currentSort,
          },
        },
      })
      .then((result) => {
        if (result.status === KvintaOperationStatus.Error) {
          this.deviceAssignmentList = [];
          this._notificationManager.sendError(result.error);
        } else {
          this.deviceAssignmentList = (result.data.list || []).map((comp) => {
            return { ...comp } as KvintaMobileAppAssignment;
          });
          this.totalCount = result.data.total || 0;
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(JSON.stringify(err));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openCreateDeviceAssignmentForm() {
    this.isLoading = true;

    this._devicesApi
      .getApplications()
      .then((result) => {
        if (result.status === KvintaOperationStatus.Ok) {
          const initialValues = {
            appName: '',
            appVersion: '',
            email: this.currentDevice.email,
            deviceId: this.currentDevice.id,
            array1: [],
          };

          this.deviceAssignmentFormData = validateForm<TDeviceAssignmentForm>(
            generateDeviceAssignmentFormData(DEVICE_ASSIGNMENT_FORM_ROOT_ID, result.data.applications, initialValues),
          );

          this.deviceAssignmentFormOpen = true;
        } else {
          this._notificationManager.sendError(`An error occurred while loading data\n${result.error}`);
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while loading data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openUpdateDeviceAssignmentForm() {
    const initialValues = {
      appName: this.currentDeviceAssignment.appName,
      appVersion: this.currentDeviceAssignment.appVersion,
      email: this.currentDeviceAssignment.email,
      deviceId: this.currentDeviceAssignment.deviceId,
    };
    this.deviceAssignmentFormData = validateForm(
      generateDeviceAssignmentFormData(DEVICE_ASSIGNMENT_FORM_ROOT_ID, this.apps, initialValues),
    );

    this.deviceAssignmentFormOpen = true;
  }

  closeDeviceAssignmentForm() {
    this.deviceAssignmentFormOpen = false;
    this.deviceAssignmentFormData = undefined;
  }

  submitDeviceAssignmentForm = (action, nextAction) => {
    this.isLoading = true;

    action()
      .then(async (response) => {
        if (response.status === KvintaOperationStatus.Ok) {
          this.deviceAssignmentFormOpen = false;
          this._notificationManager.sendSuccess(`Successfully submitted device assignment`);
          await nextAction();
        } else {
          this._notificationManager.sendError(`An error occurred while submitting data\n${response.error}`);
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  submitCreateDeviceAssignmentForm = () => {
    this.submitDeviceAssignmentForm(
      () =>
        this._devicesApi.createMobileAppAssignment({
          kvintaOperationRequestCreateMobileAppAssignmentRequest: {
            input: transformDeviceAssignmentFormData(this.deviceAssignmentFormData),
          },
        }),
      () => this.fetchDeviceAssignmentList(this.currentDevice.id),
    );
  };

  onChangeDeviceAssignmentFormField = (id: string, value: any) => {
    let formData = toJS(this.deviceAssignmentFormData);
    if (id === 'deviceAssignmentForm.appName') {
      const newAppVersionOptions = getAppVersionOptions(this.apps, value) || [];
      formData = {
        ...formData,
        'deviceAssignmentForm.appVersion': {
          ...formData['deviceAssignmentForm.appVersion'],
          options: newAppVersionOptions,
          value: newAppVersionOptions[0]?.key || '',
        },
      };
    }
    this.deviceAssignmentFormData = handleFormChange(formData, id, value);
  };

  onBlurDeviceAssignmentFormField = (id: string) => {
    const formData = toJS(this.deviceAssignmentFormData);
    this.deviceAssignmentFormData = handleFormBlur(formData, id);
  };

  submitUpdateDeviceAssignmentForm = () => {
    this.submitDeviceAssignmentForm(
      () =>
        this._devicesApi.updateMobileAppAssignment({
          kvintaOperationRequestMobileAppAssignment: {
            input: {
              ...transformDeviceAssignmentFormData(this.deviceAssignmentFormData),
              id: this.currentDeviceAssignment.id,
            },
          },
        }),
      () => this.fetchDeviceAssignment(this.currentDeviceAssignment.id),
    );
  };

  async fetchDeviceAssignment(id: string) {
    this.isLoading = true;
    this.currentDeviceAssignment = null;

    Promise.all([
      this._devicesApi.getApplications(),
      this._devicesApi.getMobileAppAssignment({ kvintaOperationRequestString: { input: id } }),
    ])
      .then(([appResponse, deviceAssignmentResponse]) => {
        if (
          !(
            deviceAssignmentResponse.status === KvintaOperationStatus.Ok &&
            appResponse.status === KvintaOperationStatus.Ok
          )
        ) {
          const errors = [appResponse.error, deviceAssignmentResponse.error].filter((err) => Boolean(err)).join('\n');
          this._notificationManager.sendError(`An error occurred while fetching data\n${errors}`);
        } else if (!deviceAssignmentResponse.data) {
          this._notificationManager.sendError('Assignment not found');
        } else {
          this.apps = appResponse.data.applications;
          this.currentDeviceAssignment = {
            appName: deviceAssignmentResponse.data.appName || '',
            appVersion: deviceAssignmentResponse.data.appVersion || '',
            deviceId: deviceAssignmentResponse.data.deviceId || '',
            email: deviceAssignmentResponse.data.email || '',
            id: deviceAssignmentResponse.data.id || '',
          };
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  sendInvitation = async (): Promise<void> => {
    this.isLoading = true;
    this._devicesApi
      .sendDownloadLink({
        kvintaOperationRequestMobileAppAssignment: {
          input: this.currentDeviceAssignment,
        },
      })
      .then((response) => {
        if (response.status === KvintaOperationStatus.Ok) {
          this._notificationManager.sendSuccess(`Successfully sent email to ${this.currentDeviceAssignment.email}`);
        } else {
          this._notificationManager.sendError(
            `An error occurred while sending email to ${this.currentDeviceAssignment.email}\n${response.error}`,
          );
        }
      })
      .catch((err) => {
        this._notificationManager.sendError(
          `An error occurred while sending email to ${this.currentDeviceAssignment.email}\n${err.message}`,
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}

export const DEVICE_STORE_ID = 'deviceStore';
export const DEVICE_FORM_ROOT_ID = 'deviceForm';
export const DEVICE_ASSIGNMENT_FORM_ROOT_ID = 'deviceAssignmentForm';

function getField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'id';
    case 1:
      return 'email';
    case 2:
      return 'locationGln13';
    case 3:
      return 'serialNumber';
    default:
      return 'id';
  }
}

function getAssignmentField(orderBy: number): string {
  switch (orderBy) {
    case 0:
      return 'appName';
    case 1:
      return 'appVersion';
    case 2:
      return 'deviceId';
    case 3:
      return 'email';
    default:
      return 'appName';
  }
}

function transformDeviceFormData(deviceFormData) {
  return {
    email: deviceFormData['deviceForm.email'].value,
    locationGln13: deviceFormData['deviceForm.locationGln13'].value,
    serialNumber: deviceFormData['deviceForm.serialNumber'].value,
  };
}

function transformDeviceAssignmentFormData(deviceAssignmentFormData) {
  return {
    deviceId: deviceAssignmentFormData['deviceAssignmentForm.deviceId'].value,
    appName: deviceAssignmentFormData['deviceAssignmentForm.appName'].value,
    appVersion: deviceAssignmentFormData['deviceAssignmentForm.appVersion'].value,
    email: deviceAssignmentFormData['deviceAssignmentForm.email'].value,
  };
}

function generateDeviceFormData(rootFormId: string, initialValues: TDevice) {
  return formRoot<TDeviceForm>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'email',
        value: initialValues.email,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isValidEmailAddress({ errorMessage: 'please provide a valid email' }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'serialNumber',
        value: initialValues.serialNumber,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'locationGln13',
        value: initialValues.locationGln13,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
    ],
  });
}

function generateDeviceAssignmentFormData(
  rootFormId: string,
  apps,
  initialValues: {
    appName: string;
    appVersion: string;
    email: string;
    deviceId: string;
  },
) {
  const appVersionOptions = getAppVersionOptions(apps, initialValues.appName);
  const appNameOptions = getAppNameOptions(apps);

  return formRoot<TDeviceAssignmentForm>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'deviceId',
        value: initialValues.deviceId,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'email',
        value: initialValues.email,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isValidEmailAddress({ errorMessage: 'please provide a valid email' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      select({
        path: 'appName',
        value: appNameOptions.find((option) => option.key === initialValues.appName) ? initialValues.appName : '',
        options: appNameOptions,
        validations: [isNotEmpty({ errorMessage: 'this value is mandatory' })],
        isRequiredField: true,
      }),
      select({
        path: 'appVersion',
        value: appVersionOptions.find((option) => option.key === initialValues.appVersion)
          ? initialValues.appVersion
          : '',
        options: appVersionOptions,
        validations: [isNotEmpty({ errorMessage: 'this value is mandatory' })],
        isRequiredField: true,
      }),
    ],
  });
}

function getAppVersionOptions(apps: KvintaAppVersion[], appName: string) {
  return apps
    .filter((app) => app.applicationName === appName)
    .map((app) => app.applicationVersion)
    .filter(unique)
    .map((appVersion) => ({ key: appVersion, label: appVersion }))
    .sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
}
function getAppNameOptions(apps: KvintaAppVersion[]) {
  return apps
    .map((app) => app.applicationName)
    .filter(unique)
    .map((appName) => ({ key: appName, label: appName }));
}
