/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAutoCommissionMode,
    KvintaAutoCommissionModeFromJSON,
    KvintaAutoCommissionModeFromJSONTyped,
    KvintaAutoCommissionModeToJSON,
    KvintaSingleMobileTransactionConfig,
    KvintaSingleMobileTransactionConfigFromJSON,
    KvintaSingleMobileTransactionConfigFromJSONTyped,
    KvintaSingleMobileTransactionConfigToJSON,
    KvintaValidationLevel,
    KvintaValidationLevelFromJSON,
    KvintaValidationLevelFromJSONTyped,
    KvintaValidationLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMobileConfig
 */
export interface KvintaMobileConfig {
    /**
     * 
     * @type {KvintaAutoCommissionMode}
     * @memberof KvintaMobileConfig
     */
    autoCommissionMode?: KvintaAutoCommissionMode | null;
    /**
     * 
     * @type {KvintaValidationLevel}
     * @memberof KvintaMobileConfig
     */
    existCodesValidationLevel?: KvintaValidationLevel | null;
    /**
     * 
     * @type {KvintaValidationLevel}
     * @memberof KvintaMobileConfig
     */
    existCodesPackingValidationLevel?: KvintaValidationLevel | null;
    /**
     * 
     * @type {KvintaValidationLevel}
     * @memberof KvintaMobileConfig
     */
    existSsccContentValidationLevel?: KvintaValidationLevel | null;
    /**
     * 
     * @type {KvintaValidationLevel}
     * @memberof KvintaMobileConfig
     */
    checkDispositionValidationLevel?: KvintaValidationLevel | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMobileConfig
     */
    ignoreUnknownDisposition: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMobileConfig
     */
    skipDeliveryContentValidation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMobileConfig
     */
    skipUnknownDeliveryValidation: boolean;
    /**
     * 
     * @type {KvintaValidationLevel}
     * @memberof KvintaMobileConfig
     */
    deliveryContentValidationLevel?: KvintaValidationLevel | null;
    /**
     * 
     * @type {KvintaValidationLevel}
     * @memberof KvintaMobileConfig
     */
    customsNumberValidationLevel?: KvintaValidationLevel | null;
    /**
     * 
     * @type {{ [key: string]: KvintaSingleMobileTransactionConfig; }}
     * @memberof KvintaMobileConfig
     */
    mobileTransactionsConfig?: { [key: string]: KvintaSingleMobileTransactionConfig; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaMobileConfig
     */
    hideTransactions?: Array<string> | null;
}

export function KvintaMobileConfigFromJSON(json: any): KvintaMobileConfig {
    return KvintaMobileConfigFromJSONTyped(json, false);
}

export function KvintaMobileConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMobileConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoCommissionMode': !exists(json, 'autoCommissionMode') ? undefined : KvintaAutoCommissionModeFromJSON(json['autoCommissionMode']),
        'existCodesValidationLevel': !exists(json, 'existCodesValidationLevel') ? undefined : KvintaValidationLevelFromJSON(json['existCodesValidationLevel']),
        'existCodesPackingValidationLevel': !exists(json, 'existCodesPackingValidationLevel') ? undefined : KvintaValidationLevelFromJSON(json['existCodesPackingValidationLevel']),
        'existSsccContentValidationLevel': !exists(json, 'existSsccContentValidationLevel') ? undefined : KvintaValidationLevelFromJSON(json['existSsccContentValidationLevel']),
        'checkDispositionValidationLevel': !exists(json, 'checkDispositionValidationLevel') ? undefined : KvintaValidationLevelFromJSON(json['checkDispositionValidationLevel']),
        'ignoreUnknownDisposition': json['ignoreUnknownDisposition'],
        'skipDeliveryContentValidation': json['skipDeliveryContentValidation'],
        'skipUnknownDeliveryValidation': json['skipUnknownDeliveryValidation'],
        'deliveryContentValidationLevel': !exists(json, 'deliveryContentValidationLevel') ? undefined : KvintaValidationLevelFromJSON(json['deliveryContentValidationLevel']),
        'customsNumberValidationLevel': !exists(json, 'customsNumberValidationLevel') ? undefined : KvintaValidationLevelFromJSON(json['customsNumberValidationLevel']),
        'mobileTransactionsConfig': !exists(json, 'mobileTransactionsConfig') ? undefined : (json['mobileTransactionsConfig'] === null ? null : mapValues(json['mobileTransactionsConfig'], KvintaSingleMobileTransactionConfigFromJSON)),
        'hideTransactions': !exists(json, 'hideTransactions') ? undefined : json['hideTransactions'],
    };
}

export function KvintaMobileConfigToJSON(value?: KvintaMobileConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autoCommissionMode': KvintaAutoCommissionModeToJSON(value.autoCommissionMode),
        'existCodesValidationLevel': KvintaValidationLevelToJSON(value.existCodesValidationLevel),
        'existCodesPackingValidationLevel': KvintaValidationLevelToJSON(value.existCodesPackingValidationLevel),
        'existSsccContentValidationLevel': KvintaValidationLevelToJSON(value.existSsccContentValidationLevel),
        'checkDispositionValidationLevel': KvintaValidationLevelToJSON(value.checkDispositionValidationLevel),
        'ignoreUnknownDisposition': value.ignoreUnknownDisposition,
        'skipDeliveryContentValidation': value.skipDeliveryContentValidation,
        'skipUnknownDeliveryValidation': value.skipUnknownDeliveryValidation,
        'deliveryContentValidationLevel': KvintaValidationLevelToJSON(value.deliveryContentValidationLevel),
        'customsNumberValidationLevel': KvintaValidationLevelToJSON(value.customsNumberValidationLevel),
        'mobileTransactionsConfig': value.mobileTransactionsConfig === undefined ? undefined : (value.mobileTransactionsConfig === null ? null : mapValues(value.mobileTransactionsConfig, KvintaSingleMobileTransactionConfigToJSON)),
        'hideTransactions': value.hideTransactions,
    };
}


