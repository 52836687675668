import { History } from 'history';
import { generateEpcRecordPath } from './utils';
import { action, makeObservable } from 'mobx';

export enum EPaths {
  MainPath = '/',
  NotFoundPath = '*',

  EpcisEventSetListPath = '/epcis-event-sets',
  EpcisEventSetSummaryPath = '/epcis-event-sets/:id',
  EpcisEventSetJsonPath = '/epcis-event-sets/:id/json',

  EpcisMessageListPath = '/epcis-messages',
  EpcisMessageSummaryPath = '/epcis-messages/:id',

  BusinessPartnersPath = '/business-partners',
  BusinessPartnerPath = '/business-partners/:id',

  LocationsPath = '/locations',
  LocationPath = '/locations/:id',
  LocationReportingPath = '/locations/:id/reporting',
  LocationMapPath = '/locations/:id/map',

  ProductsPath = '/products',
  ProductPath = '/products/:id',
  ProductTradeItemsPath = '/products/:id/trade-items',
  ProductTradeItemPath = '/products/:id/trade-items/:tradeId',

  TradeItemsPath = '/trade-items',
  TradeItemPath = '/trade-items/:id',

  DeliveryNotesPath = '/delivery-notes',
  DeliveryNotePath = '/delivery-notes/:id',
  DeliveryNoteDeliveryItemsPath = '/delivery-notes/:id/delivery-items',
  DeliveryNoteDeliveryItemPath = '/delivery-notes/:id/delivery-items/:itemId',

  DevicesPath = '/devices',
  DeviceSummaryPath = '/devices/:id',
  DeviceAssignmentsPath = '/devices/:id/assignments',
  DeviceAssignmentSummaryPath = '/devices/:id/assignment/:assignId',

  EpcisEventsPath = '/epcis-events',
  EpcisEventPath = '/epcis-events/:id',
  EpcisEventJsonPath = '/epcis-events/:id/json',
  EpcisEventEpcListPath = '/epcis-events/:id/epcs',

  EpcMessagesPath = '/epc-list',
  EpcMessagePath = '/epc-list/:id/:ts/:index',
  EpcMessageHierarchyPath = '/epc-list/:id/:ts/:index/hierarchy',
  EpcMessageMapPath = '/epc-list/:id/:ts/:index/map',

  NotificationListPath = '/notifications',
  NotificationByGln13Path = '/notifications/gln13/:gln13',

  AlarmsListPath = '/alarms',
}

export class HistoryStore {
  private _history: History<unknown>;
  private _currentRoute;

  constructor(history) {
    this._history = history;

    makeObservable(this, {
      navigateEpcisEventSetListPath: action.bound,
      navigateEpcisEventSetSummaryPath: action.bound,
      navigateEpcisMessageSummaryPath: action.bound,
      navigateEpcisMessageListPath: action.bound,
      navigateBusinessPartnersPath: action.bound,
      navigateLocationsPath: action.bound,
      navigateLocationPath: action.bound,
      navigateReportingPath: action.bound,
      navigateLocationMapPath: action.bound,
      navigateProductsPath: action.bound,
      navigateProductPath: action.bound,
      navigateProductTradeItemsPath: action.bound,
      navigateProductTradeItemPath: action.bound,
      navigateTradeItemsPath: action.bound,
      navigateTradeItemPath: action.bound,
      navigateDeliveryNotePath: action.bound,
      navigateDeliveryNoteDeliveryItemsPath: action.bound,
      navigateDeliveryNotesPath: action.bound,
      navigateDeliveryNoteDeliveryItemPath: action.bound,
      navigateDevicesPath: action.bound,
      navigateDeviceSummaryPath: action.bound,
      linkDevicePath: action.bound,
      navigateDeviceAssignmentsPath: action.bound,
      navigateDeviceAssignmentSummaryPath: action.bound,
      navigateEpcisEventsPath: action.bound,
      navigateEpcisEventPath: action.bound,
      navigateEpcisEventJsonPath: action.bound,
      navigateEpcMessagesPath: action.bound,
      navigateEpcMessagePath: action.bound,
      navigateEpcMessageHierarchyPath: action.bound,
      navigateEpcMessageMapPath: action.bound,
      navigateEpcTab: action.bound,

      saveCurrentRoute: action.bound,
      getCurrentRoute: action.bound,
    });
  }

  get history() {
    return this._history;
  }

  saveCurrentRoute = (route) => {
    this._currentRoute = route;
  };

  getCurrentRoute = () => {
    return this._currentRoute;
  };

  navigateEpcisEventSetListPath = () => {
    this._history.push(EPaths.EpcisEventSetListPath);
  };

  navigateEpcisEventSetSummaryPath = (msgId: string) => {
    this._history.push(EPaths.EpcisEventSetSummaryPath.replaceAll(':id', msgId));
  };

  navigateEpcisEventSetJsonPath = (msgId: string) => {
    this._history.push(EPaths.EpcisEventSetJsonPath.replaceAll(':id', msgId));
  };

  navigateEpcisMessageSummaryPath = (msgId: string) => {
    this._history.push(EPaths.EpcisMessageSummaryPath.replaceAll(':id', msgId));
  };

  navigateEpcisMessageListPath = () => {
    this._history.push(EPaths.EpcisMessageListPath);
  };

  navigateBusinessPartnersPath = () => {
    this._history.push(EPaths.BusinessPartnersPath);
  };

  navigateBusinessPartnerPath = (id: string) => {
    this._history.push(EPaths.BusinessPartnerPath.replaceAll(':id', id));
  };
  navigateLocationsPath = () => {
    this._history.push(EPaths.LocationsPath);
  };

  navigateLocationPath = (id: string) => {
    this._history.push(EPaths.LocationPath.replaceAll(':id', id));
  };

  navigateReportingPath = (id: string) => {
    this._history.push(EPaths.LocationReportingPath.replaceAll(':id', id));
  };

  navigateLocationMapPath = (id: string) => {
    this._history.push(EPaths.LocationMapPath.replaceAll(':id', id));
  };
  navigateProductsPath = () => {
    this._history.push(EPaths.ProductsPath);
  };

  navigateProductPath = (id: string) => {
    this._history.push(EPaths.ProductPath.replaceAll(':id', id));
  };

  navigateProductTradeItemsPath = (id: string) => {
    this._history.push(EPaths.ProductTradeItemsPath.replaceAll(':id', id));
  };

  navigateProductTradeItemPath = (id: string, tradeItemId: string) => {
    this._history.push(
      EPaths.ProductTradeItemPath.replaceAll(':id', id).replaceAll(':tradeId', encodeURIComponent(tradeItemId)),
    );
  };

  navigateTradeItemsPath = () => {
    this._history.push(EPaths.TradeItemsPath);
  };

  navigateTradeItemPath = (id: string) => {
    this._history.push(EPaths.TradeItemPath.replaceAll(':id', encodeURIComponent(id)));
  };

  navigateDeliveryNotePath = (id: string) => {
    this._history.push(EPaths.DeliveryNotePath.replaceAll(':id', id));
  };

  navigateDeliveryNoteDeliveryItemsPath = (id: string) => {
    console.log(this._history);
    this._history.push(EPaths.DeliveryNoteDeliveryItemsPath.replaceAll(':id', id));
  };

  navigateDeliveryNotesPath = () => {
    this._history.push(EPaths.DeliveryNotesPath);
  };

  navigateDeliveryNoteDeliveryItemPath = (id: string, deliveryItemId: string) => {
    this._history.push(
      EPaths.DeliveryNoteDeliveryItemPath.replaceAll(':id', id).replaceAll(
        ':itemId',
        encodeURIComponent(deliveryItemId),
      ),
    );
  };

  navigateDevicesPath = () => {
    this._history.push(EPaths.DevicesPath);
  };

  navigateDeviceSummaryPath = (id: string) => {
    this._history.push(EPaths.DeviceSummaryPath.replaceAll(':id', id));
  };
  linkDevicePath = (id: string): string => {
    return EPaths.DeviceSummaryPath.replaceAll(':id', id);
  };

  navigateDeviceAssignmentsPath = (id: string) => {
    this._history.push(EPaths.DeviceAssignmentsPath.replaceAll(':id', id));
  };

  navigateDeviceAssignmentSummaryPath = (id: string, assignId: string) => {
    this._history.push(EPaths.DeviceAssignmentSummaryPath.replaceAll(':id', id).replaceAll(':assignId', assignId));
  };

  navigateEpcisEventsPath = () => {
    this._history.push(EPaths.EpcisEventsPath);
  };

  navigateEpcisEventPath = (msgId: string) => {
    this._history.push(EPaths.EpcisEventPath.replaceAll(':id', msgId));
  };

  navigateEpcisEventEpcListPath = (msgId: string) => {
    this._history.push(EPaths.EpcisEventEpcListPath.replaceAll(':id', msgId));
  };
  navigateEpcisEventJsonPath = (msgId: string) => {
    this._history.push(EPaths.EpcisEventJsonPath.replaceAll(':id', msgId));
  };

  navigateEpcMessagesPath = () => {
    this._history.push(EPaths.EpcMessagesPath);
  };

  navigateEpcMessagePath = (msgId: string, ts: number, idx?: number) => {
    this._history.push(generateEpcRecordPath(EPaths.EpcMessagePath, msgId, ts, idx));
  };

  navigateEpcMessageHierarchyPath = (msgId: string, ts: number, idx?: number) => {
    this._history.push(generateEpcRecordPath(EPaths.EpcMessageHierarchyPath, msgId, ts, idx));
  };

  navigateEpcMessageMapPath = (msgId: string, ts: number, idx?: number) => {
    this._history.push(generateEpcRecordPath(EPaths.EpcMessageMapPath, msgId, ts, idx));
  };

  navigateNotificationByGln13Path = (gln13) => {
    this._history.push(EPaths.NotificationByGln13Path.replaceAll(':gln13', gln13));
  };

  navigateEpcTab = (tabId: string, currentEpcId: string, currentTs: number, currentIdx?: number) => {
    const newValue = parseInt(tabId);
    switch (newValue) {
      case 0: {
        this.navigateEpcMessagesPath();
        break;
      }
      case 1: {
        this.navigateEpcMessagePath(currentEpcId, currentTs, currentIdx);
        break;
      }
      case 2: {
        this.navigateEpcMessageMapPath(currentEpcId, currentTs, currentIdx);
        break;
      }
      case 3: {
        this.navigateEpcMessageHierarchyPath(currentEpcId, currentTs, currentIdx);
        break;
      }
      default:
    }
  };
}

export const STORE_ID = 'historyStore';
