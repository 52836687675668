/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaActivityConfiguration,
    KvintaActivityConfigurationFromJSON,
    KvintaActivityConfigurationFromJSONTyped,
    KvintaActivityConfigurationToJSON,
    KvintaDuration,
    KvintaDurationFromJSON,
    KvintaDurationFromJSONTyped,
    KvintaDurationToJSON,
    KvintaSearchAttributeConfiguration,
    KvintaSearchAttributeConfigurationFromJSON,
    KvintaSearchAttributeConfigurationFromJSONTyped,
    KvintaSearchAttributeConfigurationToJSON,
    KvintaTaskQueueConfiguration,
    KvintaTaskQueueConfigurationFromJSON,
    KvintaTaskQueueConfigurationFromJSONTyped,
    KvintaTaskQueueConfigurationToJSON,
    KvintaWorkflowConfiguration,
    KvintaWorkflowConfigurationFromJSON,
    KvintaWorkflowConfigurationFromJSONTyped,
    KvintaWorkflowConfigurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTemporalConfiguration
 */
export interface KvintaTemporalConfiguration {
    /**
     * 
     * @type {string}
     * @memberof KvintaTemporalConfiguration
     */
    serverUrl?: string | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaTemporalConfiguration
     */
    retentionPeriod?: KvintaDuration | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaTemporalConfiguration
     */
    taskQueue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaTemporalConfiguration
     */
    namespace?: string | null;
    /**
     * 
     * @type {Array<KvintaSearchAttributeConfiguration>}
     * @memberof KvintaTemporalConfiguration
     */
    searchAttributes?: Array<KvintaSearchAttributeConfiguration> | null;
    /**
     * 
     * @type {{ [key: string]: KvintaWorkflowConfiguration; }}
     * @memberof KvintaTemporalConfiguration
     */
    workflows?: { [key: string]: KvintaWorkflowConfiguration; } | null;
    /**
     * 
     * @type {{ [key: string]: KvintaActivityConfiguration; }}
     * @memberof KvintaTemporalConfiguration
     */
    activities?: { [key: string]: KvintaActivityConfiguration; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTemporalConfiguration
     */
    configureClusterOnStart?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTemporalConfiguration
     */
    taskQueueActivitiesPerSecond?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTemporalConfiguration
     */
    maxActivitiesPerSecond?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTemporalConfiguration
     */
    maxConcurrentActivityExecutionSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTemporalConfiguration
     */
    workflowPollThreadCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaTemporalConfiguration
     */
    activityPollThreadCount?: number | null;
    /**
     * 
     * @type {{ [key: string]: KvintaTaskQueueConfiguration; }}
     * @memberof KvintaTemporalConfiguration
     */
    taskQueues?: { [key: string]: KvintaTaskQueueConfiguration; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTemporalConfiguration
     */
    disableSearchAttributes?: boolean | null;
}

export function KvintaTemporalConfigurationFromJSON(json: any): KvintaTemporalConfiguration {
    return KvintaTemporalConfigurationFromJSONTyped(json, false);
}

export function KvintaTemporalConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTemporalConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serverUrl': !exists(json, 'serverUrl') ? undefined : json['serverUrl'],
        'retentionPeriod': !exists(json, 'retentionPeriod') ? undefined : KvintaDurationFromJSON(json['retentionPeriod']),
        'taskQueue': !exists(json, 'taskQueue') ? undefined : json['taskQueue'],
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'searchAttributes': !exists(json, 'searchAttributes') ? undefined : (json['searchAttributes'] === null ? null : (json['searchAttributes'] as Array<any>).map(KvintaSearchAttributeConfigurationFromJSON)),
        'workflows': !exists(json, 'workflows') ? undefined : (json['workflows'] === null ? null : mapValues(json['workflows'], KvintaWorkflowConfigurationFromJSON)),
        'activities': !exists(json, 'activities') ? undefined : (json['activities'] === null ? null : mapValues(json['activities'], KvintaActivityConfigurationFromJSON)),
        'configureClusterOnStart': !exists(json, 'configureClusterOnStart') ? undefined : json['configureClusterOnStart'],
        'taskQueueActivitiesPerSecond': !exists(json, 'taskQueueActivitiesPerSecond') ? undefined : json['taskQueueActivitiesPerSecond'],
        'maxActivitiesPerSecond': !exists(json, 'maxActivitiesPerSecond') ? undefined : json['maxActivitiesPerSecond'],
        'maxConcurrentActivityExecutionSize': !exists(json, 'maxConcurrentActivityExecutionSize') ? undefined : json['maxConcurrentActivityExecutionSize'],
        'workflowPollThreadCount': !exists(json, 'workflowPollThreadCount') ? undefined : json['workflowPollThreadCount'],
        'activityPollThreadCount': !exists(json, 'activityPollThreadCount') ? undefined : json['activityPollThreadCount'],
        'taskQueues': !exists(json, 'taskQueues') ? undefined : (json['taskQueues'] === null ? null : mapValues(json['taskQueues'], KvintaTaskQueueConfigurationFromJSON)),
        'disableSearchAttributes': !exists(json, 'disableSearchAttributes') ? undefined : json['disableSearchAttributes'],
    };
}

export function KvintaTemporalConfigurationToJSON(value?: KvintaTemporalConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serverUrl': value.serverUrl,
        'retentionPeriod': KvintaDurationToJSON(value.retentionPeriod),
        'taskQueue': value.taskQueue,
        'namespace': value.namespace,
        'searchAttributes': value.searchAttributes === undefined ? undefined : (value.searchAttributes === null ? null : (value.searchAttributes as Array<any>).map(KvintaSearchAttributeConfigurationToJSON)),
        'workflows': value.workflows === undefined ? undefined : (value.workflows === null ? null : mapValues(value.workflows, KvintaWorkflowConfigurationToJSON)),
        'activities': value.activities === undefined ? undefined : (value.activities === null ? null : mapValues(value.activities, KvintaActivityConfigurationToJSON)),
        'configureClusterOnStart': value.configureClusterOnStart,
        'taskQueueActivitiesPerSecond': value.taskQueueActivitiesPerSecond,
        'maxActivitiesPerSecond': value.maxActivitiesPerSecond,
        'maxConcurrentActivityExecutionSize': value.maxConcurrentActivityExecutionSize,
        'workflowPollThreadCount': value.workflowPollThreadCount,
        'activityPollThreadCount': value.activityPollThreadCount,
        'taskQueues': value.taskQueues === undefined ? undefined : (value.taskQueues === null ? null : mapValues(value.taskQueues, KvintaTaskQueueConfigurationToJSON)),
        'disableSearchAttributes': value.disableSearchAttributes,
    };
}


