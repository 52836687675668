/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaBizTransaction
 */
export interface KvintaBizTransaction {
    /**
     * 
     * @type {string}
     * @memberof KvintaBizTransaction
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaBizTransaction
     */
    bizTransaction?: string;
}

export function KvintaBizTransactionFromJSON(json: any): KvintaBizTransaction {
    return KvintaBizTransactionFromJSONTyped(json, false);
}

export function KvintaBizTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaBizTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'bizTransaction': !exists(json, 'bizTransaction') ? undefined : json['bizTransaction'],
    };
}

export function KvintaBizTransactionToJSON(value?: KvintaBizTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'bizTransaction': value.bizTransaction,
    };
}


