/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDeleteObjectResponseStatus
 */
export interface KvintaDeleteObjectResponseStatus {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaDeleteObjectResponseStatus
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeleteObjectResponseStatus
     */
    error?: string | null;
}

export function KvintaDeleteObjectResponseStatusFromJSON(json: any): KvintaDeleteObjectResponseStatus {
    return KvintaDeleteObjectResponseStatusFromJSONTyped(json, false);
}

export function KvintaDeleteObjectResponseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeleteObjectResponseStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function KvintaDeleteObjectResponseStatusToJSON(value?: KvintaDeleteObjectResponseStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
    };
}


