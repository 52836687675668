import React from 'react';
import Root from './Root';
import { IntlProvider } from 'react-intl';
import localeEn from 'kvinta/locales/en-en.json';
import { inject, observer } from 'mobx-react';
import { USER_STORE_ID, UserStore } from 'kvinta/modules/main/UserStore';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from 'kvinta/common/HistoryStore'
import { History } from "history";

const mergedTranslations = {
  en: {
    ...localeEn,
  },
};

interface AppProps {
  userStore?: UserStore;
  historyStore?: HistoryStore;
  history: History<unknown>
}

class app extends React.Component<AppProps> {
  render() {
    const userInfo = this.props.userStore.userInfo;
    if (!userInfo) {
      return null;
    }
    const lang = userInfo.lang;
    return (
      <IntlProvider locale={lang} messages={mergedTranslations[lang]}>
        <Root
          history={this.props.history}
          historyStore={this.props.historyStore} />
      </IntlProvider>
    );
  }
}

export const App = inject(HISTORY_STORE_ID, USER_STORE_ID)(observer(app));
