import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsDeliveryItemView } from './tabs';
import EditIcon from '@mui/icons-material/Edit';
import { reaction } from 'mobx';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  DELIVERY_ITEM_FORM_ROOT_ID,
  DELIVERY_ITEM_STORE_ID,
  DeliveryItemsStore,
  TDeliveryItem,
} from './DeliveryItemsStore';
import { DeliveryItemForm, EFormType } from './DeliveryItemForm';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Deliveries.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { translateSerializationType } from '../../../apis/utils';

interface DeliveryNoteDeliveryItemSummaryViewProps {
  intl: any;
  deliveryItemsStore?: DeliveryItemsStore;
  itemId: string;
  id: string;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
}

const DeliveryItemSummaryView = ({ formData }: { formData: TDeliveryItem }) => {
  const attrField = Object.keys(formData.attrConfig).map((attrKey) => {
    const value = (formData.attributes && formData.attributes[attrKey]) || '';
    return (
      <TextField
        key={attrKey}
        value={value}
        label={`attr.${formData.attrConfig[attrKey].key}`}
        defaultMessage={formData.attrConfig[attrKey].name}
      />
    );
  });
  return (
    <div className={styles.updateFormContainer} id={'DeliveryItemSummaryID'}>
      <TextField
        value={formData.productCode}
        label="delivery-item-list.header.productCode"
        style={{ gridColumn: '1/span 2' }}
      />
      <TextField value={formData.itemNumber} label="delivery-item-list.header.itemNumber" />
      <TextField value={formData.batchNumber} label="delivery-item-list.header.batchNumber" />
      <div className={styles.divider} />
      <TextField value={formData.qtyExpected} label="delivery-item-list.header.qtyExpected" />
      <TextField value={formData.dateExpected} label="delivery-item-list.header.dateExpected" />
      <TextField value={formData.qtyConfirmed} label="delivery-item-list.header.qtyConfirmed" />
      <TextField value={formData.dateConfirmed} label="delivery-item-list.header.dateConfirmed" />
      <div className={styles.divider} />
      <TextField value={formData.expiryDate} label="delivery-item-list.header.expiryDate" />
      {/*<TextField
        value={translateSerializationType(formData.serializationType)}
        label="product-form.field.serializationType"
      />*/}
      <TextField
        value={translateSerializationType(formData.serializationType as any)}
        label="product-form.field.serializationType"
      />
      {attrField}
    </div>
  );
};

class DeliveryNoteDeliveryItemSummaryViewImpl extends Component<DeliveryNoteDeliveryItemSummaryViewProps> {
  disposer: any;

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.deliveryItemsStore.fetchDeliveryItem(this.props.itemId);
    this.disposer = reaction(
      () => this.props.deliveryItemsStore.currentDeliveryItem,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.itemNumber.toString(), () => {
            this.props.historyStore.navigateDeliveryNotesPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
    this.props.deliveryItemsStore.closeDeliveryItemForm();
  }

  render() {
    if (!this.props.deliveryItemsStore.currentDeliveryItem) {
      return null;
    }
    const intl = this.props.intl;

    return (
      <div className={styles.summaryContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateDeliveryNotesPath();
            }
            if (newValue === '1') {
              this.props.historyStore.navigateDeliveryNotePath(this.props.id);
            }
            if (newValue === '2') {
              this.props.historyStore.navigateDeliveryNoteDeliveryItemsPath(this.props.id);
            }
          }}
          tabId={'3'}
          tabs={tabsDeliveryItemView}
        />

        <div className={styles.summaryMain}>
          <div className={styles.summaryButtonContainer}>
            {this.props.deliveryItemsStore.deliveryItemFormOpen && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<CloseIcon color="primary" />}
                onClick={this.props.deliveryItemsStore.closeDeliveryItemForm}
              >
                {intl.formatMessage({ id: 'button.cancel' })}
              </Button>
            )}
            {this.props.deliveryItemsStore.deliveryItemFormOpen ? (
              <ButtonGreen
                id="editOrSubmitButton"
                onClick={this.props.deliveryItemsStore.submitUpdateDeliveryItemForm}
                icon={<DoneIcon color="secondary" />}
                label={intl.formatMessage({ id: 'button.confirm' })}
                buttonState={formDataToButtonState(
                  this.props.deliveryItemsStore.deliveryItemFormData[DELIVERY_ITEM_FORM_ROOT_ID],
                )}
              />
            ) : (
              <ButtonGreen
                id="editOrSubmitButton"
                onClick={this.props.deliveryItemsStore.openUpdateDeliveryItemForm}
                icon={<EditIcon color="secondary" />}
                label={intl.formatMessage({ id: 'button.edit' })}
                buttonState={EButtonState.ACTIVE}
              />
            )}
          </div>
          {this.props.deliveryItemsStore.deliveryItemFormOpen ? (
            <DeliveryItemForm
              formData={this.props.deliveryItemsStore.deliveryItemFormData}
              onChange={this.props.deliveryItemsStore.onChangeDeliveryItemFormField}
              onBlur={this.props.deliveryItemsStore.onBlurDeliveryItemFormField}
              formType={EFormType.UPDATE}
              attrConfig={this.props.deliveryItemsStore.currentDeliveryItem.attrConfig}
            />
          ) : (
            <DeliveryItemSummaryView formData={this.props.deliveryItemsStore.currentDeliveryItem} />
          )}
        </div>
      </div>
    );
  }
}

export const DeliveryItemSummary = injectIntl(
  inject(HISTORY_STORE_ID, DELIVERY_ITEM_STORE_ID, SIDEBAR_STORE_ID)(observer(DeliveryNoteDeliveryItemSummaryViewImpl)),
);
