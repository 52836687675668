/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmStatus,
    KvintaAlarmStatusFromJSON,
    KvintaAlarmStatusFromJSONTyped,
    KvintaAlarmStatusToJSON,
    KvintaGs1DocumentType,
    KvintaGs1DocumentTypeFromJSON,
    KvintaGs1DocumentTypeFromJSONTyped,
    KvintaGs1DocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryDeliveryFilter
 */
export interface KvintaQueryDeliveryFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaQueryDeliveryFilter
     */
    deliveryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaQueryDeliveryFilter
     */
    documentNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaQueryDeliveryFilter
     */
    location?: string | null;
    /**
     * 
     * @type {KvintaGs1DocumentType}
     * @memberof KvintaQueryDeliveryFilter
     */
    type?: KvintaGs1DocumentType | null;
    /**
     * 
     * @type {Array<KvintaAlarmStatus>}
     * @memberof KvintaQueryDeliveryFilter
     */
    alarmStatus?: Array<KvintaAlarmStatus> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaQueryDeliveryFilter
     */
    referenceDocumentNumber?: string | null;
}

export function KvintaQueryDeliveryFilterFromJSON(json: any): KvintaQueryDeliveryFilter {
    return KvintaQueryDeliveryFilterFromJSONTyped(json, false);
}

export function KvintaQueryDeliveryFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryDeliveryFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryId': !exists(json, 'deliveryId') ? undefined : json['deliveryId'],
        'documentNumber': !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'type': !exists(json, 'type') ? undefined : KvintaGs1DocumentTypeFromJSON(json['type']),
        'alarmStatus': !exists(json, 'alarmStatus') ? undefined : (json['alarmStatus'] === null ? null : (json['alarmStatus'] as Array<any>).map(KvintaAlarmStatusFromJSON)),
        'referenceDocumentNumber': !exists(json, 'referenceDocumentNumber') ? undefined : json['referenceDocumentNumber'],
    };
}

export function KvintaQueryDeliveryFilterToJSON(value?: KvintaQueryDeliveryFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryId': value.deliveryId,
        'documentNumber': value.documentNumber,
        'location': value.location,
        'type': KvintaGs1DocumentTypeToJSON(value.type),
        'alarmStatus': value.alarmStatus === undefined ? undefined : (value.alarmStatus === null ? null : (value.alarmStatus as Array<any>).map(KvintaAlarmStatusToJSON)),
        'referenceDocumentNumber': value.referenceDocumentNumber,
    };
}


