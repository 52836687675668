/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaPaging,
    KvintaPagingFromJSON,
    KvintaPagingFromJSONTyped,
    KvintaPagingToJSON,
    KvintaQueryDeliveryFilter,
    KvintaQueryDeliveryFilterFromJSON,
    KvintaQueryDeliveryFilterFromJSONTyped,
    KvintaQueryDeliveryFilterToJSON,
    KvintaQueryDeliveryItemFilter,
    KvintaQueryDeliveryItemFilterFromJSON,
    KvintaQueryDeliveryItemFilterFromJSONTyped,
    KvintaQueryDeliveryItemFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryDeliveryRequest
 */
export interface KvintaQueryDeliveryRequest {
    /**
     * 
     * @type {KvintaQueryDeliveryFilter}
     * @memberof KvintaQueryDeliveryRequest
     */
    delivery?: KvintaQueryDeliveryFilter | null;
    /**
     * 
     * @type {KvintaQueryDeliveryItemFilter}
     * @memberof KvintaQueryDeliveryRequest
     */
    deliveryItem?: KvintaQueryDeliveryItemFilter | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaQueryDeliveryRequest
     */
    fetchItems?: boolean | null;
    /**
     * 
     * @type {KvintaPaging}
     * @memberof KvintaQueryDeliveryRequest
     */
    paging: KvintaPaging;
}

export function KvintaQueryDeliveryRequestFromJSON(json: any): KvintaQueryDeliveryRequest {
    return KvintaQueryDeliveryRequestFromJSONTyped(json, false);
}

export function KvintaQueryDeliveryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryDeliveryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delivery': !exists(json, 'delivery') ? undefined : KvintaQueryDeliveryFilterFromJSON(json['delivery']),
        'deliveryItem': !exists(json, 'deliveryItem') ? undefined : KvintaQueryDeliveryItemFilterFromJSON(json['deliveryItem']),
        'fetchItems': !exists(json, 'fetchItems') ? undefined : json['fetchItems'],
        'paging': KvintaPagingFromJSON(json['paging']),
    };
}

export function KvintaQueryDeliveryRequestToJSON(value?: KvintaQueryDeliveryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery': KvintaQueryDeliveryFilterToJSON(value.delivery),
        'deliveryItem': KvintaQueryDeliveryItemFilterToJSON(value.deliveryItem),
        'fetchItems': value.fetchItems,
        'paging': KvintaPagingToJSON(value.paging),
    };
}


