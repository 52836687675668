import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExportButton,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SearchField,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { BUSINESS_PARTNER_STORE_ID, BusinessPartnersStore } from './BusinessPartnerStore';
import { CreateBusinessPartnerDialog } from './CreateBusinessPartnerDialog';
import { CSVDownload } from 'react-csv';
import { ITableActionsState } from 'kvinta/common';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import MaterialTable from 'material-table';
import { getInternalId } from '../../../apis/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KvintaBusinessPartner } from '../../../apis/kvinta-masterdata';
import styles from './BusinessPartner.module.scss';
import { defineSorting } from '../../../common/utils';

interface BusinessPartnersViewProps {
  businessPartnersStore?: BusinessPartnersStore;
  historyStore: HistoryStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
}

class BusinessPartnerListBase extends Component<BusinessPartnersViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;

  constructor(props: BusinessPartnersViewProps) {
    super(props);
    this.tableRef = React.createRef<HTMLDivElement>();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.businessPartnersStore.fetchBusinessPartnerList();
    this.props.sidebarStore.clearMainTitle();
  }

  navigateBusinessPartnerSummary = (id: string) => {
    this.props.historyStore.navigateBusinessPartnerPath(id);
  };

  render() {
    const intl = this.props.intl;
    const {
      businessPartnerFormOpen,
      exportActive,
      exportData,
      searchValue,
      autofocusSearchInList,
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
    } = this.props.businessPartnersStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    this.props.businessPartnersStore.updateExported();
    return (
      <div className={styles.mainContainer}>
        <div className={styles.businessPartnerListHeader}>
          <SearchField
            intl={this.props.intl}
            searchValue={searchValue}
            onChange={(value: string) => {
              this.props.businessPartnersStore.updateSearch(value);
            }}
            onBlur={(ev) => {
              this.props.businessPartnersStore.unfocusSearchField();
            }}
            autofocus={autofocusSearchInList}
          />
          <div className={styles.businessPartnerListButtonContainer}>
            <ExportButton
              onExportAll={() => this.props.businessPartnersStore.exportAll()}
              onExportSelected={() => this.props.businessPartnersStore.exportSelected()}
              hasSelected={this.props.businessPartnersStore.checked > 0}
            />

            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.businessPartnersStore.openCreateBusinessPartnerForm();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        </div>
        <div id="BusinessPartnerListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            columns={[
              {
                title: intl.formatMessage({ id: 'business-partner-list.header.name' }),
                field: 'name',
                defaultSort: defineSorting(this.props.businessPartnersStore.currentSort, 'name'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '40%',
                  fontSize: null,
                },
              },

              {
                title: intl.formatMessage({ id: 'business-partner-list.header.gln13' }),
                field: 'gln13',
                defaultSort: defineSorting(this.props.businessPartnersStore.currentSort, 'gln13'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '20%',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'business-partner-list.header.country' }),
                field: 'country',
                defaultSort: defineSorting(this.props.businessPartnersStore.currentSort, 'country'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '20%',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'business-partner-list.header.city' }),
                field: 'city',
                defaultSort: defineSorting(this.props.businessPartnersStore.currentSort, 'city'),
                width: null,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: '20%',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.businessPartnersStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.businessPartnersStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.navigateBusinessPartnerSummary(getInternalId(rowData));
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => null,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              const shouldDelete = confirm(
                'Do you want to delete ' + (this.state.rowData as KvintaBusinessPartner).name + '?',
              );
              if (shouldDelete) {
                this.props.businessPartnersStore.deleteBusinessPartner(
                  getInternalId(this.state.rowData as KvintaBusinessPartner),
                );
              }
              this.onMenuClose();
            }}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
        {businessPartnerFormOpen && (
          <CreateBusinessPartnerDialog
            formData={this.props.businessPartnersStore.businessPartnerFormData}
            onChange={this.props.businessPartnersStore.onChangeBusinessPartnerFormField}
            onBlur={this.props.businessPartnersStore.onBlurBusinessPartnerFormField}
            closeForm={this.props.businessPartnersStore.closeBusinessPartnerForm}
            submitForm={this.props.businessPartnersStore.submitCreateBusinessPartnerForm}
          />
        )}
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </div>
    );
  }
}

export const BusinessPartnerList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    BUSINESS_PARTNER_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(BusinessPartnerListBase)),
);
