/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaComputeShipmentContentRequest
 */
export interface KvintaComputeShipmentContentRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaComputeShipmentContentRequest
     */
    shipmentEventId: string;
}

export function KvintaComputeShipmentContentRequestFromJSON(json: any): KvintaComputeShipmentContentRequest {
    return KvintaComputeShipmentContentRequestFromJSONTyped(json, false);
}

export function KvintaComputeShipmentContentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaComputeShipmentContentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentEventId': json['shipmentEventId'],
    };
}

export function KvintaComputeShipmentContentRequestToJSON(value?: KvintaComputeShipmentContentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentEventId': value.shipmentEventId,
    };
}


