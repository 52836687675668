/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSftpScnInboundConfig
 */
export interface KvintaSftpScnInboundConfig {
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpScnInboundConfig
     */
    inboxDir: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpScnInboundConfig
     */
    archiveDir: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpScnInboundConfig
     */
    errorDir: string;
    /**
     * 
     * @type {number}
     * @memberof KvintaSftpScnInboundConfig
     */
    processingBatchSize: number;
    /**
     * 
     * @type {string}
     * @memberof KvintaSftpScnInboundConfig
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSftpScnInboundConfig
     */
    disabled: boolean;
}

export function KvintaSftpScnInboundConfigFromJSON(json: any): KvintaSftpScnInboundConfig {
    return KvintaSftpScnInboundConfigFromJSONTyped(json, false);
}

export function KvintaSftpScnInboundConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSftpScnInboundConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inboxDir': json['inboxDir'],
        'archiveDir': json['archiveDir'],
        'errorDir': json['errorDir'],
        'processingBatchSize': json['processingBatchSize'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'disabled': json['disabled'],
    };
}

export function KvintaSftpScnInboundConfigToJSON(value?: KvintaSftpScnInboundConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inboxDir': value.inboxDir,
        'archiveDir': value.archiveDir,
        'errorDir': value.errorDir,
        'processingBatchSize': value.processingBatchSize,
        'name': value.name,
        'disabled': value.disabled,
    };
}


