/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaSplitByCustomsNumber
 */
export interface KvintaSplitByCustomsNumber {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaSplitByCustomsNumber
     */
    destinationCountries?: Array<string> | null;
}

export function KvintaSplitByCustomsNumberFromJSON(json: any): KvintaSplitByCustomsNumber {
    return KvintaSplitByCustomsNumberFromJSONTyped(json, false);
}

export function KvintaSplitByCustomsNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSplitByCustomsNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'destinationCountries': !exists(json, 'destinationCountries') ? undefined : json['destinationCountries'],
    };
}

export function KvintaSplitByCustomsNumberToJSON(value?: KvintaSplitByCustomsNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'destinationCountries': value.destinationCountries,
    };
}


