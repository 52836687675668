/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaStandardBusinessDocumentHeader,
    KvintaStandardBusinessDocumentHeaderFromJSON,
    KvintaStandardBusinessDocumentHeaderFromJSONTyped,
    KvintaStandardBusinessDocumentHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisHeader
 */
export interface KvintaEpcisHeader {
    /**
     * 
     * @type {KvintaStandardBusinessDocumentHeader}
     * @memberof KvintaEpcisHeader
     */
    standardBusinessDocumentHeader?: KvintaStandardBusinessDocumentHeader;
}

export function KvintaEpcisHeaderFromJSON(json: any): KvintaEpcisHeader {
    return KvintaEpcisHeaderFromJSONTyped(json, false);
}

export function KvintaEpcisHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'standardBusinessDocumentHeader': !exists(json, 'standardBusinessDocumentHeader') ? undefined : KvintaStandardBusinessDocumentHeaderFromJSON(json['standardBusinessDocumentHeader']),
    };
}

export function KvintaEpcisHeaderToJSON(value?: KvintaEpcisHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'standardBusinessDocumentHeader': KvintaStandardBusinessDocumentHeaderToJSON(value.standardBusinessDocumentHeader),
    };
}


