/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaValidationLevel {
    Warning = 'warning',
    Error = 'error'
}

export function KvintaValidationLevelFromJSON(json: any): KvintaValidationLevel {
    return KvintaValidationLevelFromJSONTyped(json, false);
}

export function KvintaValidationLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaValidationLevel {
    return json as KvintaValidationLevel;
}

export function KvintaValidationLevelToJSON(value?: KvintaValidationLevel | null): any {
    return value as any;
}

