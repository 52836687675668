/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaAutoCommissionSettings
 */
export interface KvintaAutoCommissionSettings {
    /**
     * 
     * @type {string}
     * @memberof KvintaAutoCommissionSettings
     */
    readPoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaAutoCommissionSettings
     */
    disposition?: string | null;
}

export function KvintaAutoCommissionSettingsFromJSON(json: any): KvintaAutoCommissionSettings {
    return KvintaAutoCommissionSettingsFromJSONTyped(json, false);
}

export function KvintaAutoCommissionSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAutoCommissionSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readPoint': !exists(json, 'readPoint') ? undefined : json['readPoint'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
    };
}

export function KvintaAutoCommissionSettingsToJSON(value?: KvintaAutoCommissionSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readPoint': value.readPoint,
        'disposition': value.disposition,
    };
}


