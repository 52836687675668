/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDeleteObjectsRequest,
    KvintaDeleteObjectsRequestFromJSON,
    KvintaDeleteObjectsRequestFromJSONTyped,
    KvintaDeleteObjectsRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaInlineObject3
 */
export interface KvintaInlineObject3 {
    /**
     * 
     * @type {KvintaDeleteObjectsRequest}
     * @memberof KvintaInlineObject3
     */
    request: KvintaDeleteObjectsRequest;
}

export function KvintaInlineObject3FromJSON(json: any): KvintaInlineObject3 {
    return KvintaInlineObject3FromJSONTyped(json, false);
}

export function KvintaInlineObject3FromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaInlineObject3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': KvintaDeleteObjectsRequestFromJSON(json['request']),
    };
}

export function KvintaInlineObject3ToJSON(value?: KvintaInlineObject3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': KvintaDeleteObjectsRequestToJSON(value.request),
    };
}


