import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { SelectableStore } from 'kvinta/common';
import {
  ConfigApi as MDConfigApi,
  DefaultApi as MDDocumentApi,
  KvintaAttributeConfiguration,
  KvintaBusinessPartner,
  KvintaDeliveryItem,
  KvintaDocumentKey,
  KvintaGs1DocumentType,
  KvintaSerializationType,
  KvintaSortDirection,
  KvintaSortExpression,
} from 'kvinta/apis/kvinta-masterdata';
import {
  convertSerializationType,
  convertSerializationTypeToNumber,
  getInternalDeliveryItemKey,
  translateSerializationType,
} from 'kvinta/apis/utils';
import { HistoryStore } from '../../../common/HistoryStore';
import { format, parse } from 'date-fns';
import {
  convertDeliveryNoteToInternalForm,
  fromKvintaDocument,
  TDeliveryNote,
  toKvintaDocument,
} from './DeliveryNotesStore';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';
import { formRoot, select, textInput } from '../../../common/formUtils/formDataGenerators';
import { validateForm } from '../../../common/formUtils/core';
import { isNotEmpty, isNumber, isValidForRegExp, maxLength } from '../../../common/formUtils/validators';
import { TSelectInputData, TTextInputData } from '../../../common/formUtils/types';

type TAttributes = { [key: string]: any } | null;
export type TAttrConfig = { [key: string]: KvintaAttributeConfiguration };

export type TDeliveryItem = {
  delivery: KvintaDocumentKey;
  itemNumber: string;
  productCode: string;
  batchNumber: string;
  qtyExpected: string;
  qtyConfirmed: string;
  dateExpected: string;
  expiryDate: string;
  dateConfirmed: string;
  serializationType: string;

  attributes?: TAttributes;
  attrConfig?: TAttrConfig;
};

export type TDeliveryItemFormData = {
  // "deliveryItemForm.delivery": TTextInputData
  'deliveryItemForm.itemNumber': TTextInputData;
  'deliveryItemForm.productCode': TTextInputData;
  'deliveryItemForm.batchNumber': TTextInputData;
  'deliveryItemForm.qtyExpected': TTextInputData;
  'deliveryItemForm.qtyConfirmed': TTextInputData;
  'deliveryItemForm.dateExpected': TTextInputData;
  'deliveryItemForm.expiryDate': TTextInputData;
  'deliveryItemForm.dateConfirmed': TTextInputData;
  'deliveryItemForm.serializationType': TSelectInputData;
  [key: string]: TTextInputData | TSelectInputData;
};

interface IDeliveryItemRow extends KvintaDeliveryItem, ISelectableRow {}

export class DeliveryItemsStore extends SelectableStore<IDeliveryItemRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _mdConfigApi: MDConfigApi;
  private _historyStore: HistoryStore;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  businessPartners: KvintaBusinessPartner[];
  currentSort: KvintaSortExpression;

  deliveryItemFormData?: TDeliveryItemFormData;
  deliveryItemFormOpen: boolean;

  deliveryId?: string;
  currentDeliveryNote?: TDeliveryNote;
  currentDeliveryItem?: TDeliveryItem;
  deliveryItems?: KvintaDeliveryItem[];

  autofocusSearchInList: boolean;
  searchValue: string;

  constructor(
    config: TAppOptionsConfig,
    notificationManager: NotificationManager,
    mdApi: MDDocumentApi,
    mdConfigApi: MDConfigApi,
    historyStore: HistoryStore,
  ) {
    super();
    makeObservable(this, {
      isLoading: observable,

      fetchDeliveryItemList: action.bound,
      fetchPage: action.bound,

      updateSearch: action.bound,
      page: observable,
      pageSize: observable,
      searchValue: observable,
      unfocusSearchField: action,

      fetchDeliveryItem: action.bound,
      currentDeliveryItem: observable,

      openUpdateDeliveryItemForm: action.bound,
      openCreateDeliveryItemForm: action.bound,
      closeDeliveryItemForm: action.bound,
      deliveryItemFormOpen: observable,
      deliveryItemFormData: observable,
      onChangeDeliveryItemFormField: action.bound,
      onBlurDeliveryItemFormField: action.bound,
      submitDeliveryItem: action.bound,
      submitUpdateDeliveryItemForm: action.bound,
      submitCreateDeliveryItemForm: action.bound,

      deliveryId: observable,
      currentDeliveryNote: observable,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._mdConfigApi = mdConfigApi;
    this._historyStore = historyStore;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      direction: KvintaSortDirection.Desc,
      property: 'itemNumber',
      ignoreCase: false,
    };
  }

  async fetchPage(page: number, id: string) {
    this.isLoading = true;
    this.page = page;

    this.fetchDeliveryItemList(id);
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc', id: string) {
    this.isLoading = true;
    const field = getField(orderBy);
    this.currentSort = {
      property: field,
      direction: orderDirection === 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
      ignoreCase: false,
    };
    this.fetchDeliveryItemList(id);
  }

  unsetDeliveryId() {
    this.deliveryId = undefined;
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string, id: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchDeliveryItemList(id);
  }

  resetFilter = () => {
    this.searchValue = '';
  };

  fetchDeliveryItemList(id: string) {
    this.isLoading = true;
    this.deliveryItems = [];

    Promise.all([]);
    this._mdApi
      .queryDeliveries({
        kvintaQueryDeliveryRequest: {
          delivery: {
            deliveryId: id,
          },
          fetchItems: true,
          paging: {
            page: 0,
            size: 1,
          },
        },
      })
      .then((result) => {
        if (result.total === 0) {
          this._notificationManager.sendError(`Delivery ${id} not found`);
        } else {
          const oldSelection = new Map();

          const currentDeliveryNote = result.list[0];
          const deliveryItems = currentDeliveryNote.items || [];

          this.currentDeliveryNote = convertDeliveryNoteToInternalForm(currentDeliveryNote);
          this.totalCount = deliveryItems.length;
          this.listData = deliveryItems.map((deliveryItem) => {
            const selected = oldSelection.get(deliveryItem.itemNumber) || false;
            return {
              ...deliveryItem,
              delivery: currentDeliveryNote.document,
              isSelected: selected,
              id: deliveryItem.itemNumber,
            } as IDeliveryItemRow;
          });
        }
      })
      .catch((e) => {
        this._notificationManager.sendError(`An error occurred while fetching delivery data\n${e.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openCreateDeliveryItemForm = () => {
    this._mdConfigApi
      .readDeliveryItemAttributesConfig({
        body: {},
      })
      .then((response) => {
        const initialValues = {
          itemNumber: '',
          productCode: '',
          batchNumber: '',
          qtyExpected: '0',
          qtyConfirmed: '0',
          dateExpected: '',
          expiryDate: '',
          dateConfirmed: '',
          serializationType: KvintaSerializationType.Unknown,
          attributes: {},
          attrConfig: response || {},
        };
        this.currentDeliveryItem = {
          ...initialValues,
          delivery: {
            number: '',
            type: KvintaGs1DocumentType.Inbound,
            location: '',
          },
        };
        this.deliveryItemFormData = validateForm(
          generateDeliveryItemFormData(DELIVERY_ITEM_FORM_ROOT_ID, initialValues as TDeliveryItem),
        );
        this.deliveryItemFormOpen = true;
      })
      .catch((reason) => {
        console.warn('readDeliveryItemAttributesConfig has error');
      });
  };

  closeDeliveryItemForm() {
    this.deliveryItemFormData = undefined;
    this.deliveryItemFormOpen = false;
  }

  openUpdateDeliveryItemForm() {
    const initialValues = {
      delivery: this.currentDeliveryItem.delivery,
      itemNumber: this.currentDeliveryItem.itemNumber || '',
      productCode: this.currentDeliveryItem.productCode || '',
      batchNumber: this.currentDeliveryItem.batchNumber || '',
      qtyExpected: (this.currentDeliveryItem.qtyExpected || 0).toString(),
      expiryDate:
        this.currentDeliveryItem.expiryDate !== ''
          ? convertDateToPickerFormat(this.currentDeliveryItem.expiryDate)
          : '',
      qtyConfirmed: (this.currentDeliveryItem.qtyConfirmed || 0).toString(),
      serializationType: this.currentDeliveryItem.serializationType,
      dateExpected:
        this.currentDeliveryItem.dateExpected !== ''
          ? convertDateToPickerFormat(this.currentDeliveryItem.dateExpected)
          : '',
      dateConfirmed:
        this.currentDeliveryItem.dateConfirmed !== ''
          ? convertDateTimeToPickerFormat(this.currentDeliveryItem.dateConfirmed)
          : '',

      attributes: this.currentDeliveryItem.attributes,
      attrConfig: this.currentDeliveryItem.attrConfig,

      showError: false,
    };

    this.deliveryItemFormData = validateForm(generateDeliveryItemFormData(DELIVERY_ITEM_FORM_ROOT_ID, initialValues));
    this.deliveryItemFormOpen = true;
  }

  onChangeDeliveryItemFormField(id: string, value: any) {
    const formData = toJS(this.deliveryItemFormData);
    this.deliveryItemFormData = handleFormChange(formData, id, value);
  }

  onBlurDeliveryItemFormField(id: string) {
    const formData = toJS(this.deliveryItemFormData);
    this.deliveryItemFormData = handleFormBlur(formData, id);
  }

  submitDeliveryItem(next, requiredFields) {
    this.isLoading = true;
    const submitData = convertDeliveryItemFormData(this.deliveryItemFormData, this.currentDeliveryItem);
    this._mdApi
      .mutateDeliveryItem({
        kvintaMutateRequestDeliveryItemDeliveryItemKey: {
          upsert: [
            {
              ...submitData,
              delivery: toKvintaDocument(this.currentDeliveryNote),
            },
          ],
        },
      })
      .then(async (response) => {
        if (response.errors && response.errors.length) {
          const errors = response.errors.map(({ error }) => error).join('\n');
          this._notificationManager.sendError(errors);
        } else {
          this._notificationManager.sendSuccess(
            `Successfully submitted Delivery Item ${
              response.upserted?.length && response.upserted[0].itemNumber ? response.upserted[0].itemNumber : ''
            }`,
          );
          await next();
          this.closeDeliveryItemForm();
        }
      })
      .catch((err: Error) => {
        console.log(err);
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  submitUpdateDeliveryItemForm() {
    return this.submitDeliveryItem(
      () => this.fetchDeliveryItem(getInternalDeliveryItemKey(this.currentDeliveryItem)),
      requiredDeliveryItemsFields,
    );
  }

  submitCreateDeliveryItemForm(deliveryId: string) {
    return this.submitDeliveryItem(() => this.fetchDeliveryItemList(deliveryId), requiredDeliveryItemsFields);
  }

  deleteDeliveryItem = async (delivery: KvintaDocumentKey, itemNumber: string, fetchItemsById: string) => {
    this.isLoading = true;
    this._mdApi
      .mutateDeliveryItem({
        kvintaMutateRequestDeliveryItemDeliveryItemKey: {
          _delete: [
            {
              delivery: delivery,
              itemNumber: itemNumber,
            },
          ],
        },
      })
      .then(() => {
        this.fetchDeliveryItemList(fetchItemsById);
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(`An error occurred while deleting Trade Item\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  fetchDeliveryItem = async (id: string) => {
    this.isLoading = true;
    this.currentDeliveryItem = null;
    this.currentDeliveryNote = null;
    this._mdApi
      .queryDeliveryItemWithConfig({
        kvintaQueryRequestDeliveryItemFilter: {
          paging: {
            page: 0,
            size: 1,
          },
          filter: {
            id: decodeURIComponent(id),
          },
        },
      })
      .then((deliveryItemResult) => {
        if (deliveryItemResult.list && deliveryItemResult.list[0]) {
          const currentDeliveryItem = deliveryItemResult.list[0];
          this.currentDeliveryNote = fromKvintaDocument(currentDeliveryItem.delivery);

          this.currentDeliveryItem = {
            delivery: currentDeliveryItem.delivery,
            itemNumber: currentDeliveryItem.itemNumber || '',
            productCode: currentDeliveryItem.productCode || '',
            batchNumber: currentDeliveryItem.batchNumber || '',
            qtyExpected: (currentDeliveryItem.qtyExpected || 0).toString(),
            expiryDate: currentDeliveryItem.expiryDate ? getDateFormat(currentDeliveryItem.expiryDate) : '',
            qtyConfirmed: (currentDeliveryItem.qtyConfirmed || 0).toString(),
            serializationType: convertSerializationType(currentDeliveryItem.serializationType as any),
            dateExpected: currentDeliveryItem.dateExpected ? getDateFormat(currentDeliveryItem.dateExpected) : '',
            dateConfirmed: currentDeliveryItem.dateConfirmed
              ? getDateTimeFormat(currentDeliveryItem.dateConfirmed)
              : '',

            attributes: currentDeliveryItem.attributes || {},
            attrConfig: deliveryItemResult.attrConfig || {},
          };
        } else {
          this._notificationManager.sendError(`Delivery item ${id} not found`);
          this._historyStore.navigateDeliveryNoteDeliveryItemsPath(this._historyStore.getCurrentRoute().params.id);
        }
      })
      .catch((e) => {
        console.log(e);
        this._notificationManager.sendError(e.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };
}

export const DELIVERY_ITEM_STORE_ID = 'deliveryItemsStore';
export const DELIVERY_ITEM_FORM_ROOT_ID = 'deliveryItemForm';

function getDateFormat(date: string) {
  try {
    const currentDate = new Date(date);
    return format(currentDate, 'dd.MM.yyyy');
  } catch {
    return '';
  }
}
function getDateTimeFormat(date: string) {
  try {
    const currentDate = new Date(date);
    return format(currentDate, 'dd.MM.yyyy HH:mm');
  } catch {
    return '';
  }
}

function getField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'itemNumber';
    case 2:
      return 'productCode';
    case 3:
      return 'qtyExpected';
    case 4:
      return 'qtyConfirmed';
    case 5:
      return 'dateExpected';
    case 6:
      return 'dateConfirmed';
    case 7:
      return 'batchNumber';
    default:
      return 'itemNumber';
  }
}

const requiredDeliveryItemsFields = ['itemNumber', 'productCode', 'qtyExpected'];

export function errorRequiredDeliveryItems(id: string, value: any): boolean {
  if (requiredDeliveryItemsFields.includes(id) && (value === undefined || value === '' || value === 0)) {
    return true;
  }
  return false;
}

function convertDateToPickerFormat(date) {
  const parsed = parse(date, 'dd.MM.yyyy', new Date());
  return parsed ? format(parsed, 'yyyy-MM-dd') : '';
}

function convertDateTimeToPickerFormat(date) {
  const parsed = parse(date, 'dd.MM.yyyy HH:mm', new Date());
  return parsed ? format(parsed, "yyyy-MM-dd'T'HH:mm") : '';
}

function generateDeliveryItemFormData(rootFormId: string, initialValues: TDeliveryItem) {
  // const docTypeOptions = allowedDeliveryNoteDocTypes.map((docType) => ({ key: docType, label: docType }));

  return formRoot<TDeliveryItemFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'itemNumber',
        value: initialValues.itemNumber,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          //isNumber({ errorMessage: 'this is not a number' }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'productCode',
        value: initialValues.productCode,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),

      textInput({
        path: 'batchNumber',
        value: initialValues.batchNumber,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'qtyExpected',
        value: initialValues.qtyExpected,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isNumber({ errorMessage: 'this is not a number' }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'qtyConfirmed',
        value: initialValues.qtyConfirmed,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isNumber({ errorMessage: 'this is not a number' }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'dateExpected',
        value: initialValues.dateExpected,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'expiryDate',
        value: initialValues.expiryDate,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      textInput({
        path: 'dateConfirmed',
        value: initialValues.dateConfirmed,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: false,
      }),
      select({
        path: 'serializationType',
        value: initialValues.serializationType,
        options: Object.values(KvintaSerializationType).map((serializationType) => ({
          key: serializationType,
          label: translateSerializationType(serializationType),
        })),
        validations: [isNotEmpty({ errorMessage: 'this value is mandatory' })],
        isRequiredField: false,
      }),

      // for custom attr fields
      ...setAttribFieldsForFormRoot(initialValues.attrConfig, initialValues.attributes),
    ],
  });
}

function convertDeliveryItemFormData(
  deliveryItemFormData: TDeliveryItemFormData,
  deliveryItem: TDeliveryItem,
): KvintaDeliveryItem {
  const attributes = Object.keys(deliveryItem.attrConfig).reduce((acc, currentValue) => {
    return {
      ...acc,
      [currentValue]: deliveryItemFormData[`deliveryItemForm.${currentValue}`].value.trim() || '',
    };
  }, {});

  return {
    itemNumber: deliveryItemFormData['deliveryItemForm.itemNumber'].value.trim(),
    productCode: deliveryItemFormData['deliveryItemForm.productCode'].value.trim(),
    batchNumber: deliveryItemFormData['deliveryItemForm.batchNumber'].value.trim(),
    qtyExpected: parseInt(deliveryItemFormData['deliveryItemForm.qtyExpected'].value.trim()) || 0,
    qtyConfirmed: parseInt(deliveryItemFormData['deliveryItemForm.qtyConfirmed'].value.trim()) || 0,
    expiryDate: deliveryItemFormData['deliveryItemForm.expiryDate'].value.trim(),
    dateExpected: deliveryItemFormData['deliveryItemForm.dateExpected'].value.trim(),
    dateConfirmed: deliveryItemFormData['deliveryItemForm.dateConfirmed'].value.trim(),
    /*serializationType: convertSerializationType(
      deliveryItemFormData['deliveryItemForm.serializationType'].value as KvintaSerializationType,
    ),*/
    serializationType: convertSerializationTypeToNumber(
      deliveryItemFormData['deliveryItemForm.serializationType'].value as KvintaSerializationType,
    ) as any,
    delivery: deliveryItem.delivery,

    attributes,
  };
}

export function setAttribFieldsForFormRoot(attrConfig: TAttrConfig, attributes?: TAttributes) {
  const attribFields = Object.keys(attrConfig).map((key) => {
    const validations = attrConfig[key].regExp
      ? [
          isValidForRegExp({
            errorMessage: `this value does not match regExp:${attrConfig[key].regExp}`,
            regExp: attrConfig[key].regExp,
          }),
        ]
      : [];

    return textInput({
      path: key,
      value: (attributes && attributes[key]) || '',
      validations,
      isRequiredField: false,
    });
  });
  return attribFields.filter((field) => field);
}
