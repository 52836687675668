/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisBody,
    KvintaEpcisBodyFromJSON,
    KvintaEpcisBodyFromJSONTyped,
    KvintaEpcisBodyToJSON,
    KvintaEpcisHeader,
    KvintaEpcisHeaderFromJSON,
    KvintaEpcisHeaderFromJSONTyped,
    KvintaEpcisHeaderToJSON,
    KvintaTimestamp,
    KvintaTimestampFromJSON,
    KvintaTimestampFromJSONTyped,
    KvintaTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisDocument
 */
export interface KvintaEpcisDocument {
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisDocument
     */
    id: string;
    /**
     * 
     * @type {KvintaEpcisHeader}
     * @memberof KvintaEpcisDocument
     */
    epcisHeader?: KvintaEpcisHeader | null;
    /**
     * 
     * @type {KvintaTimestamp}
     * @memberof KvintaEpcisDocument
     */
    creationDate?: KvintaTimestamp | null;
    /**
     * 
     * @type {KvintaEpcisBody}
     * @memberof KvintaEpcisDocument
     */
    epcisBody: KvintaEpcisBody;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcisDocument
     */
    schemaVersion: number;
}

export function KvintaEpcisDocumentFromJSON(json: any): KvintaEpcisDocument {
    return KvintaEpcisDocumentFromJSONTyped(json, false);
}

export function KvintaEpcisDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'epcisHeader': !exists(json, 'epcisHeader') ? undefined : KvintaEpcisHeaderFromJSON(json['epcisHeader']),
        'creationDate': !exists(json, 'creationDate') ? undefined : KvintaTimestampFromJSON(json['creationDate']),
        'epcisBody': KvintaEpcisBodyFromJSON(json['epcisBody']),
        'schemaVersion': json['schemaVersion'],
    };
}

export function KvintaEpcisDocumentToJSON(value?: KvintaEpcisDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'epcisHeader': KvintaEpcisHeaderToJSON(value.epcisHeader),
        'creationDate': KvintaTimestampToJSON(value.creationDate),
        'epcisBody': KvintaEpcisBodyToJSON(value.epcisBody),
        'schemaVersion': value.schemaVersion,
    };
}


