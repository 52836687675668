import React, { Component, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { Router, Switch, Route } from 'react-router-dom';
import 'assets/css/App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider} from "@mui/material";
import { createTheme } from '@mui/material/styles';

import { DynamicAlert, PageContent } from 'kvinta/components';

import KSidebar from 'kvinta/components/Sidebar/KSidebar';
import { routes } from './routes';
import { NotificationDetail } from 'kvinta/modules/main';
import { HistoryStore } from "./kvinta/common/HistoryStore";
import { History } from "history";

interface ISidebar {
  userStore?: any;
  historyStore: HistoryStore
}

export default class Root extends Component<ISidebar  & {history: History<unknown>}> {
  theme = createTheme({
    palette: {
      mode:'light',
      primary: { main: '#333382' }, // Purple and green play nicely together.
      secondary: { main: '#00C3A0' }, // This is just green.A700 as hex.
    },
    typography: {
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeightMedium: 500,
      button: {
        textTransform: 'none',
        fontWeight: 'normal',
        fontSize: '1em',
      },
    },
  });

  constructor(props: any) {
    super(props);

    document.addEventListener('notification', (e) => this.onNotificationReceived(e as CustomEvent<NotificationDetail>));
  }

  onNotificationReceived(e: CustomEvent<NotificationDetail>): void {
    const msg = e.detail.message;
    const type = e.detail.type;
    this.showNotification(msg, type);
  }

  showNotification(msg: string, type: any): void {
    const notification = <DynamicAlert severity={type} msg={msg}/>;
    toast(notification);
  }
  render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                path={route.path}
                key={index}
                exact={true}
                render={({match, history}) => {
                  this.props.historyStore.saveCurrentRoute(match)
                  const passPars = {...match.params, history: history};
                  return (
                    <ThemeProvider theme={this.theme}>
                      <div id="app-wrapper">
                        <KSidebar/>
                        <PageContent>
                          <route.component {...passPars} />
                        </PageContent>
                        <ToastContainer
                          hideProgressBar={true}
                          newestOnTop={true}
                          closeButton={false}
                          position="top-right"
                          toastClassName="toast-notification-wrap"
                        />
                      </div>
                    </ThemeProvider>
                  );
                }}
              />
            );
          })}
        </Switch>
      </Router>
    );
  }
}
