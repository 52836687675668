import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { inject, observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { injectIntl } from 'react-intl';
import { PRODUCT_FORM_ROOT_ID, PRODUCT_STORE_ID, TProductFormData } from './ProductsStore';
import { ProductForm } from './ProductForm';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';

interface CreateProductDialogProps {
  formData: TProductFormData;
  closeProductForm: () => void;
  submitCreateProduct: () => void;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  intl: any;
}

const CreateProductDialogBase: FunctionComponent<CreateProductDialogProps> = ({
  intl,
  formData,
  closeProductForm,
  submitCreateProduct,
  onChange,
  onBlur,
}) => {
  return (
    <Dialog fullWidth={true} maxWidth="md" open={true}>
      <KDialogTitle id="customized-dialog-title" onClose={closeProductForm}>
        {intl.formatMessage({ id: 'product-form.field.create' })}
      </KDialogTitle>
      <DialogContent>
        <ProductForm onChange={onChange} onBlur={onBlur} formData={formData as any} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeProductForm} color="primary" variant="outlined">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <ButtonGreen
          id="submitButton"
          label={intl.formatMessage({ id: 'button.submit' })}
          onClick={submitCreateProduct}
          buttonState={formDataToButtonState(formData[PRODUCT_FORM_ROOT_ID])}
        />
      </DialogActions>
    </Dialog>
  );
};

export const CreateProductDialog = injectIntl(inject(PRODUCT_STORE_ID)(observer(CreateProductDialogBase)));
