export const tabsSummary = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'menu.delivery-items',
    value: '2',
  },
];

export const tabsDeliveryItemView = [
  ...tabsSummary,
  {
    label: 'common.summary',
    value: '3',
  },
];
