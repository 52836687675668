import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import styles from './TextInput.module.scss';

type TDateInputProps = {
  intl: any;
  onChange: (id: string, val: string) => void;
  onBlur: (id: string, val: string) => void;
  value: string;
  id: string;
  label: string;
  disabled?: boolean;
  isRequiredField: boolean;
  errors: string[];
  showError: boolean;
  touched: boolean;
  style?: { [key: string]: string };
};

const DateInputBase: React.FunctionComponent<TDateInputProps> = ({
  id,
  value,
  onChange,
  onBlur,
  touched,
  isRequiredField,
  showError,
  label,
  intl,
  style,
  errors,
  disabled = false,
}) => {
  return (
    <FormControl variant="outlined" fullWidth style={style} id={`${label}-container`}>
      <TextField
        id={id}
        type="date"
        value={value}
        disabled={disabled}
        variant="outlined"
        onBlur={(e) => onBlur(id, e.target.value)}
        onChange={(e) => onChange(id, e.target.value)}
        InputLabelProps={{ shrink: true }}
        label={`${intl.formatMessage({ id: label })}${isRequiredField ? ' *' : ''}`}
      />
      {showError && touched && <div className={styles.error}>{errors}</div>}
    </FormControl>
  );
};

export const DateInput = injectIntl(observer(DateInputBase));
