import { TAppOptionsConfig } from '../../../common';
import { ConfigApi as ECaptureStoreApi, KvintaEpcisBizStep } from 'kvinta/apis/kvinta-epcis-capture';
import { NotificationManager } from '../../main';
import { action, makeObservable, observable, toJS } from 'mobx';
import { EButtonState, TTextInputData } from '../../../common/formUtils/types';
import { validateForm } from '../../../common/formUtils/core';
import { formRoot, textInput } from '../../../common/formUtils/formDataGenerators';
import {
  isNotEmpty,
  isValidEmailAddress,
  maxLength,
  valueAlreadyExistsInList,
} from '../../../common/formUtils/validators';
import { handleFormBlur, handleFormChange } from '../../../common/formUtils/handlers';

export type TNewAlarmForm = {
  'newAlarmForm.email': TTextInputData;
};

export class AlarmsStore {
  private _config: TAppOptionsConfig;
  private _epcisCaptureApi: ECaptureStoreApi;
  private _notificationManager: NotificationManager;

  newAlarmFormOpen: boolean;
  isLoading: boolean;
  formData: TNewAlarmForm;
  saveAlarmButtonState: EButtonState;
  listData: { id: string }[];

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, epcisCaptureApi: ECaptureStoreApi) {
    makeObservable(this, {
      fetchAlarmsList: action.bound,
      handleNewAlarmFormChange: action.bound,
      handleNewAlarmFormBlur: action.bound,
      openForm: action.bound,
      closeForm: action.bound,
      isLoading: observable,
      newAlarmFormOpen: observable,
      saveAlarm: action.bound,
      formData: observable,
      saveAlarmButtonState: observable,
      removeEmail: action.bound,
    });

    this._config = config;
    this._epcisCaptureApi = epcisCaptureApi;
    this._notificationManager = notificationManager;
  }

  async fetchAlarmsList() {
    this.isLoading = true;

    this._epcisCaptureApi
      .readAlarmsConfig({ body: {} })
      .then((result) => {
        this.listData = (result.emails || []).map((email) => ({ id: email }));
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while fetching data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openForm() {
    const initialValues = { email: '' };
    const emailList = this.listData.map((row) => row.id);
    this.formData = validateForm<TNewAlarmForm>(generateNewAlarmFormData(ALARM_FORM_ROOT_ID, initialValues, emailList));

    this.newAlarmFormOpen = true;
  }

  closeForm() {
    this.newAlarmFormOpen = false;
    this.formData = undefined;
  }

  handleNewAlarmFormChange(id: string, value: string) {
    const formData = toJS(this.formData);
    this.formData = handleFormChange(formData, id, value);
  }

  handleNewAlarmFormBlur(id: string) {
    const formData = toJS(this.formData);
    this.formData = handleFormBlur(formData, id);
  }

  async saveAlarm() {
    this.isLoading = true;

    this._epcisCaptureApi
      .writeAlarmsConfig({
        kvintaAlarmsConfig: {
          emails: [...toJS(this.listData).map(({ id }) => id), this.formData['newAlarmForm.email'].value],
        },
      })
      .then((result) => {
        this.listData = (result.emails || []).map((email) => ({ id: email }));
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
        this.closeForm();
      });
  }

  async removeEmail(emailToRemove: string) {
    this.isLoading = true;

    this._epcisCaptureApi
      .writeAlarmsConfig({
        kvintaAlarmsConfig: {
          emails: [
            ...toJS(this.listData)
              .map(({ id }) => id)
              .filter((email) => email !== emailToRemove),
          ],
        },
      })
      .then((result) => {
        this.listData = (result.emails || []).map((email) => ({ id: email }));
      })
      .catch((err) => {
        this._notificationManager.sendError(`An error occurred while submitting data\n${err.message}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

export const ALARM_FORM_ROOT_ID = 'newAlarmForm';

function generateNewAlarmFormData(rootFormId: string, initialValues: { email: string }, emailList: string[]) {
  return formRoot<TNewAlarmForm>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      textInput({
        path: 'email',
        value: initialValues.email,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          isValidEmailAddress({ errorMessage: 'please provide a valid email' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
          valueAlreadyExistsInList({
            errorMessage: ' this email already exists',
            list: emailList,
          }),
        ],
        isRequiredField: true,
      }),
    ],
  });
}

export const ALARMS_STORE_ID = 'alarmsStore';
