/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaGs1DocumentType,
    KvintaGs1DocumentTypeFromJSON,
    KvintaGs1DocumentTypeFromJSONTyped,
    KvintaGs1DocumentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDocumentKey
 */
export interface KvintaDocumentKey {
    /**
     * 
     * @type {KvintaGs1DocumentType}
     * @memberof KvintaDocumentKey
     */
    type: KvintaGs1DocumentType;
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentKey
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaDocumentKey
     */
    number: string;
}

export function KvintaDocumentKeyFromJSON(json: any): KvintaDocumentKey {
    return KvintaDocumentKeyFromJSONTyped(json, false);
}

export function KvintaDocumentKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDocumentKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': KvintaGs1DocumentTypeFromJSON(json['type']),
        'location': json['location'],
        'number': json['number'],
    };
}

export function KvintaDocumentKeyToJSON(value?: KvintaDocumentKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': KvintaGs1DocumentTypeToJSON(value.type),
        'location': value.location,
        'number': value.number,
    };
}


