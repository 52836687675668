/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaQueryResultBusinessPartner,
    KvintaQueryResultBusinessPartnerFromJSON,
    KvintaQueryResultBusinessPartnerFromJSONTyped,
    KvintaQueryResultBusinessPartnerToJSON,
    KvintaQueryResultLocation,
    KvintaQueryResultLocationFromJSON,
    KvintaQueryResultLocationFromJSONTyped,
    KvintaQueryResultLocationToJSON,
    KvintaQueryResultProduct,
    KvintaQueryResultProductFromJSON,
    KvintaQueryResultProductFromJSONTyped,
    KvintaQueryResultProductToJSON,
    KvintaQueryResultTradeItem,
    KvintaQueryResultTradeItemFromJSON,
    KvintaQueryResultTradeItemFromJSONTyped,
    KvintaQueryResultTradeItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryMasterdataResult
 */
export interface KvintaQueryMasterdataResult {
    /**
     * 
     * @type {KvintaQueryResultBusinessPartner}
     * @memberof KvintaQueryMasterdataResult
     */
    bp?: KvintaQueryResultBusinessPartner | null;
    /**
     * 
     * @type {KvintaQueryResultLocation}
     * @memberof KvintaQueryMasterdataResult
     */
    location?: KvintaQueryResultLocation | null;
    /**
     * 
     * @type {KvintaQueryResultProduct}
     * @memberof KvintaQueryMasterdataResult
     */
    product?: KvintaQueryResultProduct | null;
    /**
     * 
     * @type {KvintaQueryResultTradeItem}
     * @memberof KvintaQueryMasterdataResult
     */
    tradeItem?: KvintaQueryResultTradeItem | null;
}

export function KvintaQueryMasterdataResultFromJSON(json: any): KvintaQueryMasterdataResult {
    return KvintaQueryMasterdataResultFromJSONTyped(json, false);
}

export function KvintaQueryMasterdataResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryMasterdataResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bp': !exists(json, 'bp') ? undefined : KvintaQueryResultBusinessPartnerFromJSON(json['bp']),
        'location': !exists(json, 'location') ? undefined : KvintaQueryResultLocationFromJSON(json['location']),
        'product': !exists(json, 'product') ? undefined : KvintaQueryResultProductFromJSON(json['product']),
        'tradeItem': !exists(json, 'tradeItem') ? undefined : KvintaQueryResultTradeItemFromJSON(json['tradeItem']),
    };
}

export function KvintaQueryMasterdataResultToJSON(value?: KvintaQueryMasterdataResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bp': KvintaQueryResultBusinessPartnerToJSON(value.bp),
        'location': KvintaQueryResultLocationToJSON(value.location),
        'product': KvintaQueryResultProductToJSON(value.product),
        'tradeItem': KvintaQueryResultTradeItemToJSON(value.tradeItem),
    };
}


