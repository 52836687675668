import * as React from 'react';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export enum EButtonState {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ACTIVE = 'active',
}
interface PlayerButtonProps {
  onClick: () => void;
  icon: React.ElementType;
  buttonState: EButtonState;
}

const PlayerButton: React.FunctionComponent<PlayerButtonProps> = ({
  onClick,
  icon: Icon,
  buttonState,
}: PlayerButtonProps) => {
  let buttonColor: string;

  switch (buttonState) {
    case EButtonState.ENABLED:
      buttonColor = 'black';
      break;
    case EButtonState.ACTIVE:
      buttonColor = 'red';
      break;
    case EButtonState.DISABLED:
    default:
      buttonColor = 'gray';
  }

  return (
    <div className="timeline-button" onClick={onClick}>
      <Icon style={{ color: buttonColor }} />
    </div>
  );
};

interface NavButtonProps {
  onClick: () => void;
}

interface NavButtonStateProps extends NavButtonProps {
  buttonState: EButtonState;
}

export const RewindButton: React.FunctionComponent<NavButtonStateProps> = ({
  onClick,
  buttonState,
}: NavButtonStateProps) => <PlayerButton onClick={onClick} icon={FastRewindIcon} buttonState={buttonState} />;

export const StopButton: React.FunctionComponent<NavButtonStateProps> = ({
  onClick,
  buttonState,
}: NavButtonStateProps) => <PlayerButton onClick={onClick} icon={StopIcon} buttonState={buttonState} />;

export const PlayButton: React.FunctionComponent<NavButtonStateProps> = ({
  onClick,
  buttonState,
}: NavButtonStateProps) => <PlayerButton onClick={onClick} icon={PlayArrowIcon} buttonState={buttonState} />;

export const FastForwardButton: React.FunctionComponent<NavButtonStateProps> = ({
  onClick,
  buttonState,
}: NavButtonStateProps) => <PlayerButton onClick={onClick} icon={FastForwardIcon} buttonState={buttonState} />;

export const MoveLeftButton: React.FunctionComponent<NavButtonProps> = ({ onClick }: NavButtonProps) => (
  <div className="button" onClick={onClick}>
    <ArrowLeftIcon />
  </div>
);

export const MoveRightButton: React.FunctionComponent<NavButtonProps> = ({ onClick }: NavButtonProps) => (
  <div className="button" onClick={onClick}>
    <ArrowRightIcon />
  </div>
);
