/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaDeliveryIdAndAlarmStatus,
    KvintaDeliveryIdAndAlarmStatusFromJSON,
    KvintaDeliveryIdAndAlarmStatusToJSON,
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyToJSON,
    KvintaMutateDocumentsResult,
    KvintaMutateDocumentsResultFromJSON,
    KvintaMutateDocumentsResultToJSON,
    KvintaMutateMasterdataRequest,
    KvintaMutateMasterdataRequestFromJSON,
    KvintaMutateMasterdataRequestToJSON,
    KvintaMutateMasterdataResult,
    KvintaMutateMasterdataResultFromJSON,
    KvintaMutateMasterdataResultToJSON,
    KvintaMutateRequestBusinessPartnerExternalId,
    KvintaMutateRequestBusinessPartnerExternalIdFromJSON,
    KvintaMutateRequestBusinessPartnerExternalIdToJSON,
    KvintaMutateRequestDeliveryDocumentKey,
    KvintaMutateRequestDeliveryDocumentKeyFromJSON,
    KvintaMutateRequestDeliveryDocumentKeyToJSON,
    KvintaMutateRequestDeliveryItemDeliveryItemKey,
    KvintaMutateRequestDeliveryItemDeliveryItemKeyFromJSON,
    KvintaMutateRequestDeliveryItemDeliveryItemKeyToJSON,
    KvintaMutateRequestLocationExternalId,
    KvintaMutateRequestLocationExternalIdFromJSON,
    KvintaMutateRequestLocationExternalIdToJSON,
    KvintaMutateRequestProductExternalId,
    KvintaMutateRequestProductExternalIdFromJSON,
    KvintaMutateRequestProductExternalIdToJSON,
    KvintaMutateRequestTradeItemTradeItemKey,
    KvintaMutateRequestTradeItemTradeItemKeyFromJSON,
    KvintaMutateRequestTradeItemTradeItemKeyToJSON,
    KvintaMutateResultDeliveryItemKey,
    KvintaMutateResultDeliveryItemKeyFromJSON,
    KvintaMutateResultDeliveryItemKeyToJSON,
    KvintaMutateResultExternalId,
    KvintaMutateResultExternalIdFromJSON,
    KvintaMutateResultExternalIdToJSON,
    KvintaMutateResultTradeItemKey,
    KvintaMutateResultTradeItemKeyFromJSON,
    KvintaMutateResultTradeItemKeyToJSON,
    KvintaQueryDeliveryRequest,
    KvintaQueryDeliveryRequestFromJSON,
    KvintaQueryDeliveryRequestToJSON,
    KvintaQueryMasterdataRequest,
    KvintaQueryMasterdataRequestFromJSON,
    KvintaQueryMasterdataRequestToJSON,
    KvintaQueryMasterdataResult,
    KvintaQueryMasterdataResultFromJSON,
    KvintaQueryMasterdataResultToJSON,
    KvintaQueryRequestBusinessPartnerFilter,
    KvintaQueryRequestBusinessPartnerFilterFromJSON,
    KvintaQueryRequestBusinessPartnerFilterToJSON,
    KvintaQueryRequestDeliveryFilter,
    KvintaQueryRequestDeliveryFilterFromJSON,
    KvintaQueryRequestDeliveryFilterToJSON,
    KvintaQueryRequestDeliveryItemFilter,
    KvintaQueryRequestDeliveryItemFilterFromJSON,
    KvintaQueryRequestDeliveryItemFilterToJSON,
    KvintaQueryRequestLocationFilter,
    KvintaQueryRequestLocationFilterFromJSON,
    KvintaQueryRequestLocationFilterToJSON,
    KvintaQueryRequestProductFilter,
    KvintaQueryRequestProductFilterFromJSON,
    KvintaQueryRequestProductFilterToJSON,
    KvintaQueryRequestTradeItemFilter,
    KvintaQueryRequestTradeItemFilterFromJSON,
    KvintaQueryRequestTradeItemFilterToJSON,
    KvintaQueryResultBusinessPartner,
    KvintaQueryResultBusinessPartnerFromJSON,
    KvintaQueryResultBusinessPartnerToJSON,
    KvintaQueryResultDelivery,
    KvintaQueryResultDeliveryFromJSON,
    KvintaQueryResultDeliveryToJSON,
    KvintaQueryResultDeliveryItem,
    KvintaQueryResultDeliveryItemFromJSON,
    KvintaQueryResultDeliveryItemToJSON,
    KvintaQueryResultLocation,
    KvintaQueryResultLocationFromJSON,
    KvintaQueryResultLocationToJSON,
    KvintaQueryResultProduct,
    KvintaQueryResultProductFromJSON,
    KvintaQueryResultProductToJSON,
    KvintaQueryResultTradeItem,
    KvintaQueryResultTradeItemFromJSON,
    KvintaQueryResultTradeItemToJSON,
    KvintaQueryResultWithConfigDeliveryItem,
    KvintaQueryResultWithConfigDeliveryItemFromJSON,
    KvintaQueryResultWithConfigDeliveryItemToJSON,
    KvintaQueryResultWithConfigLocation,
    KvintaQueryResultWithConfigLocationFromJSON,
    KvintaQueryResultWithConfigLocationToJSON,
    KvintaQueryResultWithConfigTradeItem,
    KvintaQueryResultWithConfigTradeItemFromJSON,
    KvintaQueryResultWithConfigTradeItemToJSON,
} from '../models';

export interface ExportDeliveriesAsCsvRequest {
    kvintaDocumentKey: Array<KvintaDocumentKey>;
}

export interface MutateRequest {
    kvintaMutateMasterdataRequest: KvintaMutateMasterdataRequest;
}

export interface MutateBusinessPartnerRequest {
    kvintaMutateRequestBusinessPartnerExternalId: KvintaMutateRequestBusinessPartnerExternalId;
}

export interface MutateDeliveryRequest {
    kvintaMutateRequestDeliveryDocumentKey: KvintaMutateRequestDeliveryDocumentKey;
}

export interface MutateDeliveryItemRequest {
    kvintaMutateRequestDeliveryItemDeliveryItemKey: KvintaMutateRequestDeliveryItemDeliveryItemKey;
}

export interface MutateLocationRequest {
    kvintaMutateRequestLocationExternalId: KvintaMutateRequestLocationExternalId;
}

export interface MutateProductsRequest {
    kvintaMutateRequestProductExternalId: KvintaMutateRequestProductExternalId;
}

export interface MutateTradeItemRequest {
    kvintaMutateRequestTradeItemTradeItemKey: KvintaMutateRequestTradeItemTradeItemKey;
}

export interface QueryRequest {
    kvintaQueryMasterdataRequest: KvintaQueryMasterdataRequest;
}

export interface QueryBusinessPartnerRequest {
    kvintaQueryRequestBusinessPartnerFilter: KvintaQueryRequestBusinessPartnerFilter;
}

export interface QueryDeliveriesRequest {
    kvintaQueryDeliveryRequest: KvintaQueryDeliveryRequest;
}

export interface QueryDeliveryRequest {
    kvintaQueryRequestDeliveryFilter: KvintaQueryRequestDeliveryFilter;
}

export interface QueryDeliveryItemRequest {
    kvintaQueryRequestDeliveryItemFilter: KvintaQueryRequestDeliveryItemFilter;
}

export interface QueryDeliveryItemWithConfigRequest {
    kvintaQueryRequestDeliveryItemFilter: KvintaQueryRequestDeliveryItemFilter;
}

export interface QueryLocationsRequest {
    kvintaQueryRequestLocationFilter: KvintaQueryRequestLocationFilter;
}

export interface QueryLocationsWithConfigRequest {
    kvintaQueryRequestLocationFilter: KvintaQueryRequestLocationFilter;
}

export interface QueryProductsRequest {
    kvintaQueryRequestProductFilter: KvintaQueryRequestProductFilter;
}

export interface QueryTradeItemWithConfigRequest {
    kvintaQueryRequestTradeItemFilter: KvintaQueryRequestTradeItemFilter;
}

export interface QueryTradeItemsRequest {
    kvintaQueryRequestTradeItemFilter: KvintaQueryRequestTradeItemFilter;
}

export interface UpdateDeliveryAlarmStatusRequest {
    kvintaDeliveryIdAndAlarmStatus: KvintaDeliveryIdAndAlarmStatus;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async exportDeliveriesAsCsvRaw(requestParameters: ExportDeliveriesAsCsvRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.kvintaDocumentKey === null || requestParameters.kvintaDocumentKey === undefined) {
            throw new runtime.RequiredError('kvintaDocumentKey','Required parameter requestParameters.kvintaDocumentKey was null or undefined when calling exportDeliveriesAsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/exportDeliveriesAsCsv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.kvintaDocumentKey.map(KvintaDocumentKeyToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async exportDeliveriesAsCsv(requestParameters: ExportDeliveriesAsCsvRequest): Promise<string> {
        const response = await this.exportDeliveriesAsCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.  You can specify both `delete` and `upsert` values in the request. property,  in this case deletions will be made first, before inserts and updates.   The API implementation will first delete then insert per requested MutationRequest.  Entities will be deleted/created in the following order: BusinessPartner, Location, Product, TradeItem.   Specify .enableBatch = true if you want to do quick mass \"all-or-nothing\" inserts, e.g. set  product.enableBatch = true if you want to mass-insert new products and care about performance.
     * Deletes, inserts, and updates masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.  You can specify both `delete` and `upsert` values in the request. property,  in this case deletions will be made first, before inserts and updates.   The API implementation will first delete then insert per requested MutationRequest.  Entities will be deleted/created in the following order: BusinessPartner, Location, Product, TradeItem.   Specify .enableBatch = true if you want to do quick mass \"all-or-nothing\" inserts, e.g. set  product.enableBatch = true if you want to mass-insert new products and care about performance.
     */
    async mutateRaw(requestParameters: MutateRequest): Promise<runtime.ApiResponse<KvintaMutateMasterdataResult>> {
        if (requestParameters.kvintaMutateMasterdataRequest === null || requestParameters.kvintaMutateMasterdataRequest === undefined) {
            throw new runtime.RequiredError('kvintaMutateMasterdataRequest','Required parameter requestParameters.kvintaMutateMasterdataRequest was null or undefined when calling mutate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateMasterdata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateMasterdataRequestToJSON(requestParameters.kvintaMutateMasterdataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateMasterdataResultFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.  You can specify both `delete` and `upsert` values in the request. property,  in this case deletions will be made first, before inserts and updates.   The API implementation will first delete then insert per requested MutationRequest.  Entities will be deleted/created in the following order: BusinessPartner, Location, Product, TradeItem.   Specify .enableBatch = true if you want to do quick mass \"all-or-nothing\" inserts, e.g. set  product.enableBatch = true if you want to mass-insert new products and care about performance.
     * Deletes, inserts, and updates masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.  You can specify both `delete` and `upsert` values in the request. property,  in this case deletions will be made first, before inserts and updates.   The API implementation will first delete then insert per requested MutationRequest.  Entities will be deleted/created in the following order: BusinessPartner, Location, Product, TradeItem.   Specify .enableBatch = true if you want to do quick mass \"all-or-nothing\" inserts, e.g. set  product.enableBatch = true if you want to mass-insert new products and care about performance.
     */
    async mutate(requestParameters: MutateRequest): Promise<KvintaMutateMasterdataResult> {
        const response = await this.mutateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates BusinessPartner entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.   You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates BusinessPartner entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.   You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateBusinessPartnerRaw(requestParameters: MutateBusinessPartnerRequest): Promise<runtime.ApiResponse<KvintaMutateResultExternalId>> {
        if (requestParameters.kvintaMutateRequestBusinessPartnerExternalId === null || requestParameters.kvintaMutateRequestBusinessPartnerExternalId === undefined) {
            throw new runtime.RequiredError('kvintaMutateRequestBusinessPartnerExternalId','Required parameter requestParameters.kvintaMutateRequestBusinessPartnerExternalId was null or undefined when calling mutateBusinessPartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateBusinessPartner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateRequestBusinessPartnerExternalIdToJSON(requestParameters.kvintaMutateRequestBusinessPartnerExternalId),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateResultExternalIdFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates BusinessPartner entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.   You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates BusinessPartner entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.   You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateBusinessPartner(requestParameters: MutateBusinessPartnerRequest): Promise<KvintaMutateResultExternalId> {
        const response = await this.mutateBusinessPartnerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates Delivery entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates Delivery entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateDeliveryRaw(requestParameters: MutateDeliveryRequest): Promise<runtime.ApiResponse<KvintaMutateDocumentsResult>> {
        if (requestParameters.kvintaMutateRequestDeliveryDocumentKey === null || requestParameters.kvintaMutateRequestDeliveryDocumentKey === undefined) {
            throw new runtime.RequiredError('kvintaMutateRequestDeliveryDocumentKey','Required parameter requestParameters.kvintaMutateRequestDeliveryDocumentKey was null or undefined when calling mutateDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateDelivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateRequestDeliveryDocumentKeyToJSON(requestParameters.kvintaMutateRequestDeliveryDocumentKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateDocumentsResultFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates Delivery entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates Delivery entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateDelivery(requestParameters: MutateDeliveryRequest): Promise<KvintaMutateDocumentsResult> {
        const response = await this.mutateDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates DeliveryItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates DeliveryItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateDeliveryItemRaw(requestParameters: MutateDeliveryItemRequest): Promise<runtime.ApiResponse<KvintaMutateResultDeliveryItemKey>> {
        if (requestParameters.kvintaMutateRequestDeliveryItemDeliveryItemKey === null || requestParameters.kvintaMutateRequestDeliveryItemDeliveryItemKey === undefined) {
            throw new runtime.RequiredError('kvintaMutateRequestDeliveryItemDeliveryItemKey','Required parameter requestParameters.kvintaMutateRequestDeliveryItemDeliveryItemKey was null or undefined when calling mutateDeliveryItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateDeliveryItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateRequestDeliveryItemDeliveryItemKeyToJSON(requestParameters.kvintaMutateRequestDeliveryItemDeliveryItemKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateResultDeliveryItemKeyFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates DeliveryItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates DeliveryItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateDeliveryItem(requestParameters: MutateDeliveryItemRequest): Promise<KvintaMutateResultDeliveryItemKey> {
        const response = await this.mutateDeliveryItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates Location entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates Location entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateLocationRaw(requestParameters: MutateLocationRequest): Promise<runtime.ApiResponse<KvintaMutateResultExternalId>> {
        if (requestParameters.kvintaMutateRequestLocationExternalId === null || requestParameters.kvintaMutateRequestLocationExternalId === undefined) {
            throw new runtime.RequiredError('kvintaMutateRequestLocationExternalId','Required parameter requestParameters.kvintaMutateRequestLocationExternalId was null or undefined when calling mutateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateLocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateRequestLocationExternalIdToJSON(requestParameters.kvintaMutateRequestLocationExternalId),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateResultExternalIdFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates Location entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates Location entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateLocation(requestParameters: MutateLocationRequest): Promise<KvintaMutateResultExternalId> {
        const response = await this.mutateLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates Product entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates Product entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateProductsRaw(requestParameters: MutateProductsRequest): Promise<runtime.ApiResponse<KvintaMutateResultExternalId>> {
        if (requestParameters.kvintaMutateRequestProductExternalId === null || requestParameters.kvintaMutateRequestProductExternalId === undefined) {
            throw new runtime.RequiredError('kvintaMutateRequestProductExternalId','Required parameter requestParameters.kvintaMutateRequestProductExternalId was null or undefined when calling mutateProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateRequestProductExternalIdToJSON(requestParameters.kvintaMutateRequestProductExternalId),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateResultExternalIdFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates Product entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates Product entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateProducts(requestParameters: MutateProductsRequest): Promise<KvintaMutateResultExternalId> {
        const response = await this.mutateProductsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes, inserts, and updates TradeItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates TradeItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateTradeItemRaw(requestParameters: MutateTradeItemRequest): Promise<runtime.ApiResponse<KvintaMutateResultTradeItemKey>> {
        if (requestParameters.kvintaMutateRequestTradeItemTradeItemKey === null || requestParameters.kvintaMutateRequestTradeItemTradeItemKey === undefined) {
            throw new runtime.RequiredError('kvintaMutateRequestTradeItemTradeItemKey','Required parameter requestParameters.kvintaMutateRequestTradeItemTradeItemKey was null or undefined when calling mutateTradeItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mutateTradeItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMutateRequestTradeItemTradeItemKeyToJSON(requestParameters.kvintaMutateRequestTradeItemTradeItemKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMutateResultTradeItemKeyFromJSON(jsonValue));
    }

    /**
     * Deletes, inserts, and updates TradeItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     * Deletes, inserts, and updates TradeItem entities.  You can specify both `delete` and `upsert` values in the request,  in this case deletions will be made first, before inserts and updates.    You can enable batch mode by setting `enableBatch` property of the request to `true`.  In this case all inserts will be done in a batch (atomically), meaning either  all inserts will succeed or all of them will fail. Note that batch mode may perform much faster  than non-batch mode for mass-insertion scenarios.
     */
    async mutateTradeItem(requestParameters: MutateTradeItemRequest): Promise<KvintaMutateResultTradeItemKey> {
        const response = await this.mutateTradeItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.
     * Queries masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.
     */
    async queryRaw(requestParameters: QueryRequest): Promise<runtime.ApiResponse<KvintaQueryMasterdataResult>> {
        if (requestParameters.kvintaQueryMasterdataRequest === null || requestParameters.kvintaQueryMasterdataRequest === undefined) {
            throw new runtime.RequiredError('kvintaQueryMasterdataRequest','Required parameter requestParameters.kvintaQueryMasterdataRequest was null or undefined when calling query.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryMasterdata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryMasterdataRequestToJSON(requestParameters.kvintaQueryMasterdataRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryMasterdataResultFromJSON(jsonValue));
    }

    /**
     * Queries masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.
     * Queries masterdata entities of the following types: BusinessPartner, Location, Product, TradeItem.
     */
    async query(requestParameters: QueryRequest): Promise<KvintaQueryMasterdataResult> {
        const response = await this.queryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries BusinessPartner entities.
     * Queries BusinessPartner entities.
     */
    async queryBusinessPartnerRaw(requestParameters: QueryBusinessPartnerRequest): Promise<runtime.ApiResponse<KvintaQueryResultBusinessPartner>> {
        if (requestParameters.kvintaQueryRequestBusinessPartnerFilter === null || requestParameters.kvintaQueryRequestBusinessPartnerFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestBusinessPartnerFilter','Required parameter requestParameters.kvintaQueryRequestBusinessPartnerFilter was null or undefined when calling queryBusinessPartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryBusinessPartner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestBusinessPartnerFilterToJSON(requestParameters.kvintaQueryRequestBusinessPartnerFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultBusinessPartnerFromJSON(jsonValue));
    }

    /**
     * Queries BusinessPartner entities.
     * Queries BusinessPartner entities.
     */
    async queryBusinessPartner(requestParameters: QueryBusinessPartnerRequest): Promise<KvintaQueryResultBusinessPartner> {
        const response = await this.queryBusinessPartnerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async queryDeliveriesRaw(requestParameters: QueryDeliveriesRequest): Promise<runtime.ApiResponse<KvintaQueryResultDelivery>> {
        if (requestParameters.kvintaQueryDeliveryRequest === null || requestParameters.kvintaQueryDeliveryRequest === undefined) {
            throw new runtime.RequiredError('kvintaQueryDeliveryRequest','Required parameter requestParameters.kvintaQueryDeliveryRequest was null or undefined when calling queryDeliveries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryDeliveries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryDeliveryRequestToJSON(requestParameters.kvintaQueryDeliveryRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultDeliveryFromJSON(jsonValue));
    }

    /**
     */
    async queryDeliveries(requestParameters: QueryDeliveriesRequest): Promise<KvintaQueryResultDelivery> {
        const response = await this.queryDeliveriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries Delivery entities.
     * Queries Delivery entities.
     */
    async queryDeliveryRaw(requestParameters: QueryDeliveryRequest): Promise<runtime.ApiResponse<KvintaQueryResultDelivery>> {
        if (requestParameters.kvintaQueryRequestDeliveryFilter === null || requestParameters.kvintaQueryRequestDeliveryFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestDeliveryFilter','Required parameter requestParameters.kvintaQueryRequestDeliveryFilter was null or undefined when calling queryDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryDelivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestDeliveryFilterToJSON(requestParameters.kvintaQueryRequestDeliveryFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultDeliveryFromJSON(jsonValue));
    }

    /**
     * Queries Delivery entities.
     * Queries Delivery entities.
     */
    async queryDelivery(requestParameters: QueryDeliveryRequest): Promise<KvintaQueryResultDelivery> {
        const response = await this.queryDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries DeliveryItem entities.
     * Queries DeliveryItem entities.
     */
    async queryDeliveryItemRaw(requestParameters: QueryDeliveryItemRequest): Promise<runtime.ApiResponse<KvintaQueryResultDeliveryItem>> {
        if (requestParameters.kvintaQueryRequestDeliveryItemFilter === null || requestParameters.kvintaQueryRequestDeliveryItemFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestDeliveryItemFilter','Required parameter requestParameters.kvintaQueryRequestDeliveryItemFilter was null or undefined when calling queryDeliveryItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryDeliveryItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestDeliveryItemFilterToJSON(requestParameters.kvintaQueryRequestDeliveryItemFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultDeliveryItemFromJSON(jsonValue));
    }

    /**
     * Queries DeliveryItem entities.
     * Queries DeliveryItem entities.
     */
    async queryDeliveryItem(requestParameters: QueryDeliveryItemRequest): Promise<KvintaQueryResultDeliveryItem> {
        const response = await this.queryDeliveryItemRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async queryDeliveryItemWithConfigRaw(requestParameters: QueryDeliveryItemWithConfigRequest): Promise<runtime.ApiResponse<KvintaQueryResultWithConfigDeliveryItem>> {
        if (requestParameters.kvintaQueryRequestDeliveryItemFilter === null || requestParameters.kvintaQueryRequestDeliveryItemFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestDeliveryItemFilter','Required parameter requestParameters.kvintaQueryRequestDeliveryItemFilter was null or undefined when calling queryDeliveryItemWithConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryDeliveryItemWithConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestDeliveryItemFilterToJSON(requestParameters.kvintaQueryRequestDeliveryItemFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultWithConfigDeliveryItemFromJSON(jsonValue));
    }

    /**
     */
    async queryDeliveryItemWithConfig(requestParameters: QueryDeliveryItemWithConfigRequest): Promise<KvintaQueryResultWithConfigDeliveryItem> {
        const response = await this.queryDeliveryItemWithConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries Location entities.
     * Queries Location entities.
     */
    async queryLocationsRaw(requestParameters: QueryLocationsRequest): Promise<runtime.ApiResponse<KvintaQueryResultLocation>> {
        if (requestParameters.kvintaQueryRequestLocationFilter === null || requestParameters.kvintaQueryRequestLocationFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestLocationFilter','Required parameter requestParameters.kvintaQueryRequestLocationFilter was null or undefined when calling queryLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryLocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestLocationFilterToJSON(requestParameters.kvintaQueryRequestLocationFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultLocationFromJSON(jsonValue));
    }

    /**
     * Queries Location entities.
     * Queries Location entities.
     */
    async queryLocations(requestParameters: QueryLocationsRequest): Promise<KvintaQueryResultLocation> {
        const response = await this.queryLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async queryLocationsWithConfigRaw(requestParameters: QueryLocationsWithConfigRequest): Promise<runtime.ApiResponse<KvintaQueryResultWithConfigLocation>> {
        if (requestParameters.kvintaQueryRequestLocationFilter === null || requestParameters.kvintaQueryRequestLocationFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestLocationFilter','Required parameter requestParameters.kvintaQueryRequestLocationFilter was null or undefined when calling queryLocationsWithConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryLocationsWithConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestLocationFilterToJSON(requestParameters.kvintaQueryRequestLocationFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultWithConfigLocationFromJSON(jsonValue));
    }

    /**
     */
    async queryLocationsWithConfig(requestParameters: QueryLocationsWithConfigRequest): Promise<KvintaQueryResultWithConfigLocation> {
        const response = await this.queryLocationsWithConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries Product entities.
     * Queries Product entities.
     */
    async queryProductsRaw(requestParameters: QueryProductsRequest): Promise<runtime.ApiResponse<KvintaQueryResultProduct>> {
        if (requestParameters.kvintaQueryRequestProductFilter === null || requestParameters.kvintaQueryRequestProductFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestProductFilter','Required parameter requestParameters.kvintaQueryRequestProductFilter was null or undefined when calling queryProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestProductFilterToJSON(requestParameters.kvintaQueryRequestProductFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultProductFromJSON(jsonValue));
    }

    /**
     * Queries Product entities.
     * Queries Product entities.
     */
    async queryProducts(requestParameters: QueryProductsRequest): Promise<KvintaQueryResultProduct> {
        const response = await this.queryProductsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async queryTradeItemWithConfigRaw(requestParameters: QueryTradeItemWithConfigRequest): Promise<runtime.ApiResponse<KvintaQueryResultWithConfigTradeItem>> {
        if (requestParameters.kvintaQueryRequestTradeItemFilter === null || requestParameters.kvintaQueryRequestTradeItemFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestTradeItemFilter','Required parameter requestParameters.kvintaQueryRequestTradeItemFilter was null or undefined when calling queryTradeItemWithConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryTradeItemWithConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestTradeItemFilterToJSON(requestParameters.kvintaQueryRequestTradeItemFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultWithConfigTradeItemFromJSON(jsonValue));
    }

    /**
     */
    async queryTradeItemWithConfig(requestParameters: QueryTradeItemWithConfigRequest): Promise<KvintaQueryResultWithConfigTradeItem> {
        const response = await this.queryTradeItemWithConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries TradeItem entities.
     * Queries TradeItem entities.
     */
    async queryTradeItemsRaw(requestParameters: QueryTradeItemsRequest): Promise<runtime.ApiResponse<KvintaQueryResultTradeItem>> {
        if (requestParameters.kvintaQueryRequestTradeItemFilter === null || requestParameters.kvintaQueryRequestTradeItemFilter === undefined) {
            throw new runtime.RequiredError('kvintaQueryRequestTradeItemFilter','Required parameter requestParameters.kvintaQueryRequestTradeItemFilter was null or undefined when calling queryTradeItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/queryTradeItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaQueryRequestTradeItemFilterToJSON(requestParameters.kvintaQueryRequestTradeItemFilter),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaQueryResultTradeItemFromJSON(jsonValue));
    }

    /**
     * Queries TradeItem entities.
     * Queries TradeItem entities.
     */
    async queryTradeItems(requestParameters: QueryTradeItemsRequest): Promise<KvintaQueryResultTradeItem> {
        const response = await this.queryTradeItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries Delivery entities.
     * Queries Delivery entities.
     */
    async updateDeliveryAlarmStatusRaw(requestParameters: UpdateDeliveryAlarmStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.kvintaDeliveryIdAndAlarmStatus === null || requestParameters.kvintaDeliveryIdAndAlarmStatus === undefined) {
            throw new runtime.RequiredError('kvintaDeliveryIdAndAlarmStatus','Required parameter requestParameters.kvintaDeliveryIdAndAlarmStatus was null or undefined when calling updateDeliveryAlarmStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/updateDeliveryAlarmStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaDeliveryIdAndAlarmStatusToJSON(requestParameters.kvintaDeliveryIdAndAlarmStatus),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Queries Delivery entities.
     * Queries Delivery entities.
     */
    async updateDeliveryAlarmStatus(requestParameters: UpdateDeliveryAlarmStatusRequest): Promise<void> {
        await this.updateDeliveryAlarmStatusRaw(requestParameters);
    }

}
