import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { SelectInput } from '../../../components/FormFields/SelectInput';
import styles from './Deliveries.module.scss';
import { DateInput } from '../../../components/FormFields/DateInput';
import { DateTimeInput } from '../../../components/FormFields/DateTimeInput';
import { TAttrConfig } from './DeliveryItemsStore';
import { toJS } from 'mobx';

export interface DeliveryNoteDeliveryItemProps {
  formData: any;
  formType: EFormType;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  attrConfig?: TAttrConfig;
}

export enum EFormType {
  UPDATE = 'update',
  CREATE = 'create',
}

const DeliveryItemFormBase: FunctionComponent<DeliveryNoteDeliveryItemProps> = ({
  formData,
  onChange,
  onBlur,
  formType,
  attrConfig,
}) => {
  const attrFields = Object.keys(attrConfig || {}).map((key) => {
    return (
      <TextInput
        key={key}
        {...formData[`deliveryItemForm.${key}`]}
        onChange={onChange}
        onBlur={onBlur}
        label={`attr.${attrConfig[key].key}`}
        defaultMessage={attrConfig[key].name}
      />
    );
  });

  return (
    <div className={styles.updateFormContainer} id={'DeliveryItemFormID'}>
      <TextInput
        {...formData['deliveryItemForm.productCode']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.productCode"
        style={{ gridColumn: '1/span 2' }}
      />
      <TextInput
        {...formData['deliveryItemForm.itemNumber']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.itemNumber"
        disabled={formType === EFormType.UPDATE}
      />
      <TextInput
        {...formData['deliveryItemForm.batchNumber']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.batchNumber"
      />
      <div className={styles.divider} />
      <TextInput
        {...formData['deliveryItemForm.qtyExpected']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.qtyExpected"
      />
      <DateInput
        {...formData['deliveryItemForm.dateExpected']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.dateExpected"
      />

      <TextInput
        {...formData['deliveryItemForm.qtyConfirmed']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.qtyConfirmed"
      />
      <DateTimeInput
        {...formData['deliveryItemForm.dateConfirmed']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.dateConfirmed"
      />
      <div className={styles.divider} />
      <DateInput
        {...formData['deliveryItemForm.expiryDate']}
        onChange={onChange}
        onBlur={onBlur}
        label="delivery-item-list.header.expiryDate"
      />

      <SelectInput
        {...formData['deliveryItemForm.serializationType']}
        onChange={onChange}
        onBlur={onBlur}
        label="product-form.field.serializationType"
      />
      <div className={styles.divider} />

      {attrFields}
    </div>
  );
};

export const DeliveryItemForm = observer(DeliveryItemFormBase);
