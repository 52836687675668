import * as React from 'react';
import classNames from 'classnames';
import ArrowLeft from '../../../assets/img/arrow-left.svg';
import ArrowRight from '../../../assets/img/arrow-right.svg';
import { clamp } from './utils';

type TimelinePointId = string;

export type TTimelinePoint = { id: TimelinePointId; date: string };

export type TimelineProps = {
  data: TTimelinePoint[];
  activeTimelinePointId: TimelinePointId;
  updateActiveTimelinePoint: (id: string) => void;
};

export const Timeline: React.ComponentType<TimelineProps> = ({
  data,
  activeTimelinePointId,
  updateActiveTimelinePoint,
}: TimelineProps) => {
  if (!data) {
    return null;
  }
  const [sliderWidth, setSliderWidth] = React.useState(0);
  const [sliderWrapperWidth, setSliderWrapperWidth] = React.useState(0);
  const [sliderShift, setSliderShift] = React.useState(0);
  const [sliderButtonShift, setSliderButtonShift] = React.useState(0);

  const sliderRef = React.useCallback((node) => {
    if (node !== null) {
      setSliderWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const sliderWrapperRef = React.useCallback((node) => {
    if (node !== null) {
      setSliderWrapperWidth(node.getBoundingClientRect().width);
    }
  }, []);

  React.useMemo(() => {
    if (data.length && sliderWidth > sliderWrapperWidth) {
      const timelinePointWidth = sliderWidth / data.length;

      const sliderButtonShift = Math.floor(sliderWrapperWidth / timelinePointWidth) * timelinePointWidth;
      setSliderButtonShift(sliderButtonShift);
    }
  }, [sliderWidth, sliderWrapperWidth]);

  React.useMemo(() => {
    const activePointIndex = data.findIndex((point) => point.id === activeTimelinePointId);
    if (data.length && activePointIndex && sliderWidth > sliderWrapperWidth) {
      const timelinePointWidth = sliderWidth / data.length;
      const shift = clamp({
        actualValue: timelinePointWidth * activePointIndex - sliderWrapperWidth / 2 + timelinePointWidth / 2,
        minValue: 0,
        maxValue: sliderWidth - sliderWrapperWidth,
      });

      if (shift !== sliderShift) {
        setSliderShift(shift);
      }
    }
  }, [activeTimelinePointId, sliderWidth, sliderWrapperWidth]);

  return (
    <div className="timeline-wrapper">
      <div
        className="timeline-button"
        onClick={() =>
          setSliderShift(
            clamp({
              actualValue: sliderShift - sliderButtonShift,
              minValue: 0,
              maxValue: sliderWidth - sliderWrapperWidth,
            }),
          )
        }
      >
        <img alt="" src={ArrowLeft} />
      </div>
      <div className="timeline">
        <div className="timeline-slider" ref={sliderWrapperRef}>
          <div className="timeline-line" />
          <div className="timeline-points" ref={sliderRef} style={{ left: `-${sliderShift}px` }}>
            {data.map((timelinePoint, i) => {
              return (
                <div className="timeline-point-wrapper" key={timelinePoint.id}>
                  <div
                    className={classNames('timeline-point', {
                      ['active']: activeTimelinePointId === timelinePoint.id,
                    })}
                    onClick={() => updateActiveTimelinePoint(timelinePoint.id)}
                  >
                    {i + 1}
                  </div>
                  <div className="timeline-point-label">{timelinePoint.date}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="timeline-button"
        onClick={() =>
          setSliderShift(
            clamp({
              actualValue: sliderShift + sliderButtonShift,
              minValue: 0,
              maxValue: sliderWidth - sliderWrapperWidth,
            }),
          )
        }
      >
        <img alt="" src={ArrowRight} />
      </div>
    </div>
  );
};
