import React, { useEffect, useState } from 'react';
import { FormControl, InputAdornment, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';
import { TMultipleSelectFilter } from './FilterBar';
import { ApplyFilterButton, ClearFilterButton, RemoveFilterButton } from './TextFilter';

export type TMultipleSelectFilterProps = {
  filter: TMultipleSelectFilter;
  showRemoveButton: boolean;
  intl: any;
  onChangeValue: (field: string, value: string[]) => void;
  onClear: (field: string) => void;
  onFilter: (field: string) => void;
  hideFilter: (field: string) => void;
};

const FilterAdornment = ({ filter, showRemoveButton, hideFilter, onClear, cancelChanges, onFilter, id }) => {
  if (filter.value[0] === '$default$') {
    return (
      <>
        {!filter.isApplied && <ClearFilterButton id={id} onClick={() => cancelChanges(filter.id)} />}
        {showRemoveButton && <RemoveFilterButton id={id} onClick={() => hideFilter(filter.id)} />}
      </>
    );
  } else if (filter.isApplied) {
    return (
      <>
        {filter.value.length ? <ClearFilterButton id={id} onClick={() => onClear(filter.id)} /> : null}
        {showRemoveButton && <RemoveFilterButton id={id} onClick={() => hideFilter(filter.id)} />}
      </>
    );
  } else {
    return (
      <>
        {filter.value.length || !filter.isApplied ? (
          <>
            <ClearFilterButton id={id} onClick={() => cancelChanges(filter.id)} />
            <ApplyFilterButton id={id} onClick={() => onFilter(filter.id)} />
          </>
        ) : null}
        {showRemoveButton && <RemoveFilterButton id={id} onClick={() => hideFilter(filter.id)} />}
      </>
    );
  }
};

const MultipleSelectFilterBase: React.FunctionComponent<TMultipleSelectFilterProps> = ({
  filter,
  showRemoveButton,
  intl,
  onChangeValue,
  hideFilter,
  onClear,
  onFilter,
}) => {
  const [filterData, setFilterData] = useState(filter);
  const filterFieldId = 'filter-' + filter.id;
  const translatedLabel = intl.formatMessage({ id: 'filter.by' }) + ' ' + intl.formatMessage({ id: filter.label });

  useEffect(() => {
    setFilterData(filter);
  }, [filter]);

  return (
    <div className="ZstFilter-root" style={{ marginLeft: '5px' }}>
      <FormControl variant="outlined" key={filterData.id} style={{ height: '40px' }}>
        <Select
          id={filterFieldId}
          value={filterData.value.length ? filterData.value : ['$default$']}
          style={{ height: '40px', maxWidth: '280px' }}
          multiple={true}
          renderValue={(selected) => {
            if ((selected as string[])[0] === '$default$') {
              return translatedLabel;
            } else {
              const label = (selected as string[]).join(', ');
              return `${label.slice(0, 18)}${label.length > 18 ? '...' : ''}`;
            }
          }}
          onChange={(e) => {
            setFilterData((prevFilter) => {
              return {
                ...prevFilter,
                value: (e.target.value as string[]).filter((val) => val !== '$default$'),
                isApplied: false,
              };
            });
          }}
          endAdornment={
            <InputAdornment position="end">
              <FilterAdornment
                id={filterFieldId}
                filter={filterData}
                hideFilter={hideFilter}
                onFilter={() => {
                  onChangeValue(
                    filterData.id,
                    (filterData.value as string[]).filter((val) => val !== '$default$'),
                  );
                  onFilter(filterData.id);
                }}
                onClear={(id) => {
                  onClear(id);
                }}
                showRemoveButton={showRemoveButton}
                cancelChanges={() => {
                  setFilterData(filter);
                }}
              />
            </InputAdornment>
          }
        >
          <MenuItem disabled value="$default$">
            {translatedLabel}
          </MenuItem>
          {filter.options.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const MultipleSelectFilter = observer(MultipleSelectFilterBase);
