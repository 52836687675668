import React, { Component } from 'react';
import { tabs } from './tabs';
import { EButtonState, EPCIS_MESSAGE_STORE_ID, EpcisMessageStore } from './EpcisMessageStore';
import { KSidebarStore, PAGE_CONTENT_STORE_ID, PageContentStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { reaction } from 'mobx';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { TextField } from '../../../components/FormFields/TextField';
import styles from './EpcisMessage.module.scss';
import DownloadIcon from '@mui/icons-material/GetAppOutlined';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { USER_STORE_ID, UserStore } from '../../main';
import { KvintaMessageStatusType } from '../../../apis/kvinta-epcis-capture';

interface EpcisEventSetSummaryProps {
  intl: any;
  userStore: UserStore;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  epcisMessageStore: EpcisMessageStore;
  pageContentStore: PageContentStore;
  id: string;
}

class EpcisMessageSummaryBase extends Component<EpcisEventSetSummaryProps> {
  disposer: any;

  componentDidMount() {
    this.props.epcisMessageStore.fetchMessageById(this.props.id);
    this.props.sidebarStore.clearMainTitle();
    this.disposer = reaction(
      () => this.props.epcisMessageStore.currentMessage,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.id, () => {
            this.props.historyStore.navigateEpcisMessageListPath();
            // .navigateEpcisEventSetListPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const {
      currentMessage,
      isFailedMessageLoading,
      ignoreFailedMessage,
      isFailedMessageForRetryLoading,
      sendFailedMessageForRetry,
    } = this.props.epcisMessageStore;
    const { canAccess } = this.props.userStore;
    if (!currentMessage) {
      return null;
    }
    const ignoreFailedMessageButtonState = isFailedMessageLoading
      ? EButtonState.LOADING
      : currentMessage.status === KvintaMessageStatusType.Failed
      ? EButtonState.ACTIVE
      : EButtonState.DISABLED;
    const resendButtonState = isFailedMessageForRetryLoading
      ? EButtonState.LOADING
      : currentMessage.status === KvintaMessageStatusType.Failed ||
        currentMessage.status === KvintaMessageStatusType.FailedIgnore
      ? EButtonState.ACTIVE
      : EButtonState.DISABLED;

    return (
      <div className={styles.mainContainer}>
        <TabBar onChange={(_newValue) => null} tabId={'1'} tabs={tabs} />

        <div className={styles.summaryMain}>
          <div className={styles.summaryButtonContainer}>
            {canAccess('EpcisMessages.IgnoreFailedMessage') && (
              <>
                <ButtonGreen
                  id="ignoreFailedMessage"
                  icon={<UnsubscribeIcon color="secondary" />}
                  label={this.props.intl.formatMessage({ id: 'button.ignoreFailedMessage' })}
                  buttonState={ignoreFailedMessageButtonState}
                  onClick={() => {
                    const shouldDelete = confirm(
                      'Do you want to active  Ignore Failed Message for ID:' + currentMessage.id + '?',
                    );
                    if (shouldDelete) {
                      void ignoreFailedMessage(currentMessage.messageKey);
                    }
                  }}
                />
                <ButtonGreen
                  id="resend"
                  icon={<ForwardToInboxIcon color="secondary" />}
                  label={this.props.intl.formatMessage({ id: 'button.resend' })}
                  buttonState={resendButtonState}
                  onClick={() => {
                    const shouldDelete = confirm(
                      'Do you want to send Failed Message For Retry for ID:' + currentMessage.id + '?',
                    );
                    if (shouldDelete) {
                      void sendFailedMessageForRetry(currentMessage.messageKey);
                    }
                  }}
                />
              </>
            )}
            <ButtonGreen
              id="download"
              icon={<DownloadIcon color="secondary" />}
              label={this.props.intl.formatMessage({ id: 'button.download' })}
              buttonState={currentMessage.downloadButtonState}
              onClick={() => this.props.epcisMessageStore.downloadMessageFromMessageSummary(currentMessage.messageKey)}
            />
          </div>
          <div className={styles.formContainer} id={'EpcisMessageSummaryID'}>
            <TextField label="epcis-mesage.id" value={currentMessage.id} style={{ gridColumn: '1 / span 2' }} />
            <TextField label="epcis-mesage.direction" value={currentMessage.direction} />
            <TextField label="epcis-mesage.type" value={currentMessage.type} />

            <TextField label="epcis-mesage.path" value={currentMessage.path} />
            <TextField label="epcis-mesage.created" value={currentMessage.created} />

            <TextField label="epcis-mesage.status" value={currentMessage.status} />
            <TextField label="epcis-mesage.updated" value={currentMessage.updated} />
            {currentMessage.error && (
              <TextField label="epcis-mesage.error" value={currentMessage.error} style={{ gridColumn: '1 / span 2' }} />
            )}

            <TextField
              label="epcis-mesage.triggeringEventId"
              value={currentMessage.triggeringEventId}
              style={{ gridColumn: '1 / span 2' }}
            />
            <TextField
              label="epcis-mesage.workflowId"
              value={currentMessage.workflowId}
              style={{ gridColumn: '1 / span 2' }}
            />
            <TextField label="epcis-mesage.userId" value={currentMessage.userId} />
            <TextField label="epcis-mesage.sender" value={currentMessage.sender} />
            <TextField label="epcis-mesage.receiver" value={currentMessage.receiver} />
          </div>
        </div>
      </div>
    );
  }
}

export const EpcisMessageSummary = injectIntl(
  inject(
    USER_STORE_ID,
    HISTORY_STORE_ID,
    EPCIS_MESSAGE_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(EpcisMessageSummaryBase)),
);
