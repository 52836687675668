function getEnv(environmentVariableName: string) {
  const env = (window as any)._env_;
  if (env === undefined) {
    console.warn('_env_ is not defined, will be using the dev. cloud defaults');
    return undefined;
  }
  const value = env[environmentVariableName];
  console.info(`Environment '${environmentVariableName}' = '${value}'`);

  if (!value) {
    console.error(`Environment '${environmentVariableName}' NOT FOUND`);
    return ''
  }
  return value;
}

export const version = process.env.VERSION || 'xxx';
console.log('config:version', version);
export const baseDomain = getEnv('BASE_DOMAIN');
export const loggerEndpoint = getEnv('LOGGER_ENDPOINT');

// API URLs
export const masterdataApiPath = getEnv('KVINTA_MASTERDATA_URL');
export const devicesApiPath = getEnv('KVINTA_DEVICES_STORE_URL');
export const epcisCaptureApiPath =
  getEnv('KVINTA_EPCIS_CAPTURE_URL');
export const epcisStoreApiPath = getEnv('KVINTA_EPCIS_STORE_URL');

export const defaultTaxNumber = getEnv('DEFAULT_TAX_NUMBER');

export const keycloakApiPath = getEnv('KVINTA_ID_URL');
export const keycloakRealm = getEnv('KVINTA_ID_REALM');
export const keycloakClientId = getEnv('KVINTA_ID_CLIENT_ID');

export const productGroupIsBio = ['bio'].includes(getEnv('PRODUCT_GROUP'));

export const keycloakConfig = {
  realm: keycloakRealm,
  url: keycloakApiPath,
  clientId: keycloakClientId,
};

export const KEYCLOAK_INIT_CONFIG = {
  onLoad: 'login-required' as const,
  checkLoginIframe: false,
  enableCors: true,
  flow: 'standard' as const,
  enableLogging: true,
  pkceMethod: 'S256' as const,
  // tokenCookiePath: '/',
};

export const cookieName = 'kvinta_auth';
export const localStorageAuthKey = 'AUTH';

export const defaultDateTimeFormatting = 'dd.MM.yyyy HH:mm';
export const defaultDateFormatting = 'dd.MM.yyyy';

export const build = 'app-kvinta-dev';
export const availableLanguages = ['en', 'ru'];
