/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaAllowRequireType {
    NotAllowed = 'NOT_ALLOWED',
    Allowed = 'ALLOWED',
    Required = 'REQUIRED'
}

export function KvintaAllowRequireTypeFromJSON(json: any): KvintaAllowRequireType {
    return KvintaAllowRequireTypeFromJSONTyped(json, false);
}

export function KvintaAllowRequireTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAllowRequireType {
    return json as KvintaAllowRequireType;
}

export function KvintaAllowRequireTypeToJSON(value?: KvintaAllowRequireType | null): any {
    return value as any;
}

