/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCaptureErrorDeclaration
 */
export interface KvintaCaptureErrorDeclaration {
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureErrorDeclaration
     */
    declarationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureErrorDeclaration
     */
    reason: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaCaptureErrorDeclaration
     */
    correctiveEventIDs?: Array<string> | null;
}

export function KvintaCaptureErrorDeclarationFromJSON(json: any): KvintaCaptureErrorDeclaration {
    return KvintaCaptureErrorDeclarationFromJSONTyped(json, false);
}

export function KvintaCaptureErrorDeclarationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureErrorDeclaration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'declarationTime': (new Date(json['declarationTime'])),
        'reason': json['reason'],
        'correctiveEventIDs': !exists(json, 'correctiveEventIDs') ? undefined : json['correctiveEventIDs'],
    };
}

export function KvintaCaptureErrorDeclarationToJSON(value?: KvintaCaptureErrorDeclaration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'declarationTime': (value.declarationTime.toISOString()),
        'reason': value.reason,
        'correctiveEventIDs': value.correctiveEventIDs,
    };
}


