/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaSftpDeliveryInboundConfig,
    KvintaSftpDeliveryInboundConfigFromJSON,
    KvintaSftpDeliveryInboundConfigFromJSONTyped,
    KvintaSftpDeliveryInboundConfigToJSON,
    KvintaSftpScnInboundConfig,
    KvintaSftpScnInboundConfigFromJSON,
    KvintaSftpScnInboundConfigFromJSONTyped,
    KvintaSftpScnInboundConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaSftpInboundConfig
 */
export interface KvintaSftpInboundConfig {
    /**
     * 
     * @type {KvintaSftpScnInboundConfig}
     * @memberof KvintaSftpInboundConfig
     */
    scn?: KvintaSftpScnInboundConfig | null;
    /**
     * 
     * @type {KvintaSftpDeliveryInboundConfig}
     * @memberof KvintaSftpInboundConfig
     */
    delivery?: KvintaSftpDeliveryInboundConfig | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaSftpInboundConfig
     */
    disabled: boolean;
}

export function KvintaSftpInboundConfigFromJSON(json: any): KvintaSftpInboundConfig {
    return KvintaSftpInboundConfigFromJSONTyped(json, false);
}

export function KvintaSftpInboundConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaSftpInboundConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scn': !exists(json, 'scn') ? undefined : KvintaSftpScnInboundConfigFromJSON(json['scn']),
        'delivery': !exists(json, 'delivery') ? undefined : KvintaSftpDeliveryInboundConfigFromJSON(json['delivery']),
        'disabled': json['disabled'],
    };
}

export function KvintaSftpInboundConfigToJSON(value?: KvintaSftpInboundConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scn': KvintaSftpScnInboundConfigToJSON(value.scn),
        'delivery': KvintaSftpDeliveryInboundConfigToJSON(value.delivery),
        'disabled': value.disabled,
    };
}


