import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';

import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Alarms.module.scss';
import MaterialTable from 'material-table';
import { ALARM_FORM_ROOT_ID, ALARMS_STORE_ID, AlarmsStore } from './AlarmsStore';
import { EUserRole, USER_STORE_ID, UserStore } from '../../main';
import { Button, Dialog, DialogActions, DialogContent, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { KDialogTitle } from '../../../components/KDialog';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { EButtonState } from '../../../common/formUtils/types';
import { toJS } from 'mobx';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ITableActionsState } from '../../../common';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';

interface NotificationSummaryViewProps {
  intl: any;
  userStore: UserStore;
  alarmsStore?: AlarmsStore;
  pageContentStore?: PageContentStore;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  id: string;
  currentDevice?: any;
}

export type TAlarmFormProps = {
  intl: IntlShape;
  closeForm: () => void;
  saveAlarm: () => void;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string, value: string) => void;
  formData: any;
  saveAlarmButtonState: EButtonState;
};

const AlarmForm: React.FunctionComponent<TAlarmFormProps> = ({
  intl,
  closeForm,
  formData,
  saveAlarm,
  onChange,
  onBlur,
  saveAlarmButtonState,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeForm}>
    <KDialogTitle id="customized-dialog-title" onClose={closeForm}>
      {intl.formatMessage({ id: 'device-create-form.create' })}
    </KDialogTitle>
    <DialogContent>
      <TextInput
        {...formData['newAlarmForm.email']}
        onChange={onChange}
        onBlur={onBlur}
        label={intl.formatMessage({ id: 'alarm.email' })}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={closeForm} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        label={intl.formatMessage({ id: 'button.submit' })}
        onClick={saveAlarm}
        buttonState={formDataToButtonState(formData[ALARM_FORM_ROOT_ID])}
        id={'openOrSubmitButton'}
      />
    </DialogActions>
  </Dialog>
);

class AlarmsListBase extends Component<NotificationSummaryViewProps, ITableActionsState> {
  constructor(props: NotificationSummaryViewProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  async componentDidMount() {
    this.props.alarmsStore.fetchAlarmsList();
  }

  render() {
    const intl = this.props.intl;
    const {
      listData,
      isLoading,
      newAlarmFormOpen,
      saveAlarm,
      formData,
      saveAlarmButtonState,
      closeForm,
      handleNewAlarmFormChange,
      handleNewAlarmFormBlur,
      removeEmail,
    } = this.props.alarmsStore;
    const { canAccess } = this.props.userStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    return (
      <div className={styles.mainContainer}>
        {canAccess('Alarms.create') && (
          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.alarmsStore.openForm();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        )}
        <div id="AlarmsListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: intl.formatMessage({ id: 'alarms-list.receiver' }),
                field: 'id',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: false,
              sorting: false,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              thirdSortClick: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            actions={[
              canAccess('Alarms.delete') && {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => null,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          {canAccess('Alarms.delete') && (
            <MenuItem
              onClick={() => {
                const shouldDelete = confirm(`Do you want to remove email ${this.state.rowData.id}?`);
                if (shouldDelete) {
                  removeEmail(this.state.rowData.id);
                }
                this.onMenuClose();
              }}
            >
              {intl.formatMessage({ id: 'button.delete' })}
            </MenuItem>
          )}
        </Menu>
        {newAlarmFormOpen && (
          <AlarmForm
            saveAlarm={saveAlarm}
            formData={formData}
            onChange={handleNewAlarmFormChange}
            onBlur={handleNewAlarmFormBlur}
            saveAlarmButtonState={saveAlarmButtonState}
            closeForm={closeForm}
            intl={intl}
          />
        )}
      </div>
    );
  }
}

export const AlarmsList = injectIntl(
  inject(
    USER_STORE_ID,
    HISTORY_STORE_ID,
    ALARMS_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(AlarmsListBase)),
);
