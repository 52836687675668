/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaDeleteObjectsRequest
 */
export interface KvintaDeleteObjectsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaDeleteObjectsRequest
     */
    ids: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeleteObjectsRequest
     */
    objectType: string;
}

export function KvintaDeleteObjectsRequestFromJSON(json: any): KvintaDeleteObjectsRequest {
    return KvintaDeleteObjectsRequestFromJSONTyped(json, false);
}

export function KvintaDeleteObjectsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeleteObjectsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'objectType': json['objectType'],
    };
}

export function KvintaDeleteObjectsRequestToJSON(value?: KvintaDeleteObjectsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'objectType': value.objectType,
    };
}


