/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaAlarmStatus {
    Unknown = 'UNKNOWN',
    Finalized = 'FINALIZED',
    Mismatch = 'MISMATCH',
    NonUnique = 'NON_UNIQUE',
    Ok = 'OK'
}

export function KvintaAlarmStatusFromJSON(json: any): KvintaAlarmStatus {
    return KvintaAlarmStatusFromJSONTyped(json, false);
}

export function KvintaAlarmStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAlarmStatus {
    return json as KvintaAlarmStatus;
}

export function KvintaAlarmStatusToJSON(value?: KvintaAlarmStatus | null): any {
    return value as any;
}

