/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta EPCIS Document Store
 * Swagger for Kvinta EPCIS Document Store
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcisEventRecord,
    KvintaEpcisEventRecordFromJSON,
    KvintaEpcisEventRecordFromJSONTyped,
    KvintaEpcisEventRecordToJSON,
    KvintaOperationStatus,
    KvintaOperationStatusFromJSON,
    KvintaOperationStatusFromJSONTyped,
    KvintaOperationStatusToJSON,
    KvintaQueueAction,
    KvintaQueueActionFromJSON,
    KvintaQueueActionFromJSONTyped,
    KvintaQueueActionToJSON,
    KvintaResource,
    KvintaResourceFromJSON,
    KvintaResourceFromJSONTyped,
    KvintaResourceToJSON,
    KvintaResourceChanges,
    KvintaResourceChangesFromJSON,
    KvintaResourceChangesFromJSONTyped,
    KvintaResourceChangesToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaOperationResponseListEpcisEventRecord
 */
export interface KvintaOperationResponseListEpcisEventRecord {
    /**
     * 
     * @type {Array<KvintaEpcisEventRecord>}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    data?: Array<KvintaEpcisEventRecord> | null;
    /**
     * 
     * @type {KvintaOperationStatus}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    status?: KvintaOperationStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    executionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    rootExecutionId?: string | null;
    /**
     * 
     * @type {KvintaResource}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    resource?: KvintaResource;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    duplicate?: boolean;
    /**
     * 
     * @type {KvintaResourceChanges}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    resourceChanges?: KvintaResourceChanges;
    /**
     * 
     * @type {KvintaQueueAction}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    queueAction?: KvintaQueueAction;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaOperationResponseListEpcisEventRecord
     */
    continued?: boolean | null;
}

export function KvintaOperationResponseListEpcisEventRecordFromJSON(json: any): KvintaOperationResponseListEpcisEventRecord {
    return KvintaOperationResponseListEpcisEventRecordFromJSONTyped(json, false);
}

export function KvintaOperationResponseListEpcisEventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaOperationResponseListEpcisEventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(KvintaEpcisEventRecordFromJSON)),
        'status': !exists(json, 'status') ? undefined : KvintaOperationStatusFromJSON(json['status']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'executionId': !exists(json, 'executionId') ? undefined : json['executionId'],
        'rootExecutionId': !exists(json, 'rootExecutionId') ? undefined : json['rootExecutionId'],
        'resource': !exists(json, 'resource') ? undefined : KvintaResourceFromJSON(json['resource']),
        'duplicate': !exists(json, 'duplicate') ? undefined : json['duplicate'],
        'resourceChanges': !exists(json, 'resourceChanges') ? undefined : KvintaResourceChangesFromJSON(json['resourceChanges']),
        'queueAction': !exists(json, 'queueAction') ? undefined : KvintaQueueActionFromJSON(json['queueAction']),
        'continued': !exists(json, 'continued') ? undefined : json['continued'],
    };
}

export function KvintaOperationResponseListEpcisEventRecordToJSON(value?: KvintaOperationResponseListEpcisEventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(KvintaEpcisEventRecordToJSON)),
        'status': KvintaOperationStatusToJSON(value.status),
        'error': value.error,
        'message': value.message,
        'executionId': value.executionId,
        'rootExecutionId': value.rootExecutionId,
        'resource': KvintaResourceToJSON(value.resource),
        'duplicate': value.duplicate,
        'resourceChanges': KvintaResourceChangesToJSON(value.resourceChanges),
        'queueAction': KvintaQueueActionToJSON(value.queueAction),
        'continued': value.continued,
    };
}


