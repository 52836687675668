/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaActivityConfiguration,
    KvintaActivityConfigurationFromJSON,
    KvintaActivityConfigurationFromJSONTyped,
    KvintaActivityConfigurationToJSON,
    KvintaDuration,
    KvintaDurationFromJSON,
    KvintaDurationFromJSONTyped,
    KvintaDurationToJSON,
    KvintaRetryConfiguration,
    KvintaRetryConfigurationFromJSON,
    KvintaRetryConfigurationFromJSONTyped,
    KvintaRetryConfigurationToJSON,
    KvintaWorkflowIdReusePolicy,
    KvintaWorkflowIdReusePolicyFromJSON,
    KvintaWorkflowIdReusePolicyFromJSONTyped,
    KvintaWorkflowIdReusePolicyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaWorkflowConfiguration
 */
export interface KvintaWorkflowConfiguration {
    /**
     * 
     * @type {string}
     * @memberof KvintaWorkflowConfiguration
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaWorkflowConfiguration
     */
    taskQueue?: string | null;
    /**
     * 
     * @type {{ [key: string]: KvintaActivityConfiguration; }}
     * @memberof KvintaWorkflowConfiguration
     */
    activities?: { [key: string]: KvintaActivityConfiguration; } | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaWorkflowConfiguration
     */
    cronSchedule?: string | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaWorkflowConfiguration
     */
    workflowExecutionTimeout?: KvintaDuration | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaWorkflowConfiguration
     */
    workflowRunTimeout?: KvintaDuration | null;
    /**
     * 
     * @type {{ [key: string]: KvintaWorkflowConfiguration; }}
     * @memberof KvintaWorkflowConfiguration
     */
    childWorkflows?: { [key: string]: KvintaWorkflowConfiguration; } | null;
    /**
     * 
     * @type {KvintaRetryConfiguration}
     * @memberof KvintaWorkflowConfiguration
     */
    retryOptions?: KvintaRetryConfiguration | null;
    /**
     * 
     * @type {KvintaWorkflowIdReusePolicy}
     * @memberof KvintaWorkflowConfiguration
     */
    workflowIdReusePolicy?: KvintaWorkflowIdReusePolicy | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaWorkflowConfiguration
     */
    disabled: boolean;
}

export function KvintaWorkflowConfigurationFromJSON(json: any): KvintaWorkflowConfiguration {
    return KvintaWorkflowConfigurationFromJSONTyped(json, false);
}

export function KvintaWorkflowConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaWorkflowConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'taskQueue': !exists(json, 'taskQueue') ? undefined : json['taskQueue'],
        'activities': !exists(json, 'activities') ? undefined : (json['activities'] === null ? null : mapValues(json['activities'], KvintaActivityConfigurationFromJSON)),
        'cronSchedule': !exists(json, 'cronSchedule') ? undefined : json['cronSchedule'],
        'workflowExecutionTimeout': !exists(json, 'workflowExecutionTimeout') ? undefined : KvintaDurationFromJSON(json['workflowExecutionTimeout']),
        'workflowRunTimeout': !exists(json, 'workflowRunTimeout') ? undefined : KvintaDurationFromJSON(json['workflowRunTimeout']),
        'childWorkflows': !exists(json, 'childWorkflows') ? undefined : (json['childWorkflows'] === null ? null : mapValues(json['childWorkflows'], KvintaWorkflowConfigurationFromJSON)),
        'retryOptions': !exists(json, 'retryOptions') ? undefined : KvintaRetryConfigurationFromJSON(json['retryOptions']),
        'workflowIdReusePolicy': !exists(json, 'workflowIdReusePolicy') ? undefined : KvintaWorkflowIdReusePolicyFromJSON(json['workflowIdReusePolicy']),
        'disabled': json['disabled'],
    };
}

export function KvintaWorkflowConfigurationToJSON(value?: KvintaWorkflowConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'taskQueue': value.taskQueue,
        'activities': value.activities === undefined ? undefined : (value.activities === null ? null : mapValues(value.activities, KvintaActivityConfigurationToJSON)),
        'cronSchedule': value.cronSchedule,
        'workflowExecutionTimeout': KvintaDurationToJSON(value.workflowExecutionTimeout),
        'workflowRunTimeout': KvintaDurationToJSON(value.workflowRunTimeout),
        'childWorkflows': value.childWorkflows === undefined ? undefined : (value.childWorkflows === null ? null : mapValues(value.childWorkflows, KvintaWorkflowConfigurationToJSON)),
        'retryOptions': KvintaRetryConfigurationToJSON(value.retryOptions),
        'workflowIdReusePolicy': KvintaWorkflowIdReusePolicyToJSON(value.workflowIdReusePolicy),
        'disabled': value.disabled,
    };
}


