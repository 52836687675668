/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageRecord,
    KvintaMessageRecordFromJSON,
    KvintaMessageRecordFromJSONTyped,
    KvintaMessageRecordToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryResultMessageRecord
 */
export interface KvintaQueryResultMessageRecord {
    /**
     * 
     * @type {Array<KvintaMessageRecord>}
     * @memberof KvintaQueryResultMessageRecord
     */
    list?: Array<KvintaMessageRecord> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaQueryResultMessageRecord
     */
    total?: number | null;
}

export function KvintaQueryResultMessageRecordFromJSON(json: any): KvintaQueryResultMessageRecord {
    return KvintaQueryResultMessageRecordFromJSONTyped(json, false);
}

export function KvintaQueryResultMessageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryResultMessageRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaMessageRecordFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaQueryResultMessageRecordToJSON(value?: KvintaQueryResultMessageRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaMessageRecordToJSON)),
        'total': value.total,
    };
}


