import { Paper, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Component } from 'react';
import { injectIntl } from 'react-intl';

export type TTabSpec = {
  label: string;
  value: any;
};

type TTabBarProps = {
  intl: any;
  tabId: any;
  tabs: TTabSpec[];
  onChange: (newValue: any) => any;
};

const TabBarBase: React.FunctionComponent<TTabBarProps> = ({ tabId, tabs, intl, onChange }) => (
  <Tabs
    style={{ boxShadow: 'none', borderBottom: '1px solid rgba(224, 224, 224, 1)', backgroundColor: '#FFF' }}
    value={tabId}
    indicatorColor="secondary"
    textColor="primary"
    onChange={(event: React.ChangeEvent<unknown>, newValue: number) => {
      onChange(newValue);
    }}
  >
    {tabs.map((tab) => (
      <Tab
        label={intl.formatMessage({ id: tab.label })}
        value={tab.value}
        id={`tab-${tab.value}`}
        key={`tab-${tab.value}`}
      />
    ))}
  </Tabs>
);

export const TabBar = injectIntl(TabBarBase);
