/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaReadObjectResponseStatus
 */
export interface KvintaReadObjectResponseStatus {
    /**
     * 
     * @type {boolean}
     * @memberof KvintaReadObjectResponseStatus
     */
    success: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaReadObjectResponseStatus
     */
    result?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaReadObjectResponseStatus
     */
    error?: string | null;
}

export function KvintaReadObjectResponseStatusFromJSON(json: any): KvintaReadObjectResponseStatus {
    return KvintaReadObjectResponseStatusFromJSONTyped(json, false);
}

export function KvintaReadObjectResponseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaReadObjectResponseStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function KvintaReadObjectResponseStatusToJSON(value?: KvintaReadObjectResponseStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'result': value.result,
        'error': value.error,
    };
}


