/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcPackStatus,
    KvintaEpcPackStatusFromJSON,
    KvintaEpcPackStatusFromJSONTyped,
    KvintaEpcPackStatusToJSON,
    KvintaGetEpcContentSummaryContent,
    KvintaGetEpcContentSummaryContentFromJSON,
    KvintaGetEpcContentSummaryContentFromJSONTyped,
    KvintaGetEpcContentSummaryContentToJSON,
    KvintaResponseMessage,
    KvintaResponseMessageFromJSON,
    KvintaResponseMessageFromJSONTyped,
    KvintaResponseMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaGetEpcContentSummary
 */
export interface KvintaGetEpcContentSummary {
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcContentSummary
     */
    epc: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcContentSummary
     */
    disposition?: string | null;
    /**
     * 
     * @type {KvintaEpcPackStatus}
     * @memberof KvintaGetEpcContentSummary
     */
    packStatus: KvintaEpcPackStatus;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcContentSummary
     */
    batchNumber: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaGetEpcContentSummary
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {Array<KvintaGetEpcContentSummaryContent>}
     * @memberof KvintaGetEpcContentSummary
     */
    content?: Array<KvintaGetEpcContentSummaryContent> | null;
    /**
     * 
     * @type {Array<KvintaResponseMessage>}
     * @memberof KvintaGetEpcContentSummary
     */
    messages?: Array<KvintaResponseMessage> | null;
}

export function KvintaGetEpcContentSummaryFromJSON(json: any): KvintaGetEpcContentSummary {
    return KvintaGetEpcContentSummaryFromJSONTyped(json, false);
}

export function KvintaGetEpcContentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaGetEpcContentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epc': json['epc'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'packStatus': KvintaEpcPackStatusFromJSON(json['packStatus']),
        'batchNumber': json['batchNumber'],
        'expiryDate': !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(KvintaGetEpcContentSummaryContentFromJSON)),
        'messages': !exists(json, 'messages') ? undefined : (json['messages'] === null ? null : (json['messages'] as Array<any>).map(KvintaResponseMessageFromJSON)),
    };
}

export function KvintaGetEpcContentSummaryToJSON(value?: KvintaGetEpcContentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epc': value.epc,
        'disposition': value.disposition,
        'packStatus': KvintaEpcPackStatusToJSON(value.packStatus),
        'batchNumber': value.batchNumber,
        'expiryDate': value.expiryDate,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(KvintaGetEpcContentSummaryContentToJSON)),
        'messages': value.messages === undefined ? undefined : (value.messages === null ? null : (value.messages as Array<any>).map(KvintaResponseMessageToJSON)),
    };
}


