/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaTradeItemFilter
 */
export interface KvintaTradeItemFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemFilter
     */
    productId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemFilter
     */
    gtin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaTradeItemFilter
     */
    gtinLike?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof KvintaTradeItemFilter
     */
    gtinList?: Array<string> | null;
}

export function KvintaTradeItemFilterFromJSON(json: any): KvintaTradeItemFilter {
    return KvintaTradeItemFilterFromJSONTyped(json, false);
}

export function KvintaTradeItemFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTradeItemFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'gtinLike': !exists(json, 'gtinLike') ? undefined : json['gtinLike'],
        'gtinList': !exists(json, 'gtinList') ? undefined : json['gtinList'],
    };
}

export function KvintaTradeItemFilterToJSON(value?: KvintaTradeItemFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'gtin': value.gtin,
        'gtinLike': value.gtinLike,
        'gtinList': value.gtinList,
    };
}


