import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { observer } from 'mobx-react';
import { injectIntl, IntlShape } from 'react-intl';
import { DELIVERY_ITEM_FORM_ROOT_ID, TDeliveryItem, TDeliveryItemFormData } from './DeliveryItemsStore';
import { DeliveryItemForm, EFormType } from './DeliveryItemForm';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { ButtonGreen } from '../../../components/ButtonGreen';

interface CreateDeliveryItemDialogProps {
  intl: IntlShape;
  formData: TDeliveryItemFormData;
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  onClose: () => void;
  onSubmit: (deliveryId: string) => void;
  deliveryId: string;
  currentDeliveryItem: TDeliveryItem;
}

const CreateDeliveryItemDialogBase: React.FunctionComponent<CreateDeliveryItemDialogProps> = ({
  intl,
  formData,
  onBlur,
  onChange,
  onClose,
  onSubmit,
  deliveryId,
  currentDeliveryItem,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true}>
    <KDialogTitle id="customized-dialog-title" onClose={onClose}>
      {intl.formatMessage({ id: 'delivery-item-form.field.create' })}
    </KDialogTitle>
    <DialogContent>
      <DeliveryItemForm
        formData={formData}
        onBlur={onBlur}
        onChange={onChange}
        formType={EFormType.CREATE}
        attrConfig={currentDeliveryItem.attrConfig}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id="submitButton"
        onClick={() => onSubmit(deliveryId)}
        label={intl.formatMessage({ id: 'button.submit' })}
        buttonState={formDataToButtonState(formData[DELIVERY_ITEM_FORM_ROOT_ID])}
      />
    </DialogActions>
  </Dialog>
);

export const CreateDeliveryItemDialog = injectIntl(observer(CreateDeliveryItemDialogBase));
