/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaEpcsContentItem,
    KvintaEpcsContentItemFromJSON,
    KvintaEpcsContentItemFromJSONTyped,
    KvintaEpcsContentItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcsContent
 */
export interface KvintaEpcsContent {
    /**
     * 
     * @type {Array<KvintaEpcsContentItem>}
     * @memberof KvintaEpcsContent
     */
    items: Array<KvintaEpcsContentItem>;
    /**
     * 
     * @type {number}
     * @memberof KvintaEpcsContent
     */
    timestamp: number;
}

export function KvintaEpcsContentFromJSON(json: any): KvintaEpcsContent {
    return KvintaEpcsContentFromJSONTyped(json, false);
}

export function KvintaEpcsContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcsContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(KvintaEpcsContentItemFromJSON)),
        'timestamp': json['timestamp'],
    };
}

export function KvintaEpcsContentToJSON(value?: KvintaEpcsContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(KvintaEpcsContentItemToJSON)),
        'timestamp': value.timestamp,
    };
}


