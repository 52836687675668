/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaBusinessPartner,
    KvintaBusinessPartnerFromJSON,
    KvintaBusinessPartnerFromJSONTyped,
    KvintaBusinessPartnerToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaQueryResultBusinessPartner
 */
export interface KvintaQueryResultBusinessPartner {
    /**
     * 
     * @type {Array<KvintaBusinessPartner>}
     * @memberof KvintaQueryResultBusinessPartner
     */
    list?: Array<KvintaBusinessPartner> | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaQueryResultBusinessPartner
     */
    total?: number | null;
}

export function KvintaQueryResultBusinessPartnerFromJSON(json: any): KvintaQueryResultBusinessPartner {
    return KvintaQueryResultBusinessPartnerFromJSONTyped(json, false);
}

export function KvintaQueryResultBusinessPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaQueryResultBusinessPartner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(KvintaBusinessPartnerFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function KvintaQueryResultBusinessPartnerToJSON(value?: KvintaQueryResultBusinessPartner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(KvintaBusinessPartnerToJSON)),
        'total': value.total,
    };
}


