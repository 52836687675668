import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { TabBar } from 'kvinta/components';
import { assignmentSummaryTabs } from './tabs';
import { DEVICE_ASSIGNMENT_FORM_ROOT_ID, DEVICE_STORE_ID, DeviceStore } from './DeviceStore';
import { DeviceAssignmentForm } from './DeviceAssignmentForm';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Device.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { USER_STORE_ID, UserStore } from '../../main';

interface AssignmentSummaryViewProps {
  intl: any;
  deviceStore?: DeviceStore;
  historyStore: HistoryStore;
  userStore: UserStore;
  id: string;
  assignId: string;
  currentAssignment?: any;
}

type TDeviceAssignment = {
  appName: string;
  appVersion: string;
  deviceId: string;
  email: string;
};

const DeviceAssignmentSummaryData: React.FunctionComponent<{ deviceAssignmentData: TDeviceAssignment }> = ({
  deviceAssignmentData,
}) => (
  <div className={styles.formContainer} id={'DeviceAssignmentSummaryID'}>
    <TextField value={deviceAssignmentData.deviceId} label="assignment.deviceId" />
    <TextField value={deviceAssignmentData.email} label="assignment.email" />
    <TextField value={deviceAssignmentData.appName} label="assignment.appName" />
    <TextField value={deviceAssignmentData.appVersion} label="assignment.appVersion" />
  </div>
);

class DeviceAssignmentSummaryBase extends Component<AssignmentSummaryViewProps> {
  componentDidMount() {
    this.props.deviceStore.fetchDeviceAssignment(this.props.assignId);
  }

  componentWillUnmount() {
    this.props.deviceStore.closeDeviceAssignmentForm();
  }

  render() {
    if (!this.props.deviceStore.currentDeviceAssignment) {
      return null;
    }
    const intl = this.props.intl;
    const { canAccess } = this.props.userStore;
    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateDevicesPath();
            }
            if (newValue === '1') {
              this.props.historyStore.navigateDeviceSummaryPath(this.props.id);
            }
            if (newValue === '2') {
              this.props.historyStore.navigateDeviceAssignmentsPath(this.props.id);
            }
          }}
          tabId={'3'}
          tabs={assignmentSummaryTabs}
        />
        <div className={styles.summaryMain}>
          {canAccess('Device.assignment.edit') && (
            <div className={styles.summaryButtonContainer}>
              <ButtonGreen
                id="assignDevice"
                icon={<MailIcon color="secondary" />}
                buttonState={
                  this.props.deviceStore.isLoading || this.props.deviceStore.deviceAssignmentFormOpen
                    ? EButtonState.DISABLED
                    : EButtonState.ACTIVE
                }
                label={intl.formatMessage({ id: 'button.assign-device' })}
                onClick={this.props.deviceStore.isLoading ? () => undefined : this.props.deviceStore.sendInvitation}
              />
              {this.props.deviceStore.deviceAssignmentFormOpen ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CloseIcon color="primary" />}
                    onClick={this.props.deviceStore.closeDeviceAssignmentForm}
                  >
                    {intl.formatMessage({ id: 'button.cancel' })}
                  </Button>
                  <ButtonGreen
                    id="openOrSubmitButton"
                    label={intl.formatMessage({ id: 'button.confirm' })}
                    icon={<DoneIcon color="secondary" />}
                    buttonState={formDataToButtonState(
                      this.props.deviceStore.deviceAssignmentFormData[DEVICE_ASSIGNMENT_FORM_ROOT_ID],
                    )}
                    onClick={this.props.deviceStore.submitUpdateDeviceAssignmentForm}
                  />
                </>
              ) : (
                <ButtonGreen
                  id="openOrSubmitButton"
                  label={intl.formatMessage({ id: 'button.edit' })}
                  icon={<EditIcon color="secondary" />}
                  buttonState={EButtonState.ACTIVE}
                  onClick={this.props.deviceStore.openUpdateDeviceAssignmentForm}
                />
              )}
            </div>
          )}
          {this.props.deviceStore.deviceAssignmentFormOpen ? (
            <DeviceAssignmentForm
              onChange={this.props.deviceStore.onChangeDeviceAssignmentFormField}
              onBlur={this.props.deviceStore.onBlurDeviceAssignmentFormField}
              formData={this.props.deviceStore.deviceAssignmentFormData}
            />
          ) : (
            <DeviceAssignmentSummaryData deviceAssignmentData={this.props.deviceStore.currentDeviceAssignment} />
          )}
        </div>
      </div>
    );
  }
}

export const DeviceAssignmentSummary = injectIntl(
  inject(USER_STORE_ID, HISTORY_STORE_ID, DEVICE_STORE_ID)(observer(DeviceAssignmentSummaryBase)),
);
