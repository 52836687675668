import { FormControl, TextField } from '@mui/material';
import React, { Component } from 'react';

interface SearchFieldProps {
  intl?: any;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  searchValue: string;
  autofocus: boolean;
}

export class SearchField extends Component<SearchFieldProps> {
  constructor(props: SearchFieldProps) {
    super(props);
  }

  render() {
    const intl = this.props.intl;
    return (
      <FormControl variant="outlined" key={'searchField'} style={{ paddingLeft: '16px' }}>
        <TextField
          size={'small'}
          label={intl.formatMessage({ id: 'filter.search' })}
          placeholder={intl.formatMessage({ id: 'filter.search' })}
          value={this.props.searchValue}
          name="search-field"
          autoFocus={this.props.autofocus}
          id="search-field"
          onChange={(e) => {
            this.props.onChange(e.target.value);
          }}
          onBlur={(e) => {
            if (this.props.onBlur) this.props.onBlur(e);
          }}
        />
      </FormControl>
    );
  }
}
