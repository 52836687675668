/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMessageDirection,
    KvintaMessageDirectionFromJSON,
    KvintaMessageDirectionFromJSONTyped,
    KvintaMessageDirectionToJSON,
    KvintaMessageStatusType,
    KvintaMessageStatusTypeFromJSON,
    KvintaMessageStatusTypeFromJSONTyped,
    KvintaMessageStatusTypeToJSON,
    KvintaResourceType,
    KvintaResourceTypeFromJSON,
    KvintaResourceTypeFromJSONTyped,
    KvintaResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMessageRecordFilter
 */
export interface KvintaMessageRecordFilter {
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecordFilter
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecordFilter
     */
    messageId?: string | null;
    /**
     * 
     * @type {KvintaResourceType}
     * @memberof KvintaMessageRecordFilter
     */
    type?: KvintaResourceType | null;
    /**
     * 
     * @type {KvintaMessageDirection}
     * @memberof KvintaMessageRecordFilter
     */
    direction?: KvintaMessageDirection | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecordFilter
     */
    sender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecordFilter
     */
    receiver?: string | null;
    /**
     * 
     * @type {KvintaMessageStatusType}
     * @memberof KvintaMessageRecordFilter
     */
    lastStatus?: KvintaMessageStatusType | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecordFilter
     */
    destinationSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaMessageRecordFilter
     */
    triggeringEventId?: string | null;
}

export function KvintaMessageRecordFilterFromJSON(json: any): KvintaMessageRecordFilter {
    return KvintaMessageRecordFilterFromJSONTyped(json, false);
}

export function KvintaMessageRecordFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMessageRecordFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'type': !exists(json, 'type') ? undefined : KvintaResourceTypeFromJSON(json['type']),
        'direction': !exists(json, 'direction') ? undefined : KvintaMessageDirectionFromJSON(json['direction']),
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'receiver': !exists(json, 'receiver') ? undefined : json['receiver'],
        'lastStatus': !exists(json, 'lastStatus') ? undefined : KvintaMessageStatusTypeFromJSON(json['lastStatus']),
        'destinationSystem': !exists(json, 'destinationSystem') ? undefined : json['destinationSystem'],
        'triggeringEventId': !exists(json, 'triggeringEventId') ? undefined : json['triggeringEventId'],
    };
}

export function KvintaMessageRecordFilterToJSON(value?: KvintaMessageRecordFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messageId': value.messageId,
        'type': KvintaResourceTypeToJSON(value.type),
        'direction': KvintaMessageDirectionToJSON(value.direction),
        'sender': value.sender,
        'receiver': value.receiver,
        'lastStatus': KvintaMessageStatusTypeToJSON(value.lastStatus),
        'destinationSystem': value.destinationSystem,
        'triggeringEventId': value.triggeringEventId,
    };
}


