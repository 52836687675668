import {
  AccountTreeOutlined as ProcessesIcon,
  AvTimerOutlined as EventsIcon,
  FolderOpenOutlined as DocumentsIcon,
  HomeOutlined,
  ListAltOutlined as MasterdataIcon,
  SettingsOutlined as ConfigurationIcon,
  SvgIconComponent,
} from '@mui/icons-material';
import { EPaths } from "./kvinta/common/HistoryStore";

export enum EMenuItemType {
  DIVIDER = "divider",
  PARENT_ITEM = "parentItem",
  CHILD_ITEM = "childItem",
  EXTERNAL_ITEM = "externalItem"
}

type TCommonPropertyItems = {
  label: string;
  Icon: SvgIconComponent | null
  isClickable: boolean;
}
type TChildItemProperty = {
  selectOnPaths: EPaths[];
  path: EPaths;
}

export type TDividerMainMenu = { type: EMenuItemType.DIVIDER }
export type TChildItemMainMenu =
  { type: EMenuItemType.CHILD_ITEM | EMenuItemType.EXTERNAL_ITEM }
  & TCommonPropertyItems
  & TChildItemProperty
export type TParentItemMainMenu = { type: EMenuItemType.PARENT_ITEM } & TCommonPropertyItems & { items: TItemMenu[] }


export type TItemMenu = TDividerMainMenu | TChildItemMainMenu | TParentItemMainMenu


export function getMenus(): TItemMenu[] {
  return [
    {
      type: EMenuItemType.CHILD_ITEM,
      path: EPaths.MainPath,
      label: 'menu.home',
      Icon: HomeOutlined,
      isClickable: true,
      selectOnPaths: [EPaths.MainPath],
    },
    {
      type: EMenuItemType.DIVIDER,
    },
    {
      type: EMenuItemType.PARENT_ITEM,
      label: 'menu.master-data',
      Icon: MasterdataIcon,
      isClickable: false,
      items: [
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.BusinessPartnersPath,
          label: 'menu.businessPartners',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.BusinessPartnerPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.LocationsPath,
          label: 'menu.locations',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.LocationPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.ProductsPath,
          label: 'menu.products',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.ProductPath, EPaths.ProductTradeItemsPath, EPaths.ProductTradeItemPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.TradeItemsPath,
          label: 'menu.trade-items',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.TradeItemPath],
        },
      ],
    },
    {
      type: EMenuItemType.DIVIDER,
    },
    {
      type: EMenuItemType.PARENT_ITEM,
      label: 'menu.documents',
      Icon: DocumentsIcon,
      isClickable: false,
      items: [
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.DeliveryNotesPath,
          label: 'menu.delivery-notes',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.DeliveryNotePath, EPaths.DeliveryNoteDeliveryItemsPath, EPaths.DeliveryNoteDeliveryItemPath],
        },
      ],
    },
    {
      type: EMenuItemType.DIVIDER,
    },
    {
      type: EMenuItemType.PARENT_ITEM,
      label: 'menu.epcis',
      Icon: EventsIcon,
      isClickable: false,
      items: [
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.EpcMessagesPath,
          label: 'menu.epcs',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.EpcMessagePath, EPaths.EpcMessageHierarchyPath, EPaths.EpcMessageHierarchyPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.EpcisEventsPath,
          label: 'menu.events',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.EpcisEventPath, EPaths.EpcisEventJsonPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.EpcisEventSetListPath,
          label: 'menu.event-sets',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.EpcisEventSetSummaryPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.EpcisMessageListPath,
          label: 'menu.messages',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.EpcisMessageSummaryPath],
        },
      ],
    },
    // 'divider',
    // {
    //   label: 'menu.techdocs',
    //   Icon: TechDocIcon,
    //   isClickable: false,
    //   items: [
    //     {
    //       path: TechDocDownloadPath,
    //       label: 'menu.techdocs.download',
    //       Icon: TechDocIcon,
    //       isClickable: true,
    //       selectOnPaths: [],
    //     },
    //   ],
    // },
    // TODO: Discuss with @Jochen about shipping
    {
      type: EMenuItemType.DIVIDER,
    },
    {
      type: EMenuItemType.PARENT_ITEM,
      label: 'menu.processes',
      Icon: ProcessesIcon,
      isClickable: false,
      items: [
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.MainPath,
          label: 'menu.execution-statuses',
          Icon:null,
          isClickable: true,
          selectOnPaths: [],
        },
      ],
    },
    {
      type: EMenuItemType.DIVIDER,
    },
    {
      type: EMenuItemType.PARENT_ITEM,
      label: 'menu.configuration',
      Icon: ConfigurationIcon,
      isClickable: false,
      items: [
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.DevicesPath,
          label: 'menu.devices',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.DeviceSummaryPath, EPaths.DeviceAssignmentsPath],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.NotificationListPath,
          label: 'menu.notifications',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.NotificationListPath, EPaths.NotificationByGln13Path],
        },
        {
          type: EMenuItemType.CHILD_ITEM,
          path: EPaths.AlarmsListPath,
          label: 'menu.alarms',
          Icon: null,
          isClickable: true,
          selectOnPaths: [EPaths.AlarmsListPath],
        },
      ],
    },
  ];
}
