import { action, makeObservable, observable, toJS } from 'mobx';
import { ISelectableRow, TAppOptionsConfig } from 'kvinta/common/Interfaces';
import { NotificationManager } from 'kvinta/modules/main';
import { countries, SelectableStore } from 'kvinta/common';
import {
  DefaultApi as MDDocumentApi,
  KvintaBusinessPartner,
  KvintaLocation,
  KvintaSortDirection,
  KvintaSortExpression,
} from 'kvinta/apis/kvinta-masterdata';
import { getExternalId, getInternalId } from 'kvinta/apis/utils';
import { translateCountries } from 'kvinta/common/utils';
import { handleFormChange, handleFormBlur } from 'kvinta/common/formUtils/handlers';
import { validateForm } from 'kvinta/common/formUtils/core';
import { formRoot, select, textInput } from 'kvinta/common/formUtils/formDataGenerators';
import { isGeoCoordinate, isNotEmpty, isValidGln13, maxLength } from '../../../common/formUtils/validators';
import { TFormFieldData } from '../../../common/formUtils/types';

export type TLocation = {
  sourceSystem: string;
  idInSourceSystem: string;
  name: string;
  gln13: string;
  sgln: string;
  latitude: string;
  longitude: string;
  description: string;
  country: any;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  address3: string;
  businessPartnerId: string;
};

export type TLocationFormData = {
  'locationForm.sourceSystem': TFormFieldData;
  'locationForm.idInSourceSystem': TFormFieldData;
  'locationForm.name': TFormFieldData;
  'locationForm.gln13': TFormFieldData;
  'locationForm.sgln': TFormFieldData;
  'locationForm.latitude': TFormFieldData;
  'locationForm.longitude': TFormFieldData;
  'locationForm.description': TFormFieldData;
  'locationForm.country': TFormFieldData;
  'locationForm.city': TFormFieldData;
  'locationForm.postalCode': TFormFieldData;
  'locationForm.address1': TFormFieldData;
  'locationForm.address2': TFormFieldData;
  'locationForm.address3': TFormFieldData;
  'locationForm.businessPartnerId': TFormFieldData;
};

interface ILocationRow extends KvintaLocation, ISelectableRow {}

export class LocationsStore extends SelectableStore<ILocationRow> {
  private _config: TAppOptionsConfig;
  private _mdApi: MDDocumentApi;
  private _notificationManager: NotificationManager;

  isLoading: boolean;

  page: number;
  totalCount: number;
  pageSize: number;
  businessPartners: KvintaBusinessPartner[];
  currentSort: KvintaSortExpression;
  searchValue: string;

  locationFormOpen: boolean;
  locationFormData?: TLocationFormData;
  updateLocationFormOpen: boolean;

  currentLocation?: TLocation | null = null;

  exportActive: boolean;
  exportData: ILocationRow[] | KvintaLocation[] | undefined;

  autofocusSearchInList: boolean;

  constructor(config: TAppOptionsConfig, notificationManager: NotificationManager, mdApi: MDDocumentApi) {
    super();
    makeObservable(this, {
      fetchPage: action.bound,
      fetchLocationList: action.bound,
      updateSearch: action.bound,
      unfocusSearchField: action.bound,

      updateExported: action.bound,
      exportAll: action.bound,
      exportSelected: action.bound,
      exportActive: observable,

      isLoading: observable,
      page: observable,
      pageSize: observable,
      searchValue: observable,

      fetchLocation: action.bound,
      openLocationForm: action.bound,
      openUpdateLocationForm: action.bound,
      openCreateLocationForm: action.bound,
      locationFormOpen: observable,
      currentLocation: observable,
      locationFormData: observable,
      onChangeLocationField: action.bound,
      onBlurLocationField: action.bound,
      submitLocationForm: action.bound,
      submitCreateLocationForm: action.bound,
      submitUpdateLocationForm: action.bound,
      closeLocationForm: action.bound,
    });

    this._config = config;
    this._mdApi = mdApi;
    this._notificationManager = notificationManager;
    this.pageSize = 25;
    this.page = 0;
    this.searchValue = '';
    this.autofocusSearchInList = false;

    this.currentSort = {
      direction: KvintaSortDirection.Desc,
      property: 'name',
      ignoreCase: false,
    };
  }

  async fetchPage(page: number) {
    this.isLoading = true;
    this.page = page;
    this.fetchLocationList();
  }

  async changeOrder(orderBy: number, orderDirection: 'asc' | 'desc') {
    this.isLoading = true;
    const field = getField(orderBy);
    this.currentSort = {
      property: field,
      direction: orderDirection == 'asc' ? KvintaSortDirection.Asc : KvintaSortDirection.Desc,
      ignoreCase: false,
    };
    this.fetchLocationList();
  }

  unfocusSearchField() {
    this.autofocusSearchInList = false;
  }

  updateSearch(value: string) {
    this.searchValue = value;
    this.isLoading = true;
    this.autofocusSearchInList = true;
    this.fetchLocationList();
  }

  fetchLocationList = async () => {
    this.isLoading = true;
    this.listData = [];
    this.totalCount = 0;
    let filters = {} as { [key: string]: string } | null;

    for (const filter of searchedColumns) {
      filters = {
        ...filters,
        nameLike: this.searchValue,
      };
    }
    this._mdApi
      .queryLocations({
        kvintaQueryRequestLocationFilter: {
          paging: {
            page: this.page,
            size: this.pageSize,
            sort: { expressions: [this.currentSort] },
          },
          filter: {
            ...filters,
          },
        },
      })
      .then((result) => {
        const oldSelection = new Map();
        if (this.listData) {
          this.listData.forEach((row) => {
            oldSelection.set(row.id, row.isSelected);
          });
        }
        this.totalCount = result.total || 0;
        this.listData = (result.list || []).map((comp) => {
          const selected = oldSelection.get(getInternalId(comp)) || false;
          return { ...comp, id: getInternalId(comp), isSelected: selected } as ILocationRow;
        });
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  openUpdateLocationForm() {
    this.openLocationForm(() => this.currentLocation);
  }

  openCreateLocationForm() {
    const initialValues = (businessPartners) => ({
      sourceSystem: '',
      idInSourceSystem: '',
      name: '',
      gln13: '',
      sgln: '',
      latitude: '',
      longitude: '',
      description: '',
      city: '',
      country: 'DE',
      postalCode: '',
      address1: '',
      address2: '',
      address3: '',
      businessPartnerId: getInternalId(businessPartners[0]),
      showError: false,
    });
    this.openLocationForm(initialValues);
  }

  openLocationForm(initialValues: (businessPartners: KvintaBusinessPartner[]) => TLocation) {
    this.isLoading = true;
    this._mdApi
      .queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      })
      .then((result) => {
        const businessPartners = result.list;
        if (businessPartners.length) {
          this.locationFormData = validateForm<TLocationFormData>(
            generateLocationFormData(LOCATION_FORM_ROOT_ID, businessPartners, initialValues(businessPartners)),
          );
          this.locationFormOpen = true;
        } else {
          this._notificationManager.sendError(`Create a Business Partner First`);
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.message.toString());
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeLocationForm() {
    this.locationFormData = undefined;
    this.locationFormOpen = false;
  }

  onChangeLocationField(id: string, value: string) {
    const formData = toJS(this.locationFormData);
    this.locationFormData = handleFormChange(formData, id, value);
  }

  onBlurLocationField(id: string) {
    const formData = toJS(this.locationFormData);
    this.locationFormData = handleFormBlur(formData, id);
  }

  submitCreateLocationForm() {
    this.submitLocationForm(this.fetchLocationList);
  }

  submitLocationForm(nextAction): Promise<any> {
    this.isLoading = true;
    const updateData = transformLocationFormData(this.locationFormData);
    return this._mdApi
      .mutateLocation({
        kvintaMutateRequestLocationExternalId: {
          upsert: [updateData],
        },
      })
      .then(async (result) => {
        if (result.errors && result.errors.length) {
          const errors = result.errors.map((err) => err.error).join('\n');
          this._notificationManager.sendError(`An error occurred while submitting location data\n${errors}`);
        } else {
          this._notificationManager.sendSuccess(`Successfully submitted location ${updateData.name}`);
          await nextAction();
          this.closeLocationForm();
        }
      })
      .catch((err: Error) => {
        this._notificationManager.sendError(err.message);
      })
      .finally(() => {
        this.isLoading = false;
        this.locationFormOpen = false;
      });
  }

  submitUpdateLocationForm() {
    return this.submitLocationForm(() => this.fetchLocation(getInternalId(this.currentLocation)));
  }

  deleteLocation = async (locationId: string) => {
    this.isLoading = true;
    this._mdApi
      .mutateLocation({
        kvintaMutateRequestLocationExternalId: {
          _delete: [getExternalId(locationId)],
        },
      })
      .then(async (result) => {
        if (result.errors && result.errors.length) {
          const errors = result.errors.map((err) => err.error).join('\n');
          this._notificationManager.sendError(`An error occurred while deleting location\n${errors}`);
        } else {
          await this.fetchLocationList();
        }
      })
      .catch((e) => {
        this._notificationManager.sendError(e.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  updateExported() {
    this.exportData = undefined;
  }

  async exportSelected() {
    this.exportActive = true;
    this.exportData = this.listChecked.map((location) => ({
      ...location,
      businessPartner: location.businessPartner.idInSourceSystem,
    })) as any;
  }

  async exportAll() {
    this.exportActive = false;
    this.exportData = undefined;
    try {
      const locationListResult = await this._mdApi.queryLocations({
        kvintaQueryRequestLocationFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      });
      this.exportActive = true;
      this.exportData = locationListResult.list.map((location) => ({
        ...location,
        businessPartner: location.businessPartner.idInSourceSystem,
      })) as any;
    } catch (err) {
      this._notificationManager.sendError(JSON.stringify(err));
    }
  }

  fetchLocation(id: string) {
    this.isLoading = true;

    Promise.all([
      this._mdApi.queryLocations({
        kvintaQueryRequestLocationFilter: {
          paging: {
            page: 0,
            size: 1,
          },
          filter: {
            id: id,
          },
        },
      }),
      this._mdApi.queryBusinessPartner({
        kvintaQueryRequestBusinessPartnerFilter: {
          paging: {
            page: 0,
            size: 10000,
          },
        },
      }),
    ])
      .then(([locationResult, businessPartnersResult]) => {
        this.businessPartners = businessPartnersResult.list;

        const currentLocation = locationResult.list[0];

        this.currentLocation = {
          sourceSystem: currentLocation.sourceSystem || '',
          idInSourceSystem: currentLocation.idInSourceSystem || '',
          name: currentLocation.name || '',
          gln13: currentLocation.gln13 || '',
          sgln: currentLocation.sgln || '',
          description: currentLocation.description || '',
          city: currentLocation.city || '',
          country: currentLocation.country || '',
          postalCode: currentLocation.postalCode || '',
          address1: currentLocation.address1 || '',
          address2: currentLocation.address2 || '',
          address3: currentLocation.address3 || '',
          latitude: currentLocation.latitude ? currentLocation.latitude.toString() : '',
          longitude: currentLocation.longitude ? currentLocation.longitude.toString() : '',
          businessPartnerId: getInternalId(currentLocation.businessPartner),
        };
      })
      .catch((e) => {
        this._notificationManager.sendError(e.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

export const STORE_ID = 'locationsStore';
export const LOCATION_FORM_ROOT_ID = 'locationForm';

function getField(orderBy: number): string {
  switch (orderBy + 1) {
    case 1:
      return 'name';
    case 2:
      return 'gln13';
    case 3:
      return 'sgln';
    case 4:
      return 'country';
    case 5:
      return 'description';
    default:
      return 'name';
  }
}

const searchedColumns = ['name']; //, 'sgln', 'gln13', 'latitude', 'longitude', 'description']; // TODO: API is not adopted to search multiple columns

function generateLocationFormData(
  rootFormId: string,
  businessPartners: any[],
  initialValues: {
    businessPartnerId: string;
    name: string;
    sourceSystem: string;
    idInSourceSystem: string;
    gln13: string;
    sgln: string;
    latitude: string;
    longitude: string;
    description: string;
    country: string;
    city: string;
    postalCode: string;
    address1: string;
    address2: string;
    address3: string;
  },
) {
  return formRoot<TLocationFormData>({
    formId: rootFormId,
    validations: [],
    childrenFactories: [
      select({
        path: 'businessPartnerId',
        value: initialValues.businessPartnerId,
        options: businessPartners.map((businessPartner) => ({
          key: getInternalId(businessPartner),
          label: businessPartner.name,
        })),
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'name',
        value: initialValues.name,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'sourceSystem',
        value: initialValues.sourceSystem,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'idInSourceSystem',
        value: initialValues.idInSourceSystem,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'gln13',
        value: initialValues.gln13,
        validations: [isValidGln13({ errorMessage: 'please provide a valid gln13 number' })],
        isRequiredField: false,
      }),
      textInput({
        path: 'sgln',
        value: initialValues.sgln,
        validations: [
          isNotEmpty({ errorMessage: 'this value is mandatory' }),
          maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 }),
        ],
        isRequiredField: true,
      }),
      textInput({
        path: 'longitude',
        value: initialValues.longitude,
        validations: [isGeoCoordinate({ errorMessage: 'please provide a valid decimal longitude' })],
        isRequiredField: false,
      }),
      textInput({
        path: 'latitude',
        value: initialValues.latitude,
        validations: [isGeoCoordinate({ errorMessage: 'please provide a valid decimal longitude' })],
        isRequiredField: false,
      }),
      select({
        path: 'country',
        value: initialValues.country,
        options: translateCountries(countries),
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'city',
        value: initialValues.city,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'postalCode',
        value: initialValues.postalCode,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'address1',
        value: initialValues.address1,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'address2',
        value: initialValues.address2,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'address3',
        value: initialValues.address3,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
      textInput({
        path: 'description',
        value: initialValues.description,
        validations: [maxLength({ errorMessage: 'maximum text length is 100 characters', maxLength: 100 })],
        isRequiredField: false,
      }),
    ],
  });
}

function transformLocationFormData(locationFormData) {
  return {
    sourceSystem: locationFormData['locationForm.sourceSystem'].value,
    idInSourceSystem: locationFormData['locationForm.idInSourceSystem'].value,
    name: locationFormData['locationForm.name'].value,
    gln13: locationFormData['locationForm.gln13'].value,
    sgln: locationFormData['locationForm.sgln'].value,
    latitude: parseFloat(locationFormData['locationForm.latitude'].value),
    longitude: parseFloat(locationFormData['locationForm.longitude'].value),
    description: locationFormData['locationForm.description'].value,
    city: locationFormData['locationForm.city'].value,
    country: locationFormData['locationForm.country'].value,
    postalCode: locationFormData['locationForm.postalCode'].value,
    address1: locationFormData['locationForm.address1'].value,
    address2: locationFormData['locationForm.address2'].value,
    address3: locationFormData['locationForm.address3'].value,
    businessPartner: getExternalId(locationFormData['locationForm.businessPartnerId'].value),
  };
}
