/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
    KvintaIdAndErrorDocumentKey,
    KvintaIdAndErrorDocumentKeyFromJSON,
    KvintaIdAndErrorDocumentKeyFromJSONTyped,
    KvintaIdAndErrorDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateResultDocumentKey
 */
export interface KvintaMutateResultDocumentKey {
    /**
     * 
     * @type {Array<KvintaDocumentKey>}
     * @memberof KvintaMutateResultDocumentKey
     */
    upserted?: Array<KvintaDocumentKey> | null;
    /**
     * 
     * @type {Array<KvintaDocumentKey>}
     * @memberof KvintaMutateResultDocumentKey
     */
    deleted?: Array<KvintaDocumentKey> | null;
    /**
     * 
     * @type {Array<KvintaIdAndErrorDocumentKey>}
     * @memberof KvintaMutateResultDocumentKey
     */
    errors?: Array<KvintaIdAndErrorDocumentKey> | null;
}

export function KvintaMutateResultDocumentKeyFromJSON(json: any): KvintaMutateResultDocumentKey {
    return KvintaMutateResultDocumentKeyFromJSONTyped(json, false);
}

export function KvintaMutateResultDocumentKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateResultDocumentKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upserted': !exists(json, 'upserted') ? undefined : (json['upserted'] === null ? null : (json['upserted'] as Array<any>).map(KvintaDocumentKeyFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : (json['deleted'] === null ? null : (json['deleted'] as Array<any>).map(KvintaDocumentKeyFromJSON)),
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : (json['errors'] as Array<any>).map(KvintaIdAndErrorDocumentKeyFromJSON)),
    };
}

export function KvintaMutateResultDocumentKeyToJSON(value?: KvintaMutateResultDocumentKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upserted': value.upserted === undefined ? undefined : (value.upserted === null ? null : (value.upserted as Array<any>).map(KvintaDocumentKeyToJSON)),
        'deleted': value.deleted === undefined ? undefined : (value.deleted === null ? null : (value.deleted as Array<any>).map(KvintaDocumentKeyToJSON)),
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : (value.errors as Array<any>).map(KvintaIdAndErrorDocumentKeyToJSON)),
    };
}


