import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { BUSINESS_PARTNER_ROOT_FORM_ID, TBusinessPartnerFormData } from './BusinessPartnerStore';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { BusinessPartnerForm, EBusinessPartnerFormType } from './BusinessPartnerForm';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';

interface DialogCreateBusinessPartnerProps {
  intl: any;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string) => void;
  formData: TBusinessPartnerFormData;
  closeForm: () => void;
  submitForm: () => void;
}

const CreateBusinessPartnerDialogBase: React.FunctionComponent<DialogCreateBusinessPartnerProps> = ({
  intl,
  onChange,
  onBlur,
  formData,
  closeForm,
  submitForm,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true}>
    <KDialogTitle id="customized-dialog-title" onClose={closeForm}>
      {intl.formatMessage({ id: 'business-partner-create-form.field.create' })}
    </KDialogTitle>
    <DialogContent>
      <BusinessPartnerForm
        formData={formData}
        onChange={onChange}
        onBlur={onBlur}
        formType={EBusinessPartnerFormType.CREATE}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={closeForm} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id="submitButton"
        label={intl.formatMessage({ id: 'button.submit' })}
        onClick={submitForm}
        buttonState={formDataToButtonState(formData[BUSINESS_PARTNER_ROOT_FORM_ID])}
      />
    </DialogActions>
  </Dialog>
);

export const CreateBusinessPartnerDialog = injectIntl(observer(CreateBusinessPartnerDialogBase));
