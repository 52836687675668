import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { PRODUCT_FORM_ROOT_ID, PRODUCT_STORE_ID, ProductsStore, TProductFormData } from './ProductsStore';
import { KSidebarStore, SIDEBAR_STORE_ID, TabBar } from 'kvinta/components';
import { tabsSummary } from './tabs';
import { ProductForm } from './ProductForm';
import EditIcon from '@mui/icons-material/Edit';
import { reaction } from 'mobx';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { LoadingBackground } from '../../../components/LoadingBackground';
import styles from './Products.module.scss';
import { TextField } from '../../../components/FormFields/TextField';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';
import { EButtonState } from '../../epcis/messages/EpcisMessageStore';
import { translateSerializationType } from '../../../apis/utils';

interface ProductSummaryViewProps {
  intl: any;
  productsStore?: ProductsStore;
  sidebarStore?: KSidebarStore;
  historyStore: HistoryStore;
  id: string;
}

const ProductSummaryData = ({ formData }) => (
  <div className={styles.formContainer} id={'ProductSummaryID'}>
    <TextField value={formData.businessPartnerName} label="product-form.field.businessPartner" />
    <TextField value={formData.name} label="product-form.field.name" />
    <TextField value={formData.sourceSystem} label="product-form.field.sourceSystem" />
    <TextField value={formData.idInSourceSystem} label="product-form.field.idInSourceSystem" />
    <TextField value={formData.sku} label="product-form.field.sku" />
    <TextField value={formData.baseUom} label="product-form.field.baseUom" />
    <TextField
      value={translateSerializationType(formData.serializationType)}
      label="product-form.field.serializationType"
    />
  </div>
);

class ProductSummaryBase extends Component<ProductSummaryViewProps & { productData: TProductFormData }> {
  disposer: any;

  async componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.productsStore.fetchProduct(this.props.id);
    this.disposer = reaction(
      () => this.props.productsStore.currentProduct,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateProductsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.props.productsStore.closeProductForm();
    this.disposer();
  }

  render() {
    const intl = this.props.intl;

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateProductsPath();
            }
            if (newValue === '2') {
              this.props.historyStore.navigateProductTradeItemsPath(this.props.id);
            }
          }}
          tabId={'1'}
          tabs={tabsSummary}
        />
        {this.props.productsStore.isLoading || !this.props.productsStore.currentProduct ? (
          <LoadingBackground />
        ) : (
          <div className={styles.summaryMain}>
            <div className={styles.summaryButtonContainer}>
              {this.props.productsStore.productFormOpen && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CloseIcon color="primary" />}
                  onClick={this.props.productsStore.closeProductForm}
                >
                  {intl.formatMessage({ id: 'button.cancel' })}
                </Button>
              )}
              {this.props.productsStore.productFormOpen ? (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.productsStore.submitUpdateProduct}
                  icon={<DoneIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.confirm' })}
                  buttonState={formDataToButtonState(this.props.productsStore.productFormData[PRODUCT_FORM_ROOT_ID])}
                />
              ) : (
                <ButtonGreen
                  id="editOrSubmitButton"
                  onClick={this.props.productsStore.openUpdateProductForm}
                  icon={<EditIcon color="secondary" />}
                  label={intl.formatMessage({ id: 'button.edit' })}
                  buttonState={EButtonState.ACTIVE}
                />
              )}
            </div>
            {this.props.productsStore.productFormOpen ? (
              <ProductForm
                formData={this.props.productsStore.productFormData}
                onChange={this.props.productsStore.onChangeProductFormField}
                onBlur={this.props.productsStore.onBlurProductFormField}
                isUpdateProduct={true}
              />
            ) : (
              <ProductSummaryData formData={this.props.productsStore.currentProduct} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export const ProductSummary = injectIntl(
  inject(HISTORY_STORE_ID, PRODUCT_STORE_ID, SIDEBAR_STORE_ID)(observer(ProductSummaryBase)),
);
