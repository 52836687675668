import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { injectIntl } from 'react-intl';
import { DEVICE_FORM_ROOT_ID, TDeviceForm } from './DeviceStore';
import { KDialogTitle } from 'kvinta/components/KDialog';
import { DeviceForm } from './DeviceForm';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { formDataToButtonState } from '../../../common/formUtils/utils';

interface DialogCreateDeviceProps {
  onClose: () => void;
  onSubmit: () => void;
  onChange: (id: string, value: string) => void;
  onBlur: (id: string, value: string) => void;
  formData: TDeviceForm;
  intl: any;
}

const CreateDeviceDialogBase: React.FunctionComponent<DialogCreateDeviceProps> = ({
  intl,
  onClose,
  onChange,
  onBlur,
  onSubmit,
  formData,
}) => (
  <Dialog fullWidth={true} maxWidth="md" open={true}>
    <KDialogTitle id="customized-dialog-title" onClose={onClose}>
      {intl.formatMessage({ id: 'device-create-form.create' })}
    </KDialogTitle>
    <DialogContent>
      <DeviceForm onChange={onChange} onBlur={onBlur} formData={formData} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="outlined">
        {intl.formatMessage({ id: 'button.cancel' })}
      </Button>
      <ButtonGreen
        id={'submit'}
        label={intl.formatMessage({ id: 'button.submit' })}
        buttonState={formDataToButtonState(formData[DEVICE_FORM_ROOT_ID])}
        onClick={onSubmit}
      />
    </DialogActions>
  </Dialog>
);

export const CreateDeviceDialog = injectIntl(observer(CreateDeviceDialogBase));
