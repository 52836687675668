import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import { tabs } from './tabs';

import React, { Component } from 'react';
import { EPCIS_EVENTS_STORE_ID, EpcisEventsStore } from './EpcisEventsStore';
import { PAGE_CONTENT_STORE_ID, PageContentStore, TabBar } from 'kvinta/components';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { tabBarHeight } from 'kvinta/common';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Event.module.scss';

interface ViewEpcisEventJsonProps {
  intl: any;
  historyStore: HistoryStore;
  epcisEventsStore: EpcisEventsStore;
  pageContentStore: PageContentStore;
  id: string;
}
class ViewEpcisEventJsonImpl extends Component<ViewEpcisEventJsonProps> {
  componentDidMount() {
    this.props.epcisEventsStore.loadEvent(this.props.id);
  }

  render() {
    const currentEvent = this.props.epcisEventsStore.currentEvent;
    if (!currentEvent) {
      return null;
    }
    const { width, height } = this.props.pageContentStore;
    const newHeight = height - tabBarHeight;
    const jsonPayload = this.props.epcisEventsStore.jsonPayload;
    return (
      <div className={styles.summaryContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateEpcisEventsPath();
            }
            if (newValue === '2') {
              this.props.historyStore.navigateEpcisEventEpcListPath(this.props.id);
            } else if (newValue === '1') {
              this.props.historyStore.navigateEpcisEventPath(this.props.id);
            }
          }}
          tabId={'3'}
          tabs={tabs}
        />
        <AceEditor
          mode="json"
          theme="github"
          readOnly={true}
          style={{ width, height: newHeight }}
          setOptions={{ useWorker: false }}
          editorProps={{
            $blockScrolling: false,
            $highlightPending: false,
          }}
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          value={jsonPayload}
        />
      </div>
    );
  }
}
export const ViewEpcisEventJson = injectIntl(
  inject(HISTORY_STORE_ID, EPCIS_EVENTS_STORE_ID, PAGE_CONTENT_STORE_ID)(observer(ViewEpcisEventJsonImpl)),
);
