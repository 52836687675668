/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KvintaCancelEpcisDocumentRequest
 */
export interface KvintaCancelEpcisDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCancelEpcisDocumentRequest
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof KvintaCancelEpcisDocumentRequest
     */
    reason: string;
}

export function KvintaCancelEpcisDocumentRequestFromJSON(json: any): KvintaCancelEpcisDocumentRequest {
    return KvintaCancelEpcisDocumentRequestFromJSONTyped(json, false);
}

export function KvintaCancelEpcisDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCancelEpcisDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': json['documentId'],
        'reason': json['reason'],
    };
}

export function KvintaCancelEpcisDocumentRequestToJSON(value?: KvintaCancelEpcisDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'reason': value.reason,
    };
}


