import { Button, Menu, MenuItem, Paper } from '@mui/material';
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExportButton,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  TabBar,
  tableIcons,
} from 'kvinta/components';
import { TRADE_ITEM_STORE_ID, TradeItemsStore } from 'kvinta/modules/master-data/trade-items/TradeItemsStore';
import { CreateTradeItemDialog } from 'kvinta/modules/master-data/trade-items/CreateTradeItemDialog';
import { KvintaTradeItem } from 'kvinta/apis/kvinta-masterdata';
import { tabsSummary } from './tabs';
import { CSVDownload } from 'react-csv';
import { ProductsStore, PRODUCT_STORE_ID } from './ProductsStore';
import { reaction } from 'mobx';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ITableActionsState } from 'kvinta/common';
import { getInternalId } from 'kvinta/apis/utils';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Products.module.scss';
import { defineSorting } from '../../../common/utils';

interface ProductTradeItemsViewProps {
  tradeItemsStore?: TradeItemsStore;
  intl: any;
  historyStore: HistoryStore;
  id: string;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  productsStore?: ProductsStore;
}

class ProductTradeItemsViewImpl extends Component<ProductTradeItemsViewProps, ITableActionsState> {
  tableRef: React.RefObject<unknown>;
  disposer: any;

  constructor(props: ProductTradeItemsViewProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.fetchProductTradeItemList(this.props.id);
    this.disposer = reaction(
      () => this.props.productsStore.currentProduct,
      (comp) => {
        if (comp && comp !== null) {
          this.props.sidebarStore.updateMainTitle(comp.name, () => {
            this.props.historyStore.navigateProductsPath();
          });
        }
      },
    );
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const intl = this.props.intl;
    const {
      listData,
      isLoading,
      page,
      currentProduct,
      totalCount,
      pageSize,
      tradeItemFormOpen,
      exportActive,
      exportData,
      onChangeTradeItemField,
      onBlurTradeItemField,
      closeTradeItemForm,
      tradeItemFormData,
      submitCreateTradeItemForm,
    } = this.props.tradeItemsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault - 50;

    this.props.tradeItemsStore.updateExported();

    return (
      <div className={styles.mainContainer}>
        <TabBar
          onChange={(newValue: any) => {
            if (newValue === '0') {
              this.props.historyStore.navigateProductsPath();
            }
            if (newValue === '1') {
              this.props.historyStore.navigateProductPath(this.props.id);
            }
          }}
          tabId={'2'}
          tabs={tabsSummary}
        />
        <div className={styles.productTradeItemListButtonContainer}>
          <ExportButton
            onExportAll={() => this.props.tradeItemsStore.exportAll()}
            onExportSelected={() => this.props.tradeItemsStore.exportSelected()}
            hasSelected={this.props.tradeItemsStore.checked > 0}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon color="secondary" />}
            onClick={(e) => this.props.tradeItemsStore.openCreateTradeItemForm(this.props.id)}
          >
            {intl.formatMessage({ id: 'button.create' })}
          </Button>
        </div>
        <div id="ProductTradeItemsID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            tableRef={this.tableRef}
            title={intl.formatMessage({ id: 'menu.trade-items' })}
            columns={[
              {
                title: intl.formatMessage({ id: 'trade-item-list.header.gtin' }),
                field: 'gtin',
                defaultSort: defineSorting(this.props.tradeItemsStore.currentSort, 'gtin'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'trade-item-list.header.uom' }),
                field: 'uom',
                defaultSort: defineSorting(this.props.tradeItemsStore.currentSort, 'uom'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.tradeItemsStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.tradeItemsStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateProductTradeItemPath(this.props.id, rowData.id);
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: (props) => null,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              const shouldDelete = confirm(
                'Do you want to delete ' + (this.state.rowData as KvintaTradeItem).gtin + '?',
              );
              if (shouldDelete) {
                this.props.tradeItemsStore.deleteTradeItem(
                  getInternalId((this.state.rowData as KvintaTradeItem).product),
                  this.state.rowData.uom,
                );
              }
              this.onMenuClose();
            }}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
        {tradeItemFormOpen && (
          <CreateTradeItemDialog
            navigateToProduct={() => this.props.historyStore.navigateProductPath(getInternalId(currentProduct))}
            onChange={onChangeTradeItemField}
            onBlur={onBlurTradeItemField}
            closeTradeItemForm={closeTradeItemForm}
            formData={tradeItemFormData}
            submitCreateTradeItemForm={submitCreateTradeItemForm}
          />
        )}
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </div>
    );
  }
}

export const ProductTradeItemList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    TRADE_ITEM_STORE_ID,
    PRODUCT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(ProductTradeItemsViewImpl)),
);
