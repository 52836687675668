import { Home } from 'kvinta/modules/main';
import React from 'react';
import { DeviceList } from 'kvinta/modules/configuration/devices/DeviceList';
import { DeviceAssignmentSummary } from 'kvinta/modules/configuration/devices/DeviceAssignmentSummary';
import { DeviceAssignmentList } from 'kvinta/modules/configuration/devices/DeviceAssignmentList';
import { DeviceSummary } from 'kvinta/modules/configuration/devices/DeviceSummary';
import { EpcMessageMap } from 'kvinta/modules/epcis/epcs/EpcMessageMap';
import { EpcisEventList } from "./kvinta/modules/epcis/events/EpcisEventList";
import { DeliveryNoteList } from "./kvinta/modules/documents/deliveries/DeliveryNoteList";
import { DeliveryNoteSummary } from "./kvinta/modules/documents/deliveries/DeliveryNoteSummary";
import { ProductList } from "./kvinta/modules/master-data/products/ProductList";
import { EPaths } from "./kvinta/common/HistoryStore";
import { EpcisEventSetList } from "./kvinta/modules/epcis/eventSets/EpcisEventSetList";
import { EpcisEventSetSummary } from "./kvinta/modules/epcis/eventSets/EpcisEventSetSummary";
import { EpcisMessageList } from "./kvinta/modules/epcis/messages/EpcisMessageList";
import { EpcisMessageSummary } from "./kvinta/modules/epcis/messages/EpcisMessageSummary";
import { DeliveryItemList } from "./kvinta/modules/documents/deliveries/DeliveryItemList";
import { DeliveryItemSummary } from "./kvinta/modules/documents/deliveries/DeliveryItemSummary";
import { TradeItemSummary } from "./kvinta/modules/master-data/trade-items/TradeItemSummary";
import { TradeItemList } from "./kvinta/modules/master-data/trade-items/TradeItemList";
import { ProductSummary } from "./kvinta/modules/master-data/products/ProductSummary";
import { ProductTradeItemSummary } from "./kvinta/modules/master-data/products/ProductTradeItemSummary";
import { ProductTradeItemList } from "./kvinta/modules/master-data/products/ProductTradeItemList";
import { LocationList } from "./kvinta/modules/master-data/locations/LocationList";
import { LocationMap } from "./kvinta/modules/master-data/locations/LocationMap";
import { LocationSummary } from "./kvinta/modules/master-data/locations/LocationSummary";
import { BusinessPartnerSummary } from "./kvinta/modules/master-data/businesspartners/BusinessPartnerSummary";
import { BusinessPartnerList } from "./kvinta/modules/master-data/businesspartners/BusinessPartnerList";
import { ViewEpcHierarchy } from "./kvinta/modules/epcis/epcs/ViewEpcHierarchy";
import { EpcMessageList } from "./kvinta/modules/epcis/epcs/EpcMessageList";
import { EpcMessageSummary } from "./kvinta/modules/epcis/epcs/EpcMessageSummary";
import { EpcisEventSummary } from "./kvinta/modules/epcis/events/EpcisEventSummary";
import { ViewEpcisEventJson } from "./kvinta/modules/epcis/events/ViewEpcisEventJson";
import { EpcisEventSetJson } from "./kvinta/modules/epcis/eventSets/EpcisEventSetJson";
import { EpcisEventEpcList } from "./kvinta/modules/epcis/events/EpcisEventEpcList";
import { NotificationStepList } from "./kvinta/modules/configuration/notifications/NotificationStepList";
import { AlarmsList } from "./kvinta/modules/configuration/alarms/AlarmsList";
import { NotificationsByGln13 } from "./kvinta/modules/configuration/notifications/NotificationsByGln13";

export const routes = [
  {
    path: EPaths.EpcisEventSetListPath,
    component: (props) => <EpcisEventSetList {...props} />,
  },
  {
    path: EPaths.EpcisEventSetSummaryPath,
    component: (props) => <EpcisEventSetSummary {...props} />,
  },
  {
    path: EPaths.EpcisEventSetJsonPath,
    component: (props) => <EpcisEventSetJson {...props} />,
  },
  {
    path: EPaths.EpcisMessageListPath,
    component: (props) => <EpcisMessageList {...props} />,
  },
  {
    path: EPaths.EpcisMessageSummaryPath,
    component: (props) => <EpcisMessageSummary {...props} />,
  },
  {
    path: EPaths.EpcMessagesPath,
    component: (props) => <EpcMessageList {...props} />,
  },
  {
    path: EPaths.EpcMessagePath,
    component: (props) => <EpcMessageSummary {...props} />,
  },
  {
    path: EPaths.EpcMessageHierarchyPath,
    component: (props) => <ViewEpcHierarchy {...props} />,
  },
  {
    path: EPaths.EpcMessageMapPath,
    component: (props) => <EpcMessageMap {...props} />,
  },
  {
    path: EPaths.EpcisEventsPath,
    component: (props) => <EpcisEventList {...props} />,
  },
  {
    path: EPaths.EpcisEventPath,
    component: (props) => <EpcisEventSummary {...props} />,
  },
  {
    path: EPaths.EpcisEventEpcListPath,
    component: (props) => <EpcisEventEpcList {...props} />,
  },
  {
    path: EPaths.EpcisEventJsonPath,
    component: (props) => <ViewEpcisEventJson {...props} />,
  },
  {
    path: EPaths.BusinessPartnersPath,
    component: (props) => <BusinessPartnerList {...props} />,
  },
  {
    path: EPaths.BusinessPartnerPath,
    component: (props) => <BusinessPartnerSummary {...props} />,
  },
  {
    path: EPaths.LocationsPath,
    component: (props) => <LocationList {...props} />,
  },
  {
    path: EPaths.LocationPath,
    component: (props) => <LocationSummary {...props} />,
  },
  {
    path: EPaths.LocationMapPath,
    component: (props) => <LocationMap {...props} />,
  },
  {
    path: EPaths.ProductsPath,
    component: (props) => <ProductList {...props} />,
  },
  {
    path: EPaths.ProductPath,
    component: (props) => <ProductSummary {...props} />,
  },
  {
    path: EPaths.ProductTradeItemsPath,
    component: (props) => <ProductTradeItemList {...props} />,
  },
  {
    path: EPaths.ProductTradeItemPath,
    component: (props) => <ProductTradeItemSummary {...props} />,
  },
  {
    path: EPaths.TradeItemsPath,
    component: (props) => <TradeItemList {...props} />,
  },
  {
    path: EPaths.TradeItemPath,
    component: (props) => <TradeItemSummary {...props} />,
  },
  {
    path: EPaths.DeliveryNotesPath,
    component: (props) => <DeliveryNoteList {...props} />,
  },
  {
    path: EPaths.DeliveryNotePath,
    component: (props) => <DeliveryNoteSummary {...props} />,
  },
  {
    path: EPaths.DeliveryNoteDeliveryItemsPath,
    component: (props) => <DeliveryItemList {...props} />,
  },
  {
    path: EPaths.DeliveryNoteDeliveryItemPath,
    component: (props) => <DeliveryItemSummary {...props} />,
  },
  {
    path: EPaths.MainPath,
    component: (props) => <Home {...props} />,
  },
  {
    path: EPaths.DevicesPath,
    component: (props) => <DeviceList {...props} />,
  },
  {
    path: EPaths.DeviceSummaryPath,
    component: (props) => <DeviceSummary {...props} />,
  },
  {
    path: EPaths.DeviceAssignmentsPath,
    component: (props) => <DeviceAssignmentList {...props} />,
  },
  {
    path: EPaths.DeviceAssignmentSummaryPath,
    component: (props) => <DeviceAssignmentSummary {...props} />,
  },
  {
    path: EPaths.NotificationListPath,
    component: (props) => <NotificationStepList {...props}/>
  },
  {
    path: EPaths.NotificationByGln13Path,
    component: (props) => <NotificationsByGln13 {...props}/>
  },
  {
    path: EPaths.AlarmsListPath,
    component: (props) => <AlarmsList {...props}/>
  },
  {
    path: EPaths.NotFoundPath,
    component: (props) => <Home {...props} />,
  },
];
