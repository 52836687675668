/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAlarmStatus,
    KvintaAlarmStatusFromJSON,
    KvintaAlarmStatusFromJSONTyped,
    KvintaAlarmStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDeliveryIdAndAlarmStatus
 */
export interface KvintaDeliveryIdAndAlarmStatus {
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryIdAndAlarmStatus
     */
    deliveryId: string;
    /**
     * 
     * @type {KvintaAlarmStatus}
     * @memberof KvintaDeliveryIdAndAlarmStatus
     */
    newStatus: KvintaAlarmStatus;
}

export function KvintaDeliveryIdAndAlarmStatusFromJSON(json: any): KvintaDeliveryIdAndAlarmStatus {
    return KvintaDeliveryIdAndAlarmStatusFromJSONTyped(json, false);
}

export function KvintaDeliveryIdAndAlarmStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryIdAndAlarmStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryId': json['deliveryId'],
        'newStatus': KvintaAlarmStatusFromJSON(json['newStatus']),
    };
}

export function KvintaDeliveryIdAndAlarmStatusToJSON(value?: KvintaDeliveryIdAndAlarmStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryId': value.deliveryId,
        'newStatus': KvintaAlarmStatusToJSON(value.newStatus),
    };
}


