/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    KvintaAlarmsConfig,
    KvintaAlarmsConfigFromJSON,
    KvintaAlarmsConfigToJSON,
    KvintaAutoCommissionConfig,
    KvintaAutoCommissionConfigFromJSON,
    KvintaAutoCommissionConfigToJSON,
    KvintaEpcisCaptureConfig,
    KvintaEpcisCaptureConfigFromJSON,
    KvintaEpcisCaptureConfigToJSON,
    KvintaEpcisOutboundConfig,
    KvintaEpcisOutboundConfigFromJSON,
    KvintaEpcisOutboundConfigToJSON,
    KvintaMobileConfig,
    KvintaMobileConfigFromJSON,
    KvintaMobileConfigToJSON,
    KvintaSftpGatewayConfig,
    KvintaSftpGatewayConfigFromJSON,
    KvintaSftpGatewayConfigToJSON,
    KvintaTemporalConfiguration,
    KvintaTemporalConfigurationFromJSON,
    KvintaTemporalConfigurationToJSON,
} from '../models';

export interface ReadAlarmsConfigRequest {
    body: object;
}

export interface ReadAutoCommissionSgtinConfigRequest {
    body: object;
}

export interface ReadAutoCommissionSsccConfigRequest {
    body: object;
}

export interface ReadEpcisCaptureConfigRequest {
    body: object;
}

export interface ReadMobileConfigRequest {
    body: object;
}

export interface ReadOutboundConfigRequest {
    body: object;
}

export interface ReadPortalConfigRequest {
    body: object;
}

export interface ReadSftpGatewayConfigRequest {
    body: object;
}

export interface ReadWorkflowsConfigRequest {
    body: object;
}

export interface WriteAlarmsConfigRequest {
    kvintaAlarmsConfig: KvintaAlarmsConfig;
}

export interface WriteAutoCommissionSgtinConfigRequest {
    kvintaAutoCommissionConfig: KvintaAutoCommissionConfig;
}

export interface WriteAutoCommissionSsccConfigRequest {
    kvintaAutoCommissionConfig: KvintaAutoCommissionConfig;
}

export interface WriteEpcisCaptureConfigRequest {
    kvintaEpcisCaptureConfig: KvintaEpcisCaptureConfig;
}

export interface WriteMobileConfigRequest {
    kvintaMobileConfig: KvintaMobileConfig;
}

export interface WriteOutboundConfigRequest {
    kvintaEpcisOutboundConfig: KvintaEpcisOutboundConfig;
}

export interface WritePortalConfigRequest {
    requestBody: { [key: string]: object; };
}

export interface WriteSftpGatewayConfigRequest {
    kvintaSftpGatewayConfig: KvintaSftpGatewayConfig;
}

export interface WriteWorkflowsConfigRequest {
    kvintaTemporalConfiguration: KvintaTemporalConfiguration;
}

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     */
    async readAlarmsConfigRaw(requestParameters: ReadAlarmsConfigRequest): Promise<runtime.ApiResponse<KvintaAlarmsConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readAlarmsConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readAlarmsConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAlarmsConfigFromJSON(jsonValue));
    }

    /**
     */
    async readAlarmsConfig(requestParameters: ReadAlarmsConfigRequest): Promise<KvintaAlarmsConfig> {
        const response = await this.readAlarmsConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readAutoCommissionSgtinConfigRaw(requestParameters: ReadAutoCommissionSgtinConfigRequest): Promise<runtime.ApiResponse<KvintaAutoCommissionConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readAutoCommissionSgtinConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readAutoCommissionSgtinConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAutoCommissionConfigFromJSON(jsonValue));
    }

    /**
     */
    async readAutoCommissionSgtinConfig(requestParameters: ReadAutoCommissionSgtinConfigRequest): Promise<KvintaAutoCommissionConfig> {
        const response = await this.readAutoCommissionSgtinConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readAutoCommissionSsccConfigRaw(requestParameters: ReadAutoCommissionSsccConfigRequest): Promise<runtime.ApiResponse<KvintaAutoCommissionConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readAutoCommissionSsccConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readAutoCommissionSsccConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAutoCommissionConfigFromJSON(jsonValue));
    }

    /**
     */
    async readAutoCommissionSsccConfig(requestParameters: ReadAutoCommissionSsccConfigRequest): Promise<KvintaAutoCommissionConfig> {
        const response = await this.readAutoCommissionSsccConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readEpcisCaptureConfigRaw(requestParameters: ReadEpcisCaptureConfigRequest): Promise<runtime.ApiResponse<KvintaEpcisCaptureConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readEpcisCaptureConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readEpcisCaptureConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaEpcisCaptureConfigFromJSON(jsonValue));
    }

    /**
     */
    async readEpcisCaptureConfig(requestParameters: ReadEpcisCaptureConfigRequest): Promise<KvintaEpcisCaptureConfig> {
        const response = await this.readEpcisCaptureConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readMobileConfigRaw(requestParameters: ReadMobileConfigRequest): Promise<runtime.ApiResponse<KvintaMobileConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readMobileConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readMobileConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMobileConfigFromJSON(jsonValue));
    }

    /**
     */
    async readMobileConfig(requestParameters: ReadMobileConfigRequest): Promise<KvintaMobileConfig> {
        const response = await this.readMobileConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readOutboundConfigRaw(requestParameters: ReadOutboundConfigRequest): Promise<runtime.ApiResponse<KvintaEpcisOutboundConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readOutboundConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readOutboundConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaEpcisOutboundConfigFromJSON(jsonValue));
    }

    /**
     */
    async readOutboundConfig(requestParameters: ReadOutboundConfigRequest): Promise<KvintaEpcisOutboundConfig> {
        const response = await this.readOutboundConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readPortalConfigRaw(requestParameters: ReadPortalConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: object; }>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readPortalConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readPortalConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async readPortalConfig(requestParameters: ReadPortalConfigRequest): Promise<{ [key: string]: object; }> {
        const response = await this.readPortalConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reads persisted SftpGatewayConfig from Postgres. If there is no stored value - throws
     * Reads persisted SftpGatewayConfig from Postgres. If there is no stored value - throws
     */
    async readSftpGatewayConfigRaw(requestParameters: ReadSftpGatewayConfigRequest): Promise<runtime.ApiResponse<KvintaSftpGatewayConfig>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readSftpGatewayConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readSftpGatewayConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaSftpGatewayConfigFromJSON(jsonValue));
    }

    /**
     * Reads persisted SftpGatewayConfig from Postgres. If there is no stored value - throws
     * Reads persisted SftpGatewayConfig from Postgres. If there is no stored value - throws
     */
    async readSftpGatewayConfig(requestParameters: ReadSftpGatewayConfigRequest): Promise<KvintaSftpGatewayConfig> {
        const response = await this.readSftpGatewayConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async readWorkflowsConfigRaw(requestParameters: ReadWorkflowsConfigRequest): Promise<runtime.ApiResponse<KvintaTemporalConfiguration>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling readWorkflowsConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/readWorkflowsConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaTemporalConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async readWorkflowsConfig(requestParameters: ReadWorkflowsConfigRequest): Promise<KvintaTemporalConfiguration> {
        const response = await this.readWorkflowsConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeAlarmsConfigRaw(requestParameters: WriteAlarmsConfigRequest): Promise<runtime.ApiResponse<KvintaAlarmsConfig>> {
        if (requestParameters.kvintaAlarmsConfig === null || requestParameters.kvintaAlarmsConfig === undefined) {
            throw new runtime.RequiredError('kvintaAlarmsConfig','Required parameter requestParameters.kvintaAlarmsConfig was null or undefined when calling writeAlarmsConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeAlarmsConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaAlarmsConfigToJSON(requestParameters.kvintaAlarmsConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAlarmsConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeAlarmsConfig(requestParameters: WriteAlarmsConfigRequest): Promise<KvintaAlarmsConfig> {
        const response = await this.writeAlarmsConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeAutoCommissionSgtinConfigRaw(requestParameters: WriteAutoCommissionSgtinConfigRequest): Promise<runtime.ApiResponse<KvintaAutoCommissionConfig>> {
        if (requestParameters.kvintaAutoCommissionConfig === null || requestParameters.kvintaAutoCommissionConfig === undefined) {
            throw new runtime.RequiredError('kvintaAutoCommissionConfig','Required parameter requestParameters.kvintaAutoCommissionConfig was null or undefined when calling writeAutoCommissionSgtinConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeAutoCommissionSgtinConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaAutoCommissionConfigToJSON(requestParameters.kvintaAutoCommissionConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAutoCommissionConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeAutoCommissionSgtinConfig(requestParameters: WriteAutoCommissionSgtinConfigRequest): Promise<KvintaAutoCommissionConfig> {
        const response = await this.writeAutoCommissionSgtinConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeAutoCommissionSsccConfigRaw(requestParameters: WriteAutoCommissionSsccConfigRequest): Promise<runtime.ApiResponse<KvintaAutoCommissionConfig>> {
        if (requestParameters.kvintaAutoCommissionConfig === null || requestParameters.kvintaAutoCommissionConfig === undefined) {
            throw new runtime.RequiredError('kvintaAutoCommissionConfig','Required parameter requestParameters.kvintaAutoCommissionConfig was null or undefined when calling writeAutoCommissionSsccConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeAutoCommissionSsccConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaAutoCommissionConfigToJSON(requestParameters.kvintaAutoCommissionConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaAutoCommissionConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeAutoCommissionSsccConfig(requestParameters: WriteAutoCommissionSsccConfigRequest): Promise<KvintaAutoCommissionConfig> {
        const response = await this.writeAutoCommissionSsccConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeEpcisCaptureConfigRaw(requestParameters: WriteEpcisCaptureConfigRequest): Promise<runtime.ApiResponse<KvintaEpcisCaptureConfig>> {
        if (requestParameters.kvintaEpcisCaptureConfig === null || requestParameters.kvintaEpcisCaptureConfig === undefined) {
            throw new runtime.RequiredError('kvintaEpcisCaptureConfig','Required parameter requestParameters.kvintaEpcisCaptureConfig was null or undefined when calling writeEpcisCaptureConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeEpcisCaptureConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcisCaptureConfigToJSON(requestParameters.kvintaEpcisCaptureConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaEpcisCaptureConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeEpcisCaptureConfig(requestParameters: WriteEpcisCaptureConfigRequest): Promise<KvintaEpcisCaptureConfig> {
        const response = await this.writeEpcisCaptureConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeMobileConfigRaw(requestParameters: WriteMobileConfigRequest): Promise<runtime.ApiResponse<KvintaMobileConfig>> {
        if (requestParameters.kvintaMobileConfig === null || requestParameters.kvintaMobileConfig === undefined) {
            throw new runtime.RequiredError('kvintaMobileConfig','Required parameter requestParameters.kvintaMobileConfig was null or undefined when calling writeMobileConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeMobileConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaMobileConfigToJSON(requestParameters.kvintaMobileConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaMobileConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeMobileConfig(requestParameters: WriteMobileConfigRequest): Promise<KvintaMobileConfig> {
        const response = await this.writeMobileConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeOutboundConfigRaw(requestParameters: WriteOutboundConfigRequest): Promise<runtime.ApiResponse<KvintaEpcisOutboundConfig>> {
        if (requestParameters.kvintaEpcisOutboundConfig === null || requestParameters.kvintaEpcisOutboundConfig === undefined) {
            throw new runtime.RequiredError('kvintaEpcisOutboundConfig','Required parameter requestParameters.kvintaEpcisOutboundConfig was null or undefined when calling writeOutboundConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeOutboundConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaEpcisOutboundConfigToJSON(requestParameters.kvintaEpcisOutboundConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaEpcisOutboundConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeOutboundConfig(requestParameters: WriteOutboundConfigRequest): Promise<KvintaEpcisOutboundConfig> {
        const response = await this.writeOutboundConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writePortalConfigRaw(requestParameters: WritePortalConfigRequest): Promise<runtime.ApiResponse<{ [key: string]: object; }>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling writePortalConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writePortalConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async writePortalConfig(requestParameters: WritePortalConfigRequest): Promise<{ [key: string]: object; }> {
        const response = await this.writePortalConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeSftpGatewayConfigRaw(requestParameters: WriteSftpGatewayConfigRequest): Promise<runtime.ApiResponse<KvintaSftpGatewayConfig>> {
        if (requestParameters.kvintaSftpGatewayConfig === null || requestParameters.kvintaSftpGatewayConfig === undefined) {
            throw new runtime.RequiredError('kvintaSftpGatewayConfig','Required parameter requestParameters.kvintaSftpGatewayConfig was null or undefined when calling writeSftpGatewayConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeSftpGatewayConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaSftpGatewayConfigToJSON(requestParameters.kvintaSftpGatewayConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaSftpGatewayConfigFromJSON(jsonValue));
    }

    /**
     */
    async writeSftpGatewayConfig(requestParameters: WriteSftpGatewayConfigRequest): Promise<KvintaSftpGatewayConfig> {
        const response = await this.writeSftpGatewayConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async writeWorkflowsConfigRaw(requestParameters: WriteWorkflowsConfigRequest): Promise<runtime.ApiResponse<KvintaTemporalConfiguration>> {
        if (requestParameters.kvintaTemporalConfiguration === null || requestParameters.kvintaTemporalConfiguration === undefined) {
            throw new runtime.RequiredError('kvintaTemporalConfiguration','Required parameter requestParameters.kvintaTemporalConfiguration was null or undefined when calling writeWorkflowsConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/writeWorkflowsConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KvintaTemporalConfigurationToJSON(requestParameters.kvintaTemporalConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KvintaTemporalConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async writeWorkflowsConfig(requestParameters: WriteWorkflowsConfigRequest): Promise<KvintaTemporalConfiguration> {
        const response = await this.writeWorkflowsConfigRaw(requestParameters);
        return await response.value();
    }

}
