/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaMutateResultDeliveryItemKey,
    KvintaMutateResultDeliveryItemKeyFromJSON,
    KvintaMutateResultDeliveryItemKeyFromJSONTyped,
    KvintaMutateResultDeliveryItemKeyToJSON,
    KvintaMutateResultDocumentKey,
    KvintaMutateResultDocumentKeyFromJSON,
    KvintaMutateResultDocumentKeyFromJSONTyped,
    KvintaMutateResultDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateDocumentsResult
 */
export interface KvintaMutateDocumentsResult {
    /**
     * 
     * @type {KvintaMutateResultDocumentKey}
     * @memberof KvintaMutateDocumentsResult
     */
    delivery?: KvintaMutateResultDocumentKey | null;
    /**
     * 
     * @type {KvintaMutateResultDeliveryItemKey}
     * @memberof KvintaMutateDocumentsResult
     */
    deliveryItem?: KvintaMutateResultDeliveryItemKey | null;
}

export function KvintaMutateDocumentsResultFromJSON(json: any): KvintaMutateDocumentsResult {
    return KvintaMutateDocumentsResultFromJSONTyped(json, false);
}

export function KvintaMutateDocumentsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateDocumentsResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delivery': !exists(json, 'delivery') ? undefined : KvintaMutateResultDocumentKeyFromJSON(json['delivery']),
        'deliveryItem': !exists(json, 'deliveryItem') ? undefined : KvintaMutateResultDeliveryItemKeyFromJSON(json['deliveryItem']),
    };
}

export function KvintaMutateDocumentsResultToJSON(value?: KvintaMutateDocumentsResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery': KvintaMutateResultDocumentKeyToJSON(value.delivery),
        'deliveryItem': KvintaMutateResultDeliveryItemKeyToJSON(value.deliveryItem),
    };
}


