import React from 'react';
import { observer } from 'mobx-react';
import { TProductFormData } from './ProductsStore';
import { TextInput } from '../../../components/FormFields/TextInput_Exp';
import { SelectInput } from '../../../components/FormFields/SelectInput';
import styles from './Products.module.scss';

export interface ProductComponentProps {
  onChange: (id: string, value: any) => void;
  onBlur: (id: string) => void;
  formData: TProductFormData;
  isUpdateProduct?: boolean;
}

const ProductFormBase: React.FunctionComponent<ProductComponentProps> = ({
  isUpdateProduct = false,
  onChange,
  onBlur,
  formData,
}) => (
  <div className={styles.formContainer} id={'ProductFormID'}>
    <SelectInput
      {...formData['productForm.businessPartnerId']}
      onChange={onChange}
      onBlur={onBlur}
      label="product-form.field.businessPartner"
    />
    <TextInput {...formData['productForm.name']} onChange={onChange} onBlur={onBlur} label="product-form.field.name" />
    <TextInput
      {...formData['productForm.sourceSystem']}
      onChange={onChange}
      onBlur={onBlur}
      label="product-form.field.sourceSystem"
      disabled={isUpdateProduct}
    />
    <TextInput
      {...formData['productForm.idInSourceSystem']}
      onChange={onChange}
      onBlur={onBlur}
      label="product-form.field.idInSourceSystem"
      disabled={isUpdateProduct}
    />
    <TextInput {...formData['productForm.sku']} onChange={onChange} onBlur={onBlur} label="product-form.field.sku" />
    <TextInput
      {...formData['productForm.baseUom']}
      onChange={onChange}
      onBlur={onBlur}
      label="product-form.field.baseUom"
    />
    <SelectInput
      {...formData['productForm.serializationType']}
      onChange={onChange}
      onBlur={onBlur}
      label="product-form.field.serializationType"
    />
  </div>
);

export const ProductForm = observer(ProductFormBase);
