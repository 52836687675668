/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDuration,
    KvintaDurationFromJSON,
    KvintaDurationFromJSONTyped,
    KvintaDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaTemporalUnit
 */
export interface KvintaTemporalUnit {
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaTemporalUnit
     */
    duration?: KvintaDuration;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTemporalUnit
     */
    durationEstimated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTemporalUnit
     */
    dateBased?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaTemporalUnit
     */
    timeBased?: boolean;
}

export function KvintaTemporalUnitFromJSON(json: any): KvintaTemporalUnit {
    return KvintaTemporalUnitFromJSONTyped(json, false);
}

export function KvintaTemporalUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaTemporalUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': !exists(json, 'duration') ? undefined : KvintaDurationFromJSON(json['duration']),
        'durationEstimated': !exists(json, 'durationEstimated') ? undefined : json['durationEstimated'],
        'dateBased': !exists(json, 'dateBased') ? undefined : json['dateBased'],
        'timeBased': !exists(json, 'timeBased') ? undefined : json['timeBased'],
    };
}

export function KvintaTemporalUnitToJSON(value?: KvintaTemporalUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': KvintaDurationToJSON(value.duration),
        'durationEstimated': value.durationEstimated,
        'dateBased': value.dateBased,
        'timeBased': value.timeBased,
    };
}


