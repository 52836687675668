import { Link, Paper } from '@mui/material';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { EPCIS_EVENT_SET_STORE_ID, EpcisEventSetStore } from './EpcisEventSetStore';
import { FilterBar } from '../../../components/FilterBar/FilterBar';
import { defineSorting, generateLinkEpcisEventSetPath } from '../../../common/utils';
import styles from './EventSet.module.scss';

interface EpcisEventSetListProps {
  epcisEventSetStore?: EpcisEventSetStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
  intl: any;
  historyStore: HistoryStore;
}

class EpcisEventSetListBase extends Component<EpcisEventSetListProps> {
  tableRef: React.RefObject<unknown>;

  constructor(props: EpcisEventSetListProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcisEventSetStore.fetchEventSetList();
  }

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.epcisEventSetStore;
    const { height } = this.props.pageContentStore;
    if (!height || height === NaN || height === 0) {
      return null;
    }
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <div id="EpcisEventSetListID" className={styles.muiTableContainer}>
        <MaterialTable
          icons={tableIcons}
          tableRef={this.tableRef}
          columns={[
            {
              title: intl.formatMessage({ id: 'epcis-document.record-date' }),
              field: 'recordDate',
              defaultSort: defineSorting(this.props.epcisEventSetStore.currentSort?.expressions[0], 'recordDate'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-document.createdate' }),
              field: 'createDate',
              defaultSort: defineSorting(this.props.epcisEventSetStore.currentSort?.expressions[0], 'createDate'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-document.location' }),
              field: 'location',
              defaultSort: defineSorting(this.props.epcisEventSetStore.currentSort?.expressions[0], 'location'),
            },
            {
              title: intl.formatMessage({ id: 'epcis-document.earliesteventdate' }),
              field: 'earliestEventDate',
              defaultSort: defineSorting(
                this.props.epcisEventSetStore.currentSort?.expressions[0],
                'earliestEventDate',
              ),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-document.latesteventdate' }),
              field: 'latestEventDate',
              defaultSort: defineSorting(this.props.epcisEventSetStore.currentSort?.expressions[0], 'latestEventDate'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-document.eventsnumber' }),
              field: 'eventNumber',
              defaultSort: defineSorting(this.props.epcisEventSetStore.currentSort?.expressions[0], 'eventsCount'),
              width: '5%',
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-document.id' }),
              field: 'id',
              defaultSort: defineSorting(this.props.epcisEventSetStore.currentSort?.expressions[0], 'id'),
              sorting: false,
              width: '25%',
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
              render: (data) => {
                return (
                  <Link href={generateLinkEpcisEventSetPath(data.id)} onClick={preventDefault}>
                    {data.id}
                  </Link>
                );
              },
            },
          ]}
          data={listData}
          page={page}
          totalCount={totalCount}
          onChangePage={(page: number) => {
            this.props.epcisEventSetStore.fetchPage(page);
          }}
          onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
            this.props.epcisEventSetStore.changeOrder(orderBy, orderDirection);
          }}
          isLoading={isLoading}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            sorting: true,
            draggable: false,
            search: false,
            pageSize: pageSize,
            pageSizeOptions: [pageSize],
            minBodyHeight: bodyHeight,
            maxBodyHeight: bodyHeight,
            showTitle: false,
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
          }}
          onRowClick={(event, rowData) => {
            this.props.historyStore.navigateEpcisEventSetSummaryPath(rowData.id);
          }}
          components={{
            Toolbar: (props) => (
              <div className={styles.toolbarContainer}>
                <FilterBar filter={filter} />
              </div>
            ),
          }}
        />
      </div>
    );
  }
}

export const EpcisEventSetList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    EPCIS_EVENT_SET_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(EpcisEventSetListBase)),
);
