import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from '../../../components';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import { ENotificationFilterList, TBizStepListByGln13 } from './NotificationStore';
import { ButtonGreen } from '../../../components/ButtonGreen';
import { EButtonState } from '../../../common/formUtils/types';
import { KDialogTitle } from '../../../components/KDialog';
import { IntlShape } from 'react-intl';
import { observer } from 'mobx-react';
import styles from './Notifications.module.scss';

type TBizStepListFormProps = {
  userIsAdmin: boolean;
  toggleBizStepList: (id: string, list: ENotificationFilterList) => void;
  bizStepListData: TBizStepListByGln13[];
  isLoading: boolean;
  intl: IntlShape;
};

type TBizStepFiltersFormProps = {
  intl: IntlShape;
  filterListFormData: { gln13: string; options: { id: string; gln13: string; name: string }[] };
  bizStepListData: TBizStepListByGln13[];
  toggleBizStepList: (id: string, list: ENotificationFilterList) => void;
  saveBizStepListDataButtonState: EButtonState;
  saveBizStepListData: (gln13: string) => void;
  closeFilterListForm: () => void;
  onChangeGln13: (gln13: string) => void;
};

const BizStepFiltersFormBase: React.FunctionComponent<TBizStepFiltersFormProps> = ({
  intl,
  filterListFormData,
  bizStepListData,
  toggleBizStepList,
  saveBizStepListDataButtonState,
  saveBizStepListData,
  closeFilterListForm,
  onChangeGln13,
}) => {
  return (
    <Dialog fullWidth={true} maxWidth="md" open={true} onClose={closeFilterListForm}>
      <KDialogTitle id="customized-dialog-title" onClose={closeFilterListForm}>
        {intl.formatMessage({ id: 'device-create-form.create' })}
      </KDialogTitle>
      <DialogContent>
        <FormControl variant="outlined" fullWidth>
          <InputLabel style={{ backgroundColor: 'white' }} htmlFor={'gln13'}>
            {intl.formatMessage({ id: 'notification-list.gln13' })}
          </InputLabel>

          <Select id={'gln13'} value={filterListFormData.gln13} onChange={(e) => onChangeGln13(e.target.value)}>
            {filterListFormData.options.map(({ id, gln13, name }) => (
              <MenuItem key={id} value={gln13}>
                {`${name} |${gln13}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.formContainer}>
          <BizStepListForm
            userIsAdmin={true}
            bizStepListData={bizStepListData}
            toggleBizStepList={toggleBizStepList}
            intl={intl}
            isLoading={false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFilterListForm} color="primary" variant="outlined">
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <ButtonGreen
          label={intl.formatMessage({ id: 'button.submit' })}
          onClick={() => saveBizStepListData(filterListFormData.gln13)}
          buttonState={saveBizStepListDataButtonState}
          id={'openOrSubmitButton'}
        />
      </DialogActions>
    </Dialog>
  );
};

export const BizStepListForm: React.FunctionComponent<TBizStepListFormProps> = ({
  toggleBizStepList,
  intl,
  bizStepListData,
  userIsAdmin,
  isLoading,
}) => (
  <MaterialTable
    icons={tableIcons}
    style={{ overflow: 'auto' }}
    columns={[
      {
        title: intl.formatMessage({ id: 'notification-filter-list.step-name' }),
        field: 'id',
        cellStyle: {
          whiteSpace: 'nowrap',
          fontSize: null,
        },
      },
      {
        title: intl.formatMessage({ id: 'notification-filter-list.triggering' }),
        field: 'id',
        cellStyle: {
          whiteSpace: 'nowrap',
          fontSize: null,
        },
        render: (val) => (
          <Checkbox
            checked={val.list === ENotificationFilterList.TRIGGERING}
            onClick={
              userIsAdmin ? () => toggleBizStepList(val.id, ENotificationFilterList.TRIGGERING) : () => undefined
            }
          />
        ),
      },
      {
        title: intl.formatMessage({ id: 'notification-filter-list.blocked' }),
        field: 'id',
        cellStyle: {
          whiteSpace: 'nowrap',
          fontSize: null,
        },
        render: (val) => (
          <Checkbox
            checked={val.list === ENotificationFilterList.BLOCKED}
            onClick={userIsAdmin ? () => toggleBizStepList(val.id, ENotificationFilterList.BLOCKED) : () => undefined}
          />
        ),
      },
    ]}
    data={bizStepListData}
    isLoading={isLoading}
    options={{
      actionsColumnIndex: 0,
      paging: false,
      sorting: false,
      draggable: false,
      search: false,
      showTitle: false,
      thirdSortClick: false,
      emptyRowsWhenPaging: false,
    }}
    localization={{
      header: {
        actions: '',
      },
    }}
    onRowClick={() => null}
    components={{
      Toolbar: () => null,
      Container: (props) => <Paper id="BizStepListID" {...props} />,
    }}
  />
);

export const BizStepFiltersForm = observer(BizStepFiltersFormBase);
