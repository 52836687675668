/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaAutoCommissionSettings,
    KvintaAutoCommissionSettingsFromJSON,
    KvintaAutoCommissionSettingsFromJSONTyped,
    KvintaAutoCommissionSettingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaAutoCommissionConfig
 */
export interface KvintaAutoCommissionConfig {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof KvintaAutoCommissionConfig
     */
    conditions?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {KvintaAutoCommissionSettings}
     * @memberof KvintaAutoCommissionConfig
     */
    settings?: KvintaAutoCommissionSettings | null;
}

export function KvintaAutoCommissionConfigFromJSON(json: any): KvintaAutoCommissionConfig {
    return KvintaAutoCommissionConfigFromJSONTyped(json, false);
}

export function KvintaAutoCommissionConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaAutoCommissionConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditions': !exists(json, 'conditions') ? undefined : json['conditions'],
        'settings': !exists(json, 'settings') ? undefined : KvintaAutoCommissionSettingsFromJSON(json['settings']),
    };
}

export function KvintaAutoCommissionConfigToJSON(value?: KvintaAutoCommissionConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditions': value.conditions,
        'settings': KvintaAutoCommissionSettingsToJSON(value.settings),
    };
}


