/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaDeliveryItemKey
 */
export interface KvintaDeliveryItemKey {
    /**
     * 
     * @type {KvintaDocumentKey}
     * @memberof KvintaDeliveryItemKey
     */
    delivery: KvintaDocumentKey;
    /**
     * 
     * @type {string}
     * @memberof KvintaDeliveryItemKey
     */
    itemNumber: string;
}

export function KvintaDeliveryItemKeyFromJSON(json: any): KvintaDeliveryItemKey {
    return KvintaDeliveryItemKeyFromJSONTyped(json, false);
}

export function KvintaDeliveryItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaDeliveryItemKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delivery': KvintaDocumentKeyFromJSON(json['delivery']),
        'itemNumber': json['itemNumber'],
    };
}

export function KvintaDeliveryItemKeyToJSON(value?: KvintaDeliveryItemKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery': KvintaDocumentKeyToJSON(value.delivery),
        'itemNumber': value.itemNumber,
    };
}


