/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaConsistencyCheckConfig,
    KvintaConsistencyCheckConfigFromJSON,
    KvintaConsistencyCheckConfigFromJSONTyped,
    KvintaConsistencyCheckConfigToJSON,
    KvintaEpcisDestinationDb,
    KvintaEpcisDestinationDbFromJSON,
    KvintaEpcisDestinationDbFromJSONTyped,
    KvintaEpcisDestinationDbToJSON,
    KvintaEpcisSourceDb,
    KvintaEpcisSourceDbFromJSON,
    KvintaEpcisSourceDbFromJSONTyped,
    KvintaEpcisSourceDbToJSON,
    KvintaQuantityCalculationConfig,
    KvintaQuantityCalculationConfigFromJSON,
    KvintaQuantityCalculationConfigFromJSONTyped,
    KvintaQuantityCalculationConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaEpcisCaptureConfig
 */
export interface KvintaEpcisCaptureConfig {
    /**
     * 
     * @type {KvintaEpcisDestinationDb}
     * @memberof KvintaEpcisCaptureConfig
     */
    epcisDestinationDb: KvintaEpcisDestinationDb;
    /**
     * 
     * @type {KvintaEpcisSourceDb}
     * @memberof KvintaEpcisCaptureConfig
     */
    epcisSourceDb: KvintaEpcisSourceDb;
    /**
     * 
     * @type {string}
     * @memberof KvintaEpcisCaptureConfig
     */
    captureEpcisDocumentWorkflowType?: string | null;
    /**
     * 
     * @type {KvintaQuantityCalculationConfig}
     * @memberof KvintaEpcisCaptureConfig
     */
    quantityCalculation?: KvintaQuantityCalculationConfig | null;
    /**
     * 
     * @type {KvintaConsistencyCheckConfig}
     * @memberof KvintaEpcisCaptureConfig
     */
    consistencyCheckConfig?: KvintaConsistencyCheckConfig | null;
}

export function KvintaEpcisCaptureConfigFromJSON(json: any): KvintaEpcisCaptureConfig {
    return KvintaEpcisCaptureConfigFromJSONTyped(json, false);
}

export function KvintaEpcisCaptureConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaEpcisCaptureConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epcisDestinationDb': KvintaEpcisDestinationDbFromJSON(json['epcisDestinationDb']),
        'epcisSourceDb': KvintaEpcisSourceDbFromJSON(json['epcisSourceDb']),
        'captureEpcisDocumentWorkflowType': !exists(json, 'captureEpcisDocumentWorkflowType') ? undefined : json['captureEpcisDocumentWorkflowType'],
        'quantityCalculation': !exists(json, 'quantityCalculation') ? undefined : KvintaQuantityCalculationConfigFromJSON(json['quantityCalculation']),
        'consistencyCheckConfig': !exists(json, 'consistencyCheckConfig') ? undefined : KvintaConsistencyCheckConfigFromJSON(json['consistencyCheckConfig']),
    };
}

export function KvintaEpcisCaptureConfigToJSON(value?: KvintaEpcisCaptureConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epcisDestinationDb': KvintaEpcisDestinationDbToJSON(value.epcisDestinationDb),
        'epcisSourceDb': KvintaEpcisSourceDbToJSON(value.epcisSourceDb),
        'captureEpcisDocumentWorkflowType': value.captureEpcisDocumentWorkflowType,
        'quantityCalculation': KvintaQuantityCalculationConfigToJSON(value.quantityCalculation),
        'consistencyCheckConfig': KvintaConsistencyCheckConfigToJSON(value.consistencyCheckConfig),
    };
}


