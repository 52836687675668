import React from 'react';
import { Alert, AlertProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

function CustomAlert(props: AlertProps) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function DynamicAlert(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CustomAlert severity={props.severity}>{props.msg}</CustomAlert>
    </div>
  );
}
