import * as React from 'react';
import { HierarchyView } from './HierarchyView';
import { Timeline } from 'kvinta/components';
import { observer } from 'mobx-react';
import { EpcMessagesStore } from '../EpcMessagesStore';
import { HierarchyHistoryStore } from './HierarchyHistoryStore';
import { HistoryStore } from '../../../../common/HistoryStore';

export type THierarchyHistoryData = {
  hierarchyHistoryStore: HierarchyHistoryStore;
  epcMessagesStore: EpcMessagesStore;
  historyStore: HistoryStore;
  width: number;
  height: number;
  updateActiveTimelinePoint: (id: string) => void;
};

const HierarchyComp: React.FunctionComponent<THierarchyHistoryData> = (props: THierarchyHistoryData) => {
  const containerRef = React.createRef<HTMLDivElement>();

  return (
    <div className="wrapper">
      <Timeline
        data={props.hierarchyHistoryStore.timelinePoints}
        activeTimelinePointId={props.hierarchyHistoryStore.activeTimelinePointId}
        updateActiveTimelinePoint={props.hierarchyHistoryStore.updateActiveTimelinePoint}
      />
      <div className="hierarchy-container" ref={containerRef}>
        <HierarchyView
          historyStore={props.historyStore}
          data={props.hierarchyHistoryStore.currentHierarchyHistory}
          epcMessagesStore={props.epcMessagesStore}
          height={props.height}
          width={props.width}
          historyHierarchyStore={props.hierarchyHistoryStore}
        />
      </div>
    </div>
  );
};

export const Hierarchy = observer(HierarchyComp);
