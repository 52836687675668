/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaCaptureEpcisBody,
    KvintaCaptureEpcisBodyFromJSON,
    KvintaCaptureEpcisBodyFromJSONTyped,
    KvintaCaptureEpcisBodyToJSON,
    KvintaEpcisHeader,
    KvintaEpcisHeaderFromJSON,
    KvintaEpcisHeaderFromJSONTyped,
    KvintaEpcisHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaCaptureEpcisDocumentRequest
 */
export interface KvintaCaptureEpcisDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {KvintaCaptureEpcisBody}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    epcisBody: KvintaCaptureEpcisBody;
    /**
     * 
     * @type {KvintaEpcisHeader}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    epcisHeader?: KvintaEpcisHeader | null;
    /**
     * 
     * @type {number}
     * @memberof KvintaCaptureEpcisDocumentRequest
     */
    schemaVersion: number;
}

export function KvintaCaptureEpcisDocumentRequestFromJSON(json: any): KvintaCaptureEpcisDocumentRequest {
    return KvintaCaptureEpcisDocumentRequestFromJSONTyped(json, false);
}

export function KvintaCaptureEpcisDocumentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaCaptureEpcisDocumentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'epcisBody': KvintaCaptureEpcisBodyFromJSON(json['epcisBody']),
        'epcisHeader': !exists(json, 'epcisHeader') ? undefined : KvintaEpcisHeaderFromJSON(json['epcisHeader']),
        'schemaVersion': json['schemaVersion'],
    };
}

export function KvintaCaptureEpcisDocumentRequestToJSON(value?: KvintaCaptureEpcisDocumentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'epcisBody': KvintaCaptureEpcisBodyToJSON(value.epcisBody),
        'epcisHeader': KvintaEpcisHeaderToJSON(value.epcisHeader),
        'schemaVersion': value.schemaVersion,
    };
}


