import { EPaths } from './HistoryStore';
import { KvintaSortExpression } from '../apis/kvinta-masterdata';
import { format } from 'date-fns';
import {
  KvintaSortDirection,
  KvintaSortExpression as KvintaSortExpressionDevicesStore,
} from '../apis/kvinta-devices-store';

function escapeId(id: string) {
  // console.log('Escape', id);
  return btoa(id);
}
export function unescapeId(id: string) {
  // console.log('Unescape', id);
  return atob(id);
  // return id.replaceAll('__', '\\').replaceAll('_-_', '%');
}

export function generateEpcRecordPath(path: string, id: string, ts: number, idx?: number) {
  return path
    .replaceAll(':id', escapeId(id))
    .replaceAll(':ts', ts.toString())
    .replaceAll(':index', idx ? idx.toString() : '0');
}

export function generateLinkEpcisEventSetPath(msgId: string): string {
  return EPaths.EpcisEventSetSummaryPath.replaceAll(':id', msgId);
}

export function generateLinkEpcMessagePath(msgId: string, ts: number, idx?: number): string {
  return generateEpcRecordPath(EPaths.EpcMessagePath, msgId, ts, idx);
}

export function generateLinkEpcisEventPath(msgId: string): string {
  return EPaths.EpcisEventPath.replaceAll(':id', msgId);
}

export function unique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}

export function getAppNames(apps) {
  return apps != null && apps != undefined
    ? apps
        .map((app) => {
          return app.applicationName;
        })
        .filter(unique)
    : [];
}

export function getAppVersions(apps) {
  return apps != null && apps != undefined
    ? apps
        .map((app) => {
          return app.applicationVersion;
        })
        .filter(unique)
    : [];
}

export function translateCountries(countries: { shortCode: string; countryName: string }[]) {
  return countries.map((country) => ({
    key: country.shortCode,
    label: country.countryName,
  }));
}

export function defineSorting(currentSort: KvintaSortExpression | KvintaSortExpressionDevicesStore, sortField: string) {
  if (currentSort?.property === sortField) {
    return currentSort.direction === KvintaSortDirection.Asc ? 'asc' : 'desc';
  } else {
    return null;
  }
}

export function timeFormatting(date: Date | number) {
  return format(date, 'yyyy-MM-dd, HH:mm:ss');
}
