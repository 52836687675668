/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KvintaActivityCancellationType {
    WaitCancellationCompleted = 'WAIT_CANCELLATION_COMPLETED',
    TryCancel = 'TRY_CANCEL',
    Abandon = 'ABANDON'
}

export function KvintaActivityCancellationTypeFromJSON(json: any): KvintaActivityCancellationType {
    return KvintaActivityCancellationTypeFromJSONTyped(json, false);
}

export function KvintaActivityCancellationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaActivityCancellationType {
    return json as KvintaActivityCancellationType;
}

export function KvintaActivityCancellationTypeToJSON(value?: KvintaActivityCancellationType | null): any {
    return value as any;
}

