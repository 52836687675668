export const tabs = [
  {
    label: 'common.summary',
    value: '1',
  },
  {
    label: 'epcis-event.tab.epc-list',
    value: '2',
  },
  {
    label: 'common.json',
    value: '3',
  },
];
