/* tslint:disable */
/* eslint-disable */
/**
 * Kvinta Epcis-Capture Workflows
 * Swagger for Epcis-Capture Workflows
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaActivityCancellationType,
    KvintaActivityCancellationTypeFromJSON,
    KvintaActivityCancellationTypeFromJSONTyped,
    KvintaActivityCancellationTypeToJSON,
    KvintaDuration,
    KvintaDurationFromJSON,
    KvintaDurationFromJSONTyped,
    KvintaDurationToJSON,
    KvintaRetryConfiguration,
    KvintaRetryConfigurationFromJSON,
    KvintaRetryConfigurationFromJSONTyped,
    KvintaRetryConfigurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaActivityConfiguration
 */
export interface KvintaActivityConfiguration {
    /**
     * 
     * @type {KvintaActivityCancellationType}
     * @memberof KvintaActivityConfiguration
     */
    cancellationType?: KvintaActivityCancellationType | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaActivityConfiguration
     */
    heartbeatTimeout?: KvintaDuration | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaActivityConfiguration
     */
    scheduleToCloseTimeout?: KvintaDuration | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaActivityConfiguration
     */
    scheduleToStartTimeout?: KvintaDuration | null;
    /**
     * 
     * @type {KvintaDuration}
     * @memberof KvintaActivityConfiguration
     */
    startToCloseTimeout?: KvintaDuration | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaActivityConfiguration
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KvintaActivityConfiguration
     */
    taskQueue?: string | null;
    /**
     * 
     * @type {KvintaRetryConfiguration}
     * @memberof KvintaActivityConfiguration
     */
    retryOptions?: KvintaRetryConfiguration | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaActivityConfiguration
     */
    disabled: boolean;
}

export function KvintaActivityConfigurationFromJSON(json: any): KvintaActivityConfiguration {
    return KvintaActivityConfigurationFromJSONTyped(json, false);
}

export function KvintaActivityConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaActivityConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancellationType': !exists(json, 'cancellationType') ? undefined : KvintaActivityCancellationTypeFromJSON(json['cancellationType']),
        'heartbeatTimeout': !exists(json, 'heartbeatTimeout') ? undefined : KvintaDurationFromJSON(json['heartbeatTimeout']),
        'scheduleToCloseTimeout': !exists(json, 'scheduleToCloseTimeout') ? undefined : KvintaDurationFromJSON(json['scheduleToCloseTimeout']),
        'scheduleToStartTimeout': !exists(json, 'scheduleToStartTimeout') ? undefined : KvintaDurationFromJSON(json['scheduleToStartTimeout']),
        'startToCloseTimeout': !exists(json, 'startToCloseTimeout') ? undefined : KvintaDurationFromJSON(json['startToCloseTimeout']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'taskQueue': !exists(json, 'taskQueue') ? undefined : json['taskQueue'],
        'retryOptions': !exists(json, 'retryOptions') ? undefined : KvintaRetryConfigurationFromJSON(json['retryOptions']),
        'disabled': json['disabled'],
    };
}

export function KvintaActivityConfigurationToJSON(value?: KvintaActivityConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancellationType': KvintaActivityCancellationTypeToJSON(value.cancellationType),
        'heartbeatTimeout': KvintaDurationToJSON(value.heartbeatTimeout),
        'scheduleToCloseTimeout': KvintaDurationToJSON(value.scheduleToCloseTimeout),
        'scheduleToStartTimeout': KvintaDurationToJSON(value.scheduleToStartTimeout),
        'startToCloseTimeout': KvintaDurationToJSON(value.startToCloseTimeout),
        'name': value.name,
        'taskQueue': value.taskQueue,
        'retryOptions': KvintaRetryConfigurationToJSON(value.retryOptions),
        'disabled': value.disabled,
    };
}


