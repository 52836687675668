/* tslint:disable */
/* eslint-disable */
/**
 * Masterdata
 * Swagger Masterdata
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KvintaDelivery,
    KvintaDeliveryFromJSON,
    KvintaDeliveryFromJSONTyped,
    KvintaDeliveryToJSON,
    KvintaDocumentKey,
    KvintaDocumentKeyFromJSON,
    KvintaDocumentKeyFromJSONTyped,
    KvintaDocumentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface KvintaMutateRequestDeliveryDocumentKey
 */
export interface KvintaMutateRequestDeliveryDocumentKey {
    /**
     * 
     * @type {Array<KvintaDelivery>}
     * @memberof KvintaMutateRequestDeliveryDocumentKey
     */
    upsert?: Array<KvintaDelivery> | null;
    /**
     * 
     * @type {Array<KvintaDocumentKey>}
     * @memberof KvintaMutateRequestDeliveryDocumentKey
     */
    _delete?: Array<KvintaDocumentKey> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestDeliveryDocumentKey
     */
    enableBatch?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof KvintaMutateRequestDeliveryDocumentKey
     */
    createOnly?: boolean | null;
}

export function KvintaMutateRequestDeliveryDocumentKeyFromJSON(json: any): KvintaMutateRequestDeliveryDocumentKey {
    return KvintaMutateRequestDeliveryDocumentKeyFromJSONTyped(json, false);
}

export function KvintaMutateRequestDeliveryDocumentKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KvintaMutateRequestDeliveryDocumentKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upsert': !exists(json, 'upsert') ? undefined : (json['upsert'] === null ? null : (json['upsert'] as Array<any>).map(KvintaDeliveryFromJSON)),
        '_delete': !exists(json, 'delete') ? undefined : (json['delete'] === null ? null : (json['delete'] as Array<any>).map(KvintaDocumentKeyFromJSON)),
        'enableBatch': !exists(json, 'enableBatch') ? undefined : json['enableBatch'],
        'createOnly': !exists(json, 'createOnly') ? undefined : json['createOnly'],
    };
}

export function KvintaMutateRequestDeliveryDocumentKeyToJSON(value?: KvintaMutateRequestDeliveryDocumentKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upsert': value.upsert === undefined ? undefined : (value.upsert === null ? null : (value.upsert as Array<any>).map(KvintaDeliveryToJSON)),
        'delete': value._delete === undefined ? undefined : (value._delete === null ? null : (value._delete as Array<any>).map(KvintaDocumentKeyToJSON)),
        'enableBatch': value.enableBatch,
        'createOnly': value.createOnly,
    };
}


