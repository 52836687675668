import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { NOTIFICATION_STORE_ID, NotificationStore } from './NotificationStore';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import styles from './Notifications.module.scss';
import MaterialTable from 'material-table';
import { Button, Menu, MenuItem, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { BizStepFiltersForm } from './BizStepListForm';
import { EUserRole, USER_STORE_ID, UserStore } from '../../main';
import { ITableActionsState } from '../../../common';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface NotificationSummaryViewProps {
  intl: any;
  userStore: UserStore;
  notificationStore?: NotificationStore;
  pageContentStore?: PageContentStore;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  id: string;
  currentDevice?: any;
}

class NotificationSummaryBase extends Component<NotificationSummaryViewProps, ITableActionsState> {
  constructor(props: NotificationSummaryViewProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  async componentDidMount() {
    this.props.notificationStore.fetchNotificationList();
  }

  render() {
    const intl = this.props.intl;
    const {
      listData,
      isLoading,
      filterListFormData,
      bizStepListData,
      onChangeNotificationStepForm,
      bizStepListSubmitButtonState,
      saveChangesInBizStepList,
      closeFilterListForm,
      onChangeGln13,
      deleteNotificationSettingForGln13,
    } = this.props.notificationStore;
    const { canAccess } = this.props.userStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    return (
      <div className={styles.mainContainer}>
        {canAccess('Notification.create') && (
          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon color="secondary" />}
              onClick={(e) => {
                this.props.notificationStore.openFilterListForm();
              }}
            >
              {intl.formatMessage({ id: 'button.create' })}
            </Button>
          </div>
        )}
        <div id="NotificationListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              {
                title: intl.formatMessage({ id: 'notification-list.business-partner' }),
                field: 'name',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'notification-list.gln13' }),
                field: 'id',
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: false,
              sorting: false,
              draggable: false,
              search: false,
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              thirdSortClick: false,
              emptyRowsWhenPaging: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.props.historyStore.navigateNotificationByGln13Path(rowData.id);
              }
            }}
            actions={[
              canAccess('Notification.delete') && {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: () => null,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          {canAccess('Notification.delete') && (
            <MenuItem
              onClick={() => {
                const shouldDelete = confirm(
                  `Do you want to delete notification settings for gln13 ${this.state.rowData.id}?`,
                );
                if (shouldDelete) {
                  deleteNotificationSettingForGln13(this.state.rowData.id);
                }
                this.onMenuClose();
              }}
            >
              {intl.formatMessage({ id: 'button.delete' })}
            </MenuItem>
          )}
        </Menu>
        {this.props.notificationStore.filterListFormIsOpen && (
          <BizStepFiltersForm
            saveBizStepListData={saveChangesInBizStepList}
            saveBizStepListDataButtonState={bizStepListSubmitButtonState}
            filterListFormData={filterListFormData}
            bizStepListData={bizStepListData}
            toggleBizStepList={onChangeNotificationStepForm}
            closeFilterListForm={closeFilterListForm}
            onChangeGln13={onChangeGln13}
            intl={intl}
          />
        )}
      </div>
    );
  }
}

export const NotificationStepList = injectIntl(
  inject(
    USER_STORE_ID,
    HISTORY_STORE_ID,
    NOTIFICATION_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(NotificationSummaryBase)),
);
