import { Menu, MenuItem, Paper } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  ExportButton,
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SearchField,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { TRADE_ITEM_STORE_ID, TradeItemsStore } from './TradeItemsStore';
import { CSVDownload } from 'react-csv';
import { ITableActionsState } from 'kvinta/common';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import MaterialTable from 'material-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KvintaTradeItem } from '../../../apis/kvinta-masterdata';
import { getInternalId } from '../../../apis/utils';
import styles from './TradeItem.module.scss';
import { defineSorting } from '../../../common/utils';

interface TradeItemsViewProps {
  tradeItemsStore?: TradeItemsStore;
  intl: any;
  historyStore: HistoryStore;
  pageContentStore?: PageContentStore;
  sidebarStore?: KSidebarStore;
}

class TradeItemListBase extends Component<TradeItemsViewProps, ITableActionsState> {
  constructor(props: TradeItemsViewProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    };
  }

  handleActionsMenuOpen = (event: React.MouseEvent<HTMLElement>, rowData: any) => {
    this.setState({
      isMenuOpen: event.currentTarget !== null,
      anchorEl: event.currentTarget,
      rowData: rowData,
    });
  };

  onMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      anchorEl: null,
      rowData: null,
    });
  };

  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.tradeItemsStore.unsetProductId();
    this.props.tradeItemsStore.fetchTradeItemList();
  }

  navigateTradeItemSummary = (id: string) => {
    this.props.historyStore.navigateTradeItemPath(id);
  };

  render() {
    const intl = this.props.intl;
    const {
      exportActive,
      exportData,
      searchValue,
      autofocusSearchInList,
      listData,
      isLoading,
      page,
      totalCount,
      pageSize,
      checked,
    } = this.props.tradeItemsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;

    const selected =
      listData &&
      listData.reduce((acc, row) => {
        acc[row.id] = row.isSelected;
        return acc;
      }, {});

    this.props.tradeItemsStore.updateExported();
    return (
      <div className={styles.mainContainer}>
        <div className={styles.listHeader}>
          <SearchField
            intl={this.props.intl}
            searchValue={searchValue}
            onChange={(value: string) => {
              this.props.tradeItemsStore.updateSearch(value);
            }}
            autofocus={autofocusSearchInList}
          />
          <div className={styles.listButtonContainer}>
            <ExportButton
              onExportAll={() => this.props.tradeItemsStore.exportAll()}
              onExportSelected={() => this.props.tradeItemsStore.exportSelected()}
              hasSelected={this.props.tradeItemsStore.checked > 0}
            />
          </div>
        </div>
        <div id="TradeItemsListID" className={styles.muiTableContainer}>
          <MaterialTable
            icons={tableIcons}
            title={intl.formatMessage({ id: 'menu.trade-items' })}
            columns={[
              {
                title: intl.formatMessage({ id: 'trade-item-list.header.gtin' }),
                field: 'gtin',
                defaultSort: defineSorting(this.props.tradeItemsStore.currentSort, 'gtin'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
              {
                title: intl.formatMessage({ id: 'trade-item-list.header.uom' }),
                field: 'uom',
                defaultSort: defineSorting(this.props.tradeItemsStore.currentSort, 'uom'),
                cellStyle: {
                  whiteSpace: 'nowrap',
                  fontSize: null,
                },
              },
            ]}
            data={listData}
            page={page}
            totalCount={totalCount}
            onChangePage={(page: number) => {
              this.props.tradeItemsStore.fetchPage(page);
            }}
            onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
              this.props.tradeItemsStore.changeOrder(orderBy, orderDirection);
            }}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: 0,
              paging: true,
              sorting: true,
              draggable: false,
              search: false,
              pageSize: pageSize,
              pageSizeOptions: [pageSize],
              minBodyHeight: bodyHeight,
              maxBodyHeight: bodyHeight,
              showTitle: false,
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
            onRowClick={(event, rowData) => {
              if (!(event.target instanceof HTMLInputElement)) {
                this.navigateTradeItemSummary(rowData.id);
              }
            }}
            actions={[
              {
                icon: MoreVertIcon,
                tooltip: 'Actions',
                isFreeAction: false,
                onClick: (event, rowData) => {
                  this.handleActionsMenuOpen(event, rowData);
                },
              },
            ]}
            components={{
              Toolbar: (props) => <></>,
            }}
          />
        </div>
        <Menu
          id="row-action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.isMenuOpen}
          onClose={this.onMenuClose}
        >
          <MenuItem
            onClick={() => {
              const shouldDelete = confirm(
                'Do you want to delete ' + (this.state.rowData as KvintaTradeItem).gtin + '?',
              );
              if (shouldDelete) {
                this.props.tradeItemsStore.deleteTradeItem(
                  getInternalId((this.state.rowData as KvintaTradeItem).product),
                  (this.state.rowData as KvintaTradeItem).uom,
                );
              }
              this.onMenuClose();
            }}
          >
            {intl.formatMessage({ id: 'button.delete' })}
          </MenuItem>
        </Menu>
        {exportActive && exportData !== undefined && <CSVDownload data={exportData} target="_blank" />}
      </div>
    );
  }
}

export const TradeItemList = injectIntl(
  inject(HISTORY_STORE_ID, PAGE_CONTENT_STORE_ID, TRADE_ITEM_STORE_ID, SIDEBAR_STORE_ID)(observer(TradeItemListBase)),
);
