import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  KSidebarStore,
  PAGE_CONTENT_STORE_ID,
  PageContentStore,
  SIDEBAR_STORE_ID,
  tableIcons,
} from 'kvinta/components';
import { EPCIS_EVENTS_STORE_ID, EpcisEventsStore } from './EpcisEventsStore';
import { Link, Paper } from '@mui/material';
import { FilterBar } from '../../../components/FilterBar/FilterBar';
import { HistoryStore, STORE_ID as HISTORY_STORE_ID } from '../../../common/HistoryStore';
import { defineSorting, generateLinkEpcisEventPath } from '../../../common/utils';
import styles from './Event.module.scss';

interface ViewListEpcisEventsProps {
  epcisEventsStore?: EpcisEventsStore;
  pageContentStore?: PageContentStore;
  historyStore: HistoryStore;
  sidebarStore?: KSidebarStore;
  intl: any;
}

class EpcisEventsListBase extends Component<ViewListEpcisEventsProps> {
  componentDidMount() {
    this.props.sidebarStore.clearMainTitle();
    this.props.epcisEventsStore.fetchData();
  }

  navigateMessageSummary = (id: string) => {
    this.props.historyStore.navigateEpcisEventPath(id);
  };

  navigateMessageJson = (id: string) => {
    this.props.historyStore.navigateEpcisEventJsonPath(id);
  };

  render() {
    const intl = this.props.intl;
    const { listData, isLoading, page, totalCount, pageSize, filter } = this.props.epcisEventsStore;
    const bodyHeight = this.props.pageContentStore.tableContentHeightDefault;
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
      <div id="EpcisEventsListID" className={styles.muiTableContainer}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: intl.formatMessage({ id: 'epcis-event.eventtime' }),
              field: 'eventTimeExt',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'eventTime'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
              render: (data) => {
                return (
                  <Link href={generateLinkEpcisEventPath(data.id)} onClick={preventDefault}>
                    {data.eventTime.epochMillis ? new Date(data.eventTime.epochMillis).toISOString() : ''}
                  </Link>
                );
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.recordtime' }),
              field: 'recordTimeExt',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'recordTime'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.action' }),
              field: 'action',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'action'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.bizstep' }),
              field: 'bizStep',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'bizStep'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.readpoint' }),
              field: 'readPoint',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'readPoint'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.bizlocation' }),
              field: 'bizLocation',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'bizLocation'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.disposition' }),
              field: 'disposition',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'disposition'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.persistentdisposition' }),
              field: 'persistentDisposition',
              defaultSort: defineSorting(
                this.props.epcisEventsStore.currentSort?.expressions[0],
                'persistentDisposition',
              ),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.user-id' }),
              field: 'userId',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'userId'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.epc-count' }),
              field: 'epcCount',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'epcCount'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.biz-transaction' }),
              field: 'bizTransaction',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'bizTransactions'),
              sorting: false,
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
            {
              title: intl.formatMessage({ id: 'epcis-event.prod-batch' }),
              field: 'prodBatch',
              defaultSort: defineSorting(this.props.epcisEventsStore.currentSort?.expressions[0], 'prodBatch'),
              cellStyle: {
                whiteSpace: 'nowrap',
                fontSize: null,
              },
            },
          ]}
          data={listData}
          page={page}
          totalCount={totalCount}
          onChangePage={(page: number) => {
            this.props.epcisEventsStore.fetchPage(page);
          }}
          onOrderChange={(orderBy: number, orderDirection: 'asc' | 'desc') => {
            this.props.epcisEventsStore.changeOrder(orderBy, orderDirection);
          }}
          isLoading={isLoading}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            sorting: true,
            draggable: false,
            search: false,
            pageSize: pageSize,
            pageSizeOptions: [pageSize],
            minBodyHeight: bodyHeight,
            maxBodyHeight: bodyHeight,
            showTitle: false,
            thirdSortClick: false,
            emptyRowsWhenPaging: false,
          }}
          onRowClick={(event, rowData) => {
            this.navigateMessageSummary(rowData.id);
          }}
          components={{
            Toolbar: (props) => (
              <div className={styles.toolbarContainer}>
                <FilterBar filter={filter} />
              </div>
            ),
          }}
        />
      </div>
    );
  }
}

export const EpcisEventList = injectIntl(
  inject(
    HISTORY_STORE_ID,
    PAGE_CONTENT_STORE_ID,
    EPCIS_EVENTS_STORE_ID,
    SIDEBAR_STORE_ID,
  )(observer(EpcisEventsListBase)),
);
